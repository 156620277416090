/* eslint-disable */
import {
  borderedInputWhite,
  borderedInputWhiteWrap,
  boxContainer,
  label,
} from "../../../shared/styles";
import Props from "../../../store/props";

const CustomHtml: React.FC<Props> = ({ setGlobalState, globalState }) => {
  const { alternative_review_html } = globalState;

  return (
    <>
      <h2 className="absolute top-32 right-36">Custom HTML</h2>
      <div className={boxContainer}>
        <label className={label}>
          Alternate HTML
          <textarea
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalState({
                ...globalState,
                alternative_review_html: e.target.value,
              })
            }
            value={alternative_review_html}
            rows={3}
          />
        </label>
      </div>
    </>
  );
};

export default CustomHtml;
