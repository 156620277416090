/* eslint-disable */
import { useState } from "react";
import axios from "axios";
import Props from "../../../store/props";
import { useAtom } from "jotai";
import globalState from "../../../store";

interface GenerateProps {
  service: string;
  index: number;
  setReview: any;
}

const Generate: React.FC<GenerateProps> = ({ service, setReview, index }) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { testimonials, services, service_area_array } = globalStateAtom;

  const [loading, setLoading] = useState(false);

  const generate = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_SERVER_URL}/getRandomReview`
      );
      const randomReview = response.data;
      const newReviews = [...testimonials];
      newReviews[index] = {
        ...testimonials[index],
        ...randomReview,
        service:
          testimonials[index].service || services[0]["service-name"] || "",
        title: `${
          service_area_array &&
          service_area_array[0] &&
          service_area_array[0][0]
        }, ${
          service_area_array &&
          service_area_array[0] &&
          service_area_array[0][1]
        }`,
      };
      // console.log(newReviews[index], 'new review')
      setReview(newReviews[index]);
      setGlobalState({ ...globalStateAtom, testimonials: newReviews });
      setLoading(false);
    } catch (error) {
      alert("Error generating");
      setLoading(false);
    }
  };

  return (
    <div onClick={generate}>
      {loading ? (
        <img src="/loading.gif" width="20" alt="loading" />
      ) : (
        <p>Generate</p>
      )}
    </div>
  );
};

export default Generate;
