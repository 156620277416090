/* eslint-disable */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import axios from "axios";
import globalState from "../../../store";
import PhotoProps from "../types/photoProps";
import Props from "../../../store/props";
// import AddFaqButton from './components/AddFAQButton'

const PullMoreFacebookPhotosButton: React.FC<any> = ({
  setGlobalState,
  globalStateAtom,
  setSelectedPhotos,
  setLocalGalleryState,
}) => {
  // const {gallery_names, gallery, fbData} = globalState
  const { sitePk, fbData } = globalStateAtom;
  const [gallery, setGallery] = useState(globalStateAtom.gallery);
  const [loading, setLoading] = useState(false);
  const [currentFbPhotosCount, setCurrentFbPhotosCount] = useState(
    fbData && fbData.photos && fbData?.photos.length
  );
  const [currentFbPhotosPulledCount, setCurrentFbPhotosPulledCount] = useState(
    fbData && fbData.extraPhotos && fbData?.extraPhotos.length
  );

  const [timeCounter, setTimeCounter] = useState(180);
  const saveExtraFacebookPhotos = async (
    extraPhotos: any,
    facebook_slug: string
  ) => {
    try {
      const saveFbDataResponse = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/save_extra_facebook_photos`,
        {
          facebook_slug: facebook_slug,
          extra_photos: extraPhotos,
        }
      );
    } catch {
      console.log("error saving extra photos");
    }
  };
  const pullMorePhotos = async () => {
    // // console.log('PULLINGMIORE PHOTOS',fbData)
    setLoading(true);
    const intervalId = setInterval(
      () => setTimeCounter((prevCount) => prevCount - 1),
      1000
    );
    try {
      let { fbData } = globalStateAtom;
      // // console.log('fbData', fbData)
      // // console.log('bData.photos && fbData.photos.length == 0', fbData?.photos && fbData?.photos?.length == 0)
      let photos;
      // if({
      // hasMorePhotos = true
      // }
      // // console.log('test')
      if (
        !fbData ||
        (fbData?.photos && fbData?.photos?.length == 0) ||
        (fbData && fbData?.extraPhotos && fbData?.extraPhotos?.length === 0) ||
        (fbData &&
          fbData?.extraPhotos &&
          fbData?.extraPhotos?.length < fbData?.photos?.length) ||
        fbData === undefined ||
        (fbData &&
          fbData?.photos &&
          fbData?.photos?.length > 0 &&
          !("extraPhotos" in fbData))
      ) {
        // // console.log('PRE conditionalGET ALL')

        // We need to get the photos, then add 20 more
        let { fbSlug } = globalStateAtom;
        let photosResponse = {} as any;
        // // console.log('PRE conditionalGET ALL')
        if (fbSlug === undefined || fbSlug === "") {
          const { facebook } = globalStateAtom;
          fbSlug = facebook
            .replace("https://facebook.com/", "")
            .replace("http://facebook.com/", "")
            .replace("https://www.facebook.com/", "")
            .replace("http://www.facebook.com/", "")
            .replace("facebook.com/", "");
          // photosResponse = await axios.post(`${process.env.REACT_APP_NODE_SERVER_URL}/getMorePhotosEndpoint`, {fbSlug})
        }
        // // console.log('PRE GET ALL')
        if (fbData === undefined) {
          fbData = {};
        }
        console.log("FB SLUG", fbSlug);
        // // console.log('fbData pre get', fbData)
        // `http://localhost:3001/getAllFacebookPhotosEndpoint/TCPaintsMN
        photosResponse = await axios.get(
          `${process.env.REACT_APP_NODE_SERVER_URL}/getAllFacebookPhotosEndpoint/${fbSlug}`
        );
        // // console.log('photosResponse', photosResponse)
        photos = photosResponse.data;
        // // console.log('photos', photos)
        // // console.log('photos', photos)

        // save extra facebook photos
        console.log("photos prep save ", photos);
        // if (photos) await saveExtraFacebookPhotos(photos, fbSlug)

        fbData.extraPhotos = photos;
        setCurrentFbPhotosCount(fbData.extraPhotos.length);
        // // console.log('globalState', globalState)

        setGlobalState((prev) => {
          return { ...prev, fbData };
        });
      }
      // // console.log('test')

      // // console.log('aaafbData', fbData)
      // is comparing the totalPhotosCount to the currentFbPhotoCount the right logic here?
      const totalPhotosCount = fbData.extraPhotos?.length
        ? fbData.extraPhotos.length
        : 0;
      const currentFbPhotoCount = fbData.currentPulledPhotoCount
        ? fbData.currentPulledPhotoCount
        : 0;
      const hasMorePhotos = totalPhotosCount > currentFbPhotoCount;
      // // console.log('hasMorePhotos', hasMorePhotos)

      console.log("nofb data? hasMorePhotos", hasMorePhotos);

      if (hasMorePhotos) {
        // We already have extra photos, just add 20 more to gallery
        // // console.log('globalState', globalState)
        let photosToGrab; //= globalState.fbData && globalState.fbData.extraPhotos ? globalState.fbData.extraPhotos :
        if (globalStateAtom.fbData && globalStateAtom.fbData.extraPhotos) {
          photosToGrab = globalStateAtom.fbData.extraPhotos;
        } else if (photos !== undefined && photos && photos.length) {
          photosToGrab = photos;
        } else {
          photosToGrab = [];
        }
        console.log("photosToGrab", photosToGrab);

        const newGalleryAdditionsPreFormatted: any[] = photosToGrab.slice(
          currentFbPhotoCount,
          currentFbPhotoCount + 20
        );
        // const newGalleryAdditionsPreFormatted: any[] = globalState.fbData.extraPhotos.slice(currentFbPhotoCount,currentFbPhotoCount+20)
        // // console.log(
        // 'newGalleryAdditionsPreFormatted',
        // newGalleryAdditionsPreFormatted
        // )
        const indexesToMap = [];
        for (let i = 0; i < newGalleryAdditionsPreFormatted.length; i++) {
          indexesToMap.push(i);
        }
        indexesToMap.reverse();

        const newGalleryAdditions = newGalleryAdditionsPreFormatted.map(
          (fbPhoto: any, idx: number) => {
            // // console.log(idx)
            return {
              alt_tag: fbPhoto.fbImgNum, // 'idx',// TODO: change this
              archived: false,
              associated_service: "idx", // TODO: change this
              gallery: null,
              photo: fbPhoto.imgSrc,
              source: "facebook",
              largeImageHref: fbPhoto.largeImageHref,
              landing_page: sitePk,
              all_photos_order: idx,
              homepage_order: -1,
              gallery_order: -1,
              uncropped_gallery_photo: null,
              gallery_name: "",
              selected: false,
              saved: false,
            };
          }
        );
        fbData.currentPulledPhotoCount =
          currentFbPhotoCount + newGalleryAdditions.length;

        const finalGallery = [...gallery, ...newGalleryAdditions];
        const newIndexesToMap = [];
        for (let i = 0; i < finalGallery.length; i++) {
          newIndexesToMap.push(i);
        }
        newIndexesToMap.reverse();
        const finalFinalGallery = finalGallery.map((photo, idx) => {
          photo.order = newIndexesToMap[idx];
          return photo;
        });

        setLocalGalleryState([...finalFinalGallery]);
        setGlobalState({
          ...globalStateAtom,
          fbData,
          gallery: [...finalFinalGallery],
        });
        setGallery([...finalFinalGallery]);
        setCurrentFbPhotosCount(fbData.currentPulledPhotoCount);
        setCurrentFbPhotosPulledCount(fbData?.extraPhotos.length);
      } else {
        alert("No more photos to pull");
        setLoading(false);
        setTimeCounter(180);
        clearInterval(intervalId);
        return;
      }
    } catch (e: any) {
      // console.log('ERRRRRO',e)
      alert("Error pulling photos");
    }
    setLoading(false);
    setTimeCounter(180);
    clearInterval(intervalId);
  };
  return loading ? (
    <div className="w-96 m-auto my-8 flex">
      <img src="/loading.gif" width="20" alt="loading" />
      <p className="ml-3">Estimated time remaining: {timeCounter} seconds</p>
    </div>
  ) : (
    <div>
      <button
        onClick={(e) => {
          pullMorePhotos();
        }}
        className="px-2  m-3 rounded-full border-2 border-red-500 text-red-500 bg-white"
      >
        Pull More Photos - Cur: {currentFbPhotosCount} :{" "}
        {currentFbPhotosPulledCount}
      </button>
      <input
        value={globalStateAtom.fbSlug}
        onChange={(event) => {
          setGlobalState({
            ...globalStateAtom,
            fbSlug: event.target.value,
          });
        }}
      ></input>
    </div>
  );
};

export default PullMoreFacebookPhotosButton;
