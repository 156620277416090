/* eslint-disable */
// import { useAtom } from 'jotai'
// import globalState from '../../store'
import Logo from "./components/Logo";
import ColorScheme from "./components/ColorScheme";
import { pageContainer } from "../../shared/styles";
import LogoSize from "./components/LogoSize";
import Font from "./components/Font";
import LogoGenerator from "../logo_generator/LogoGenerator";

export default function LogoPage() {
  return (
    <div className={pageContainer}>
      <div className="flex flex-wrap">
        <Logo />
        <ColorScheme />
        <LogoSize />
        <Font />

        <LogoGenerator />
      </div>
    </div>
  );
}
