import axios from "axios";
import { planTypes } from "./constants";
import {
  FormattedStripePlansByTier,
  GetProphoneContactsResponse,
  GetStripeCouponsResponse,
  ICoupon,
  IStripePlan,
  PlanType,
  StripePlansByTier,
  StripePlansResponse,
} from "./types";

const filterStripePlansToDisplay = (plans: IStripePlan[]): IStripePlan[] => {
  /*
   * Filter out plans that are not active or not recurring.
   * Also filter out plans with the metadata key "winter" set to true.
   * Optionally, filter by a custom metadata key if the REACT_APP_FILTER_STRIPE_DATA_BY_METADATA_KEY env var is set.
   */
  return plans.filter((plan) => {
    if (!plan.active || plan.type !== "recurring") {
      return false;
    }

    const isWinterPlan = plan.metadata?.winter || false;
    if (isWinterPlan) {
      return false;
    }

    if (process.env?.REACT_APP_FILTER_STRIPE_DATA_BY_METADATA_KEY) {
      const metadataKey = process.env.REACT_APP_FILTER_STRIPE_DATA_BY_METADATA_KEY;
      return Boolean(plan.metadata[metadataKey]);
    }

    return true;
  });
};

const formatStripePlans = (plans: IStripePlan[]): string[] => {
  /*
   * Format the plans into a string array with the format:
   * [planType]-[interval]-[amount]
   * Example: ["starter-monthly-9.99", "core-annual-99.99"]
   */
  const formattedPlans: string[] = [];

  plans.forEach((p) => {
    const metadata = p?.metadata?.plan;

    let interval;
    if (p?.recurring?.interval === "month") {
      interval = "monthly";
    } else if (
      p?.recurring?.interval === "year" ||
      metadata?.includes("annual")
    ) {
      interval = "annual";
    } else {
      return;
    }

    const plan = p.planType;
    const amount = p.unit_amount / 100;
    const planText = `${plan}-${interval}-${amount}`;

    if (formattedPlans.includes(planText)) {
      return;
    }

    formattedPlans.push(planText);
  });

  return formattedPlans;
};

export const transformStripePlans = (
  stripePlans: StripePlansResponse
): FormattedStripePlansByTier[] => {
  const tierMap: { [tier: string]: IStripePlan[] } = {};

  planTypes.forEach((planType: PlanType) => {
    const plans = stripePlans[planType];
    filterStripePlansToDisplay(plans).forEach((plan) => {
      // Attach planType to each plan for sorting later
      const planWithType: IStripePlan = { ...plan, planType };

      // Extract and validate the pricing_tier
      let tier = plan.metadata?.pricing_tier;
      if (!tier || isNaN(Number(tier))) {
        tier = "Other";
      }
      if (!tierMap[tier]) {
        tierMap[tier] = [];
      }
      tierMap[tier].push(planWithType);
    });
  });

  // Convert the tierMap into an array of StripePlansByTier[]
  const result: StripePlansByTier[] = Object.entries(tierMap).map(
    ([tier, plans]) => ({
      tier,
      plans,
    })
  );

  // Sort the result array in descending order by tier, with "Other" last
  result.sort((a, b) => {
    if (a.tier === "Other") return 1; // Place "Other" at the end
    if (b.tier === "Other") return -1;
    return Number(b.tier) - Number(a.tier); // Descending order
  });

  return result.map((tierGroup) => {
    const plans = [...tierGroup.plans];
    plans.sort((a, b) => {
      // Sort by PlanType (Starter = 1, Core = 2, Pro = 3)
      const planTypeOrder: { [key in PlanType]: number } = {
        starter: 1,
        core: 2,
        pro: 3,
      };
      const planTypeA = planTypeOrder[a.planType as PlanType];
      const planTypeB = planTypeOrder[b.planType as PlanType];

      if (planTypeA !== planTypeB) {
        return planTypeA - planTypeB;
      }

      // Sort by billing interval (month = 1, year = 2)
      const intervalOrder: { [key: string]: number } = { month: 1, year: 2 };
      const intervalA =
        (a.recurring && intervalOrder[a.recurring.interval]) || 0;
      const intervalB =
        (b.recurring && intervalOrder[b.recurring.interval]) || 0;

      return intervalA - intervalB;
    });

    const formattedPlans = formatStripePlans(plans);

    return {
      tier: tierGroup.tier,
      plans: formattedPlans,
    };
  });
};

export const getAllStripeCoupons = async () => {
  try {
    const response = await axios.get<GetStripeCouponsResponse>(
      `${process.env.REACT_APP_SERVER_URL}/api/stripe-coupons`
    );
    const coupons = response.data.message;

    return coupons;
  } catch (e) {
    console.log("error getting stripe coupons", e);
    return [];
  }
};

export const filterCoupons = (coupons: ICoupon[]): ICoupon[] => {
  return coupons.filter((coupon) => {
    if (process.env?.REACT_APP_FILTER_STRIPE_DATA_BY_METADATA_KEY) {
      const metadataKey = process.env.REACT_APP_FILTER_STRIPE_DATA_BY_METADATA_KEY;
      return Boolean(coupon.metadata[metadataKey]);
    }

    return true;
  });
};

export const getProphoneContacts = async () => {
  try {
    const contactResponse = await axios.get<GetProphoneContactsResponse>(
      `${process.env.REACT_APP_SERVER_URL}/api/getProphoneContacts`
    );

    return contactResponse.data.message;
  } catch (e) {
    console.log("error getting phone contacts", e);
    return [];
  }
};
