import { borderedInputWhite } from "../../../../shared/styles";

interface KeywordFamilyProps {
  keywords: string[];
  primaryKeyword: string;
  keywordCount: number;
  onKeywordsChange: (keywords: string[]) => void;
  onPrimaryKeywordChange: (keyword: string) => void;
}

const KeywordFamily: React.FC<KeywordFamilyProps> = ({
  onKeywordsChange,
  onPrimaryKeywordChange,
  keywords,
  primaryKeyword,
  keywordCount,
}) => {
  return (
    <div className="flex flex-row w-full justify-between space-x-2">
      <div>
        <label>Primary Keyword</label>
        <input
          className={borderedInputWhite}
          value={primaryKeyword}
          onChange={(e) => onPrimaryKeywordChange(e.target.value)}
        ></input>
      </div>
      <Keywords
        keywordCount={keywordCount}
        keywords={keywords}
        onKeywordsChange={onKeywordsChange}
      />
    </div>
  );
};

interface KeywordsProps {
  keywords: string[];
  keywordCount: number;
  onKeywordsChange: (keywords: string[]) => void;
}

const Keywords: React.FC<KeywordsProps> = ({
  keywords,
  keywordCount,
  onKeywordsChange,
}) => {
  const onKeywordChange = (keyword: string, index: number) => {
    const newKeywords = [...keywords];
    newKeywords[index] = keyword;
    onKeywordsChange(newKeywords);
  };

  return (
    <>
      {[...Array(keywordCount)].map((_, index) => (
        <Keyword
          key={index}
          index={index}
          keyword={keywords[index]}
          onKeywordChange={onKeywordChange}
        />
      ))}
    </>
  );
};

interface KeywordProps {
  keyword: string;
  index: number;
  onKeywordChange: (keyword: string, index: number) => void;
}

const Keyword: React.FC<KeywordProps> = ({
  index,
  keyword,
  onKeywordChange,
}) => {
  const value = keyword || "";

  return (
    <div>
      <label htmlFor={`keyword_${index}`}>Keyword {index + 1}</label>
      <input
        id={`keyword_${index}`}
        value={value}
        className={borderedInputWhite}
        onKeyDown={filterCommas}
        onChange={(e) => onKeywordChange(e.target.value, index)}
      ></input>
    </div>
  );
};

const filterCommas = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.code === "Comma") event.preventDefault();
};

export default KeywordFamily;
