/* eslint-disable */
import axios from "axios";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { pageContainerWithOverflow } from "../../shared/styles";
import globalState from "../../store";
import DomainInfo from "./components/DomainInfo";
import Layout from "./components/Layout";
import {
  addPhotoToLocation,
  createGMBAccount,
  createGMBLocation,
  getAllGMBAccounts,
  getGMBPhotos,
  getLocationsByAccountId,
  getLocationVerificationStatus,
  getMetadata,
  makeCustomerAdmin,
} from "./utils";
import CreateGMB from "./components/CreateGMB";
import ManageGMB from "./components/ManageGMB";
import CheckoutLink from "./components/CheckoutLink/CheckoutLink";
import SignIntoGMB from "./components/SignIntoGMB";
import CustomerInfo from "./components/CustomerInfo/CustomerInfo";
import ParkCustomerWebsite from "./components/ParkCustomerWebsite";
import ScheduleSocialPost from "./components/ScheduleSocialPost";
import CustomerSegment from "./components/CustomerSegment";
import { Industry } from "./components/Industry";
import { Redirects } from "./components/Redirects";
import ServiceBellToggle from './components/ServiceBellToggle';

export default function DomainPage() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const {
    sitePk,
    paid_url,
    company_name,
    state,
    city,
    about,
    business_hours,
    logo,
    hero_gallery_photos,
    gallery,
    slug,
    gmb_account_id,
    service_areas,
    publish_to_google_storage,
  } = globalStateAtom;

  const [address, setAddress] = useState("");
  const [primaryCategory, setPrimaryCategory] = useState("");
  const [verifyLink, setVerifyLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [photoErrorMessages, setPhotoErrorMessages] = useState<string[]>([]);

  const [gmbAccessToken, setGmbAccessToken] = useState("");
  const [gmbAccount, setGmbAccount] = useState<any>({});
  const [gmbLocation, setGmbLocation] = useState<any>({});
  const [placeId, setPlaceId] = useState<string>("");
  const [mapsUri, setMapsUri] = useState<string>("");
  const [linkToGMBDashboard, setLinkToGMBDashboard] = useState<string>("");
  const [verificationProcessing, setVerificationProcessing] =
    useState<boolean>(false);
  const [verified, setVerified] = useState<boolean>(false);
  const [needsToAuthenticate, setNeedsToAuthenticate] =
    useState<boolean>(false);
  // const [isDomainInDatabase, setIsDomainInDatabase] = useState<boolean>(
  //     globalState?.paid_url !== ''
  // )
  console.log("globalState in Checkout Link", globalState?.init);

  useEffect(() => {
    (async () => {
      const token = await localStorage.getItem("gmb_access_token");
      if (token) {
        setGmbAccessToken(token);
        // getLocation(token)
      } else {
        setNeedsToAuthenticate(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (gmbAccessToken) getLocation(gmbAccessToken);
  }, [gmbAccessToken]);

  async function getLocation(gmbAccessToken) {
    let allAccounts;
    try {
      allAccounts = await getAllGMBAccounts(gmbAccessToken);
      setNeedsToAuthenticate(false);
    } catch {
      setNeedsToAuthenticate(true);
    }
    if (!allAccounts) setNeedsToAuthenticate(true);
    allAccounts.accounts.forEach(async (acc, idx) => {
      if (acc.accountName === company_name || acc.name === gmb_account_id) {
        setGmbAccount(acc);
        console.log(acc, "account that matches");
        const location = await getLocationsByAccountId(
          acc.name,
          gmbAccessToken
        );
        if (location) {
          console.log(location, "location");
          setGmbLocation(location[0].name); // TODO: when multiple locations, allow user to specify which location they're updated based on the address
          const verificationComplete = await getLocationVerificationStatus(
            location,
            gmbAccessToken
          );
          console.log(verificationComplete, "isVerified");
          if (!verificationComplete) {
            setVerifyLink(`https://business.google.com/u/4/dashboard`);
          }
          const metadata = await getMetadata(
            acc.name,
            location.title,
            gmbAccessToken
          );
          console.log(metadata, "metadata"); // also accessible is the leave_reviews_link
          if (Object.keys(metadata).includes("placeId")) {
            setPlaceId(metadata.placeId);
            setMapsUri(metadata.mapsUri);
            setLinkToGMBDashboard(
              `https://business.google.com/u/4/organizations/${
                acc.name.split("/")[1]
              }/groups/${location[0].name.split("/")[1]}/locations`
            );
            setVerified(true); // submitting the verification may have completed, but only when there's metadata has the verificaiton gone all the way through
          } else {
            setVerificationProcessing(true);
          }
          // }
        }
      }
    });
  }

  async function useGMBToken(func, ...args) {
    const returned = await func(...args, gmbAccessToken);
    return returned;
  }

  async function createGMB(token) {
    setLoading(true);
    try {
      const customerDataResponse = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/get-user-by-landing-page`, // only need this for email, personal phone number
        {
          landing_page: `${globalStateAtom.sitePk}`,
        }
      );
      const customerData = customerDataResponse.data.message;
      console.log(customerData, "customerData");
      const account = await createGMBAccount(company_name, token);
      console.log(account, "account!");
      const accountId = account.name.replace("accounts/", "");
      console.log(accountId, "accountId");
      const saveAccountIdInDatabase = axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/save_gmb_account_id/${slug}/${accountId}`
      );
      const admin = await makeCustomerAdmin(accountId, customerData, token);
      console.log(admin, "ADMIN");
      const location = await createGMBLocation(
        accountId,
        sitePk,
        business_hours,
        company_name,
        paid_url,
        about,
        primaryCategory,
        address,
        city,
        state,
        customerData.phone,
        service_areas,
        () =>
          setErrorMessage(
            "Invalid address. Please use the exact address taken from google maps."
          ),
        token
      );
      console.log(location, "LOCATION");
      if (location) {
        const locationId = location.name.split("locations/")[1];
        alert(
          "Successfully created Google My Business account and location. \nAdministrator access invitation has been sent to the customer. \nFollow the link below to verify location, then you can add pictures and manage reviews."
        ); // TODO: change this warning when we are vetted
        setVerifyLink(
          `https://business.google.com/u/4/dashboard/l/${locationId}?pma=${accountId}&oi=106131752896257989837`
        );
      }
    } catch (e) {
      alert("Error creating GMB: " + e);
    }
    setLoading(false);
  }

  return (
    <div>
      <div
        className={`${pageContainerWithOverflow} grid grid-cols-2 space-x-6`}
      >
        <div className="w-full">
          <DomainInfo />
          <CustomerInfo />
          <Layout />
        </div>
        <div>
          <CheckoutLink />
          <CustomerSegment />
          <Industry />

          {needsToAuthenticate ? (
            <SignIntoGMB setGMBAccessToken={setGmbAccessToken} />
          ) : verified ? (
            <ManageGMB
              placeId={placeId}
              mapsUri={mapsUri}
              linkToGMBDashboard={linkToGMBDashboard}
              addPhotos={async () => {
                // Error handling
                const picErrs: string[] = [];
                setPhotoErrorMessages([]);

                // Gallery Photos
                const allGMBPhotos = await getGMBPhotos(
                  gmbAccount.name,
                  gmbLocation,
                  gmbAccessToken
                );

                const gmbPhotoCache = {};
                allGMBPhotos?.mediaItems?.forEach((p) => {
                  if (p.description) gmbPhotoCache[p.description] = true;
                });

                gallery.forEach(async (photo, idx) => {
                  if (
                    !gmbPhotoCache[
                      photo.photo.split(
                        "https://landing-page-app-hero-images.s3.amazonaws.com/media/"
                      )[1]
                    ]
                  ) {
                    const gmbPhoto = await useGMBToken(
                      addPhotoToLocation,
                      photo.photo,
                      "CATEGORY_UNSPECIFIED",
                      gmbAccount.name,
                      gmbLocation
                    );
                    if (typeof gmbPhoto === "string")
                      // if the error message from the utility function is returned, add the photo error to the error messages mapped in the JSX
                      picErrs.push(gmbPhoto + " " + idx.toString());
                  }
                  setPhotoErrorMessages([...photoErrorMessages, ...picErrs]);
                });

                // Logo
                const logoRes = await useGMBToken(
                  addPhotoToLocation,
                  logo,
                  "LOGO",
                  gmbAccount.name,
                  gmbLocation
                );
                if (typeof logoRes === "string")
                  // if the error message from the utility function is returned, add the photo error to the error messages mapped in the JSX
                  picErrs.push(logoRes);

                // Cover
                const cover = await useGMBToken(
                  addPhotoToLocation,
                  hero_gallery_photos[0].photo,
                  "COVER",
                  gmbAccount.name,
                  gmbLocation
                );
                if (typeof cover === "string")
                  // if the error message from the utility function is returned, add the photo error to the error messages mapped in the JSX
                  picErrs.push(cover);
                setPhotoErrorMessages(picErrs);
              }}
            />
          ) : (
            <CreateGMB
              createGMB={createGMB}
              loading={loading}
              address={address}
              setAddress={setAddress}
              primaryCategory={primaryCategory}
              setPrimaryCategory={setPrimaryCategory}
              setErrorMessage={setErrorMessage}
              verifyLink={verifyLink}
              verificationProcessing={verificationProcessing}
              needsToAuthenticate={needsToAuthenticate}
              setNeedsToAuthenticate={setNeedsToAuthenticate}
            />
          )}
          {photoErrorMessages && photoErrorMessages.length ? (
            photoErrorMessages.map((err, idx) => (
              <p
                className={`${idx % 2 === 1 ? "text-red-800" : "text-red-500"}`}
              >
                {err}
              </p>
            ))
          ) : (
            <></>
          )}
          {errorMessage && errorMessage !== "" && (
            <p className="text-red-500">{errorMessage}</p>
          )}
          <ParkCustomerWebsite />
          <ScheduleSocialPost />
          <ServiceBellToggle />
        </div>
      </div>
      {publish_to_google_storage && (
        <div className={pageContainerWithOverflow}>
          <Redirects />
        </div>
      )}
    </div>
  );
}

//     try {
//         const verify = await useGMBToken(
//             verifyLocation,
//             customerData,
//             accountId,
//             sitePk.toString(),
//             business_hours,
//             company_name,
//             paid_url,
//             about,
//             primaryCategory,
//             address,
//             city,
//             state,
//             () => null, // error callback
//             phone
//         )
//         console.log(verify, 'VERIFY')
//     } catch {
//         console.log('could not verify location')
//     }
