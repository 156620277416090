/* eslint-disable */
import { borderedInputWhite, label } from "../../../shared/styles";
import globalState from "../../../store";
import Props from "../../../store/props";
import { useAtom } from "jotai";
interface TeamColumnProps {
  column: number;
}

const TeamColumn: React.FC<TeamColumnProps> = ({ column }) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  // const {
  //     team_col_1_header,
  //     team_col_1,
  //     team_col_2_header,
  //     team_col_2,
  // } = globalStateAtom

  return (
    <div className="bg-white w-6/12 my-12 mx-3 rounded-lg p-6">
      <h3>Column {column}</h3>
      <label className={`${label} w-full`}>
        Header
        <input
          className={borderedInputWhite}
          onChange={(e) => {
            if (column === 1)
              setGlobalState({
                ...globalStateAtom,
                team_col_1_header: e.target.value,
              });
            else
              setGlobalState({
                ...globalStateAtom,
                team_col_2_header: e.target.value,
              });
          }}
          value={
            column === 1
              ? globalStateAtom.team_col_1_header
              : globalStateAtom.team_col_2_header
          }
        />
      </label>
      <label className={label}>
        Content
        <textarea
          className={borderedInputWhite}
          onChange={(e) => {
            if (column === 1)
              setGlobalState({
                ...globalStateAtom,
                team_col_1: e.target.value,
              });
            else
              setGlobalState({
                ...globalStateAtom,
                team_col_2: e.target.value,
              });
          }}
          value={
            column === 1
              ? globalStateAtom.team_col_1
              : globalStateAtom.team_col_2
          }
          rows={3}
        />
      </label>
    </div>
  );
};

export default TeamColumn;
