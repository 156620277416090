/* eslint-disable */
import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/north-america.json";

export default function AllTextsMap(props) {
  const { geoDataTexts, geoDataPros } = props;

  return (
    <div>
      {geoDataTexts.length || geoDataPros.length ? (
        <>
          <div className="flex flex-row justify-center my-4">
            <div className="flex mb-3">
              <div className="bg-red-500 w-6 h-6 mr-2"></div>
              <div className="h-6 mr-6">Text messages</div>
            </div>
            <div className="flex">
              <div className="bg-blue-900 w-6 h-6 mr-2"></div>
              <div className="h-6">Pros</div>
            </div>
          </div>
          <ComposableMap projection="geoAlbers" className="w-2/3 mt-4 m-auto">
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#DDD"
                    stroke="#FFF"
                  />
                ))
              }
            </Geographies>

            {geoDataTexts.map((coordinate) => {
              return (
                <Marker
                  coordinates={[coordinate.longitude, coordinate.latitude]}
                >
                  <circle r={4} fill="#F53" />
                </Marker>
              );
            })}
            {geoDataPros.map((coordinate) => {
              return (
                <Marker
                  coordinates={[coordinate.longitude, coordinate.latitude]}
                >
                  <circle r={4} fill="#000080" />
                </Marker>
              );
            })}
          </ComposableMap>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}
