/* eslint-disable */
import { useAtom } from "jotai";
import React, { useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { borderedInputWhite, label } from "../../../shared/styles";
import globalState from "../../../store";

function AdditionalPhoneNumbersForLeadText({ setAlert }: any) {
    const [globalStateAtom, setGlobalState] = useAtom(globalState);
    const { additional_phone_number_list } = globalStateAtom;

    function handleChangeAddtionalPhoneNumber(e: any, idx: number) {
        setAlert("");
        const newAdditionalPhoneNumberArr = [...additional_phone_number_list];
        newAdditionalPhoneNumberArr[idx] = e.target.value;

        setGlobalState({
            ...globalStateAtom,
            additional_phone_number_list: newAdditionalPhoneNumberArr,
        });
    }

    function handleDeleteAddtionalPhoneNumber(e: any, idx: number) {
        setAlert("");
        const newAdditionalPhoneNumberList = [...additional_phone_number_list];
        newAdditionalPhoneNumberList.splice(idx, 1);

        setGlobalState({
            ...globalStateAtom,
            additional_phone_number_list: newAdditionalPhoneNumberList,
        });
    }

    return (
        <div>
            {additional_phone_number_list?.map((number: any, idx: number) => (
                <div key={idx}>
                    <label className={label}>
                        <div className="flex justify-between w-full">
                            <div
                                onClick={(e) => {
                                    handleDeleteAddtionalPhoneNumber(e, idx);
                                }}
                                className="absolute transform -translate-x-6 -translate-y-3 cursor-pointer rounded-full flex p-2"
                            >
                                <IoCloseCircle
                                    color="#EA580C"
                                    size={30}
                                    id={`${idx}`}
                                />
                            </div>
                            <div className="flex items-center ml-4">
                                Phone Number {idx + 2}
                            </div>
                        </div>
                        <input
                            onChange={(e) =>
                                handleChangeAddtionalPhoneNumber(e, idx)
                            }
                            value={number}
                            className={borderedInputWhite}
                        />
                    </label>
                </div>
            ))}
        </div>
    );
}

export default AdditionalPhoneNumbersForLeadText;
