/* eslint-disable */
import { useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import Props from "../../../store/props";
import ThumbnailSelectable from "../../../shared/ThumbnailSelectable";
// import ThumbnailSelectable from './ThumbnailSelectable'
import { borderedInputWhiteWrap } from "../../../shared/styles";
import globalState from "../../../store";
import { useAtom } from "jotai";
import { ListManager } from "react-beautiful-dnd-grid";

interface AllPhotosProps {
  setSelectedPhotos: any;
  selectedPhotos: any;
  addPhotosToService: () => void;
}

const PhotoGallery: React.FC<AllPhotosProps> = ({
  setSelectedPhotos,
  selectedPhotos,
  addPhotosToService,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { gallery, gallery_names } = globalStateAtom;
  const [activeGalleryName, setActiveGalleryName] =
    useState<string>("Uncategorized");

  const selectedItems = { ...selectedPhotos };
  // TODO: consider eliminating this orderCounter and giving facebook photos an 'order' property in another way, the filter/sort logic in the DraggablePhotoGallery component makes this unused/useless
  const [orderCounter, setOrderCounter] = useState(0);
  const handleSelect = (item: any) => {
    if (!selectedItems[item.photo]) {
      setOrderCounter(orderCounter + 1);
      selectedItems[item.photo] = { ...item, order: orderCounter };
    } else {
      setOrderCounter(orderCounter - 1);
      delete selectedItems[item.photo];
    }
    setSelectedPhotos(selectedItems);
  };

  const handleDelete = (photoUrl: string) => {
    // make placeholder array for added image
    const newGalleryArray: any[] = [...gallery];
    // find position of selected image
    const posOfImage = newGalleryArray.findIndex(
      (img) => img.photo === photoUrl
    );
    // splice that image out of the array
    if (posOfImage !== -1) newGalleryArray.splice(posOfImage, 1);

    // set the state of images to the spliced array
    setGlobalState({ ...globalStateAtom, gallery: newGalleryArray });
  };

  return (
    <div className="bg-white w-full mx-12 rounded-lg px-6 h-full">
      <div
        className="absolute cursor-pointer transform translate-y-3 -translate-x-3"
        onClick={addPhotosToService}
      >
        <IoAddOutline color="black" opacity="40%" size={40} />
      </div>
      <select
        className={`${borderedInputWhiteWrap} mt-10 mb-4`}
        onChange={(e) => setActiveGalleryName(e.target.value)}
      >
        {gallery_names &&
          [{ "gallery-name": "Uncategorized" }, ...gallery_names].map(
            (gal: any) => (
              <option key={gal["gallery-name"]} value={gal["gallery-name"]}>
                {gal["gallery-name"]}
              </option>
            )
          )}
      </select>
      <div className="w-full h-96 flex flex-wrap -ml-6  pt-10 overflow-scroll">
        <ListManager
          items={gallery}
          direction="horizontal"
          maxItems={3}
          render={(photo) => {
            const activeGal =
              activeGalleryName === "Uncategorized" ? "" : activeGalleryName;
            return (
              photo.gallery_name === activeGal && (
                <ThumbnailSelectable
                  photo={photo}
                  handleDelete={handleDelete}
                  handleSelect={handleSelect}
                  selectedPhotos={selectedPhotos}
                  rerenderAfterDeletePhoto={() => null}
                  draggable={false}
                />
              )
            );
          }}
          onDragEnd={() => null}
        />
      </div>
      {/* <div className='flex justify-center mb-3'>
                <button className={buttonSmall}>Previous</button>
                <button className={buttonSmall}>Next</button>
            </div> */}
    </div>
  );
};

export default PhotoGallery;
