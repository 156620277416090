/* eslint-disable */
import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import { borderedInputWhite, label } from "../../../shared/styles";
import globalState from "../../../store";
import Props from "../../../store/props";

interface CtaProps {
  item: string;
}

const CallToAction: React.FC<CtaProps> = ({ item }) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const {
    phone,
    email,
    external_link,
    cta_main,
    cta_main_1,
    cta_nav,
    cta_nav_1,
    cta_slide_in,
    cta_recruiting,
    cta_main_link,
    cta_main_1_link,
    cta_nav_link,
    cta_nav_1_link,
    cta_slide_in_link,
    cta_recruiting_link,
    call_to_action_message,
    call_to_action_message_main_1,
    call_to_action_message_nav,
    call_to_action_message_nav_1,
    call_to_action_message_slide_in,
    call_to_action_message_recruiting,
    cta_get_a_quote,
    cta_get_a_quote_link,
    call_to_action_message_get_a_quote,
  } = globalStateAtom;

  const handleSetGlobalMessage = (
    item: string,
    value: string,
    whichInput: string
  ) => {
    let message;
    let callToAction;
    if (whichInput === "message") {
      message =
        value === "text"
          ? "Get a Free Quote"
          : value === "call"
          ? "Give us a Call"
          : value === "link"
          ? "Book Now"
          : value === "email"
          ? "Email Us"
          : value === "Popup"
          ? "Apply Today"
          : value === "none"
          ? ""
          : value;
    } else if (whichInput === "cta") {
      // // console.log('cta value', value)
      callToAction =
        value === "text" || value === "call"
          ? phone
          : value === "link"
          ? external_link
          : value === "email"
          ? email
          : value === "Popup"
          ? ""
          : value === "none"
          ? ""
          : value;
    } else {
      value = value === "none" ? "" : value;
      callToAction =
        value === "text" || value === "call"
          ? phone
          : value === "link"
          ? external_link
          : value === "email"
          ? email
          : value === "Popup"
          ? ""
          : value === "none"
          ? ""
          : value;
      message =
        value === "text"
          ? "Get a Free Quote"
          : value === "call"
          ? "Give us a Call"
          : value === "link"
          ? "Book Now"
          : value === "email"
          ? "Email Us"
          : value === "Popup"
          ? "Apply Today"
          : value === "none"
          ? ""
          : value;
    }

    switch (item) {
      case "Main 1":
        setGlobalState({
          ...globalStateAtom,
          cta_main:
            whichInput !== "both" ? cta_main : value === "" ? "none" : value,
          cta_main_link:
            callToAction || callToAction === "" ? callToAction : cta_main_link,
          call_to_action_message:
            message || message === "" ? message : call_to_action_message,
        });
        break;
      case "Main 2":
        setGlobalState({
          ...globalStateAtom,
          cta_main_1:
            whichInput !== "both" ? cta_main_1 : value === "" ? "none" : value,
          cta_main_1_link:
            callToAction || callToAction === ""
              ? callToAction
              : cta_main_1_link,
          call_to_action_message_main_1:
            message || message === "" ? message : call_to_action_message_main_1,
        });
        break;
      case "Nav Bar 1":
        setGlobalState({
          ...globalStateAtom,
          cta_nav:
            whichInput !== "both" ? cta_nav : value === "" ? "none" : value,
          cta_nav_link:
            callToAction || callToAction === "" ? callToAction : cta_nav_link,
          call_to_action_message_nav:
            message || message === "" ? message : call_to_action_message_nav,
        });
        break;
      case "Nav Bar 2":
        setGlobalState({
          ...globalStateAtom,
          cta_nav_1:
            whichInput !== "both" ? cta_nav_1 : value === "" ? "none" : value,
          cta_nav_1_link:
            callToAction || callToAction === "" ? callToAction : cta_nav_1_link,
          call_to_action_message_nav_1:
            message || message === "" ? message : call_to_action_message_nav_1,
        });
        break;
      case "Slide In":
        setGlobalState({
          ...globalStateAtom,
          cta_slide_in:
            whichInput !== "both"
              ? cta_slide_in
              : value === ""
              ? "none"
              : value,
          cta_slide_in_link:
            callToAction || callToAction === ""
              ? callToAction
              : cta_slide_in_link,
          call_to_action_message_slide_in:
            message || message === ""
              ? message
              : call_to_action_message_slide_in,
        });
        break;
      case "Recruiting":
        setGlobalState({
          ...globalStateAtom,
          cta_recruiting:
            whichInput !== "both"
              ? cta_recruiting
              : value === ""
              ? "none"
              : value,
          cta_recruiting_link:
            callToAction || callToAction === ""
              ? callToAction
              : cta_recruiting_link,
          call_to_action_message_recruiting:
            message || message === ""
              ? message
              : call_to_action_message_recruiting,
        });
        break;
      case "Get a quote":
        setGlobalState({
          ...globalStateAtom,
          cta_get_a_quote:
            whichInput !== "both"
              ? cta_get_a_quote
              : value === ""
              ? "none"
              : value,
          cta_get_a_quote_link:
            callToAction || callToAction === ""
              ? callToAction
              : cta_get_a_quote_link,
          call_to_action_message_get_a_quote:
            message || message === ""
              ? message
              : call_to_action_message_get_a_quote,
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-full flex justify-between items-center">
      <label className={label + ` w-full`}>
        {item}
        <div className="flex">
          <select
            onChange={(e) => {
              handleSetGlobalMessage(item, e.target.value, "both");
            }}
            value={
              item === "Main 1"
                ? cta_main
                : item === "Main 2"
                ? cta_main_1
                : item === "Nav Bar 1"
                ? cta_nav
                : item === "Nav Bar 2"
                ? cta_nav_1
                : item === "Slide In"
                ? cta_slide_in
                : item === "Recruiting"
                ? cta_recruiting
                : cta_get_a_quote
            }
            className="p-2 mt-3 w-20 rounded-l-md border-2 border-gray-200 bg-gray-200"
          >
            <option value="text">Text</option>
            <option value="call">Call</option>
            <option value="link">Link</option>
            <option value="email">Email</option>
            <option value="none">None</option>
          </select>
          <input
            value={
              item === "Main 1"
                ? cta_main_link
                : item === "Main 2"
                ? cta_main_1_link
                : item === "Nav Bar 1"
                ? cta_nav_link
                : item === "Nav Bar 2"
                ? cta_nav_1_link
                : item === "Slide In"
                ? cta_slide_in_link
                : item === "Recruiting"
                ? cta_recruiting_link
                : cta_get_a_quote_link
            }
            onChange={(e) =>
              handleSetGlobalMessage(item, e.target.value, "cta")
            }
            className="p-2 mt-3 w-36 rounded-r-md border-2 border-gray-200"
          />
        </div>
      </label>
      <label className={`${label} w-full`}>
        Display Text
        <input
          value={
            item === "Main 1"
              ? call_to_action_message
              : item === "Main 2"
              ? call_to_action_message_main_1
              : item === "Nav Bar 1"
              ? call_to_action_message_nav
              : item === "Nav Bar 2"
              ? call_to_action_message_nav_1
              : item === "Slide In"
              ? call_to_action_message_slide_in
              : item === "Recruiting"
              ? call_to_action_message_recruiting
              : call_to_action_message_get_a_quote
          }
          onChange={(e) =>
            handleSetGlobalMessage(item, e.target.value, "message")
          }
          className={borderedInputWhite}
        />
      </label>
    </div>
  );
};

export default CallToAction;
