/* eslint-disable */
import React, { useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";
import TeamMemberCard from "./TeamMemberCard";
import Props from "../../../store/props";
import { useAtom } from "jotai";
import globalState from "../../../store";

interface ColumnProps {
  localTeamMembers: any;
  // setLocalTeamMembers: React.Dispatch<React.SetStateAction<ItemProps[]>>
  droppableId: number;
}

interface ItemProps {
  name: string;
  role: string;
  photo: string;
  draggableId: string;
  order: number;
}

const Column: React.FC<ColumnProps> = ({ localTeamMembers, droppableId }) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { teammates } = globalStateAtom;

  const handleDelete = (index: number) => {
    const thisService = localTeamMembers[index];
    // console.log(thisService, 'thisService')
    // make placeholder array for added services
    const newServices = [...teammates];
    // find position of selected service
    const posOfService = newServices.findIndex((serv) => serv === thisService);
    // splice that service out of the array
    newServices.splice(posOfService, 1);
    // set the state of services to the spliced array
    // setLocalTeamMembers(newServices)
    setGlobalState({ ...globalStateAtom, teammates: newServices });
  };

  //     // when localTeamMembers changes, change the global state so the changes can be accurately saved to the db
  //     useEffect(() => {
  //         // make sure the order of the items matches the order of the drag-n-drop
  //         const orderedList = localTeamMembers.map((item:any,idx:any) => {
  //             return {...item,order:idx}
  //         })
  //         // console.log(orderedList,'orderedList')
  //         // setGlobalState({...globalState,teammates:orderedList})
  //         teammates = orderedList
  //   }, [localTeamMembers])

  return (
    <Droppable droppableId={`${droppableId}`}>
      {(provided) => (
        <div
          className="flex flex-col w-4/12 mr-9 -ml-3 items-start"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {localTeamMembers &&
            localTeamMembers.map((review: ItemProps, index: number) => {
              return (
                <TeamMemberCard
                  key={review.draggableId}
                  // setLocalTeamMembers={setLocalTeamMembers}
                  localTeamMembers={localTeamMembers}
                  draggableId={review.draggableId}
                  handleDelete={() => handleDelete(index)}
                  review={review}
                  index={index}
                />
              );
            })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Column;
