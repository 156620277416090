/* eslint-disable */
import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import globalState from "../../../store";
import Props from "../../../store/props";
import PhotoProps from "../types/photoProps";
import DraggablePhotoGallery from "./DraggablePhotoGallery";
import UploadFiles from "./UploadFiles";

interface AllPhotosProps {
  localGalleryNames: any;
  addPhotoToGallery: any;
  setSelectedPhotos: React.Dispatch<React.SetStateAction<any>>;
  selectedPhotos: any;
  setParentLocalGallery: React.Dispatch<React.SetStateAction<PhotoProps[]>>;
  handleSelect: any;
  filter: string;
  rerenderAfterDeletePhoto: (item: PhotoProps) => void;
  addSelectedPhotosToGallery: any;
}

const DraggablePhotoGalleryContainer: React.FC<AllPhotosProps> = ({
  addSelectedPhotosToGallery,
  localGalleryNames,
  addPhotoToGallery,
  setSelectedPhotos,
  selectedPhotos,
  setParentLocalGallery,
  handleSelect,
  filter,
  rerenderAfterDeletePhoto,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { gallery, gallery_names, sitePk } = globalStateAtom;

  const [localGallery, setLocalGalleryState] = useState<any>(
    gallery
      .filter(
        (photo: PhotoProps) =>
          !!(photo && photo.gallery_name && photo.gallery_name === filter)
      )
      .sort((a, b) => b.gallery_order - a.gallery_order)
  );

  useEffect(() => {
    setLocalGalleryState(
      gallery
        .filter(
          (photo: PhotoProps) =>
            !!(photo && photo.gallery_name && photo.gallery_name === filter)
        )
        .sort((a, b) => b.gallery_order - a.gallery_order)
    );
    // 'filter' changes when the sidenav menu item changes -> the photos displayed change because of the setLocalGalleryState above
  }, [filter, globalStateAtom]);

  // Function to ensure the state of the gallery is saved on drag
  const onDragEnd = ({ source, destination }: DropResult) => {
    return;
  };

  return (
    // We need to put both the <Droppable /> and <Draggable /> components within a context
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        className={`${
          gallery_names.length > 4 ? "mt-40" : "mt-16"
        } flex w-full`}
      >
        {/* The <Droppable /> component has <Draggable /> children and needs an id (see the other <Droppable /> component below, which has a different droppableId) */}
        <UploadFiles
          context="Gallery"
          filter={filter}
          setParentLocalGallery={setParentLocalGallery}
          localGallery={localGallery}
          setLocalGalleryState={setLocalGalleryState}
          setSelectedPhotos={setSelectedPhotos}
        />
        <Droppable droppableId="col-1-drag-photo-gallery">
          {/* Both <Draggable /> and <Droppable /> need 'provided' props */}
          {(provided) => (
            // This div has the provided props and ref needed to drag <Draggables /> within it
            <div
              className="flex flex-col  w-full items-start"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <DraggablePhotoGallery
                context="Gallery"
                addSelectedPhotosToGallery={addSelectedPhotosToGallery}
                localGalleryNames={localGalleryNames}
                addPhotoToGallery={addPhotoToGallery}
                setSelectedPhotos={setSelectedPhotos}
                selectedPhotos={selectedPhotos}
                handleSelect={handleSelect}
                filter={filter}
                setLocalGallery={setLocalGalleryState}
                localGallery={localGallery}
                rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
              />
              {/* The Droppable needs to render a placeholder on the screen while the user drags an element */}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default DraggablePhotoGalleryContainer;
