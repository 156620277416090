/* eslint-disable */
import { useAtom } from "jotai";
import { boxContainer, inputWhite } from "../../../shared/styles";
import globalState from "../../../store";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/solid";

const ColorScheme: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { custom_primary, custom_secondary } = globalStateAtom;

  const handleSwitchColors = () => {
    setGlobalState({
      ...globalStateAtom,
      custom_primary: custom_secondary,
      custom_secondary: custom_primary,
    });
  };

  return (
    <>
      <h2 className="absolute top-32 right-36">Color Scheme</h2>
      <div className={`${boxContainer} flex justify-between`}>
        <label className="flex flex-col items-start bg-gray-100 m-3 p-3 rounded-lg">
          Primary
          <input
            type="color"
            onChange={(e) => {
              // swap colors if chosen primary color is the same as secondary color
              if (e.target.value === custom_secondary) {
                const swapColor = custom_primary;
                setGlobalState({
                  ...globalStateAtom,
                  custom_primary: e.target.value,
                  custom_secondary: swapColor,
                });
              } else {
                setGlobalState({
                  ...globalStateAtom,
                  custom_primary: e.target.value,
                });
              }
            }}
            value={custom_primary}
            className={inputWhite}
          />
          <div
            style={{ backgroundColor: custom_primary }}
            className="w-full h-12 rounded-lg mt-3"
          />
        </label>
        <button
          onClick={handleSwitchColors}
          className="self-center cursor-pointer shadow p-1 rounded-md bg-gray-100 active:bg-gray-200"
        >
          <ArrowsRightLeftIcon className="w-6 h-6" />
        </button>
        <label className="flex flex-col items-start bg-gray-100 m-3 p-3 rounded-lg">
          Secondary
          <input
            type="color"
            onChange={(e) => {
              // swap colors if chosen secondary color is the same as primary color
              if (e.target.value === custom_primary) {
                const swapColor = custom_secondary;
                setGlobalState({
                  ...globalStateAtom,
                  custom_primary: swapColor,
                  custom_secondary: e.target.value,
                });
              } else {
                setGlobalState({
                ...globalStateAtom,
                custom_secondary: e.target.value,
                });
              }
            }}
            value={custom_secondary}
            className={inputWhite}
          />
          <div
            style={{ backgroundColor: custom_secondary }}
            className="w-full h-12 rounded-lg mt-3"
          />
        </label>
      </div>
    </>
  );
};

export default ColorScheme;
