/* eslint-disable */
import React, { useState, useEffect } from 'react';

const MultiSelectModal = ({ items, selectedIds, onSave, onClose, renderItem, idAttribute = 'id' }) => {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected(selectedIds);
    }, [selectedIds]);

    const handleCheckboxChange = (itemId) => {
        if (selected.includes(itemId)) {
            setSelected(selected.filter(id => id !== itemId));
        } else {
            setSelected([...selected, itemId]);
        }
    };

    const handleSave = () => {
        onSave(selected);
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
            <div className="modal-content bg-white p-5 rounded-lg shadow-xl">
                <span className="close-button text-right text-xl font-bold cursor-pointer" onClick={onClose}>&times;</span>
                <h2 className="text-2xl mb-4">Select Items</h2>
                <div className=" flex flex-wrap max-h-96 overflow-y-auto space-y-4">
                    {items.map(item => (
                        <div key={item[idAttribute]} className="flex items-center">
                            <input
                                type="checkbox"
                                className="mr-2"
                                checked={selected.includes(item[idAttribute])}
                                onChange={() => handleCheckboxChange(item[idAttribute])}
                            />
                            {renderItem ? renderItem(item) : <span>{item.name}</span>} {/* Default rendering */}
                        </div>
                    ))}
                </div>
                <div className="text-right mt-6">
                    <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700" onClick={handleSave}>Save Changes</button>
                </div>
            </div>
        </div>
    );
};


export default MultiSelectModal