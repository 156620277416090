/* eslint-disable */
import ApexCharts from "apexcharts";
import { useEffect } from "react";
import { renderOptions } from "./utils";

export default function TreeMap(props) {
  const { geoDataTexts, geoDataPros } = props;

  const textData = geoDataTexts.map((text) => {
    return text.state;
  });
  const textCounts = {};
  for (const num of textData) {
    textCounts[num] = textCounts[num] ? textCounts[num] + 1 : 1;
  }
  const textChartData = [];
  for (const state in textCounts) {
    textChartData.push({
      x: state,
      y: textCounts[state],
    });
  }
  textChartData.sort((a, b) => (a.y < b.y ? 1 : -1));

  const proData = geoDataPros.map((pro) => {
    return pro.state;
  });
  const proCounts = {};
  for (const num of proData) {
    proCounts[num] = proCounts[num] ? proCounts[num] + 1 : 1;
  }
  const proChartData = [];
  for (const state in proCounts) {
    proChartData.push({
      x: state,
      y: proCounts[state],
    });
  }
  proChartData.sort((a, b) => (a.y < b.y ? 1 : -1));

  useEffect(() => {
    if (textChartData.length > 0) {
      const textChart = new ApexCharts(
        document.querySelector("#text-chart"),
        renderOptions(textChartData, "Text messages by location")
      );
      textChart.render();
    }
  }, [textChartData]);
  useEffect(() => {
    if (proChartData.length > 0) {
      const proChart = new ApexCharts(
        document.querySelector("#pro-chart"),
        renderOptions(proChartData, "Pros by location")
      );
      proChart.render();
    }
  }, [proChartData]);

  return (
    <div key={1} className="flex flex-row flex-wrap justify-center mt-20">
      <div className="flex mr-40" id="pro-chart"></div>
      <div className="flex" id="text-chart"></div>
    </div>
  );
}
