/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { IoTrashOutline } from "react-icons/io5";
import globalState from "../../store";
import {
  borderedInputWhite,
  labelWrap,
  pageContainerWithOverflow,
} from "../../shared/styles";
import SideNav from "./components/SideNav";
import Generate from "./components/Generate";
import AddServiceButton from "./components/AddServiceButton";
import ServiceImages from "./components/ServiceImages";
import PhotoGallery from "./components/PhotoGallery";
import axios from "axios";
import ServicePackages from "./components/ServicePackages";
import ServiceKeywordFamily from "../basicInfo/components/KeywordFamily/ServiceKeywordFamily";
import { getServiceKeywordFamilies } from "./utils";
import { IService } from "../../types/types";

interface ItemProps {
  "landing-page": number;
  order: number;
  "service-description": string;
  "service-description-long": string;
  "service-name": string;
  photos: any[];
  draggableId: string;
}

export default function ServicesPage() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { services, sitePk, company_name, company_type } = globalStateAtom;
  const [localMounted, setLocalMounted] = useState<boolean>(false);
  const [selectedPhotos, setSelectedPhotos] = useState<any>({});
  const [activeService, setActiveService] = useState<any>(
    services[0] ? services[0] : {}
  );

  const blankServiceObject: ItemProps = {
    "landing-page": sitePk,
    order: services && services.length ? services.length : 0,
    "service-description": "",
    "service-description-long": "",
    "service-name": `NEW SERVICE ${
      !services
        .map((s) => s["service-name"])
        // @ts-ignore
        .includes(`NEW SERVICE ${services.length}`)
        ? services && services.length
          ? services.length
          : 0
        : uuidv4()
    }`,
    photos: [],
    draggableId: uuidv4(),
  };

  // generating a consistent draggableId and key for each review
  // also ensuring every service has properties aligned to the new template (service-description-long, etc.)
  useEffect(() => {
    // // console.log('IN THIS SERVICE LONG USE EFFECT')
    const draggableServices =
      services && services.length
        ? services.map((item: ItemProps, index: number) => {
            return {
              ...item,
              "service-name": item["service-name"],
              order: index,
              draggableId: uuidv4(),
            };
          })
        : [{ ...blankServiceObject }];
    const servicesWithAllProperties: any[] = [];
    for (let i = 0; i < draggableServices.length; i++) {
      servicesWithAllProperties[i] = draggableServices[i];
      // @ts-ignore
      const splittable =
        servicesWithAllProperties[i]["service-description-long"] &&
        servicesWithAllProperties[i]["service-description-long"].split(
          "XXXXXX"
        ) &&
        true;
      // // console.log(splittable, 'splittable')
      let splitLongDescription;
      // @ts-ignore
      if (splittable)
        splitLongDescription =
          servicesWithAllProperties[i]["service-description-long"].split(
            "XXXXXX"
          );
      // @ts-ignore
      // set the 'why us' section to the second part of the split
      // @ts-ignore
      if (!draggableServices[i].photos)
        servicesWithAllProperties[i] = {
          ...servicesWithAllProperties[i],
          photos: [],
        };
      // @ts-ignore
      if (!draggableServices[i]["service-name"])
        servicesWithAllProperties[i] = {
          ...servicesWithAllProperties[i],
          "service-name": `SERVICE ${i}`,
        };
    }
    // this ensures that even if there are no services it will render the page with one blank service (see the map on line 50)
    setActiveService(servicesWithAllProperties[0]);
    setGlobalState({
      ...globalStateAtom,
      // @ts-ignore
      services: servicesWithAllProperties,
    });
    setLocalMounted(true);
  }, []);

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (destination.index === source.index) return null;

    // Move the item within the list
    // Start by making a new list without the dragged item
    const newList = services.filter(
      (_: any, idx: number) => idx !== source.index
    );

    // Then insert the item at the right location
    newList.splice(destination.index, 0, services[source.index]);
    const orderedList = newList.map((item: any, index) => {
      return {
        ...item,
        order: index,
      };
    });

    // Update the list
    // @ts-ignore
    setGlobalState({ ...globalStateAtom, services: orderedList });
  };

  const updateServiceName = (name: string) => {
    let thisService: Record<string, unknown>;
    if (activeService.draggableId) {
      thisService = services.find(
        // @ts-ignore
        (s) => s.draggableId === activeService.draggableId
      );
    } else {
      thisService = services.find(
        (s) => s["service-name"] === activeService["service-name"]
      );
    }

    // make copy of the services array
    const newServices = [...services];
    if (thisService) {
      // find the service with the matching 'order' property
      const posOfThisService = newServices.findIndex(
        (serv) => serv === thisService
      );
      newServices[posOfThisService] = {
        ...activeService,
        // @ts-ignore
        "service-name": name,
      };
      const newActiveService = { ...activeService, "service-name": name };
      setActiveService(newActiveService);
      setGlobalState({ ...globalStateAtom, services: newServices });
      setTimeout(async () => {
        if (!newActiveService?.primary_keyword && !newActiveService?.keywords?.filter(Boolean)?.length)
          await updateServiceKeywordFamily(name, newActiveService, newServices);
        if (
          activeService["service-description"] == "" &&
          activeService["service-description-long"] == ""
        ) {
          triggerGenerate();
        }
      }, 0);
    }
    handleServiceNameError(name);
  };

  const updateServiceKeywordFamily = async (
    serviceName: string,
    activeService: IService,
    services: IService[]
  ) => {
    const keywordFamilies = await getServiceKeywordFamilies(
      serviceName,
      company_type
    );
    const keywordsObject = keywordFamilies[0] || {primary_keyword: "", keywords: []};
    const newServices = [...services];
    const posOfThisService = newServices.findIndex(
      (service) => service.order === activeService.order
    );
    newServices[posOfThisService] = {
      ...activeService,
      ...keywordsObject,
    };
    setActiveService({ ...activeService, ...keywordsObject });
    setGlobalState({ ...globalStateAtom, services: newServices });
    return keywordsObject;
  };

  const triggerGenerate = () => {
    const generateButtons = document.getElementsByClassName("generate-button");

    Array.from(generateButtons)
      .filter((button) => button.id !== "Generate-v1")
      .forEach((button) => {
        button.click();
      });
  };

  const updateService = async (
    service: any,
    description: string,
    descriptionType: "short" | "long"
  ) => {
    let updatedService: any;
    if (descriptionType == "short") {
      updatedService = {
        ...service,
        [`service-description`]: description,
      };
    } else {
      updatedService = {
        ...service,
        [`service-description-long`]: description,
      };
    }
    const newServices = services.map((s) =>
      s.order === service.order ? updatedService : s
    );
    setActiveService(updatedService);
    setGlobalState({ ...globalStateAtom, services: newServices });
  };

  const updateShortDescription = (description: string) => {
    const thisService = services.find((s) => s.order === activeService.order);
    if (thisService) {
      updateService(thisService, description, "short");
    }
  };

  const updateLongDescription = (description: string) => {
    const thisService = services.find((s) => s.order === activeService.order);
    if (thisService) {
      updateService(thisService, description, "long");
    }
  };

  const reorderServiceImage = (
    sourceIndex: number,
    destinationIndex: number
  ) => {
    const result = [...activeService.photos];
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);
    const newPhotos = result.map((elem, index) => ({ ...elem, order: index }));
    const newServices = services.map((s) =>
      s.order === activeService.order
        ? { ...activeService, photos: newPhotos }
        : s
    );
    setActiveService({ ...activeService, photos: newPhotos });
    setGlobalState({ ...globalStateAtom, services: newServices });
  };

  const [loading, setLoading] = useState(false);
  const [longGenerateLoading, setLongGenerateLoading] = useState(false);
  const [longV2GenerateLoading, setLongV2GenerateLoading] = useState(false);

  async function generate(type: "short" | "long" | "longV2") {
    try {
      switch (type) {
        case "short":
          setLoading(true);
          break;
        case "long":
          setLongGenerateLoading(true);
          break;
        case "longV2":
          setLongV2GenerateLoading(true);
          break;
      }

      const newServices = [...services];
      const serviceName =
        services[activeService.order] &&
        services[activeService.order]["service-name"];

      const thisService = newServices.find(
        (service: any) => service["service-name"] === serviceName
      );

      if (!thisService["service-description-long"])
        thisService["service-description-long"] = "";

      if (thisService) {
        if (!type) throw new Error("No type provided");
        if (!company_type) throw new Error("No industry provided");
        if (!serviceName) throw new Error("No service provided");
        if (!company_name) throw new Error("No company name provided");
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/services/generate-description`,
          {
            params: {
              type: type,
              industry: company_type,
              service: serviceName.replace("/", " "),
              company_name: company_name,
              primary_keyword: thisService.primary_keyword,
              keywords: thisService.keywords,
            },
          }
        );
        const generatedContent = response.data.message;

        if (type === "short") {
          thisService["service-description"] = generatedContent;
          updateShortDescription(generatedContent);
        } else if (type === "long" || type === "longV2") {
          thisService["service-description-long"] = generatedContent;
          updateLongDescription(generatedContent);
        }
      }
      setGlobalState({ ...globalStateAtom, services: newServices });
      if (type === "short") {
        setLoading(false);
      } else if (type === "long") {
        setLongGenerateLoading(false);
      } else {
        setLongV2GenerateLoading(false);
      }
    } catch (error) {
      // console.log('Error generating',error)
      const e = error as Error;
      alert(`Error generating: ${e.message}`);
      setLoading(false);
      setLongGenerateLoading(false);
      setLongV2GenerateLoading(false);
    }
  }

  const addPhotosToService = () => {
    // @ts-ignore
    const thisService = activeService;
    // make copy of the services array
    const newServices = [...services] as any[];
    if (thisService) {
      // find the service with the matching 'order' property
      const posOfThisService = newServices.findIndex(
        (serv: any) => serv.order === thisService.order
      );
      // @ts-ignore
      const selectedPhotosArray = Object.keys(selectedPhotos)
        .filter((s) => s.includes("https://"))
        .map((item, idx) => {
          return {
            photo: item,
            order:
              // eslint-disable-next-line no-unsafe-optional-chaining
              idx + newServices[posOfThisService]?.photos?.length
                ? idx +
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  newServices[posOfThisService]?.photos?.length
                : idx,
            stock: false,
          };
        });
      // @ts-ignore
      if (
        newServices[posOfThisService] &&
        newServices[posOfThisService].photos
      ) {
        // @ts-ignore
        newServices[posOfThisService].photos = [
          ...newServices[posOfThisService].photos,
          ...selectedPhotosArray,
        ];
      } else {
        // @ts-ignore
        newServices[posOfThisService].photos = [...selectedPhotosArray];
      }
      setActiveService(newServices[posOfThisService]);
      setGlobalState({ ...globalStateAtom, services: newServices });
    }
    setSelectedPhotos({});
  };

  const deleteService = (galleryName: any) => {
    setActiveService(services[0] ? services[0] : {});
    const confirmation = prompt(
      `Are you sure you want to delete the ${galleryName} service?`,
      "yes"
    );

    if (confirmation) {
      const newServices: any[] = [...services];
      const posOfGalleryName = newServices.findIndex(
        (galName) => galName["service-name"] === galleryName
      );
      newServices.splice(posOfGalleryName, 1);
      const orderedServices = newServices.map((s, index) => {
        return {
          ...s,
          order: index,
        };
      });
      // @ts-ignore
      setGlobalState({ ...globalStateAtom, services: orderedServices });
    }
  };

  const handleServiceNameError = (name: string) => {
    const newServices = [...services];
    // make sure the service isn't left blank bc that causes problems
    if (name === "") {
      const posOfThisService = newServices.findIndex(
        (serv) =>
          serv["service-name"] === "" || serv["service-name"] === undefined
      );
      // @ts-ignore
      newServices[posOfThisService]["service-name"] = `SERVICE ${uuidv4()}`;
      setGlobalState({ ...globalStateAtom, services: newServices });
      return;
    }
    // find the service with the matching 'order' property
    // @ts-ignore
    const thisService =
      services[activeService.order] && services[activeService.order];

    // @ts-ignore
    const anyItemsWithSameName = newServices.filter(
      (serv: any) => serv["service-name"] === thisService["service-name"]
    );
    // make sure it doesn't have the same name as another service bc that causes problems
    if (anyItemsWithSameName.length > 1) {
      // @ts-ignore
      newServices[thisService.order] = {
        ...activeService,
        // @ts-ignore
        "service-name": activeService["service-name"] + uuidv4(),
      };
      setGlobalState({ ...globalStateAtom, services: newServices });
      alert("Changing the name of this service to remove duplicates");
    }
  };

  return !localMounted ? (
    <img src="/loading.gif" width="25px" />
  ) : (
    <div>
      <div className={`${pageContainerWithOverflow} flex w-screen`}>
        <div className="flex-none flex flex-col w-80 whitespace-nowrap overflow-scroll bg-white h-full min-h-screen -ml-6 -mt-6">
          <a
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/1c0MpioTBXoOHeJU9xBDudsoeG15oFtLWL1A56Tv_DAE/edit"
          >
            ideas
          </a>

          <SideNav
            currentGalleryNames={
              services &&
              services.length &&
              services.map((s) => s["service-name"])
            }
            updateGalleryName={updateServiceName}
            onDragEnd={onDragEnd}
            activeItemId={`/services/${
              services[0] ? services[0]["service-name"] : "noservice"
            }`}
            onSelect={({ itemId }) => {
              // if the service name has a slash in it we need to do some further string massaging
              const selectedServiceName =
                itemId.split("/")[1] === "services"
                  ? itemId.split("/").length === 4
                    ? `${itemId.split("/")[2]}/${itemId.split("/")[3]}`
                    : itemId.split("/")[2]
                  : "";
              const selectedService = services?.find(
                (service) => service["service-name"] === selectedServiceName
              );
              if (selectedService) setActiveService(selectedService);
            }}
            items={[
              {
                title: "Service",
                itemId: "/services",
                subNav: services &&
                  services.length && [
                    ...services.map((service: any) => {
                      return {
                        title: service["service-name"],
                        itemId: `/services/${service["service-name"]}`,
                        // eslint-disable-next-line
                        elemBefore: () => (
                          <IoTrashOutline
                            opacity="80%"
                            size={20}
                            onClick={() => {
                              deleteService(service["service-name"]);
                            }}
                          />
                        ),
                        draggableId: uuidv4(),
                      };
                    }),
                  ],
                draggableId: uuidv4(),
              },
            ]}
          />
          <div className="self-center mt-3">
            <AddServiceButton setActiveService={setActiveService} />
          </div>
          <div className="mt-12 m-2 py-2 px-4 rounded-md">
            <label className={`${labelWrap} -ml-3 pl-6 w-full`}>
              Link text:
              <input
                className={borderedInputWhite}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    service_link_text: e.target.value,
                  })
                }
                value={globalStateAtom.service_link_text}
              />
            </label>
          </div>
        </div>
        <div className="w-full">
          <ServiceKeywordFamily
            service={activeService}
            setActiveService={setActiveService}
            generate={generate}
          />
          <div className="flex space-x-6 flex-row m-12">
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="flex-none w-1/2 bg-white rounded-lg p-6 flex flex-col">
                <label
                  className={
                    services[activeService.order] &&
                    services[activeService.order]["service-description"]
                      ?.toLowerCase()
                      ?.includes("year")
                      ? "bg-red-200 w-full mb-4"
                      : "bg-green-200 w-full mb-4"
                  }
                >
                  <div className="flex justify-between w-full -mb-1">
                    <p>Short Description</p>
                    <Generate
                      loading={loading}
                      generate={() => generate("short")}
                    />
                  </div>
                  <textarea
                    spellCheck="true"
                    className={`${borderedInputWhite} text-sm`}
                    onChange={(e) => {
                      updateShortDescription(e.target.value);
                    }}
                    value={
                      services[activeService.order] &&
                      services[activeService.order]["service-description"]
                    }
                    rows={4}
                  />
                </label>
                <label
                  className={
                    (
                      services[activeService.order] &&
                      services[activeService.order]["service-description-long"]
                    )
                      ?.toLowerCase()
                      ?.includes("year")
                      ? "bg-red-200 w-full mb-4"
                      : "bg-green-200 w-full mb-4"
                  }
                >
                  <div className="flex justify-between w-full -mb-1">
                    <p className="">Long Description - Benefits</p>
                    <Generate
                      loading={longGenerateLoading}
                      serviceName={activeService["service-name"]}
                      type="benefits"
                      generate={() => generate("long")}
                      buttonName="Generate-v1"
                    />
                    <Generate
                      loading={longV2GenerateLoading}
                      serviceName={activeService["service-name"]}
                      type="benefits"
                      generate={() => generate("longV2")}
                      buttonName="Generate-v2"
                    />
                  </div>
                  <textarea
                  spellCheck="true"
                    className={`${borderedInputWhite} text-sm`}
                    onChange={(e) => updateLongDescription(e.target.value)}
                    value={
                      services[activeService.order] &&
                      services[activeService.order]["service-description-long"]
                    }
                    rows={28}
                  />
                </label>
              </div>
            </DragDropContext>
            <div className="flex-1 flex flex-col">
              <ServiceImages
                thisService={
                  services[activeService.order] && services[activeService.order]
                }
                activeService={activeService}
                setActiveService={setActiveService}
                reorderServiceImage={reorderServiceImage}
              />
              <PhotoGallery
                setSelectedPhotos={setSelectedPhotos}
                selectedPhotos={selectedPhotos}
                addPhotosToService={addPhotosToService}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-none m-12 p-6 ml-5 flex flex-col">
        <div className="flex justify-between w-full -mb-1 bg-green-200 w-full mb-4">
          <p className="">Service Packages</p>
        </div>
        <ServicePackages
          service={activeService}
          setActiveService={setActiveService}
        />
      </div>
    </div>
  );
}
