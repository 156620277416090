/* eslint-disable */
import axios from "axios";
import { useAtom } from "jotai";
import { v4 as uuidv4 } from "uuid";
import globalState from "../../../store";
import { useState } from "react";

const PullReviewsButton: React.FC = () => {
    const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);
    const [loading, setLoading] = useState<boolean>(false);
    const { facebook, testimonials, address } = globalStateAtom;

    const handleclick = async () => {
        if (!facebook) {
            return alert("No facebook link. Please fill in the form");
        }
        setLoading(true);
        const fbSlug: string = facebook
            .replace("https://facebook.com/", "")
            .replace("http://facebook.com/", "")
            .replace("https://www.facebook.com/", "")
            .replace("http://www.facebook.com/", "")
            .replace("facebook.com/", "");

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_NODE_SERVER_URL}/getFacebookReviewsEndpoint/${fbSlug}`
            );
            let reviews = response.data.reviews;

            // get array of reviews that are already in db
            const testimonialsBodyArr = testimonials.map(
                (review: any) => review.review
            );
            const sortedTestimonials = testimonials
                .sort((a: any, b: any) => a.order - b.order)
                .map((testimonial: any, index: number) => ({
                    ...testimonial,
                    order: index,
                }));

            // remove duplicates
            reviews = reviews.filter(
                (review: any) => !testimonialsBodyArr.includes(review.review)
            );
            if (!reviews.length) {
                setLoading(false);
                return alert("No more reviews to pull.");
            }

            // format reviews
            reviews.map((review: any, index: number) => ({
                ...review,
                order: sortedTestimonials.length + index,
                title: review.title ? review.title : address,
                draggableId: uuidv4(),
            }));
            const newReviews = [...sortedTestimonials, ...reviews];

            setGlobalStateAtom({
                ...globalStateAtom,
                testimonials: newReviews,
            });
        } catch (e: any) {
            alert("Error pulling reviews");
        }
        setLoading(false);
    };

    return loading ? (
        <div className="flex">
            <img src="/loading.gif" width="20" alt="loading" />
            <p className="ml-3">Pulling reviews...</p>
        </div>
    ) : (
        <button
            className="py-2 px-4 rounded-full border-2 border-red-500 text-red-500 bg-white hover:bg-gray-300"
            onClick={handleclick}
        >
            Pull More Reviews
        </button>
    );
};

export default PullReviewsButton;
