/* eslint-disable */
import {
  borderedInputWhite,
  boxContainerFull,
  label,
  labelWrap,
} from "../../../shared/styles";
import Props from "../../../store/props";
import GenerateThreeStep from "./GenerateThreeStep";

const ThreeStep: React.FC<Props> = ({ setGlobalState, globalState }) => {
  const {
    three_step_1_header,
    three_step_1_subtitle,
    three_step_2_header,
    three_step_2_subtitle,
    three_step_3_header,
    three_step_3_subtitle,
  } = globalState;

  return (
    <div className="w-full flex flex-col">
      <section className="flex w-full justify-between -mb-6">
        <h2 className="self-start ml-12 ">3-Step</h2>
      </section>
      <div className={`${boxContainerFull} w-1/2 h-full`}>
        <div className="flex justify-between">
          <label className={labelWrap}>
            Step 1 Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  three_step_1_header: e.target.value,
                })
              }
              value={three_step_1_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            <div className="flex justify-between w-full ">
              <label>Step 1 Subtitle</label>
              <GenerateThreeStep step={0} />
            </div>
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  three_step_1_subtitle: e.target.value,
                })
              }
              value={three_step_1_subtitle}
            />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={labelWrap}>
            Step 2 Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  three_step_2_header: e.target.value,
                })
              }
              value={three_step_2_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            <div className="flex justify-between w-full ">
              <label>Step 2 Subtitle</label>
              <GenerateThreeStep step={1} />
            </div>
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  three_step_2_subtitle: e.target.value,
                })
              }
              value={three_step_2_subtitle}
            />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={labelWrap}>
            Step 3 Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  three_step_3_header: e.target.value,
                })
              }
              value={three_step_3_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            <div className="flex justify-between w-full ">
              <label>Step 2 Subtitle</label>
              <GenerateThreeStep step={2} />
            </div>
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  three_step_3_subtitle: e.target.value,
                })
              }
              value={three_step_3_subtitle}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default ThreeStep;
