import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { IoCloseCircle } from "react-icons/io5";
import {
  borderedInputWhite,
  borderedInputWhiteWrap,
  boxContainerFull,
  label,
} from "../../../shared/styles";
import Generate from "./Generate";
import { useAtom } from "jotai";
import globalState from "../../../store";
import { IReviewSource, ITestimonial } from "../../../types/types";

interface ReviewProps {
  review: ITestimonial;
  draggableId: string;
  handleDelete: () => void;
  index: number;
  reviewSources: IReviewSource[];
  loading: boolean;
}

const Review: React.FC<ReviewProps> = ({
  review,
  index,
  draggableId,
  handleDelete,
  reviewSources,
  loading,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { testimonials } = globalStateAtom;

  const setReview = (review: ITestimonial) => {
    const newReviews = [...testimonials];
    newReviews[index] = review;
    setGlobalState({ ...globalStateAtom, testimonials: newReviews });
  };

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        // 'provided' is a built-in ref of the beautiful-dnd library that keeps track of the element
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${boxContainerFull} flex flex-wrap my-3 ml-6 px-9 pb-9 pt-5 relative overflow-hidden`}
        >
          {loading && (
            <div
              className="flex items-center justify-center absolute inset-0 bg-gray-400 bg-opacity-50 cursor-not-allowed"
              onClick={(e) => e.stopPropagation()}
              onDrag={(e) => e.stopPropagation()}
            >
              <img src="/loading.gif" width="20" alt="loading" />
              <p className="ml-3">Loading...</p>
            </div>
          )}
          <button
            disabled={loading}
            onClick={() => {
              const confirmation = prompt(
                "Are you sure you want to delete this review?",
                "yes"
              );
              if (confirmation) handleDelete();
            }}
            className="absolute transform -translate-y-4 -translate-x-8 rounded-full cursor-pointer disabled:cursor-not-allowed"
          >
            <IoCloseCircle color="#E5E7EB" size={30} />
          </button>
          <div className="flex flex-col w-1/3 pr-9">
            <label className={`${label} pb-1`}>
              Name
              <input
                className={borderedInputWhite}
                onChange={(e) =>
                  setReview({
                    ...review,
                    reviewer: e.target.value,
                  })
                }
                value={review.reviewer}
              />
            </label>
            <label className={`${label} pb-1`}>
              Stars
              <input
                className={borderedInputWhiteWrap}
                onChange={(e) =>
                  setReview({
                    ...review,
                    stars: e.target.value,
                  })
                }
                value={review.stars}
              />
            </label>
            <label className={`${label} pb-1`}>
              Service
              <input
                className={borderedInputWhite}
                onChange={(e) =>
                  setReview({
                    ...review,
                    service: e.target.value,
                  })
                }
                value={review.service}
              />
            </label>
            {/* DATA CHANGE: add location to each review */}
            <label className={`${label} pb-1`}>
              Location
              <input
                className={borderedInputWhite}
                onChange={(e) =>
                  setReview({
                    ...review,
                    title: e.target.value,
                  })
                }
                value={review.title}
              />
            </label>
            <label className={`${label} pb-1`}>
              Approved
              <select
                onChange={(e) => {
                  setReview({
                    ...review,
                    status: e.target.value,
                  });
                }}
                name="status"
                value={review.status}
                className={`${borderedInputWhite} flex py-2 pb-3`}
              >
                <option value="approved">approved</option>
                <option value="unapproved">unapproved</option>
              </select>
            </label>
          </div>
          <div className="flex flex-col w-2/3">
            <label className={`${label} w-full`}>
              <div className="flex justify-between w-full">
                <p>Review</p>
                <Generate
                  service={review.service}
                  index={index}
                  setReview={setReview}
                />
              </div>
              <textarea
                className={borderedInputWhite}
                onChange={(e) => {
                  setReview({
                    ...review,
                    review: e.target.value,
                  });
                }}
                value={review.review}
                rows={9}
              />
            </label>
            <label className={`${label} w-full pt-1`}>
              Source
              <select
                onChange={(e) => {
                  setReview({
                    ...review,
                    source: e.target.value,
                  });
                }}
                value={review.source}
                name="source"
                className={`${borderedInputWhite} flex py-2 pb-3`}
              >
                <option value="Facebook">Facebook</option>
                <option value="Google">Google</option>
                <option value="Yelp">Yelp</option>
                {reviewSources &&
                  reviewSources?.map((source) => {
                    return (
                      <option key={source?.name} value={source?.name}>
                        {source?.name}
                      </option>
                    );
                  })}
              </select>
            </label>
            <div className="pt-2">
              <label className="flex gap-1 items-center">
                <input
                  type="checkbox"
                  className="cursor-pointer"
                  // value={reviewState?.draggableId}
                  checked={review?.checked}
                  onChange={(e) => {
                    setReview({
                      ...review,
                      checked: e.target.checked,
                    });
                  }}
                />
                Categorize Review Location & Service
              </label>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Review;
