/* eslint-disable */
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { IoTrashOutline, IoCropOutline } from "react-icons/io5";
import { boxContainer } from "../../../shared/styles";
import globalState from "../../../store";
import { getExternalPhotoUrl } from "../../../utils/helpers/getExternalPhotoUrl";
import {
  pushImageToS3,
  uploadExternalImageUrl,
} from "../../../utils/helpers/uploadFileUtil";
import { getJpegFileFromHeic } from "../../photos/components/utils";
import UploadImages from "../../services/components/upload/UploadImages";
import {
  checkApplePatentImageExtension,
  generateRefinedLogo,
  getFileExtensionFromType,
} from "./utils";
import ImageCropModal from "./image-crop-modal/ImageCropModal";
import {
  getMediaTypeFromURL,
  removeBgTypes,
  videoTypes,
} from "../../../utils/helpers/getMediaType";
import SwitchToggle from "../../../shared/SwitchToggle";

const Logo: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { logo, sitePk, logo_refined, use_refined_logo } = globalStateAtom;
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [logoType, setLogoType] = useState<string>("");

  useEffect(() => {
    if (logo) {
      setLogoType(getMediaTypeFromURL(logo));
    } else {
      setLogoType("");
    }
  }, [logo]);

  const onChangeHandler = async (files: FileList) => {
    // check if any link is dropped but not a file
    setLoading(true);
    let file = (files && files[0]) || null;
    if (/image\//.test(file?.type) === true) {
      const fileExtension = getFileExtensionFromType(file.type);
      if (checkApplePatentImageExtension(fileExtension)) {
        file = await getJpegFileFromHeic(file);
      }
    }
    // // console.log(file, 'file here')
    if (file) {
      const [uploadedImageUrl] = await pushImageToS3(file);
      // // console.log(uploadedImageUrl, 'uploadedImageUrl')
      if (uploadedImageUrl) {
        setLoading(false);
        setGlobalState({
          ...globalStateAtom,
          logo: uploadedImageUrl,
          logo_refined: "",
          use_refined_logo: false,
        });
      }
    }
  };

  const onDropHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      onChangeHandler(files);
    } else {
      // const imageUrl = event.dataTransfer.getData('text/html')
      // const rex = /src="?([^"\s]+)"?\s*/
      // let url = rex.exec(imageUrl)[1]
      // url = url.replace(/;/g, '&')
      const url = await getExternalPhotoUrl(event.dataTransfer.getData("url"));

      if (url) {
        const uploadedLogoDataFileName = await uploadExternalImageUrl(url);
        if (uploadedLogoDataFileName) {
          const uploadedLogoDataUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${uploadedLogoDataFileName}`;
          setGlobalState({
            ...globalStateAtom,
            logo: uploadedLogoDataUrl,
            logo_refined: "",
            use_refined_logo: false,
          });
        }
      }
    }
    setLoading(false);
  };

  const openImageCropModal = () => {
    if (!logo || videoTypes.includes(logoType)) return;
    setIsModalOpen(true);
  };

  const closeImageCropModal = () => {
    setIsModalOpen(false);
  };

  const setCroppedImage = (imageURL: string) => {
    setGlobalState({
      ...globalStateAtom,
      logo: imageURL,
      logo_refined: "",
      use_refined_logo: false,
    });
  };

  const onUseRefinedLogoChange = async (checked: boolean) => {
    if (checked && !logo_refined && logo && removeBgTypes.includes(logoType)) {
      setLoading(true);

      try {
        const new_refined_logo = await generateRefinedLogo(sitePk, logo);
        setGlobalState({
          ...globalStateAtom,
          logo_refined: new_refined_logo,
          use_refined_logo: true,
        });
      } catch (e) {
        console.log(e);
        setGlobalState({
          ...globalStateAtom,
          use_refined_logo: false,
        });
      }
      setLoading(false);
    } else {
      setGlobalState({
        ...globalStateAtom,
        use_refined_logo: !use_refined_logo,
      });
    }
  };

  return (
    <>
      <h2 className="absolute top-32 left-24">Logo</h2>
      <div className={boxContainer}>
        <div className="flex flex-col justify-center">
          <div className="flex justify-between w-full">
            <p className="self-start">Upload your logo</p>
            <div className="flex items-center justify-between w-48">
              <p className="self-start">Current Logo</p>
              <IoCropOutline
                className={
                  !logo || videoTypes.includes(logoType) || logoType === "gif"
                    ? "opacity-25 cursor-not-allowed"
                    : "cursor-pointer"
                }
                size={20}
                title="Crop logo"
                onClick={openImageCropModal}
              />
              <IoTrashOutline
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  setGlobalState({
                    ...globalStateAtom,
                    logo: "",
                    logo_refined: "",
                    use_refined_logo: false,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex-col justify-start items-center mt-4">
            <div className="h-24">
              <UploadImages
                onChangeHandler={onChangeHandler}
                onDropHandler={onDropHandler}
              />
            </div>
            {use_refined_logo ? (
              <div className="flex justify-center w-full mt-2">
                {loading ? (
                  <img src="/loading.gif" width="25px" />
                ) : (
                  <img
                    src={logo_refined}
                    className="max-w-auto max-h-24"
                    id="logo-image"
                  />
                )}
              </div>
            ) : (
              <div className="flex justify-center w-full mt-2">
                {loading ? (
                  <img src="/loading.gif" width="25px" />
                ) : videoTypes.includes(logoType) ? (
                  <video
                    className="max-w-auto max-h-24"
                    autoPlay
                    loop
                    muted
                    playsInline
                  >
                    <source
                      src={logo}
                      type={logoType != "quicktime" ? "video/" + logoType : ""}
                    />
                  </video>
                ) : (
                  <img
                    src={logo}
                    className="max-w-auto max-h-24"
                    id="logo-image"
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {logo && removeBgTypes.includes(logoType) && (
          <div className="mt-2 ml-2 p-3 flex bg-gray-100 rounded-lg">
            <SwitchToggle
              checked={use_refined_logo}
              onChange={
                onUseRefinedLogoChange as React.Dispatch<
                  React.SetStateAction<boolean>
                >
              }
              label="Use logo with transparent background"
            />
          </div>
        )}
      </div>
      <ImageCropModal
        isOpen={isModalOpen}
        closeModal={closeImageCropModal}
        setImageURL={setCroppedImage}
        imageURL={logo}
      />
    </>
  );
};

export default Logo;
