/* eslint-disable */
import React from "react";
import UploadFile from "./UploadFile";

interface UploadImagesProps {
  onDropHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeHandler: (files: FileList) => void;
  activeService?: any;
  accept?: string;
  multiple?: boolean;
}

const UploadImages = ({
  onDropHandler,
  onChangeHandler,
  activeService,
  multiple = false
}: UploadImagesProps) => {
  return (
    <UploadFile
      onDropHandler={onDropHandler}
      onChangeHandler={onChangeHandler}
      activeService={activeService}
      accept={"image/*,video/*"}
      multiple={multiple}
    />
  );
};

export default UploadImages;
