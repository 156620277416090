import axios from "axios";
import { IReviewSource } from "../../../types/types";

export const getReviewSources = async (): Promise<IReviewSource[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/get-review-sources`
    );
    return response.data.data as IReviewSource[];
  } catch (e) {
    console.log("error getting review sources");
    return [];
  }
};
