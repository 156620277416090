/* eslint-disable */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import PhotoProps from "../types/photoProps";
import Props from "../../../store/props";
import globalState from "../../../store";
// import AddFaqButton from './components/AddFAQButton'

interface GalleriesButtonsProps {
  addSelectedPhotosToGallery: React.MouseEventHandler<HTMLButtonElement>;
  // addSelectedPhotosToGallery: (item:any, selectedPhotos:any) => void
  selectedPhotos: PhotoProps[];
}

const UncategorizeButton: React.FC<GalleriesButtonsProps> = ({
  selectedPhotos,
  addSelectedPhotosToGallery,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { gallery_names, services, gallery, fbData } = globalStateAtom;

  // needed to move this into a component which had received globalState as a prop so it wouldn't run into a TypeScript error
  const updateGlobalGalleryState = () => {
    const selectedItems: any = { ...selectedPhotos };
    // // console.log(selectedItems, 'sellelkjtlk')

    const newGallery: PhotoProps[] = [...gallery];
    for (let i = 0; i < newGallery.length; i++) {
      const item: any = newGallery[i] && newGallery[i].photo;
      // // console.log(item, 'item!')
      if (selectedItems[item]) {
        // // console.log(selectedItems[item], 'selectedItems[item]')
        newGallery[i] = selectedItems[item];
        delete selectedItems[item];
      }
    }

    // Adding facebook photos
    for (let i = 0; i < Object.keys(selectedItems).length; i++) {
      try {
        const fbPhoto = {
          alt_tag: services[0]["service-name"], // TODO: change this
          archived: false,
          associated_service: services[0]["service-name"],
          gallery: null,
          photo: Object.keys(selectedItems)[i],
          landing_page: globalStateAtom.sitePk,
          order: i + newGallery.length, // TODO: probably change this
          uncropped_gallery_photo: null,
          gallery_name: "",
          selected: false,
          caption: "",
        };
        if (newGallery[newGallery.length] !== fbPhoto)
          newGallery[newGallery.length] = fbPhoto;
        // // console.log(newGallery.length, '-----')
        // delete selectedItems[Object.keys(selectedItems)[i]]
      } catch (e) {
        // console.log(e)
      }
    }
    // remove some weird 'undefined's
    const newFilteredGallery = newGallery.filter((item) => item && item);
    setGlobalState({ ...globalStateAtom, gallery: newFilteredGallery });
  };

  return (
    <button
      onClick={(e) => {
        updateGlobalGalleryState();
        addSelectedPhotosToGallery(e);
      }}
      className="px-2  m-3 rounded-full border-2 border-gray-500 text-gray-500 bg-white"
    >
      Uncategorize
    </button>
  );
};

export default UncategorizeButton;
