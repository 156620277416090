/* eslint-disable */
import React, { useEffect, useState } from "react";

import { IoLocateOutline } from "react-icons/io5";
import ThumbnailPhoto from "../../../shared/ThumbnailPhoto";

const VerticalAlignPhotoThumbnail = ({ selectedPhotos }: any) => {
  const [showMaximizeImage, setShowMaximizeImage] = useState(false);
  const [imageObjectFit, setImageObjectFit] = useState("cover");
  useEffect(() => {
    if (showMaximizeImage) {
      setImageObjectFit("contain");
    } else {
      setImageObjectFit("cover");
    }
  }, [showMaximizeImage]);
  return (
    <div
      className={
        "w-1/3 flex-shrink-0 flex sm:h-30 md:h-auto  hover:bg-opacity-40"
      }
    >
      <div
        className={
          "translate-x-28 w-0 flex flex-row  justify-between pr-5 transform"
        }
      >
        <div
          className={`-ml-20 w-8 h-8 mt-2 mx-2 cursor-pointer rounded-full border-2 border-white bg-gray-600`}
        >
          <IoLocateOutline
            color="white"
            opacity="80%"
            className="w-8 h-8 pb-1 pr-1"
            onClick={() => {
              // handleSelect(photo)
              setShowMaximizeImage(!showMaximizeImage);
            }}
          />
        </div>
      </div>
      <div className="flex flex-row h-48  w-48">
        {Object.keys(selectedPhotos).map((imageUrl) => (
          <ThumbnailPhoto
            imageSrc={imageUrl}
            divClassName={"flex flex-row h-48 mr-px  w-48 "}
            imgClassName={`w-full object-${imageObjectFit} rounded min-h-0   w-48`}
          />
        ))}
      </div>
    </div>
  );
};

export default VerticalAlignPhotoThumbnail;
