/*eslint-disable*/
import axios from "axios";
export async function saveImageInDb(
  slug,
  selectedImage,
  signedS3Photo,
  text0Value,
  text1Value,
  textSettings,
  imagePosition,
  textPosition0,
  textPosition1,
  backgroundColor
) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/ai-logo-create`,
      {
        slug,
        selectedImage,
        signedS3Photo,
        text0Value,
        text1Value,
        textSettings,
        imagePosition,
        textPosition0,
        textPosition1,
        backgroundColor,
      }
    );
    if (response.data.status === "success") return "success";
    else return "error";
  } catch (error) {
    console.log("Error getting image info", error);
    return "error";
  }
}

export async function getAllLogos(slug: string) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/ai-logo-get-all/${slug}`
    );
    return response?.data?.message;
  } catch (e) {
    return [];
  }
}

export function loadFont(fontName) {
  if (document.querySelector(`link[href*='${fontName?.replace(/ /g, "+")}']`)) {
    return;
  }
  const link = document.createElement("link");
  link.href = `https://fonts.googleapis.com/css?family=${fontName.replace(
    / /g,
    "+"
  )}&display=swap`;
  link.rel = "stylesheet";
  document.head.appendChild(link);
}

export async function loadAllGoogleFonts() {
  const allFonts = await axios.get(
    `https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyAjfkfHY7fTWSNgnU4ywiT27Q7336VVxIs`
  );
  const fetchedFonts = allFonts.data.items.map((item) => ({
    name: item.family,
    style: `font-family: ${item.family};`,
  }));

  const fontsToLoad = fetchedFonts.slice(0, 200);
  fontsToLoad.forEach((font) => loadFont(font.name));
}
