/* eslint-disable */
import axios from "axios";
import { sleep } from "./uploadFileUtil";
export const getExternalPhotoUrl = async (photo: string): Promise<string> => {
  let imageUrl = photo;
  if (imageUrl.includes("facebook.com")) {
    const urlParts = imageUrl.split("/");
    if (urlParts[3] !== "photo") {
      imageUrl = `https://www.facebook.com/photo/?fbid=${urlParts.at(-2)}`;
    }
    const response = await axios.get(
      `${process.env.REACT_APP_NODE_SERVER_URL}/getFaceBookPhotoUrl?pageUrl=${imageUrl}`
    );
    if (response.status === 200) {
      imageUrl = response.data.imageSrc;
    }
  }
  return imageUrl;
};
