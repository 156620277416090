import axios from "axios";

type GeonamesResponse = {
  data: {
    geonames: {
      name: string;
      adminCode1: string;
      population: number;
      lat: number;
      lng: number;
    }[];
  };
};

export async function getGeonames(city: string, state: string) {
  try {
    const params = {
      adminCode1: state,
      q: city,
      username: "jonathanrstern",
      featureClass: "P",
      continentCode: "NA",
    };

    const response: GeonamesResponse = await axios.get(
      `https://secure.geonames.org/searchJSON`,
      {
        params,
      }
    );
    return response.data.geonames;
  } catch (e) {
    console.log("error:", e);
    return [];
  }
}

export async function getGeonamesByCoordinates(
  latitude: number,
  longitude: number,
  radius: number
) {
  try {
    const response: GeonamesResponse = await axios.get(
      `https://secure.geonames.org/findNearbyPlaceNameJSON?lat=${latitude}&lng=${longitude}&radius=${radius}&maxRows=100&cities=cities15000&username=jonathanrstern`
    );
    return response.data.geonames;
  } catch (e) {
    console.log("error:", e);
    return [];
  }
}

interface GenericSuggestedGeoName {
  canonical_name: string;
  country_code: string;
  id: number;
  name: string;
  resource_name: string;
  status: number;
  target_type: ["State" | "City" | "Country"];
  population?: number;
  state?: string;
  state_code?: string;
  city?: string;
  parent?: GenericSuggestedGeoName[];
}

export interface SuggestedGeoName extends GenericSuggestedGeoName {
  population: number;
  state: string;
  state_code: string;
  city: string;
  parent: GenericSuggestedGeoName[];
}

export const getSuggestedGeoNames = async (
  searchText: string,
  searchGlobe = false
): Promise<SuggestedGeoName[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/ads/location-suggestion`,
      {
        params: {
          search_term: searchText,
          search_globe: searchGlobe,
        },
      }
    );
    return response.data?.message || [];
  } catch (e) {
    console.error("Error in getSuggestedGeoNames:", e);
    return [];
  }
};

export const getGeoName = async (
  query: string
): Promise<SuggestedGeoName | undefined> => {
  const suggestedGeoNames = await getSuggestedGeoNames(query, true).then(
    (data) => {
      return data.filter(
        (geoName) => geoName.state_code && geoName.country_code === "US"
      );
    }
  );
  return suggestedGeoNames[0];
};
