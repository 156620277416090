/* eslint-disable */
import {
  borderedInputWhite,
  boxContainer,
  boxContainerFull,
  label,
} from "../../../shared/styles";
import Props from "../../../store/props";

const Deals: React.FC<Props> = ({ setGlobalState, globalState }) => {
  const { deal_header, deal_subtitle, deal_button_text, deal_button_link } =
    globalState;

  return (
    <div className="w-full flex flex-col">
      <h2 className="self-start ml-12 -mb-6">Deals</h2>
      <div className={boxContainerFull}>
        <div className="flex justify-between">
          <label className={`${label} w-full`}>
            Deal Header
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  deal_header: e.target.value,
                })
              }
              value={deal_header}
            />
          </label>
          <label className={`${label} w-full ml-6`}>
            Deal Subtitle
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  deal_subtitle: e.target.value,
                })
              }
              value={deal_subtitle}
            />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={`${label} w-full`}>
            Deal Link
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  deal_button_link: e.target.value,
                })
              }
              value={deal_button_link}
            />
          </label>
          <label className={`${label} w-full ml-6`}>
            Display Text
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  deal_button_text: e.target.value,
                })
              }
              value={deal_button_text}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default Deals;
