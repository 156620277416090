/* eslint-disable */
import React from "react";

interface ThumbnailPhotoProps {
  imageSrc: string;
  imgClassName?: string;
  divClassName?: string;
  imageAlt?: string;
}

const ThumbnailPhoto: React.FC<ThumbnailPhotoProps> = ({
  imageSrc,
  imgClassName,
  divClassName,
  imageAlt,
}) => {
  return (
    <div className={divClassName}>
      <img className={imgClassName} src={imageSrc} alt={imageAlt} />
    </div>
  );
};
ThumbnailPhoto.defaultProps = {
  divClassName: "flex flex-col h-48 w-48 w-full ",
  imgClassName: " object-cover h-48 w-48 min-h-0",
  imageAlt: "",
};
export default ThumbnailPhoto;
