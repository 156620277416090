/* eslint-disable */
import { useAtom } from "jotai";
import { useState } from "react";
import { borderedInputWhite, label } from "../../../shared/styles";
import globalState from "../../../store";

const BusinessHours: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { business_hours } = globalStateAtom;

  const [businessHoursState, setBusinessHours] = useState(business_hours);

  console.log("business_hours from global state", business_hours);
  console.log("businessHoursState", businessHoursState);

  const changeBusinessHours = (
    val: string,
    idx: number,
    fieldToEdit: string
  ) => {
    const newBusinessHours: { to: string; from: string; custom: string }[] = [
      ...businessHoursState,
    ];
    if (!newBusinessHours[idx]) newBusinessHours[idx] = {} as any;
    if (fieldToEdit === "to") newBusinessHours[idx].to = val;
    else if (fieldToEdit === "custom") newBusinessHours[idx].custom = val;
    else newBusinessHours[idx].from = val;
    setBusinessHours(newBusinessHours);
    setGlobalState({ ...globalStateAtom, business_hours: newBusinessHours });
  };
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <>
      {days.map((day, index) => {
        return (
          <label key={day} className={label}>
            <div className="flex justify-between w-full items-center">
              <div className="w-24">{day}</div>
              <div className="flex justify-between w-full items-center">
                <input
                  type="time"
                  value={businessHoursState[index]?.from}
                  onChange={(e) =>
                    changeBusinessHours(e.target.value, index, "from")
                  }
                  className={`${borderedInputWhite} m-5`}
                />
                <input
                  type="time"
                  value={businessHoursState[index]?.to}
                  onChange={(e) =>
                    changeBusinessHours(e.target.value, index, "to")
                  }
                  className={`${borderedInputWhite} m-5`}
                />
                <label>
                  Custom
                  <input
                    type="text"
                    value={businessHoursState[index]?.custom || ""}
                    onChange={(e) =>
                      changeBusinessHours(e.target.value, index, "custom")
                    }
                    className={`${borderedInputWhite} m-5`}
                  />
                </label>
              </div>
            </div>
          </label>
        );
      })}
    </>
  );
};

export default BusinessHours;
