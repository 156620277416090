/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import globalState from "../../store";
import AddServiceButton from "./components/AddFaqButton";
import Column from "./components/Column";
import { pageContainerWithOverflow } from "../../shared/styles";
import GenerateFaqs from "./components/GenerateFaqs";

interface ItemProps {
  answer: string;
  "landing-page": number;
  order: number;
  question: string;
  draggableId: string;
}

export default function FaqsPage() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { faqs } = globalStateAtom;

  // const [localFaqs, setLocalFaqs] = useState<ItemProps[]>(faqs)

  // generating a consistent draggableId and key for each review
  useEffect(() => {
    const fAQs = faqs.map((item: ItemProps) => {
      return { ...item, draggableId: uuidv4() };
    });
    // setLocalFaqs(fAQs)
    // @ts-ignore
    setGlobalState({ ...globalStateAtom, faqs: fAQs });
  }, []);

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (destination.index === source.index) return null;

    // Move the item within the list
    // Start by making a new list without the dragged item
    const newList = globalStateAtom.faqs.filter(
      (_: any, idx: number) => idx !== source.index
    );

    // Then insert the item at the right location
    newList.splice(destination.index, 0, globalStateAtom.faqs[source.index]);

    // Update the list
    // setLocalFaqs(newList)
    // @ts-ignore
    setGlobalState({ ...globalStateAtom, faqs: newList });
  };

  return (
    <div className={pageContainerWithOverflow}>
      <section className="flex justify-around align-middle items-center">
        <AddServiceButton />
        <GenerateFaqs />
      </section>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="w-full flex justify-center">
          <Column
          // setGlobalState={setGlobalState}
          // globalState={globalStateAtom}
          // localFaqs={localFaqs}
          // setLocalFaqs={setLocalFaqs}
          />
        </div>
      </DragDropContext>
    </div>
  );
}
