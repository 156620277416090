/* eslint-disable */
import { useAtom } from "jotai";
import React, { useState, useEffect, useRef } from "react";
import {
  DragDropContext,
  DropResult,
  Draggable,
  Droppable,
} from "react-beautiful-dnd";

import globalState from "../../../store";
import {
  getDefaultIndustry,
  getDefaultIndustryServices,
} from "../../../utils/server/industry";
import InActiveService from "./InActiveService";
import { IDefaultService } from "../../../types/types";
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ElemBefore: React.ReactNode;
    }
  }
}

export type NavItemProps = {
  title: string;
  itemId: string;
  disabled?: boolean;
  elemBefore?: React.FC<unknown>;
  subNav?: NavItemProps[];
  draggableId: string;
};

export type SideNavProps = {
  items: NavItemProps[];
  activeItemId: string;
  onSelect?: ({ itemId }: { itemId: string }) => void;
  updateGalleryName: (galleryName: any) => void;
  onDragEnd: ({ source, destination }: DropResult) => null | undefined;
  currentGalleryNames: any[];
};

const SideNav: React.FC<SideNavProps> = ({
  activeItemId,
  onSelect,
  items,
  updateGalleryName,
  onDragEnd,
  currentGalleryNames,
}) => {
  const [activeSubNav, setActiveSubNav] = useState({
    expanded: true,
    selectedId: activeItemId,
  });
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [industryServices, setIndustryServices] = useState<IDefaultService[]>(
    []
  );
  const [inactiveIndustryServices, setInactiveIndustryServices] = useState<
    IDefaultService[]
  >([]);
  const [editing, setEditing] = useState("");
  const [focused, setFocused] = useState(false);
  const [newGalleryName, setNewGalleryName] = useState("");
  const inputEl = useRef<HTMLInputElement>(null);

  const { company_type = "" } = globalStateAtom || {};

  useEffect(() => {
    fetchAndSetDefaultIndustryServices();
  }, []);

  useEffect(() => {
    const filteredServices = getFilteredServices(industryServices);
    setInactiveIndustryServices(filteredServices);
  }, [items]);
  // Listen for parent prop changes and update state
  useEffect(() => {
    setActiveSubNav((originalSubNav) => ({
      expanded: true,
      selectedId: activeItemId,
    }));
  }, [activeItemId]);

  async function fetchAndSetDefaultIndustryServices() {
    const defaultIndustries = await getDefaultIndustry();

    const currentIndustry = defaultIndustries.find(
      (industry: any) => industry.company_type === company_type
    );
    if (currentIndustry) {
      const { id } = currentIndustry;
      const defaultIndustryServices = await getDefaultIndustryServices(id);
      // filter services that are already in the current items.item.subNav
      setIndustryServices(defaultIndustryServices);
      const filteredServices = getFilteredServices(defaultIndustryServices);

      setInactiveIndustryServices(filteredServices);
    }
  }
  function getFilteredServices(services: IDefaultService[]) {
    let filteredServices: IDefaultService[] = [];
    if (
      items &&
      items.length &&
      items.length > 0 &&
      items[0].subNav &&
      items[0].subNav.length > 0 &&
      services &&
      services.length > 0
    ) {
      filteredServices = services.filter((service: IDefaultService) => {
        const found =
          items &&
          items.length > 0 &&
          items[0]?.subNav?.find(
            (item: any) => item.title === service.service_name
          );
        return !found;
      });
    }
    return filteredServices;
  }

  function handleClick(itemId: string): void {
    // call the callback if supplied
    onSelect?.({ itemId });
  }

  function handleFocus() {
    // this highlights the text as soon as they click into the input
    if (inputEl && inputEl.current && !focused) inputEl.current.select();
    // this makes sure the mouseover event doesn't continue to highlight the text after the initial focus
    setFocused(true);
  }

  function handleSubNavExpand(item: NavItemProps): void {
    if (activeSubNav) {
      const currentItemOrSubNavItemIsOpen: boolean =
        // either the parent item is expanded already
        item.itemId === activeSubNav.selectedId ||
        // or one of its expandable children is selected
        (item.subNav &&
          item.subNav.some(
            (_subNavItem) => _subNavItem.itemId === activeSubNav.selectedId
          )) ||
        false;

      setActiveSubNav({
        expanded: true,
        //   item.subNav && item.subNav.length > 0
        //     ? !currentItemOrSubNavItemIsOpen
        //     : false, // disable expansion value, if not expandable
        selectedId: item.itemId,
      });
    } else {
      setActiveSubNav({
        expanded: true, // expand if expandable
        selectedId: item.itemId,
      });
    }
  }

  function isDuplicateName(name, subNavItem) {
    for (let i = 0; i < currentGalleryNames.length; i++) {
      if (
        name === currentGalleryNames[i] &&
        name !== subNavItem.selectedId.split("/services/")[1]
      ) {
        alert(
          "Please make sure the gallery does not have the same name as another gallery."
        );
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {items.length > 0 && (
          <nav
            role="navigation"
            aria-label="side-navigation"
            className="side-navigation-panel -ml-6"
          >
            {items.map((item: NavItemProps) => {
              const ElemBefore = item.elemBefore;
              const isItemSelected: boolean =
                item.itemId === activeSubNav.selectedId;
              const isActiveTab: boolean =
                // item is expanded and
                activeSubNav.expanded &&
                // either the current expandable section is selected
                (isItemSelected ||
                  // or some item in the expandable section of the current item is selected or active
                  (item.subNav &&
                    item.subNav.some(
                      (_subNavItem: NavItemProps) =>
                        _subNavItem.itemId === activeSubNav.selectedId
                    )) ||
                  true);

              return (
                <ul
                  key={item.draggableId}
                  className="side-navigation-panel-select"
                >
                  <li className="side-navigation-panel-select-wrap">
                    <div
                      onClick={(): void => {
                        handleSubNavExpand(item);
                        handleClick(item.itemId);
                      }}
                      className={`side-navigation-panel-select-option ${
                        isItemSelected
                          ? "side-navigation-panel-select-option-selected"
                          : ""
                      }`}
                    >
                      <span className="side-navigation-panel-select-option-wrap">
                        {/** Prefix Icon Component */}
                        {ElemBefore && <ElemBefore />}

                        <span className="side-navigation-panel-select-option-text pl-6">
                          {item.title}
                        </span>
                      </span>
                    </div>
                  </li>

                  {item.subNav && item.subNav.length > 0 && isActiveTab && (
                    <ul className="side-navigation-panel-select-inner">
                      <Droppable droppableId={"col-1" + item.title}>
                        {(provided) => (
                          <div
                            className="flex flex-col w-8/12 items-start"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {item.subNav!.map(
                              (subNavItem: NavItemProps, index: number) => {
                                const SubItemElemBefore = subNavItem.elemBefore;
                                return (
                                  <li
                                    key={subNavItem.draggableId}
                                    className="side-navigation-panel-select-inner-wrap"
                                  >
                                    <Draggable
                                      draggableId={
                                        subNavItem.draggableId
                                          ? subNavItem.draggableId
                                          : "x" + index
                                      }
                                      index={index}
                                    >
                                      {(provided) => (
                                        // 'provided' is a built-in ref of the beautiful-dnd library that keeps track of the element
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          // className='flex flex-col items-start w-full py-5 m-6 rounded-lg border-2 border-gray-100 bg-white'
                                        >
                                          <div
                                            onClick={(): void => {
                                              setActiveSubNav({
                                                ...activeSubNav,
                                                selectedId: subNavItem.itemId,
                                              });
                                              handleClick(subNavItem.itemId);
                                              // console.log(
                                              //     activeSubNav,
                                              //     subNavItem,
                                              //     'active, subnav'
                                              // )
                                              if (
                                                activeSubNav.selectedId !==
                                                subNavItem.itemId
                                              ) {
                                                setEditing("");
                                                if (
                                                  editing ===
                                                    activeSubNav.selectedId &&
                                                  !isDuplicateName(
                                                    newGalleryName,
                                                    activeSubNav
                                                  ) &&
                                                  newGalleryName !==
                                                    activeSubNav.selectedId
                                                )
                                                  updateGalleryName(
                                                    newGalleryName
                                                  );
                                              }
                                            }}
                                            onDoubleClick={(): void => {
                                              setEditing(subNavItem.itemId);
                                              setNewGalleryName(
                                                subNavItem.title
                                              );
                                            }}
                                            className={`side-navigation-panel-select-inner-option ${
                                              activeSubNav.selectedId ===
                                              subNavItem.itemId
                                                ? "side-navigation-panel-select-inner-option-selected"
                                                : ""
                                            } `}
                                          >
                                            <span className="side-navigation-panel-select-inner-option-wrap">
                                              {/** Prefix Icon Component */}
                                              {SubItemElemBefore && (
                                                <SubItemElemBefore />
                                              )}
                                              {/* {SubItemElemBefore && subNavItem.itemId === activeSubNav.selectedId && <SubItemElemBefore />} */}
                                              {editing === subNavItem.itemId ? (
                                                <input
                                                  ref={inputEl}
                                                  onMouseOver={handleFocus}
                                                  onChange={(e) =>
                                                    setNewGalleryName(
                                                      e.target.value
                                                    )
                                                  }
                                                  value={
                                                    editing
                                                      ? newGalleryName
                                                      : subNavItem.title
                                                  }
                                                  onBlur={(e) => {
                                                    const isDupe =
                                                      isDuplicateName(
                                                        e.target.value,
                                                        subNavItem
                                                      );
                                                    if (isDupe) return;
                                                    updateGalleryName(
                                                      e.target.value
                                                    );
                                                    setEditing("");
                                                    setNewGalleryName("");
                                                    setFocused(false);
                                                  }}
                                                />
                                              ) : (
                                                <span className="side-navigation-panel-select-inner-option-text">
                                                  {subNavItem.title}
                                                </span>
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  </li>
                                );
                              }
                            )}

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </ul>
                  )}
                </ul>
              );
            })}
          </nav>
        )}
      </DragDropContext>
      <div>Default Services To Choose From</div>
      <InActiveService inactiveIndustryServices={inactiveIndustryServices} />
    </>
  );
};

export default SideNav;
