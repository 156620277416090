/* eslint-disable */
import React, { useEffect, useState } from "react";
import HorizontalAlignPhotoThumbnail from "./HorizontalAlignPhotoThumbnail";
import VerticalAlignPhotoThumbnail from "./VerticalAlignPhotoThumbnail";

const MergedThumbnailPhoto = ({
  IsVerticalSelected,
  isHorizontalSelected,
  selectedPhotos,
  onVerticalClickHandler,
  onHorizontalClickHandler,
}: any) => {
  return (
    <>
      <>
        {IsVerticalSelected && Object.keys(selectedPhotos).length > 0 && (
          <VerticalAlignPhotoThumbnail selectedPhotos={selectedPhotos} />
        )}
        {isHorizontalSelected && (
          <HorizontalAlignPhotoThumbnail selectedPhotos={selectedPhotos} />
        )}
      </>
    </>
  );
};

export default MergedThumbnailPhoto;
