/* eslint-disable */
import React from "react";
import { IoTrashOutline } from "react-icons/io5";
import { videoTypes, getMediaType } from "../utils/helpers/getMediaType";

interface ThumbnailProps {
  photo: any;
  handleDelete: (photo: string) => void;
  handleClick: (event: any) => void;
  draggable: boolean;
  height?: string;
  width?: string;
  quality?: number;
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  photo,
  handleDelete,
  handleClick,
  draggable,
  height = "150",
  width = "150",
  quality = 15,
}) => {
  // // console.log('photo',photo)
  return (
    <div
      onClick={handleClick}
      className="flex cursor-pointer w-full h-full mt-3 mx-3 hover:opacity-40"
    >
      <div
        className={`${
          draggable ? "absolute" : "transform -translate-x-3"
        } flex flex-row justify-between pr-5 z-10`}
      >
        <div
          onClick={() => {
            // const confirmation = prompt(
            //   "Are you sure you want to delete this photo?",
            //   "yes"
            // );
            // if (confirmation) {
            // this actually deletes the image from globalState.gallery
            handleDelete(photo.photo);
            // this literally only sets the state of selectedItems so the page re-renders
            // and the item disappears (see the useEffect at the top of photos/index.tsx)
            // rerenderAfterDeletePhoto(photo)
            // }
          }}
          className="w-8 h-8 mt-2 mx-2 px-1 cursor-pointer rounded-full border-2 border-white bg-gray-600 flex justify-center items-center"
        >
          <IoTrashOutline color="white" opacity="80%" size={20} />
        </div>
      </div>
      {videoTypes.includes(getMediaType(photo)) ? ( // ? : }
        <video
          className={
            draggable
              ? "absolute"
              : `transform -translate-x-20 rounded shadow-md object-cover w-full h-fit`
          }
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={photo.photo} type={"video/" + getMediaType(photo)} />
        </video>
      ) : (
        <img
          src={
            photo && photo.photo
              ? photo.photo.includes("fbcdn.net")
                ? photo.photo
                : photo.photo.includes("https")
                ? `https://d3p2r6ofnvoe67.cloudfront.net/fit-in/${height}x${width}/filters:quality(${quality})/filters:strip_exif()/filters:format(webp)/media/${
                    photo.photo.split(
                      "https://landing-page-app-hero-images.s3.amazonaws.com/media/"
                    )[1]
                  }`
                : `https://d3p2r6ofnvoe67.cloudfront.net/fit-in/${height}x${width}/filters:quality(${quality})/filters:strip_exif()/filters:format(webp)/media/${
                    photo.photo.split(
                      "http://landing-page-app-hero-images.s3.amazonaws.com/media/"
                    )[1]
                  }`
              : "/prophone_favicon_square.png"
          }
          className={
            draggable
              ? "absolute"
              : `transform -translate-x-20 rounded shadow-md object-cover w-full h-fit`
          }
        />
      )}
    </div>
  );
};

export default Thumbnail;
