/* eslint-disable */
import { useAtom } from "jotai";
import {
  borderedInputWhite,
  boxContainerFull,
  labelWrap,
} from "../../../shared/styles";
import globalState from "../../../store";

const NavLinks: React.FC = () => {
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState)
  const {
    home_nav_title,
    service_nav_title,
    gallery_nav_title,
    reviews_nav_title,
    team_nav_title,
    jobs_nav_title
  } = globalStateAtom

  return (
    <div className={`${boxContainerFull} w-1/2 h-full`}>
      <h2>Pages Nav Titles</h2>
      <div className="flex justify-between">
        <label className={labelWrap}>
          Home Nav Title
          <input
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalStateAtom({
                ...globalStateAtom,
                home_nav_title: e.target.value,
              })
            }
            value={home_nav_title}
          />
        </label>
        <label className={`${labelWrap} -ml-3 pl-6 w-7/12`}>
          Services Nav Title
          <input
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalStateAtom({
                ...globalStateAtom,
                service_nav_title: e.target.value,
              })
            }
            value={service_nav_title}
          />
        </label>
      </div>
      <div className="flex justify-between">
        <label className={labelWrap}>
          Galleries Nav Title
          <input
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalStateAtom({
                ...globalStateAtom,
                gallery_nav_title: e.target.value,
              })
            }
            value={gallery_nav_title}
          />
        </label>
        <label className={`${labelWrap} -ml-3 pl-6 w-7/12`}>
          Reviews Nav Title
          <input
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalStateAtom({
                ...globalStateAtom,
                reviews_nav_title: e.target.value,
              })
            }
            value={reviews_nav_title}
          />
        </label>
      </div>
      <label className={labelWrap}>
        Team Nav Title
        <input
          className={borderedInputWhite}
          onChange={(e) =>
            setGlobalStateAtom({
              ...globalStateAtom,
              team_nav_title: e.target.value,
            })
          }
          value={team_nav_title}
        />
      </label>
      <label className={labelWrap}>
        Jobs Nav Title
        <input
          className={borderedInputWhite}
          onChange={(e) =>
            setGlobalStateAtom({
              ...globalStateAtom,
              jobs_nav_title: e.target.value,
            })
          }
          value={jobs_nav_title}
        />
      </label>
    </div>
  )
}

export default NavLinks;
