/* eslint-disable */
import parsePhoneNumberFromString from "libphonenumber-js";

export default function formatPhoneNumber(
  phoneNumber: string,
  formatType: string
): string {
  // National
  // International
  // For Display
  const reg = /^[0-9]/;

  const onlyContainsNumbers = (string: string): boolean =>
    !reg.test(string[string.length - 1]);

  if (formatType === "DISPLAY") {
    const stripped = phoneNumber.slice(
      phoneNumber.includes("+") ? 2 : phoneNumber.charAt(0) === "1" ? 1 : 0
    );
    const cleaned = `${stripped}`.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return "";
  }
  if (formatType === "INTERNATIONAL") {
    if (phoneNumber.includes("+1")) {
      // input is +19177140601
      return phoneNumber;
    }
    if (phoneNumber.charAt(0) === "1") {
      // input is 19177140601
      return `+${phoneNumber}`;
    }
    if (onlyContainsNumbers(phoneNumber) && phoneNumber.length === 10) {
      // input is 9177140601
      return `+1${phoneNumber}`;
    }
    if (!onlyContainsNumbers(phoneNumber)) {
      // input is (917) 714-0601
      const number = parsePhoneNumberFromString(phoneNumber, "US");
      if (number) {
        return number.number.toString();
      }
    }
    return "";
  }
  if (formatType === "INTERNATIONAL_NO_PLUS") {
    if (phoneNumber.includes("+1")) {
      // input is +19177140601
      return phoneNumber.substring(1);
    }
    if (phoneNumber.charAt(0) === "1") {
      // input is 19177140601
      return phoneNumber;
    }
    if (onlyContainsNumbers(phoneNumber) && phoneNumber.length === 10) {
      // input is 9177140601
      return `1${phoneNumber}`;
    }
    if (!onlyContainsNumbers(phoneNumber)) {
      // input is (917) 714-0601
      const number = parsePhoneNumberFromString(phoneNumber, "US");
      if (number) {
        return number.number.toString().substring(1);
      }
    }
    return "";
  }
  if (formatType === "NATIONAL") {
    if (phoneNumber.includes("+1")) {
      // input is +19177140601
      return phoneNumber.substring(2);
    }
    if (phoneNumber.charAt(0) === "1") {
      // input is 19177140601
      return phoneNumber.substring(1);
    }
    if (onlyContainsNumbers(phoneNumber) && phoneNumber.length === 10) {
      // input is 9177140601
      return phoneNumber;
    }
    if (!onlyContainsNumbers(phoneNumber)) {
      // input is (917) 714-0601
      const number = parsePhoneNumberFromString(phoneNumber, "US");
      if (number) {
        return number.number.toString().substring(2);
      }
    }
    return "";
  }
  return "";
}
