/* eslint-disable */
import { useAtom } from "jotai";
import React, { useState } from "react";
import globalState from "../../../store";

const ScheduleSocialPost = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [isChecked, setIsChecked] = useState(
    globalState?.scheduled_social_post_active || false
  );
  function handleCheckboxChange(event) {
    setIsChecked(event.target.checked);
    setGlobalState({
      ...globalStateAtom,
      scheduled_social_post_active: event.target.checked,
    });
  }
  return (
    <div className="mt-1 bg-white h-24 flex flex-col justify-center align-middle items-center">
      <h1 className="font-semibold text-xl">Schedule Social Post</h1>
      <label className="ml-2">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        Schedule Social Post
      </label>
    </div>
  );
};

export default ScheduleSocialPost;
