/* eslint-disable */
import { useEffect, useState } from "react";
import { buttonWhiteSmall, input } from "../../../shared/styles";
import { primaryCategories } from "../utils";
import { GoogleLogin } from "react-google-login";

interface GMBProps {
  createGMB: (arg1: string) => void;
  loading: boolean;
  address: string;
  setAddress: any;
  primaryCategory: string;
  setPrimaryCategory: any;
  setErrorMessage: any;
  verifyLink: string;
  verificationProcessing: boolean;
  needsToAuthenticate: boolean;
  setNeedsToAuthenticate: any;
}

const CreateGMB: React.FC<GMBProps> = ({
  createGMB,
  loading,
  address,
  setAddress,
  primaryCategory,
  setPrimaryCategory,
  setErrorMessage,
  verifyLink,
  verificationProcessing,
  needsToAuthenticate,
  setNeedsToAuthenticate,
}) => {
  const [predictions, setPredictions] = useState<string[]>([]);
  const [validInputValues, setValidInputValues] = useState<boolean>(false);
  // input validation
  useEffect(() => {
    if (!primaryCategories.includes(primaryCategory))
      setValidInputValues(false);
    else if (primaryCategory === "") setValidInputValues(false);
    else setValidInputValues(true);
  }, [primaryCategory]);

  // input validation
  useEffect(() => {
    if (address === "") setValidInputValues(false);
    else if (address.split(",").length < 4) setValidInputValues(false);
    else setValidInputValues(true);
  }, [address]);

  function handleChange(val: string) {
    if (val && val.length) {
      const categories = primaryCategories.filter(
        (cat) => cat.includes(val) && cat !== val
      );
      setPredictions(categories);
    } else {
      setPredictions([]);
    }
    setPrimaryCategory(val);
    setErrorMessage("");
  }

  function responseGoogle(response) {
    console.log(response, "response");
    if (response.tokenObj.access_token) {
      localStorage.setItem("gmb_access_token", response.tokenObj.access_token);
      try {
        setPredictions([]);
        if (!needsToAuthenticate) createGMB(response.tokenObj.access_token);
        else setNeedsToAuthenticate(false);
      } catch (e) {
        alert("Error creating Google My Business profile: " + e);
        setPredictions([]);
      }
    } else {
      alert(
        "Please sign into ProPhone Support gmail using the button at the bottom of the screen."
      );
    }
  }

  return (
    <>
      <h2 className="absolute top-32 right-24">Google My Business</h2>
      <div className={`bg-white m-12 rounded-lg p-6 ${!loading && "flex"}`}>
        {verificationProcessing ? (
          <div>GMB Verification Processing</div>
        ) : (
          <>
            {loading ? (
              <img
                src="/loading.gif"
                width="20px"
                height="20px"
                alt="loading"
              />
            ) : (
              !verifyLink && (
                <GoogleLogin
                  clientId="555479901310-7nf23if6or5cter47gt3ifcg4uhgoc69.apps.googleusercontent.com"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                  scope="https://www.googleapis.com/auth/webmasters"
                  children={
                    <button
                      className={`${buttonWhiteSmall} ${
                        !validInputValues && ""
                      } max-h-16`}
                      // disabled={!validInputValues} // TODO: make sure the button can be clicked if the user needsAuthentication
                    >
                      {needsToAuthenticate ? "Sign In" : "Create GMB"}
                    </button>
                  }
                  className="border-none shadow-none bg-red-500"
                />
              )
            )}
            {!needsToAuthenticate && (
              <div>
                <label>
                  Business Address (Street, City, State Zip)
                  {/* // TODO: input validation so value matches the above format (if it doesn't it will error the GMB creation) */}
                  <input
                    onChange={(e) => {
                      setErrorMessage("");
                      setAddress(e.target.value);
                    }}
                    placeholder="e.g. 123 Pretend St, Boston, MA 01234"
                    value={address}
                    className={input}
                  />
                </label>
                <br />
                <label>
                  Primary Category
                  <input
                    onChange={(e) => handleChange(e.target.value)}
                    // onChange={(e) => setPrimaryCategory(e.target.value)}
                    placeholder="Search for primary category"
                    value={primaryCategory}
                    className={input}
                  />
                </label>
                {predictions && predictions.length ? (
                  <div className="bg-white">
                    {predictions.map((p) => (
                      <div
                        onClick={() => handleChange(p)}
                        className={"cursor-pointer"}
                      >
                        {p}
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                {verifyLink && (
                  <>
                    <div
                      onClick={() => {
                        window.open(verifyLink, "_blank");
                      }}
                      className="mt-6 text-blue-600 underline cursor-pointer"
                    >
                      Click here to verify GMB location
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CreateGMB;
