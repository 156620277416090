/* eslint-disable */
import axios from "axios";
import { useAtom } from "jotai";
import { pageContainerWithOverflow } from "../../shared/styles";
import globalState from "../../store";
import Awards from "./components/Awards";
import CallsToAction from "./components/CallsToAction";
import Deals from "./components/Deals";
import GoogleAnalytics from "./components/GoogleAnalytics";

import JobsDescription from "./components/JobsDescription";
import NavLinks from "./components/NavLinks";
import Payments from "./components/Payments";
import SectionHeaders from "./components/SectionHeaders";
import SoTellUs from "./components/SoTellUs";
import ThreeStep from "./components/ThreeStep";

export default function CustomPage() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const custom_publish = async () => {
    // await publish_function()

    const { github_repo, paid_url, id_key, slug, multipage } = globalStateAtom;
    const githubData = {
      github_repo, // paid_url,
      paid_url,
      id_key,
      slug,
      first_publish_url: github_repo ? "" : paid_url, // first_publish_url Need this value to publish for first time
    };
    const postResponse = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/${multipage ? "m/" : ""}publish`,
      githubData
    );

    const message =
      postResponse && postResponse.data ? postResponse.data.message : "";
    // console.log("message", message);
    // if (githubData.first_publish_url) await sendWebhook();

    setGlobalState({
      ...globalStateAtom,
      github_repo: githubData.github_repo
        ? githubData.github_repo
        : githubData.first_publish_url,
    });

    // setLoading(false);
    // alert("Saved and published sucessfully");
  };
  return (
    <div className={`${pageContainerWithOverflow} flex w-full`}>
      <div className="flex flex-col h-full w-full -ml-6 mr-12">
        <SectionHeaders
          setGlobalState={setGlobalState}
          globalState={globalStateAtom}
        />
        <NavLinks />
        <Deals setGlobalState={setGlobalState} globalState={globalStateAtom} />
        <ThreeStep
          setGlobalState={setGlobalState}
          globalState={globalStateAtom}
        />
      </div>
      <div className="flex flex-col w-full mr-12 ml-6">
        <div onClick={custom_publish}>PUBLISH NO SAVE</div>
        <CallsToAction />
        <Payments />
        <Awards />
        <JobsDescription
          setGlobalState={setGlobalState}
          globalState={globalStateAtom}
        />
        <GoogleAnalytics />
        <SoTellUs />
      </div>
    </div>
  );
}
