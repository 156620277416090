/* eslint-disable */
import { useAtom } from "jotai";
import { useEffect } from "react";
import { boxContainerFull } from "../../../shared/styles";
import globalState from "../../../store";
import Props from "../../../store/props";
import CallToAction from "./CallToAction";

const CallsToAction: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const {
    phone,
    email,
    external_link,
    cta_main,
    cta_main_1,
    cta_nav,
    cta_nav_1,
    cta_slide_in,
    cta_recruiting,
    call_to_action_message,
    call_to_action_message_main_1,
    call_to_action_message_nav,
    call_to_action_message_nav_1,
    call_to_action_message_slide_in,
    call_to_action_message_recruiting,
    cta_get_a_quote,
    call_to_action_message_get_a_quote,
  } = globalStateAtom;

  // default the values on first visit to this tab
  useEffect(() => {
    if (
      !cta_main &&
      !cta_main_1 &&
      !cta_nav &&
      !cta_nav_1 &&
      !cta_slide_in &&
      !cta_recruiting &&
      !cta_get_a_quote &&
      !call_to_action_message &&
      !call_to_action_message_main_1 &&
      !call_to_action_message_nav &&
      !call_to_action_message_nav_1 &&
      !call_to_action_message_slide_in &&
      !call_to_action_message_recruiting &&
      !call_to_action_message_get_a_quote
    )
      setGlobalState({
        ...globalStateAtom,
        cta_main: phone,
        cta_main_1: phone,
        cta_nav: external_link,
        cta_nav_1: external_link,
        cta_slide_in: email,
        cta_recruiting: "",
        cta_get_a_quote: "",
        call_to_action_message: "Get a Free Quote",
        call_to_action_message_main_1: "Give us a Call",
        call_to_action_message_nav: "Book Now",
        call_to_action_message_nav_1: "Book Now",
        call_to_action_message_slide_in: "Email Us",
        call_to_action_message_recruiting: "Apply Today",
        call_to_action_message_get_a_quote: "Text Us",
      });
  }, []);

  return (
    <>
      {/* <h2 className="">Calls To Action</h2> */}
      <div className={`bg-white rounded-lg p-3 w-full`}>
        {[
          "Main 1",
          "Main 2",
          "Nav Bar 1",
          "Nav Bar 2",
          "Slide In",
          "Recruiting",
          "Get a quote",
        ].map((item) => {
          return <CallToAction key={item} item={item} />;
        })}
      </div>
    </>
  );
};

export default CallsToAction;
