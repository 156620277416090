/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import Review from "./Review";
import { useAtom } from "jotai";
import globalState from "../../../store";
import { IReviewSource, ITestimonial } from "../../../types/types";
import { getReviewSources } from "./utils";

const Column: React.FC<{ loading: boolean }> = ({ loading }) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [reviewSources, setReviewSources] = useState<IReviewSource[]>([]);
  const { testimonials } = globalStateAtom;

  const handleDelete = (index: number) => {
    const thisTestimonial = globalStateAtom.testimonials[index];

    // filter array to delete testimonial
    const newTestimonials = globalStateAtom.testimonials.filter(
      (testimonial) => testimonial !== thisTestimonial
    );
    // reorder testimonials
    const newListWithOrder = newTestimonials.map((item: any, index: number) => {
      return { ...item, order: index };
    });
    // set the state of testimonials
    setGlobalState({ ...globalStateAtom, testimonials: newListWithOrder });
  };

  useEffect(() => {
    getReviewSources().then((data) => setReviewSources(data));
  }, []);

  return (
    <Droppable droppableId={"col-1-droppable"}>
      {(provided) => (
        <div
          className="flex flex-col w-8/12 items-start"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {testimonials &&
            testimonials.map((review: ITestimonial, index: number) => {
              return (
                <Review
                  key={review.draggableId}
                  draggableId={review?.draggableId || ""}
                  handleDelete={() => handleDelete(index)}
                  review={review}
                  index={index}
                  reviewSources={reviewSources}
                  loading={loading}
                />
              );
            })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Column;
