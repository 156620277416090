/* eslint-disable */
import React from "react";
import { buttonSmall } from "../../../../shared/styles";
import globalState from "../../../../store";
import { mergePhotoRequest } from "../../service/photoService";
import { useAtom } from "jotai";

const PostAlignActions = ({
  // setGlobalState,
  // globalState,
  onVerticalClickHandler,
  onHorizontalClickHandler,
  selectedPhotos,
  setSelectedPhotos,
  IsVerticalSelected,
  isHorizontalSelected,
  setLocalGallery,
  localGallery,
  setIs_show,
  setIsMergePhotoSelected,
  setLoading,
  setManualOrderButtonShowing,
}: any) => {
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);

  const onUndoHandler = () => {
    setSelectedPhotos({});
    onVerticalClickHandler(false);
    onHorizontalClickHandler(false);
  };

  const uploadMergedImageToS3 = async () => {
    const { id_key } = globalStateAtom;
    const alignment = IsVerticalSelected ? "vertical" : "Horizontal";
    const selectedPhotoArray: any = Object.values(selectedPhotos);
    const gallery_name = selectedPhotoArray[0].gallery_name;

    const mergePhotoRequestBody = {
      images: selectedPhotoArray,
      id_key,
      alignment,
      gallery_name,
    };
    const mergedPhotoInfo: any = await mergePhotoRequest(mergePhotoRequestBody);
    const updatedMergedPhotoInfo = {
      ...mergedPhotoInfo.image,
      photo: `${process.env.REACT_APP_S3_URL}/media/${mergedPhotoInfo.image.photo}`,
    };
    return updatedMergedPhotoInfo;
  };
  const onSaveHandler = async () => {
    setLoading(true);
    // console.log('onSaveHandler')
    // update current gallery with the merge photo and push it to first index
    const mergedPhotoInfo = await uploadMergedImageToS3();
    const currentGallery = localGallery;
    currentGallery.unshift(mergedPhotoInfo);
    console.log('mergedphoto',mergedPhotoInfo)
    const reorderedCurrentGallery = currentGallery.map((item, index) => {
      item.order = currentGallery.length - 1 - index;
      return item;
    });
    // .sort((a, b) => a.order - b.order)
    // set all merge tool option  to false
    onUndoHandler();

    // set all gallery tools to true
    setIs_show(true);
    setIsMergePhotoSelected(false);
    setManualOrderButtonShowing(true);

    // Set all galleries
    setLocalGallery(reorderedCurrentGallery);
    setGlobalStateAtom({
      ...globalStateAtom,
      gallery: reorderedCurrentGallery,
    });
    setLoading(false);
  };
  return (
    <div className="flex ">
      <button
        className={`${buttonSmall}  right-3 bottom-3 z-10  py-3 px-6`}
        onClick={onUndoHandler}
      >
        Undo
      </button>
      <button
        className={`${buttonSmall}  right-3 bottom-3 z-10  py-3 px-6`}
        onClick={onSaveHandler}
      >
        Save
      </button>
    </div>
  );
};

export default PostAlignActions;
