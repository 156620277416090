/* eslint-disable */
import { useAtom } from "jotai";
import React, { useState, useEffect } from "react";
import CustomSearchBar from "../../../../shared/CustomSearchBar";
import globalState from "../../../../store";
import ExistingCustomers from "./ExistingCustomer";
import { getCustomerBySearchQuery } from "./utils";
import { debounce } from "lodash";
import AddCustomers from "./AddCustomers";

const CustomerInfo = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    if (globalStateAtom) {
      const { customerInfo = [] } = globalStateAtom;
      setCustomers(customerInfo);
    }
  }, [globalStateAtom]);

  return (
    <div className="bg-red p-2">
      <p>View only - Edit in /admin on customer</p>
      <AddCustomers />
      <ExistingCustomers customers={customers} />
    </div>
  );
};

export default CustomerInfo;
