/* eslint-disable */
import { useState } from "react";
import { useAtom } from "jotai";
import { IoArrowBack } from "react-icons/io5";
import { button, containerCentered, input } from "../../../shared/styles";
import authState, { phoneNumberJotai } from "../../../store/auth";

interface AuthFormProps {
  setFormNum: any;
}

const VerificationForm: React.FC<AuthFormProps> = ({ setFormNum }) => {
  const [authCode, setAuthCode] = useState("");
  const [authStateAtom, setAuthState] = useAtom(authState);
  const [phoneNumberAtom] = useAtom(phoneNumberJotai);

  const checkCode = async () => {
    const verify_res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/pi/verify`,
      {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          phoneNumber: phoneNumberAtom,
          authCode: authCode,
        }),
      }
    );
    const authResult: any = await verify_res.json();
    if (authResult.access_token) {
      setAuthState({
        ...authStateAtom,
        access_token: authResult.access_token,
        refresh_token: authResult.refresh_token,
      });
      Object.keys(authResult).forEach((key) => {
        localStorage.setItem(key, authResult[key]);
      });
    }
  };

  return (
    <div>
      <div
        onClick={() => setFormNum(1)}
        className="absolute left-2 top-2 flex items-center"
      >
        <IoArrowBack />
        <p className="cursor-pointer">Back</p>
      </div>
      <div className={containerCentered}>
        <img src="prophone_favicon_square.png" width="50" className="mb-2" />
        <h2 className="text-3xl">Admin Portal</h2>
        <input
          onChange={(e) => setAuthCode(e.target.value)}
          placeholder="6-Digit Code"
          value={authCode}
          className={input}
          autoFocus
        />
        <button onClick={() => checkCode()} className={button}>
          Verify number
        </button>
      </div>
    </div>
  );
};

export default VerificationForm;
