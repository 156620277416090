/* eslint-disable */
import React, { useState } from "react";
import { buttonSmall } from "../../../../shared/styles";
import MergePhotoTool from "./MergePhotoTool";
import ManualOrderingTool from "./ManualOrderingTool";

const DraggablePhotoGalleryHeaderTools = ({
  onVerticalClickHandler,
  onHorizontalClickHandler,
  selectedPhotos,
  setSelectedPhotos,
  IsVerticalSelected,
  isHorizontalSelected,
  setLocalGallery,
  localGallery,
  setLoading,
  context,
  addSelectedPhotos,
}: any) => {
  const [is_show, setIs_show] = useState(true);
  const [isMergePhotoSelected, setIsMergePhotoSelected] = useState(false);
  const [manualOrderButtonShowing, setManualOrderButtonShowing] =
    useState(true);
  const [manualOrderingTool, setManualOrderingTool] = useState(false);

  const onMergeToolHandler = () => {
    setIs_show(false);
    setIsMergePhotoSelected(true);
    setManualOrderButtonShowing(false);
  };

  const manualOrderToolHandler = () => {
    setManualOrderButtonShowing(false);
    setManualOrderingTool(true);
    setIs_show(false);
  };

  return (
    <div className="flex ">
      <div name="merge-photo-tool">
        {is_show ? (
          <button
            className={`${buttonSmall}  right-3 bottom-3 z-10  py-3 px-6`}
            onClick={onMergeToolHandler}
          >
            Merge Photo
          </button>
        ) : null}
        {manualOrderButtonShowing ? (
          <button
            className={`${buttonSmall}  right-3 bottom-3 z-10  py-3 px-6`}
            onClick={manualOrderToolHandler}
          >
            Set Order
          </button>
        ) : null}

        <button
          className={`p-2 w-44 m-3 rounded-full border-2 text-white text-sm bg-gray-500 right-3 bottom-3 z-10 py-3 px-6 whitespace-nowrap`}
          onClick={addSelectedPhotos}
        >
          Add To Homepage
        </button>

        {isMergePhotoSelected && (
          <MergePhotoTool
            onVerticalClickHandler={onVerticalClickHandler}
            onHorizontalClickHandler={onHorizontalClickHandler}
            selectedPhotos={selectedPhotos}
            setSelectedPhotos={setSelectedPhotos}
            IsVerticalSelected={IsVerticalSelected}
            isHorizontalSelected={isHorizontalSelected}
            // setGlobalState={setGlobalState}
            // globalState={globalState}
            setLocalGallery={setLocalGallery}
            localGallery={localGallery}
            setIs_show={setIs_show}
            setIsMergePhotoSelected={setIsMergePhotoSelected}
            setLoading={setLoading}
            setManualOrderButtonShowing={setManualOrderButtonShowing}
          />
        )}
        {manualOrderingTool && (
          <ManualOrderingTool
            selectedPhotos={selectedPhotos}
            setSelectedPhotos={setSelectedPhotos}
            setLocalGallery={setLocalGallery}
            localGallery={localGallery}
            manualOrderButtonShowing={manualOrderButtonShowing}
            setManualOrderButtonShowing={setManualOrderButtonShowing}
            setManualOrderingTool={setManualOrderingTool}
            setLoading={setLoading}
            setIs_show={setIs_show}
            context={context}
          />
        )}
      </div>
    </div>
  );
};

export default DraggablePhotoGalleryHeaderTools;
