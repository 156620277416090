/* eslint-disable */
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import globalState from "../../../store";

const Address = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("US");

  useEffect(() => {
    const { street_address, city, state, zip_code, country } = globalStateAtom;
    setStreetAddress(street_address);
    setCity(city);
    setState(state);
    setZipCode(zip_code);
    setCountry(country);
  }, [globalStateAtom]);

  const handleStreetAddressChange = (e: any) => {
    const { value } = e.target;
    setStreetAddress(value);
    setGlobalState({
      ...globalStateAtom,
      street_address: value,
    });
  };

  const handleCityChange = (e: any) => {
    const { value } = e.target;
    setCity(value);
    setGlobalState({
      ...globalStateAtom,
      city: value,
    });
  };

  const handleStateChange = (e: any) => {
    const { value } = e.target;
    setState(value);
    setGlobalState({
      ...globalStateAtom,
      state: value,
    });
  };

  const handleZipCodeChange = (e: any) => {
    const { value } = e.target;
    setZipCode(value);
    setGlobalState({
      ...globalStateAtom,
      zip_code: value,
    });
  };

  const handleCountryChange = (e: any) => {
    const { value } = e.target;
    setCountry(value);
    setGlobalState({
      ...globalStateAtom,
      country: value,
    });
  };

  return (
    <>
      <div className="w-full flex flex-col justify-start gap-y-2">
        <div className="flex flex-col">
          <label htmlFor="street-address" className="flex">Street Address</label>
          <input
            id="street-address"
            className="w-full border-gray-200 border-2 rounded-md p-2"
            type="text"
            onChange={handleStreetAddressChange}
            value={streetAddress}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="city" className="flex">City</label>
          <input
            id="city"
            className="w-full border-gray-200 border-2 rounded-md p-2"
            type="text"
            onChange={handleCityChange}
            value={city}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="state" className="flex">State</label>
          <input
            id="state"
            className="w-full border-gray-200 border-2 rounded-md p-2"
            type="text"
            onChange={handleStateChange}
            value={state}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="zip-code" className="flex">Zip Code</label>
          <input
            id="zip-code"
            className="w-full border-gray-200 border-2 rounded-md p-2"
            type="text"
            onChange={handleZipCodeChange}
            value={zipCode}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="country" className="flex">Country</label>
          <select
            id="country"
            className="w-full border-gray-200 border-2 rounded-md p-2"
            onChange={handleCountryChange}
          >
            <option value="US" defaultChecked>United States</option>
            <option value="CA">Canada</option>
            <option value="MX">Mexico</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Address;
