/* eslint-disable */
import { useAtom } from "jotai";
import React, { useState } from "react";
import globalState from "../../../store";

const ServiceBellToggle = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [isChecked, setIsChecked] = useState(
    globalState?.service_bell_enabled || false
  );
  function handleCheckboxChange(event) {
    setIsChecked(event.target.checked);
    setGlobalState({
      ...globalStateAtom,
      service_bell_enabled: event.target.checked,
    });
  }
  return (
    <div className="mt-1 bg-white h-24 flex flex-col justify-center align-middle items-center">
      <h2 className="font-semibold text-xl">Enable Service Bell</h2>
      <label className="ml-2">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        Enable Service Bell
      </label>
    </div>
  );
};

export default ServiceBellToggle;
