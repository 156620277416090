/* eslint-disable */
import {
  boxContainer,
  boxContainerAuto,
  buttonWhiteSmall,
} from "../../../shared/styles";
import globalState from "../../../store";
import Props from "../../../store/props";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import CustomLayout from "./CustomLayout";
import { sections } from "../utils";
import CustomBackGroundTransparencyAndTextColor from "./CustomBackGroundTransparencyAndTextColor";

const Layout: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [versionForUrl, setVersionForUrl] = useState<string>(
    globalStateAtom.version
  );
  const [customLayout, setCustomLayout] = useState(
    globalStateAtom.custom_layout
  );

  const [
    backgroundTransparencyComponentArr,
    setBackgroundTransparencyComponentArr,
  ] = useState([]);

  useEffect(() => {
    const arr = [
      "hero",
      "service-card",
      "services-page",
      "reviews-page",
      "gallery-page",
      "cta",
      "final-cta",
      "navigation",
    ];
    let resultArr: any = [];
    for (let i = 0; i < arr.length; i++) {
      // if (globalStateAtom.custom_layout?.[arr[i]]?.version === "v2.1") {
      resultArr = [...resultArr, arr[i].replaceAll("-", "_")];
      // }
    }
    setBackgroundTransparencyComponentArr(resultArr);
    // globalStateAtom.custom_layout?.[dataField]?.version === "v2.1"
    console.log("globalAtom????", globalStateAtom.custom_layout);
  }, [globalStateAtom.custom_layout]);

  console.log("what is background arr", backgroundTransparencyComponentArr);

  useEffect(() => {
    if (versionForUrl !== "vC") selectAllVersion(versionForUrl);
    else setGlobalState({ ...globalStateAtom, custom_layout: customLayout });
  }, [versionForUrl]);

  const [prositeLink, setPrositeLink] = useState(
    process.env.REACT_APP_SERVER_URL +
      "/m/" +
      versionForUrl +
      ("/" + globalStateAtom.slug + "/index")
  );

  function selectAllVersion(version: string) {
    const allComponentsObject: any = {};
    // for (const property in globalStateAtom.custom_layout) {
    sections.forEach((section, index) => {
      let order;
      if (
        globalStateAtom?.custom_layout &&
        section.section in globalStateAtom.custom_layout &&
        "order" in globalStateAtom.custom_layout[section.section]
      ) {
        order = globalStateAtom.custom_layout[section.section].order;
      } else {
        order = index;
      }
      allComponentsObject[section.section] = { version, order };
    });

    setGlobalState({
      ...globalStateAtom,
      custom_layout: allComponentsObject,
    });
    return;
  }

  // const set
  // let prositeLink = process.env.REACT_APP_SERVER_URL
  // if(globalStateAtom.multipage) prositeLink = prositeLink + "/m"
  // if(globalStateAtom.version == 'v2') prositeLink = prositeLink + "/v2"
  // prositeLink = prositeLink +"/" + globalStateAtom.slug + "/index"
  // navigator.clipboard.writeText(prositeLink)")
  return (
    <div className="flex flex-col">
      <h2 className="text-left ml-16 -mb-6">Layout</h2>
      <div className={`${boxContainer} flex flex-col`}>
        <div className="flex items-center">
          <input
            checked={!globalStateAtom.multipage}
            type="radio"
            name="multipage"
            value="single"
            onChange={(e) => {
              setPrositeLink(
                process.env.REACT_APP_SERVER_URL +
                  ("/" + globalStateAtom.slug + "/index")
              );
              setGlobalState({
                ...globalStateAtom,
                multipage: e.target.value === "multi" && true,
              });
            }}
          />
          <p className="ml-2">Single-page site</p>
        </div>
        <div className="flex items-center">
          <input
            checked={globalStateAtom.multipage}
            type="radio"
            name="multipage"
            value="multi"
            onChange={(e) => {
              setPrositeLink(
                process.env.REACT_APP_SERVER_URL +
                  "/m" +
                  (globalStateAtom.version !== "v1"
                    ? `/${globalStateAtom.version}`
                    : "") +
                  ("/" + globalStateAtom.slug + "/index")
              );
              setGlobalState({
                ...globalStateAtom,
                multipage: e.target.value === "multi" && true,
              });
            }}
          />
          <p className="ml-2">Multi-page site</p>
        </div>
        <div className="flex items-center">
          <input
            checked={globalStateAtom.version === "v1"}
            type="radio"
            name="version"
            value="v1"
            onChange={(e) => {
              setPrositeLink(
                process.env.REACT_APP_SERVER_URL +
                  (globalStateAtom.multipage ? "/m" : "") +
                  "/" +
                  globalStateAtom.slug +
                  "/index"
              );
              setGlobalState({
                ...globalStateAtom,
                version: "v1",
              });
              setVersionForUrl(e.target.value);
            }}
          />
          <p className="ml-2">v1.0</p>
        </div>
        <div className="flex items-center">
          {/* TODO: when we role out v2 single page, add a button and fix this logic to not immediately default to multipage */}
          <input
            checked={globalStateAtom.version === "v2"}
            type="radio"
            name="version"
            value="v2"
            onChange={(e) => {
              setPrositeLink(
                process.env.REACT_APP_SERVER_URL +
                  (globalStateAtom.multipage ? "/m" : "") +
                  (globalStateAtom.multipage ? "/v2" : "") +
                  ("/" + globalStateAtom.slug + "/index")
              );
              setGlobalState({
                ...globalStateAtom,
                version: "v2",
              });
              setVersionForUrl(e.target.value);
            }}
          />
          <p className="ml-2">v2.0</p>
        </div>
        <div className="flex items-center">
          {/* TODO: when we role out v2 single page, add a button and fix this logic to not immediately default to multipage */}
          <input
            checked={globalStateAtom.version === "v2.1"}
            type="radio"
            name="version"
            value="v2.1"
            onChange={(e) => {
              setPrositeLink(
                process.env.REACT_APP_SERVER_URL +
                  (globalStateAtom.multipage ? "/m" : "") +
                  (globalStateAtom.multipage ? "/v2.1" : "") +
                  ("/" + globalStateAtom.slug + "/index")
              );
              setGlobalState({
                ...globalStateAtom,
                version: "v2.1",
                scheduled_social_post_active: true,
                custom_hero_background_transparency: "25",
                custom_reviews_page_background_transparency: "25",
                custom_services_page_background_transparency: "25",
                custom_gallery_page_background_transparency: "25",
                custom_cta_background_transparency: "25",
                custom_final_cta_background_transparency: "25",
                custom_hero_text_color: "text-white",
                custom_reviews_page_text_color: "text-white",
                custom_services_page_text_color: "text-white",
                custom_gallery_page_text_color: "text-white",
                custom_cta_text_color: "text-white",
                custom_final_cta_text_color: "text-white",
              });
              setVersionForUrl(e.target.value);
            }}
          />
          <p className="ml-2">v2.1</p>
        </div>
        <div className="flex items-center">
          <input
            checked={globalStateAtom.version === "v3"}
            type="radio"
            name="version"
            value="v3"
            onChange={(e) => {
              setPrositeLink(
                process.env.REACT_APP_SERVER_URL +
                  (globalStateAtom.multipage ? "/m" : "") +
                  (globalStateAtom.multipage ? "/v3" : "") +
                  ("/" + globalStateAtom.slug + "/index")
              );
              setGlobalState({
                ...globalStateAtom,
                version: "v3",
              });
              setVersionForUrl(e.target.value);
            }}
          />
          <p className="ml-2">v3</p>
        </div>
        <div className="flex items-center">
          <input
            checked={globalStateAtom.version === "v4"}
            type="radio"
            name="version"
            value="v4"
            onChange={(e) => {
              setPrositeLink(
                process.env.REACT_APP_SERVER_URL +
                  (globalStateAtom.multipage ? "/m" : "") +
                  (globalStateAtom.multipage ? "/v4" : "") +
                  ("/" + globalStateAtom.slug + "/index")
              );
              setGlobalState({
                ...globalStateAtom,
                version: "v4",
              });
              setVersionForUrl(e.target.value);
            }}
          />
          <p className="ml-2">v4</p>
        </div>
        <div className="flex items-center">
          {/* TODO: when we role out v2 single page, add a button and fix this logic to not immediately default to multipage */}
          <input
            checked={globalStateAtom.version === "vC"}
            type="radio"
            name="version"
            value="vC"
            onChange={(e) => {
              setPrositeLink(
                process.env.REACT_APP_SERVER_URL +
                  (globalStateAtom.multipage ? "/m" : "") +
                  (globalStateAtom.multipage ? "/vC" : "") +
                  ("/" + globalStateAtom.slug + "/index")
              );
              setGlobalState({
                ...globalStateAtom,
                version: "vC",
              });
              setVersionForUrl(e.target.value);
            }}
          />
          <p className="ml-2">vC</p>
        </div>
        <div className="flex items-center">
          <label className={""}>ProSite-Link</label>
          <br />
          <a className={"text-blue-900"} href={prositeLink} target="_blank">
            {prositeLink}
          </a>
          {/* <input
                        className={' rounded-md border-2 border-black'}
                        onChange={(e) =>
                            setGlobalState({
                                ...globalStateAtom,
                                prosite_link: e.target.value,
                            })
                        }
                        value={prosite_link}
                    /> */}
          <br />
        </div>

        <button
          className={`${buttonWhiteSmall} mt-6 w-full`}
          onClick={() => {
            // let newPrositeLink = prositeLink
            // if(globalStateAtom.multipage) newPrositeLink = newPrositeLink + "/m"
            // if(globalStateAtom.version == 'v2') newPrositeLink = newPrositeLink + "/v2"
            // newPrositeLink = newPrositeLink +"/" + globalStateAtom.slug + "/index"
            navigator.clipboard.writeText(prositeLink);
            return;
          }}
        >
          copy
        </button>
      </div>

      {versionForUrl === "vC" && (
        <div className="flex w-screen justify-between mx-4 z-50">
          <CustomLayout setCustomLayout={setCustomLayout} />
          <CustomBackGroundTransparencyAndTextColor
            setCustomLayout={setCustomLayout}
            backgroundTransparencyComponentArr={
              backgroundTransparencyComponentArr
            }
          />
        </div>
      )}
    </div>
  );
};

export default Layout;
