/* eslint-disable */
import { useState, useEffect } from "react";
import {
  DragDropContext,
  DropResult,
  Draggable,
  Droppable,
} from "react-beautiful-dnd";

import Props from "../../../store/props";
// import ThumbnailSelectable from '../../../shared/ThumbnailSelectable'
import ThumbnailSelectable from "../../../shared/ThumbnailSelectable";
import PhotoProps from "../types/photoProps";
import { buttonSmall } from "../../../shared/styles";
import DraggablePhotoGalleryHeaderTools from "./photoGalleryTools/DraggablePhotoGalleryHeaderTools";
import MergedThumbnailPhoto from "./MergedThumbnailPhoto";
import { useAtom } from "jotai";
import globalState from "../../../store";
import { ListManager } from "react-beautiful-dnd-grid";

interface AllPhotosProps {
  showMicroGalleryButtons: any;
  addPhotoToGallery: any;
  localGalleryNames: any;
  // setSelectedPhotos: any
  addSelectedPhotosToGallery: any;
  setSelectedPhotos: React.Dispatch<React.SetStateAction<any>>;
  selectedPhotos: any;
  handleSelect: any;
  filter: string;
  setLocalGallery: React.Dispatch<React.SetStateAction<PhotoProps[]>>;
  localGallery: any;
  rerenderAfterDeletePhoto: (item: PhotoProps) => void;
  context: string;
  addSelectedPhotos: any;
}

const DraggablePhotoGallery: React.FC<AllPhotosProps> = ({
  showMicroGalleryButtons,
  addPhotoToGallery,
  localGalleryNames,
  addSelectedPhotosToGallery,
  setSelectedPhotos,
  selectedPhotos,
  handleSelect,
  filter,
  setLocalGallery,
  localGallery,
  rerenderAfterDeletePhoto,
  context,
  addSelectedPhotos,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const [IsVerticalSelected, setIsVerticalSelected] = useState(false);
  const [isHorizontalSelected, setIsHorizontalSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showPhoto, setShowPhoto] = useState(true);
  const [warningMessage, setWarningMessage] = useState("false");

  useEffect(() => {
    const selectedPhotoCount = Object.keys(selectedPhotos).length;
    if (
      selectedPhotoCount !== 2 &&
      (IsVerticalSelected || isHorizontalSelected)
    ) {
      const mismatchSelectedPhotoCountWarning = `Need Only 2 Selected Photo Current ${selectedPhotoCount} photos are selected`;
      setIsVerticalSelected(false);
      setIsHorizontalSelected(false);
      setWarningMessage(mismatchSelectedPhotoCountWarning);
      setShowWarning(true);
      setShowPhoto(false);
    }
    if (selectedPhotoCount === 2) {
      setShowWarning(false);
      setShowPhoto(true);
    }
  }, [selectedPhotos, IsVerticalSelected, isHorizontalSelected]);

  const { gallery } = globalStateAtom;

  const handleDelete = (photoUrl: string) => {
    const newGallery = gallery.filter((img) => img.photo !== photoUrl);
    const newLocalGallery = localGallery.filter(
      (img) => img.photo !== photoUrl
    );
    setGlobalState({ ...globalStateAtom, gallery: newGallery });
    setLocalGallery(newLocalGallery);
  };

  const onVerticalClickHandler = (flag = true) => {
    setIsVerticalSelected(flag);
    if (isHorizontalSelected) setIsHorizontalSelected(!isHorizontalSelected);
  };

  const onHorizontalClickHandler = (flag = true) => {
    setIsHorizontalSelected(flag);
    if (IsVerticalSelected) setIsVerticalSelected(!IsVerticalSelected);
  };

  function arrayMove(arr: Array<any>, fromIndex: number, toIndex: number) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  }

  const reorderList = (sourceIndex: number, destinationIndex: number) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    const list = [...localGallery];
    const reorderedList = arrayMove(list, sourceIndex, destinationIndex);

    const photosCount = list.length;
    const indexesToReorder: any[] = [];
    for (let i = 1; i <= photosCount; i++) {
      indexesToReorder.push(i);
    }
    indexesToReorder.reverse();

    let reMappedList: any;
    if (context === "All Photos") {
      reMappedList = reorderedList.map((item, index) => {
        return { ...item, all_photos_order: indexesToReorder[index] };
      });
    } else {
      reMappedList = reorderedList.map((item, index) => {
        return { ...item, gallery_order: indexesToReorder[index] };
      });
    }

    if (context === "All Photos") {
      setLocalGallery(reMappedList);
      setGlobalState({ ...globalStateAtom, gallery: reMappedList });
    } else {
      const allPhotos = globalStateAtom.gallery.map((currentImage) => {
        if (reMappedList.some((image) => image.photo === currentImage.photo)) {
          return reMappedList.find(
            (image) => image.photo === currentImage.photo
          );
        } else {
          return currentImage;
        }
      });

      const allPhotosReordered = allPhotos.sort(
        (a, b) => b.all_photos_order - a.all_photos_order
      );

      setLocalGallery(reMappedList);
      setGlobalState({ ...globalStateAtom, gallery: allPhotosReordered });
    }
  };

  return (
    <div className=" bg-white">
      <DraggablePhotoGalleryHeaderTools
        onVerticalClickHandler={onVerticalClickHandler}
        onHorizontalClickHandler={onHorizontalClickHandler}
        selectedPhotos={selectedPhotos}
        setSelectedPhotos={setSelectedPhotos}
        IsVerticalSelected={IsVerticalSelected}
        isHorizontalSelected={isHorizontalSelected}
        // setGlobalStateAtom={setGlobalStateAtom}
        // globalStateAtom={globalStateAtom}
        setLocalGallery={setLocalGallery}
        localGallery={localGallery}
        setLoading={setLoading}
        context={context}
        addSelectedPhotos={addSelectedPhotos}

      />
      {loading ? (
        <div className="flex justify-center">
          <img src="/loading.gif" width="47px" height="47px" />
          <p>Saving</p>
        </div>
      ) : (
        <></>
      )}
      {(IsVerticalSelected || isHorizontalSelected) && showPhoto && (
        <MergedThumbnailPhoto
          IsVerticalSelected={IsVerticalSelected}
          isHorizontalSelected={isHorizontalSelected}
          selectedPhotos={selectedPhotos}
          setSelectedPhotos={setSelectedPhotos}
          onVerticalClickHandler={onVerticalClickHandler}
          onHorizontalClickHandler={onHorizontalClickHandler}
        />
      )}
      {showWarning && <p>{warningMessage}</p>}
      <div className="flex flex-row flex-wrap relative ml-3 pt-6 overflow-hidden bg-white">
        <>
          <ListManager
            items={localGallery}
            direction="horizontal"
            maxItems={3}
            render={(item) => (
              <ThumbnailSelectable
                showMicroGalleryButtons={showMicroGalleryButtons}
                setSelectedPhotos={setSelectedPhotos}
                localGalleryNames={localGalleryNames}
                addSelectedPhotosToGallery={addSelectedPhotosToGallery}
                // globalState={globalState}
                // setGlobalState={setGlobalState}
                photo={item}
                handleDelete={handleDelete}
                handleSelect={handleSelect}
                selectedPhotos={selectedPhotos}
                rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
                draggable
                addPhotoToGallery={addPhotoToGallery}
              />
            )}
            onDragEnd={reorderList}
          />
        </>
      </div>
    </div>
  );
};

export default DraggablePhotoGallery;
