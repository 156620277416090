/* eslint-disable */
import {
  borderedInputWhite,
  boxContainer,
  buttonWhiteSmall,
  label,
} from "../../../shared/styles";
import globalState from "../../../store";
import { useAtom } from "jotai";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../App";
import GenericConfirmationPopUp from "../../../shared/GenericConfirmationPopUp";
import { parkUserWebsiteProsite } from "../utils";

const messageType = ["Involuntary Churn", "Voluntary Churn", "No Message"];

const ParkCustomerWebsite = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [
    showParkingPageConfirmationModal,
    setShowParkingPageConfirmationModal,
  ] = useState(false);
  const [messageTypeSelected, setMessageTypeSelected] = useState("No Message");

  const showParkingConfirmationModal = () => {
    setShowParkingPageConfirmationModal(true);
  };
  const hideParkingConfirmationModal = () => {
    setShowParkingPageConfirmationModal(false);
  };

  const handleParkPageConfirmation = async () => {
    const domain = globalStateAtom.paid_url;
    const userContactInfo = {
      email: globalStateAtom.email,
      phone_number: globalStateAtom.phone,
    };
    const messageDataInfo = {
      domain: globalStateAtom.paid_url,
      company_name: globalStateAtom.company_name,
      first_name: globalStateAtom?.customerInfo?.first_name,
      image_url: "",
    };
    await parkUserWebsiteProsite(domain, messageTypeSelected);
    setShowParkingPageConfirmationModal(false);
  };

  return (
    <div className="bg-white">
      <h2 className="top-32 left-24">
        Park This Page -{globalStateAtom?.paid_url || globalStateAtom.sitePk}
      </h2>
      {/* use messageType to render radio buttons */}
      <div className="flex items-center justify-center mt-4 w-full space-x-3">
        {messageType &&
          messageType.length > 0 &&
          messageType.map((type) => (
            <>
              <input
                type="radio"
                name="messageType"
                value={type}
                checked={messageTypeSelected === type}
                onChange={(e) => setMessageTypeSelected(e.target.value)}
              />
              <label>{type}</label>
            </>
          ))}
      </div>
      <button
        className={`${buttonWhiteSmall} mt-6 bg-red-400 `}
        onClick={showParkingConfirmationModal}
      >
        Park Website
      </button>
      {showParkingPageConfirmationModal && (
        <GenericConfirmationPopUp
          title="Are you sure you want to park this site?"
          onCancel={hideParkingConfirmationModal}
          onConfirm={handleParkPageConfirmation}
          confirmButtonText="Yes"
          cancelButtonText="No"
        />
      )}
    </div>
  );
};

export default ParkCustomerWebsite;
