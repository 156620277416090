/* eslint-disable */
import axios from "axios";
import { dataURLtoFile } from "../../../utils/helpers/dataURLtoFile";

export const getJpegFileFromHeic = async (file: File) => {
  const data = new FormData();
  await data.append("img", file, file.name);

  const pending_response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_NODE_SERVER_URL}/convert_heic_base64_to_png_base64`,
    data: data,
    headers: { "Content-Type": "multipart/form-data" },
  });

  if (pending_response.data) {
    const base64 = pending_response.data[0].base64;
    const newFile = dataURLtoFile(base64, file?.name);
    return newFile;
  }
  return file;
};

export const getIndustryStockPhotos = async (company_type: string | number) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/get_industry_stock_photos/${company_type}`
    );
    return response?.data?.data || [];
  } catch (e) {
    console.log("Error getting industry stock photos - ", e);
    return [];
  }
};
