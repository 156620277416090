/* eslint-disable */
import axios from "axios";

export function addPhotoToService(
  url: string,
  activeService: any,
  services: any
) {
  if (url) {
    const newImagesArray = [...activeService.photos];
    newImagesArray.push({
      photo: url,
      order: newImagesArray.length,
      stock: false,
    });
    const newServices = [...services];
    const posOfThisService = activeService.order;
    newServices[posOfThisService].photos = newImagesArray;
    return newServices;
  }
}

export function getArrayofThreeRandomPhoto(gallery: any) {
  if (!gallery) return [];
  if (gallery.length === 0) return [];
  let randomPhotoArr: any = [];
  const newGallery = [...gallery];
  for (let i = 0; i < 3; i++) {
    const randomNum = Math.floor(Math.random() * newGallery.length);
    const randPhoto = newGallery[randomNum];
    randomPhotoArr = [...randomPhotoArr, { ...randPhoto, stock: false }];

    // delete photo in newGallery to avoid duplicate photo
    newGallery.splice(randomNum, 1);

    // in case gallery has less than 3 photos for all photos
    if (newGallery.length === 0) break;
  }
  console.log("about to return ", randomPhotoArr);
  return randomPhotoArr;
}

export async function getServiceKeywordFamilies(
  serviceName: string,
  industry: string
): Promise<{ primary_keyword: string; keywords: string[] }[]> {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/seo/default-keyword-families/search`,
      {
        params: { industry, service: serviceName },
      }
    );
    return response.data.message
  } catch (e) {
    console.log(e);
    return [];
  }
}
