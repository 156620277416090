import { useAtom } from "jotai";
import { buttonSmall } from "../../../shared/styles";
import globalState from "../../../store";
import { IService } from "../../../types/types";
import { v4 as uuidv4 } from "uuid";

interface AddServicePackageButtonProps {
  service: IService;
  // eslint-disable-next-line
  setActiveService: React.Dispatch<React.SetStateAction<any>>;
}

const AddServicePackageButton: React.FC<AddServicePackageButtonProps> = ({
  service,
  setActiveService,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const servicePackages = service.packages || [];

  const blankServicePackageObject = {
    id: uuidv4(),
    service_package_title: "",
    service_footers: [],
    service_products: [],
    service_id: service["service_id"],
    service: service["service-name"],
    service_product_col_view: false,
  };

  const addNewServicePackage = () => {
    const newServicePackages = [...servicePackages];
    newServicePackages.push(blankServicePackageObject);
    const newService = { ...service, packages: newServicePackages };
    setGlobalState({
      ...globalStateAtom,
      services: globalStateAtom.services.map((s: IService) =>
        s.order === service.order ? newService : s
      ),
    });

    // Should get rid of this pattern
    setActiveService(newService);
  };

  return (
    <button
      onClick={addNewServicePackage}
      className={`${buttonSmall} relative`}
    >
      Add Service Package
    </button>
  );
};

export default AddServicePackageButton;
