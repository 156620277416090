/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";

interface UploadFileProps {
  onDropHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeHandler: (files: FileList) => void;
  accept?: string;
  activeService?: any;
  multiple?: boolean;
  title?: string;
}

const UploadFile: React.FC<UploadFileProps> = ({
  onDropHandler,
  onChangeHandler,
  activeService,
  accept = "*",
  multiple = false,
  title = "Upload Photo",
}) => {
  const [dropZoneBackgroundColor, setDropZoneBackgroundColor] =
    useState<string>("bg-white");
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // prevent browser from opening the link as new tab
    window.addEventListener("dragover", customPreventDefault, false);
    window.addEventListener("drop", customPreventDefault, false);
    return () => {
      window.removeEventListener("dragover", customPreventDefault, false);
      window.removeEventListener("drop", customPreventDefault, false);
    };
  }, []);

  useEffect(() => {
    if (!dropZoneRef.current) return;
    dropZoneRef.current.addEventListener("drop", handleDragOver);
    dropZoneRef.current.addEventListener("dragenter", handleDragEnter);
    dropZoneRef.current.addEventListener(
      "dragleave",
      resetDropZoneBackgroundColor
    );
    dropZoneRef.current.addEventListener(
      "dragend",
      resetDropZoneBackgroundColor
    );
    return function cleanup() {
      if (dropZoneRef.current) {
        dropZoneRef.current.removeEventListener("drop", handleDragOver);
      }
    };
  }, [dropZoneRef?.current, activeService]);

  const handleDragEnter = () => {
    setDropZoneBackgroundColor("bg-black");
  };

  const resetDropZoneBackgroundColor = () => {
    setDropZoneBackgroundColor("bg-white");
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setDropZoneBackgroundColor("bg-white");
    onDropHandler(e);
  };
  const customPreventDefault = (e: any) => {
    e.preventDefault();
  };
  const onButtonClick = (e: any) => {
    e.stopPropagation();
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const onInputChange = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      onChangeHandler(files);
    }
  };

  return (
    <div
      ref={dropZoneRef}
      className={`w-full h-full border-gray-200 border-2 border-dashed rounded-lg  flex items-center justify-center cursor-pointer ${dropZoneBackgroundColor}`}
      onClick={onButtonClick}
    >
      <div className="flex justify-center text-center">
        <label className="cursor-pointer">{title}</label>
        <input
          ref={inputFile}
          className="hidden"
          onChange={onInputChange}
          type="file"
          accept={accept}
          multiple={multiple}
        />
      </div>
    </div>
  );
};

export default UploadFile;
