import axios from "axios";
import { IDefaultService, IIndustry } from "../../types/types";

export const getDefaultIndustry = async (): Promise<IIndustry[]> => {
  try {
    const industriesRes = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/get_all_industries`
    );
    return industriesRes.data.message;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getDefaultIndustryServices = async (
  industryId: number
): Promise<IDefaultService[]> => {
  try {
    const serviceResponse = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/get_default_services/${industryId}`
    );
    return serviceResponse.data.message;
  } catch (e) {
    console.error(e);
    return [];
  }
};
