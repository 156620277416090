/* eslint-disable */
import {
  borderedInputWhite,
  boxContainer,
  inputWhite,
  label,
} from "../../../shared/styles";
import globalState from "../../../store";
import Props from "../../../store/props";
import { useAtom } from "jotai";
import { globalAgent } from "http";

const SocialMedia: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const {
    twitter,
    instagram,
    facebook,
    google,
    youtube,
    nextdoor,
    tiktok,
    yelp,
    thumbtack,
    bbb,
    homeadvisor,
  } = globalStateAtom;

  return (
    <div className="flex flex-col">
      <h2 className="transform translate-y-6 translate-x-32">Social Media</h2>
      <div
        className={`${boxContainer} flex-wrap ml-1 w-full transform -translate-x-4`}
      >
        <div className="flex">
          <label className={label}>
            Facebook
            <div className="flex">
              <p className="p-2 mt-3 w-1/3 rounded-l-md border-2 border-gray-200 w-2 bg-gray-200">
                http://
              </p>
              <input
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    facebook: e.target.value,
                    leave_review_link_3: e.target.value,
                  })
                }
                value={
                  facebook && facebook.includes("//")
                    ? facebook.split("//")[1]
                    : facebook
                }
                className="p-2 mt-3 w-full rounded-r-md border-2 border-gray-200 w-72 mr-3"
              />
            </div>
          </label>
          <label className={label}>
            Google
            <div className="flex">
              <div className="flex">
                <p className="p-2 mt-3 w-1/3 rounded-l-md border-2 border-gray-200 w-2 bg-gray-200">
                  http://
                </p>
                <input
                  onChange={(e) =>
                    setGlobalState({
                      ...globalStateAtom,
                      google: e.target.value,
                      review_link: e.target.value,
                    })
                  }
                  value={
                    google && google.includes("//")
                      ? google.split("//")[1]
                      : google
                  }
                  className="p-2 mt-3 w-full rounded-r-md border-2 border-gray-200 w-72"
                />
              </div>
            </div>
          </label>
        </div>
        <div className="flex">
          <label className={label}>
            Yelp
            <div className="flex">
              <div className="flex">
                <p className="p-2 mt-3 w-1/3 rounded-l-md border-2 border-gray-200 w-2 bg-gray-200">
                  http://
                </p>
                <input
                  onChange={(e) =>
                    setGlobalState({
                      ...globalStateAtom,
                      yelp: e.target.value,
                      review_link_more: `https://${e.target.value}`,
                    })
                  }
                  value={
                    yelp && yelp.includes("//") ? yelp.split("//")[1] : yelp
                  }
                  className="p-2 mt-3 w-full rounded-r-md border-2 border-gray-200 w-72 mr-3"
                />
              </div>
            </div>
          </label>
          <label className={label}>
            NextDoor
            <div className="flex">
              <div className="flex">
                <p className="p-2 mt-3 w-1/3 rounded-l-md border-2 border-gray-200 w-2 bg-gray-200">
                  http://
                </p>
                <input
                  onChange={(e) =>
                    setGlobalState({
                      ...globalStateAtom,
                      nextdoor: e.target.value,
                    })
                  }
                  value={
                    nextdoor && nextdoor.includes("//")
                      ? nextdoor.split("//")[1]
                      : nextdoor
                  }
                  className="p-2 mt-3 w-full rounded-r-md border-2 border-gray-200 w-72"
                />
              </div>
            </div>
          </label>
        </div>
        <div className="flex">
          <label className={label}>
            Twitter
            <div className="flex">
              <div className="flex">
                <p className="p-2 mt-3 w-1/3 rounded-l-md border-2 border-gray-200 w-2 bg-gray-200">
                  http://
                </p>
                <input
                  onChange={(e) =>
                    setGlobalState({
                      ...globalStateAtom,
                      twitter: e.target.value,
                    })
                  }
                  value={
                    twitter && twitter.includes("//")
                      ? twitter.split("//")[1]
                      : twitter
                  }
                  className="p-2 mt-3 w-full rounded-r-md border-2 border-gray-200 w-72 mr-3"
                />
              </div>
            </div>
          </label>
          <label className={label}>
            Instagram
            <div className="flex">
              <div className="flex">
                <p className="p-2 mt-3 w-1/3 rounded-l-md border-2 border-gray-200 w-2 bg-gray-200">
                  http://
                </p>
                <input
                  onChange={(e) =>
                    setGlobalState({
                      ...globalStateAtom,
                      instagram: e.target.value,
                    })
                  }
                  value={
                    instagram && instagram.includes("//")
                      ? instagram.split("//")[1]
                      : instagram
                  }
                  className="p-2 mt-3 w-full rounded-r-md border-2 border-gray-200 w-72"
                />
              </div>
            </div>
          </label>
        </div>
        <div className="flex">
          <label className={label}>
            Thumbtack
            <div className="flex">
              <div className="flex">
                <p className="p-2 mt-3 w-1/3 rounded-l-md border-2 border-gray-200 w-2 bg-gray-200">
                  http://
                </p>
                <input
                  onChange={(e) =>
                    setGlobalState({
                      ...globalStateAtom,
                      thumbtack: e.target.value,
                    })
                  }
                  value={
                    thumbtack && thumbtack.includes("//")
                      ? thumbtack.split("//")[1]
                      : thumbtack
                  }
                  className="p-2 mt-3 w-full rounded-r-md border-2 border-gray-200 w-72 mr-3"
                />
              </div>
            </div>
          </label>
          <label className={label}>
            YouTube
            <div className="flex">
              <div className="flex">
                <p className="p-2 mt-3 w-1/3 rounded-l-md border-2 border-gray-200 w-2 bg-gray-200">
                  http://
                </p>
                <input
                  onChange={(e) =>
                    setGlobalState({
                      ...globalStateAtom,
                      youtube: e.target.value,
                    })
                  }
                  value={
                    youtube && youtube.includes("//")
                      ? youtube.split("//")[1]
                      : youtube
                  }
                  className="p-2 mt-3 w-full rounded-r-md border-2 border-gray-200 w-72"
                />
              </div>
            </div>
          </label>
        </div>
        <div className="flex">
          <label className={label}>
            TikTok
            <div className="flex">
              <div className="flex">
                <p className="p-2 mt-3 w-1/3 rounded-l-md border-2 border-gray-200 w-2 bg-gray-200">
                  http://
                </p>
                <input
                  onChange={(e) =>
                    setGlobalState({
                      ...globalStateAtom,
                      tiktok: e.target.value,
                    })
                  }
                  value={
                    tiktok && tiktok.includes("//")
                      ? tiktok.split("//")[1]
                      : tiktok
                  }
                  className="p-2 mt-3 w-full rounded-r-md border-2 border-gray-200 w-72 mr-3"
                />
              </div>
            </div>
          </label>
          <label className={label}>
            Better Business Bureau
            <div className="flex">
              <div className="flex">
                <p className="p-2 mt-3 w-1/3 rounded-l-md border-2 border-gray-200 w-2 bg-gray-200">
                  http://
                </p>
                <input
                  onChange={(e) =>
                    setGlobalState({
                      ...globalStateAtom,
                      bbb: e.target.value,
                    })
                  }
                  value={bbb && bbb.includes("//") ? bbb.split("//")[1] : bbb}
                  className="p-2 mt-3 w-full rounded-r-md border-2 border-gray-200 w-72"
                />
              </div>
            </div>
          </label>
        </div>
        <label className={label}>
          HomeAdvisor
          <div className="flex">
            <div className="flex">
              <p className="p-2 mt-3 w-1/3 rounded-l-md border-2 border-gray-200 w-2 bg-gray-200">
                http://
              </p>
              <input
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    homeadvisor: e.target.value,
                  })
                }
                value={
                  homeadvisor && homeadvisor.includes("//")
                    ? homeadvisor.split("//")[1]
                    : homeadvisor
                }
                className="p-2 mt-3 w-48 rounded-r-md border-2 border-gray-200 w-72"
              />
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default SocialMedia;
