/* eslint-disable */
import { PhotoIcon, SparklesIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../shared/LoadingSpinner";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import ReactSlider from "react-slider";
import AvatarEditor from "react-avatar-editor";
import { dataURLtoFile } from "../../utils/helpers/dataURLtoFile";
import { pushImageToS3 } from "../../utils/helpers/uploadFileUtil";
import { SketchPicker } from "react-color";
import { useAtom } from "jotai";
import globalState from "../../store";
import FabricCanvas from "./Fabric";
import FontDropdown from "./FontDropdown";
import {
  getAllLogos,
  loadAllGoogleFonts,
  loadFont,
  saveImageInDb,
} from "./utils";
import { IoDownloadOutline } from "react-icons/io5";

const initialTextSettings: any = {
  size: 48,
  color: "#000000",
  family: "Inter",
  weight: 900,
  alignment: "center",
  width: 500,
  lineHeight: "1.15",
};
const initialPosition: any = { x: 0, y: 0 };

export default function LogoGenerator() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const slug = globalStateAtom.slug;
  const [allLogos, setAllLogos] = useState([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrls, setImageUrls] = useState<any[]>([]);
  const [stage, setStage] = useState<number>(1);
  const [selectedImage, setSelectedImage] = useState<string>();
  const [base64Data, setBase64Data] = useState("");
  const [scale, setScale] = useState(1);
  const [showColorPicker, setShowColorPicker] = useState("");
  const [showFirstTextbox, setShowFirstTextbox] = useState(true);
  const [showSecondTextbox, setShowSecondTextbox] = useState(false);
  const [fontSelected, setFontSelected] = useState({ name: "Inter" });
  const [prompt, setPrompt] = useState("");

  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [textSettings, setTextSettings] = useState([
    { ...initialTextSettings },
    { ...initialTextSettings },
  ]);
  const [activeTextbox, setActiveTextbox] = useState(0);

  const companyName = globalStateAtom.company_name;
  const [text0Value, setText0Value] = useState(companyName);
  const [text1Value, setText1Value] = useState("");

  const defaultColors = [
    globalStateAtom?.custom_primary,
    globalStateAtom?.custom_secondary,
    "#ffffff",
    "#000000",
  ];
  const [photoSettings, setPhotoSettings] = useState({
    dimensions: 800,
  });
  const [imagePosition, setImagePosition] = useState(initialPosition);
  const [textPosition0, setTextPosition0] = useState(initialPosition);
  const [textPosition1, setTextPosition1] = useState(initialPosition);

  const fabricCanvas = useRef();
  const cropper = useRef();
  const textColorPicker = useRef();
  const backgroundColorPicker = useRef();

  useEffect(() => {
    getAndSetAllLogos();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        showColorPicker === "text" &&
        !textColorPicker?.current?.contains(event.target)
      ) {
        setShowColorPicker("");
      }
      if (
        showColorPicker === "background" &&
        !backgroundColorPicker?.current?.contains(event.target)
      ) {
        setShowColorPicker("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showColorPicker]);

  useEffect(() => {
    loadAllGoogleFonts();
  }, []);

  async function getAndSetAllLogos() {
    const logos = await getAllLogos(slug);
    setAllLogos(logos);
  }

  async function generateLogos() {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/ai-logo-generate/${slug}`,
        { prompt }
      );
      const urls = response.data.message;
      let finalUrls = [...imageUrls];

      // for (let i = 0; i < urls.length; i++) {
      //   finalUrls.push(urls[i]);
      // }

      for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/ai-logo-remove-background`,
          { url }
        );

        finalUrls.push(response.data.message);
      }

      setImageUrls(finalUrls);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  console.log(imageUrls);

  function getStageText() {
    if (stage === 1) {
      return "Step 1: Generate some images, then select one";
    } else if (stage === 2) {
      return "Step 2: Arrange";
    } else if (stage === 3) {
      return "Step 3: Crop and save";
    }
  }

  function selectImage(image: string) {
    setSelectedImage(image);
  }

  function getStage1Buttons() {
    return (
      <>
        {imageUrls.length > 0 && (
          <button
            style={{ width: 240 }}
            disabled={loading}
            onClick={generateLogos}
            className="flex justify-center items-center space-x-2 rounded-lg bg-blue-800 text-white text-lg font-bold px-4 py-3 h-16 cursor-pointer"
          >
            {loading ? (
              <LoadingSpinner size="xl" color="white" />
            ) : (
              <>
                <SparklesIcon className="w-8 h-8" />
                <div className="text-lg whitespace-nowrap">Generate more</div>
              </>
            )}
          </button>
        )}
        {selectedImage && (
          <button
            style={{ width: 120 }}
            onClick={() => {
              resetPositionData();
              setStage(2);
            }}
            className="flex justify-center items-center space-x-2 rounded-lg bg-green-800 text-white text-lg font-bold px-4 py-3 h-16 cursor-pointer"
          >
            <div className="text-lg">Next</div>
            <ArrowRightIcon className="w-6 h-6" />
          </button>
        )}
      </>
    );
  }

  function getStage2Buttons() {
    return (
      <>
        <button
          style={{ width: 120 }}
          onClick={() => setStage(1)}
          className="flex justify-center items-center space-x-2 rounded-lg bg-red-800 text-white text-lg font-bold px-4 py-3 h-16 cursor-pointer"
        >
          <ArrowLeftIcon className="w-6 h-6" />
          <div className="text-lg">Back</div>
        </button>
        <button
          style={{ width: 120 }}
          onClick={getBase64Data}
          className="flex justify-center items-center space-x-2 rounded-lg bg-green-800 text-white text-lg font-bold px-4 py-3 h-16 cursor-pointer"
        >
          <div className="text-lg">Next</div>
          <ArrowRightIcon className="w-6 h-6" />
        </button>
      </>
    );
  }

  function getStage3Buttons() {
    return (
      <>
        <button
          style={{ width: 120 }}
          onClick={() => setStage(2)}
          className="flex justify-center items-center space-x-2 rounded-lg bg-red-800 text-white text-lg font-bold px-4 py-3 h-16 cursor-pointer"
        >
          <ArrowLeftIcon className="w-6 h-6" />
          <div className="text-lg">Back</div>
        </button>
        <button
          style={{ width: 120 }}
          onClick={saveImage}
          className="flex justify-center items-center space-x-2 rounded-lg bg-green-800 text-white text-lg font-bold px-4 py-3 h-16 cursor-pointer"
        >
          {loading ? (
            <LoadingSpinner size="xl" color="white" />
          ) : (
            <div className="text-lg">Save</div>
          )}
        </button>
      </>
    );
  }

  function storePositionData() {
    const { imagePosition, textPosition0, textPosition1 } =
      getElementPositions();

    setImagePosition(imagePosition);
    setTextPosition0(textPosition0);
    setTextPosition1(textPosition1);
  }

  function resetPositionData() {
    setImagePosition(initialPosition);
    setTextPosition0(initialPosition);
    setTextPosition1(initialPosition);
  }

  function getBase64Data() {
    storePositionData();

    fabricCanvas.current.discardActiveObject().renderAll();
    const canvas = document.getElementById("canvas");
    const dataUrl = canvas?.toDataURL();

    setBase64Data(dataUrl);
    setStage(3);
  }

  async function downloadLogo(imageUrl: string) {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "logo.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const getObjectById = (id) => {
    return fabricCanvas.current.getObjects().find((obj) => obj.id === id);
  };

  function getElementPositions() {
    const imageObject = getObjectById("image0");
    const textbox0Object = getObjectById("textbox0");
    const textbox1Object = getObjectById("textbox1");

    const imagePosition = {
      x: imageObject?.left,
      y: imageObject?.top,
    };
    const textPosition0 = {
      x: textbox0Object?.left,
      y: textbox0Object?.top,
    };
    const textPosition1 = {
      x: textbox1Object?.left,
      y: textbox1Object?.top,
    };

    return { imagePosition, textPosition0, textPosition1 };
  }

  function getSelectedImage() {
    const splitString = selectedImage?.split("/");
    const mediaKey = splitString[splitString?.length - 1];
    return mediaKey;
  }

  async function saveImage() {
    setLoading(true);
    const dataUrl = cropper?.current?.getImage().toDataURL();
    const file = dataURLtoFile(dataUrl, "image.jpg");

    const [completePhotoUrl, signedS3Photo] = await pushImageToS3(file);

    const selectedImageId = getSelectedImage();
    const { imagePosition, textPosition0, textPosition1 } =
      getElementPositions();

    await saveImageInDb(
      slug,
      selectedImageId,
      signedS3Photo,
      text0Value,
      text1Value,
      textSettings,
      imagePosition,
      textPosition0,
      textPosition1,
      backgroundColor
    );

    setLoading(false);

    setStage(1);
    getAndSetAllLogos();
  }

  async function saveSingleImageToDb(image) {
    const splitString = image.split("/");
    const mediaKey = splitString[splitString.length - 1];
    const response = await saveImageInDb(
      slug,
      mediaKey,
      mediaKey,
      text0Value,
      text1Value,
      textSettings,
      imagePosition,
      textPosition0,
      textPosition1,
      backgroundColor
    );
    getAndSetAllLogos();
  }

  function renderEmptyState() {
    return imageUrls.length === 0;
  }

  function getActiveTextbox() {
    try {
      if (fabricCanvas?.current) {
        const activeId = fabricCanvas.current.getActiveObject().id;
        const regex = /\d+/g;
        const active = parseInt(activeId.match(regex)[0]);
        setActiveTextbox(active);
        return active;
      } else {
        setActiveTextbox(0);
        return 0;
      }
    } catch (e) {
      return 0;
    }
  }

  function updateText(value, setting) {
    const activeIndex = getActiveTextbox();

    const newTextSettings = [...textSettings];
    newTextSettings[activeIndex] = {
      ...textSettings[activeIndex],
      [setting]: value,
    };

    setTextSettings(newTextSettings);
  }

  function startEdit(logo) {
    const selectedImage = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${logo.image}`;

    setSelectedImage(selectedImage);
    setBackgroundColor(logo.background_color);

    loadFont(logo.title_font_family);
    loadFont(logo.subheading_font_family);
    setFontSelected({ name: logo.title_font_family });

    setTextSettings([
      {
        size: logo.title_font_size,
        color: logo.title_color,
        family: logo.title_font_family,
        weight: logo.title_font_weight,
        alignment: logo.title_alignment,
        width: logo.title_width,
        lineHeight: logo.title_line_height,
      },
      {
        size: logo.subheading_font_size,
        color: logo.subheading_color,
        family: logo.subheading_font_family,
        weight: logo.subheading_font_weight,
        alignment: logo.subheading_alignment,
        width: logo.subheading_width,
        lineHeight: logo.subheading_line_height,
      },
    ]);
    setText0Value(logo.title);
    setText1Value(logo.subheading);
    setShowFirstTextbox(!!logo.title);
    setShowSecondTextbox(!!logo.subheading);
    setImagePosition({ x: logo.image_x_position, y: logo.image_y_position });
    setTextPosition0({ x: logo.title_x_position, y: logo.title_y_position });
    setTextPosition1({
      x: logo.subheading_x_position,
      y: logo.subheading_y_position,
    });
    // setPhotoSettings;

    setStage(2);
  }

  function handleChangePrompt(e) {
    setPrompt(e.target.value);
  }

  return (
    <>
      <div
        style={{ minHeight: 1000 }}
        className="flex flex-col w-full h-full bg-white my-8 rounded-lg"
      >
        <div className="flex w-full justify-between items-center p-8">
          <div className="flex flex-col">
            <div className="inline-flex items-center justify-start text-2xl font-semibold">
              Logo Generator 🚀
            </div>
            <div className="inline-flex items-center justify-start text-lg font-semibold py-2 text-gray-600">
              {getStageText()}
            </div>
          </div>

          <div className="flex flex-col items-start">
            <div className="text-xl font-bold pb-1">Prompt</div>
            <textarea
              style={{ width: 400 }}
              className="border border-gray-300 rounded-lg px-2.5 py-1 text-base"
              value={prompt}
              onChange={handleChangePrompt}
              placeholder="Type a prompt. The more descriptive the better..."
              rows={4}
            />
          </div>

          <div className="inline-flex justify-center items-center space-x-4">
            {stage === 1 && getStage1Buttons()}
            {stage === 2 && getStage2Buttons()}
            {stage === 3 && getStage3Buttons()}
          </div>
        </div>

        <div className="flex justify-start w-full px-8 text-lg font-bold mb-2">
          Library
        </div>

        {stage === 1 && (
          <div
            className={`flex space-x-12 pb-8 px-8 w-full ${
              renderEmptyState() ? "flex-grow h-full" : "items-start"
            }`}
          >
            <div className="border rounded-lg" style={{ height: 1000 }}>
              <div
                style={{ width: 360 }}
                className="flex flex-col flex-shrink-0 w-full grid grid-cols-2 gap-4 p-4"
              >
                {allLogos.map((logo) => {
                  const imageUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${logo.final_image}`;
                  return (
                    <div className="flex flex-col items-center">
                      <div
                        onClick={() => startEdit(logo)}
                        className="flex justify-center rounded-lg w-full cursor-pointer"
                      >
                        <img className="rounded-lg w-40 h-40" src={imageUrl} />
                      </div>
                      <div className="pt-3">
                        <button
                          onClick={() => {
                            downloadLogo(imageUrl);
                          }}
                          className="flex border rounded-full justify-center items-center p-3 hover:bg-gray-50"
                        >
                          <IoDownloadOutline className="flex flex-shrink-0 w-6 h-6" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              className={`flex flex-wrap w-full mb-40 ${
                imageUrls.length
                  ? "flex-row"
                  : "flex-col items-center justify-center"
              }`}
            >
              {imageUrls?.map((image: string, index) => {
                const selected = selectedImage === image;
                return (
                  <div className="flex flex-col mr-10 mb-10">
                    <div
                      key={image}
                      onClick={() => selectImage(image)}
                      className={`relative w-60 h-60 object-cover rounded-xl p-6 cursor-pointer ${
                        selected
                          ? "border border-transparent ring-4 ring-blue-800"
                          : "border"
                      }`}
                    >
                      <div className="absolute flex items-center justify-center w-6 h-6 rounded-full text-white text-sm font-bold bg-blue-800 left-3 top-3">
                        {index + 1}
                      </div>
                      <img src={image} />
                    </div>

                    <div className="flex justify-center items-center space-x-4 pt-3">
                      <button
                        onClick={() => {
                          downloadLogo(image);
                        }}
                        className="flex border rounded-full justify-center items-center p-3 hover:bg-gray-50"
                      >
                        <IoDownloadOutline className="flex flex-shrink-0 w-6 h-6" />
                      </button>

                      <button
                        className="rounded-full bg-blue-800 text-white font-medium p-2 w-40 hover:bg-blue-900"
                        onClick={() => saveSingleImageToDb(image)}
                      >
                        Save to DB
                      </button>
                    </div>
                  </div>
                );
              })}
              {imageUrls.length === 0 && (
                <>
                  <div className="text-4xl font-semibold text-gray-900 mb-8">
                    Let's generate some logos! 🚀
                  </div>
                  <button
                    style={{ width: 200 }}
                    disabled={loading}
                    onClick={generateLogos}
                    className="flex justify-center items-center space-x-2 rounded-lg bg-blue-800 text-white text-lg font-bold px-4 py-3 h-16 cursor-pointer"
                  >
                    {loading ? (
                      <LoadingSpinner size="xl" color="white" />
                    ) : (
                      <>
                        <SparklesIcon className="w-8 h-8" />
                        <div className="text-lg">Generate</div>
                      </>
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        )}

        {stage === 2 && (
          <div className="flex justify-start space-x-8">
            <FabricCanvas
              fabricCanvas={fabricCanvas}
              image={selectedImage}
              textSettings={textSettings}
              showFirstTextbox={showFirstTextbox}
              backgroundColor={backgroundColor}
              photoSettings={photoSettings}
              setPhotoSettings={setPhotoSettings}
              showSecondTextbox={showSecondTextbox}
              getActiveTextbox={getActiveTextbox}
              text0Value={text0Value}
              setText0Value={setText0Value}
              text1Value={text1Value}
              setText1Value={setText1Value}
              imagePosition={imagePosition}
              textPosition0={textPosition0}
              textPosition1={textPosition1}
            />

            <>
              <div className="flex w-full bg-white p-12">
                <div className="flex w-full">
                  <div className="flex flex-col space-y-3 w-full">
                    <div className="flex">
                      <div
                        onClick={() => setShowFirstTextbox(!showFirstTextbox)}
                        className="flex font-semibold text-lg items-center cursor-pointer"
                      >
                        <input
                          style={{ width: 16, height: 16 }}
                          className="flex flex-shrink-0 mr-2 cursor-pointer"
                          name="add-company-name"
                          type="checkbox"
                          checked={showFirstTextbox}
                        />
                        Show company name
                      </div>
                    </div>

                    <div className="flex">
                      <div
                        onClick={() => setShowSecondTextbox(!showSecondTextbox)}
                        className="flex font-semibold text-lg items-center cursor-pointer"
                      >
                        <input
                          style={{ width: 16, height: 16 }}
                          className="flex flex-shrink-0 mr-2 cursor-pointer"
                          name="add-company-name"
                          type="checkbox"
                          checked={showSecondTextbox}
                        />
                        Show second textbox
                      </div>
                    </div>

                    <div>
                      <div className="text-left font-semibold text-lg my-2">
                        Font family
                      </div>
                      <div className="flex w-full text-left">
                        <FontDropdown
                          defaultFont={fontSelected}
                          setFont={(font) => updateText(font.name, "family")}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="text-left font-semibold text-lg mt-2">
                        Font Color
                      </div>
                      <div
                        ref={textColorPicker}
                        className="relative flex flex-col items-start bg-gray-100 my-2 p-3 rounded-lg"
                      >
                        <div className={`absolute z-50`}>
                          {showColorPicker === "text" && (
                            <SketchPicker
                              key={"text"}
                              width={225}
                              presetColors={defaultColors}
                              color={textSettings[activeTextbox].color}
                              onChange={(e) => {
                                updateText(e.hex, "color");
                              }}
                            />
                          )}
                        </div>

                        <div
                          onClick={() => setShowColorPicker("text")}
                          style={{
                            backgroundColor: textSettings[activeTextbox].color,
                          }}
                          className="w-full h-12 rounded-lg"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="text-left font-semibold text-lg mt-2">
                        Font Size
                      </div>
                      <div className="flex w-full space-x-3">
                        <div className="w-full flex items-center cursor-pointer">
                          <ReactSlider
                            step={1}
                            min={12}
                            max={100}
                            className="w-full h-1.5 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                            thumbClassName="absolute w-5 h-5 cursor-pointer bg-blue-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-1.5"
                            value={textSettings[activeTextbox].size}
                            onChange={(value: any) => updateText(value, "size")}
                          />
                        </div>
                        <div className="flex flex-shrink-0 w-20 items-center border rounded-lg overflow-hidden">
                          <input
                            className="w-full p-2 overflow-hidden"
                            onChange={(e) => updateText(e.target.value, "size")}
                            value={`${textSettings[activeTextbox]?.size}`}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="text-left font-semibold text-lg mt-2">
                        Font Weight
                      </div>
                      <div className="flex w-full space-x-3">
                        <div className="w-full flex items-center cursor-pointer">
                          <ReactSlider
                            step={100}
                            min={100}
                            max={900}
                            className="w-full h-1.5 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                            thumbClassName="absolute w-5 h-5 cursor-pointer bg-blue-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-1.5"
                            value={textSettings[activeTextbox].weight}
                            onChange={(value: any) =>
                              updateText(value, "weight")
                            }
                          />
                        </div>
                        <div className="flex flex-shrink-0 w-20 items-center border rounded-lg overflow-hidden">
                          <input
                            className="w-full p-2 overflow-hidden"
                            onChange={(e) =>
                              updateText(e.target.value, "weight")
                            }
                            value={`${textSettings[activeTextbox]?.weight}`}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="text-left font-semibold text-lg mt-2">
                        Line Height
                      </div>
                      <div className="flex w-full space-x-3">
                        <div className="w-full flex items-center cursor-pointer">
                          <ReactSlider
                            step={0.01}
                            min={0.5}
                            max={3}
                            className="w-full h-1.5 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                            thumbClassName="absolute w-5 h-5 cursor-pointer bg-blue-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-1.5"
                            value={textSettings[activeTextbox].lineHeight}
                            onChange={(value: any) =>
                              updateText(value, "lineHeight")
                            }
                          />
                        </div>
                        <div className="flex flex-shrink-0 w-20 items-center border rounded-lg overflow-hidden">
                          <input
                            className="w-full p-2 overflow-hidden"
                            onChange={(e) =>
                              updateText(e.target.value, "lineHeight")
                            }
                            value={`${textSettings[activeTextbox]?.lineHeight}`}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="text-left font-semibold text-lg mt-2">
                        Text Align
                      </div>
                      <div className="flex w-full space-x-3 mt-2">
                        <div
                          onClick={() => updateText("left", "alignment")}
                          className={`border rounded-lg px-2 py-3 w-20 font-medium cursor-pointer ${
                            textSettings[activeTextbox].alignment === "left"
                              ? "border-blue-800 bg-blue-800 text-white"
                              : "bg-white text-gray-900"
                          }`}
                        >
                          Left
                        </div>
                        <div
                          onClick={() => updateText("center", "alignment")}
                          className={`border rounded-lg px-2 py-3 w-20 font-medium cursor-pointer ${
                            textSettings[activeTextbox].alignment === "center"
                              ? "border-blue-800 bg-blue-800 text-white"
                              : "bg-white text-gray-900"
                          }`}
                        >
                          Center
                        </div>
                        <div
                          onClick={() => updateText("right", "alignment")}
                          className={`border rounded-lg px-2 py-3 w-20 font-medium cursor-pointer ${
                            textSettings[activeTextbox].alignment === "right"
                              ? "border-blue-800 bg-blue-800 text-white"
                              : "bg-white text-gray-900"
                          }`}
                        >
                          Right
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="text-left font-semibold text-lg mt-2">
                        Background Color
                      </div>
                      <div
                        ref={backgroundColorPicker}
                        className="relative flex flex-col items-start bg-gray-100 my-2 p-3 rounded-lg"
                      >
                        <div className={`absolute z-50`}>
                          {showColorPicker === "background" && (
                            <SketchPicker
                              key={"background"}
                              width={225}
                              presetColors={defaultColors}
                              color={backgroundColor}
                              onChange={(e) => {
                                setBackgroundColor(e.hex);
                              }}
                            />
                          )}
                        </div>

                        <div
                          onClick={() => setShowColorPicker("background")}
                          style={{ backgroundColor: backgroundColor }}
                          className="w-full h-12 rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        )}

        {stage === 3 && (
          <div className="flex space-x-2 justify-center">
            <div className="border">
              <AvatarEditor
                ref={cropper}
                image={base64Data}
                width={600}
                height={600}
                border={[100, 100]}
                borderRadius={0}
                scale={scale}
                rotate={0}
                crossOrigin="anonymous"
                disableBoundaryChecks={true}
              />
            </div>
            <div className="flex flex-col space-y-3">
              <div
                onClick={() => setScale((scale) => scale + 0.1)}
                className="w-10 h-10 inline bg-black cursor-pointer rounded-lg"
              >
                <PlusIcon className="w-10 h-10 p-1 text-white" />
              </div>
              <div
                onClick={() => setScale((scale) => scale - 0.1)}
                className="w-10 h-10 inline bg-black cursor-pointer rounded-lg"
              >
                <MinusIcon className="w-10 h-10 p-1 text-white" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
