/* eslint-disable */
import ReactCrop, {
  PixelCrop,
  Crop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { canvasPreview, getBase64UrlFromEndpoint } from "./utils";
import { pushImageToS3 } from "../../../../utils/helpers/uploadFileUtil";
import SwitchToggle from "../../../../shared/SwitchToggle";

interface ImageCropModalProps {
  closeModal: () => void;
  isOpen: boolean;
  imageURL: string | null | undefined;
  setImageURL: (imageURL: string) => void;
}

const ImageCropModal: React.FC<ImageCropModalProps> = ({
  closeModal,
  isOpen,
  imageURL,
  setImageURL,
}) => {
  const [base64Url, setBase64Url] = useState<string>("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [loading, setLoading] = useState<boolean>(false);
  const [aspectSquare, setAspectSquare] = useState<boolean>(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imageURL) {
      getBase64UrlFromEndpoint(imageURL).then((data: string) =>
        setBase64Url(data)
      );
    }
  }, [imageURL]);

  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      canvasRef.current
    )
      canvasPreview(imgRef.current, canvasRef.current, completedCrop);
  }, [completedCrop, isOpen]);

  if (!isOpen || !imageURL) return null;

  const onImageLoad = (e: any) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "%",
          width: 90,
          height: 90,
        },
        16 / 9,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  };

  const handleClickClose = () => {
    closeModal();
  };

  const onChangeHandler = (crop: Crop) => {
    setCrop(crop);
  };

  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (!loading && e.key === "Enter") {
      handleCrop();
    }
    if (!loading && e.key === "Escape") {
      closeModal();
    }
  };

  const handleCropComplete = (crop: PixelCrop) => {
    setCompletedCrop(crop);
  };

  const handleCrop = async () => {
    canvasRef.current?.toBlob(async (blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      setLoading(true);
      try {
        const [uploadedImageUrl] = await pushImageToS3(blob);
        setImageURL(uploadedImageUrl);
        setLoading(false);
        closeModal();
      } catch (e) {
        setLoading(false);
        throw new Error("programmable-voice-server-node does not respond");
      }
    });
  };

  return (
    <div
      className="fixed left-0 top-0 right-0 left-0 bottom-0 bg-gray-300 z-20 bg-opacity-50 rounded-lg flex items-center justify-center"
      tabIndex={0}
      onKeyDown={handleKeyPress}
    >
      <div className="w-4/6 h-5/7 rounded-lg bg-white p-8">

        <div
          className="flex space-x-8"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-2/5 self-center	">
            <ReactCrop
              crop={crop}
              onChange={onChangeHandler}
              onComplete={handleCropComplete}
              aspect={aspectSquare ? 1 : undefined}
            >
              <img ref={imgRef} src={base64Url} onLoad={onImageLoad} />
            </ReactCrop>
          </div>

          <div className="w-3/5 h-inherit min-h-full p-4 bg-gray-300 rounded-md overflow-hidden flex">
            <div className="flex flex-col w-1/3 justify-center pr-4 space-y-4">
              <button
                onClick={handleCrop}
                disabled={loading}
                className="bg-green-600 hover:bg-green-400 w-full text-white rounded-lg py-2 px-4 cursor-pointer disabled:bg-gray-100"
              >
                Crop
              </button>
              <button
                onClick={handleClickClose}
                disabled={loading}
                className="bg-red-600 hover:bg-red-400 w-full text-white rounded-lg py-2 px-4 cursor-pointer disabled:bg-gray-100"
              >
                Cancel
              </button>
            </div>
            <div className="h-full w-2/3 flex items-center justify-center border border-black border-dashed relative">
              {loading && (
                <div className="absolute left-0 top-0 right-0 left-0 bottom-0 bg-white bg-opacity-50 flex justify-center items-center">
                  <img src="/loading.gif" width="25px" />
                </div>
              )}
              <canvas
                ref={canvasRef}
                className="object-contain"
                style={{
                  height: completedCrop?.height,
                  width: completedCrop?.width,
                }}
              ></canvas>
            </div>
          </div>
        </div>
        <div className="mt-2 p-3  bg-gray-100 rounded-lg">
          <SwitchToggle
            checked={aspectSquare}
            onChange={setAspectSquare}
            label="Square"
          />
        </div>
      </div>
    </div>
  );
};

export default ImageCropModal;
