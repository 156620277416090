import { useAtom } from "jotai";
import KeywordFamily from "./KeywordFamily";
import globalStateStore from "../../../../store";
import { button } from "../../../../shared/styles";
import { useState } from "react";
import {
  generateAboutUs,
  generateMetadataDescription,
  generateMetadataTitle,
  generateTagline,
} from "../Generate";

const HomepageKeywordFamily: React.FC = () => {
  const [globalState, setGlobalState] = useAtom(globalStateStore);
  const [loading, setLoading] = useState(false);

  const onKeywordsChange = (keywords: string[]) => {
    setGlobalState({ ...globalState, keywords });
  };

  const onPrimaryKeywordChange = (keyword: string) => {
    setGlobalState({ ...globalState, primary_keyword: keyword });
  };

  const generateBasicInfoContent = async () => {
    try {
      setLoading(true);
      const [company_tagline, about, meta_description, title_tag] =
        await Promise.all([
          generateTagline(globalState),
          generateAboutUs(globalState),
          generateMetadataDescription(globalState),
          generateMetadataTitle(globalState),
        ]);

      setGlobalState({
        ...globalState,
        company_tagline,
        about,
        title_tag,
        meta_description,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-row w-12/12 rounded-lg bg-white p-6 m-12">
      <KeywordFamily
        keywords={globalState.keywords}
        primaryKeyword={globalState.primary_keyword}
        onKeywordsChange={onKeywordsChange}
        onPrimaryKeywordChange={onPrimaryKeywordChange}
        keywordCount={KEYWORD_COUNT}
      />
      {loading ? (
        <div className="flex flex-row justify-center w-72 items-center">
          <img src="/loading.gif" width="20" alt="loading" />
        </div>
      ) : (
        <button className={`${button}`} onClick={generateBasicInfoContent}>
          Update Content
        </button>
      )}
    </div>
  );
};

export default HomepageKeywordFamily;
export const KEYWORD_COUNT = 4;
