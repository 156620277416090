/* eslint-disable */
import { useAtom } from "jotai";
import CompanyInfo from "./components/CompanyInfo";
import LocationsContainer from "./components/LocationsContainer";
import BusinessHours from "./components/BusinessHours";
import globalState from "../../store";
import SocialMedia from "./components/SocialMedia";
import {
  borderedInputWhite,
  boxContainer,
  pageContainerWithOverflow,
} from "../../shared/styles";
import Announcement from "./components/Announcement/Announcement";
import HomepageKeywordFamily from './components/KeywordFamily/HomepageKeywordFamily';

const BasicInfoPage: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  return (
    <div className={pageContainerWithOverflow}>
      <HomepageKeywordFamily />
      <div className="flex flex-wrap">

        <CompanyInfo
        // setGlobalState={setGlobalState}
        // globalState={globalStateAtom}
        />
        <div className={boxContainer}>
          <h2>Business Hours</h2>
          <BusinessHours
          // setGlobalState={setGlobalState}
          // globalState={globalStateAtom}
          />
          <div className="mt-12 bg-white py-2 px-4 rounded-md">
            <label>
              CompanyCam widget code for Home Page:
              <textarea
                className={borderedInputWhite}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    home_cc_widget: e.target.value,
                  })
                }
                value={globalStateAtom.home_cc_widget}
                cols={10}
                rows={5}
              ></textarea>
            </label>
          </div>
          <Announcement />
        </div>
        <div className="flex">
          <LocationsContainer
          // setGlobalState={setGlobalState}
          // globalState={globalStateAtom}
          />
          <SocialMedia
          // setGlobalState={setGlobalState}
          // globalState={globalStateAtom}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInfoPage;
