/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import globalState from "../../store";
import Column from "./components/Column";
import TeamColumn from "./components/TeamColumn";
import AddTeamMemberButton from "./components/AddTeamMemberButton";
import {
  borderedInputWhite,
  boxContainerFull,
  label,
  labelWrap,
  pageContainerWithOverflow,
} from "../../shared/styles";
// Matthew was here
interface TeamMemberProps {
  name: string;
  role: string;
  photo: string;
  order: number;
  draggableId: string;
}

export default function TeamPage() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { teammates, show_team_page, team_show_image, team_header, team_subtitle } =
    globalStateAtom;

  // generating a consistent draggableId and key for each review
  useEffect(() => {
    if (teammates && teammates.length) {
      const draggableServices = teammates.map(
        (item: TeamMemberProps, index: number) => {
          return { ...item, order: index, draggableId: uuidv4() };
        }
      );
      // @ts-ignore
      setGlobalState({ ...globalStateAtom, teammates: draggableServices });
    }
  }, []);

  useEffect(() => {
    if (
      teammates &&
      teammates.length &&
      teammates[0] &&
      // @ts-ignore
      !teammates[0].draggableId
    ) {
      const draggableServices =
        teammates && teammates.length
          ? teammates.map((item: TeamMemberProps, index: number) => {
              return { ...item, draggableId: uuidv4() };
            })
          : [
              {
                name: "",
                role: "",
                photo: "",
                order: 0,
                draggableId: uuidv4(),
              },
            ];
      // @ts-ignore
      setGlobalState({ ...globalStateAtom, teammates: draggableServices });
    }
  }, [teammates]);

  const columnMultiplier = (
    index: number,
    column: string,
    srcOrDes: string
  ) => {
    const actualIndex =
      index === 0 && column === "1"
        ? index
        : index === 0 && column === "2"
        ? (index = 1)
        : index === 0 && column === "3"
        ? (index = 2)
        : (index = parseInt(column) + index * 3 - 1);
    // console.log(actualIndex, srcOrDes)
    return actualIndex;
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    )
      return null;

    // If they move to the same column
    const sameColumn = destination.droppableId === source.droppableId;

    // Move the item within the list
    const newList = [...teammates];

    // Start by making a new list without the dragged item
    const newMembers = sameColumn
      ? newList
      : newList.filter(
          (serv) =>
            // @ts-ignore
            serv.draggableId !==
            teammates[
              columnMultiplier(source.index, source.droppableId, "src")
              // @ts-ignore
            ].draggableId
        );
    // Then insert the item at the right location
    if (sameColumn) {
      // If dragging into the same column, simply switch the items
      const prevSrc =
        newMembers[columnMultiplier(source.index, source.droppableId, "src")];
      newMembers[columnMultiplier(source.index, source.droppableId, "src")] =
        newMembers[
          columnMultiplier(destination.index, destination.droppableId, "des")
        ];
      newMembers[
        columnMultiplier(destination.index, destination.droppableId, "des")
      ] = prevSrc;
      // Else move everything to the right one place
    } else
      newMembers.splice(
        columnMultiplier(destination.index, destination.droppableId, "des"),
        0,
        teammates[columnMultiplier(source.index, source.droppableId, "src")]
      );
    // newList.splice(columnMultiplier(source.index, source.droppableId, 'src'), 0, services[columnMultiplier(destination.index, destination.droppableId,'des')])
    // console.log(newList, 'list')
    const finalList = newMembers.map((item: any, index) => {
      return {
        ...item,
        order: index,
      };
    });

    // Update the list
    // @ts-ignore
    setGlobalState({ ...globalStateAtom, teammates: finalList });
  };
  const [col1, setCol1] = useState<TeamMemberProps[]>();
  const [col2, setCol2] = useState<TeamMemberProps[]>();
  const [col3, setCol3] = useState<TeamMemberProps[]>();
  let lastColumn = 0;
  const column1TeamMembers: TeamMemberProps[] = [];
  const column2TeamMembers: TeamMemberProps[] = [];
  const column3TeamMembers: TeamMemberProps[] = [];

  useEffect(() => {
    const team =
      teammates &&
      teammates.forEach((member, index) => {
        if (lastColumn === 0) {
          column1TeamMembers.push(member);
          // setCol1Services(column1Services)
          lastColumn = 1;
        } else if (lastColumn === 1) {
          column2TeamMembers.push(member);
          // setCol2Services(column2Services)
          lastColumn = 2;
        } else {
          column3TeamMembers.push(member);
          // setCol3Services(column3Services)
          lastColumn = 0;
        }
      });
    setCol1(column1TeamMembers);
    setCol2(column2TeamMembers);
    setCol3(column3TeamMembers);
    // console.log(team, 'changed team')
  }, [teammates]);

  return (
    <div className={pageContainerWithOverflow}>
      <label className="flex justify-center items-center">
        Show team page
        <input
          type="checkbox"
          className="ml-3"
          onChange={(e) =>
            setGlobalState({
              ...globalStateAtom,
              show_team_page: !show_team_page,
            })
          }
          checked={show_team_page}
        />
      </label>
      <label className="flex justify-center items-center">
        Show about us image
        <input
          type="checkbox"
          className="ml-3"
          onChange={(e) =>
            setGlobalState({
              ...globalStateAtom,
              team_show_image: !team_show_image,
            })
          }
          checked={team_show_image}
        />
      </label>
      <div className={`bg-white mt-4 rounded-lg p-6 w-1/2 h-full mx-auto`}>
        <div className="flex flex-col justify-between">
          <label className="flex flex-col items-start mt-3">
            Team Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalStateAtom,
                  team_header: e.target.value,
                })
              }
              value={team_header}
            />
          </label>
          <label className="flex flex-col items-start mt-3">
            Team Subtitle
            <textarea
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalStateAtom,
                  team_subtitle: e.target.value,
                })
              }
              value={team_subtitle}
            />
          </label>
        </div>
      </div>
      {/* <div>
                <h2 className="">Colum 1</h2>
                <h2 className="">Colum 2</h2>
            </div> */}
      <div className="flex justify-between">
        <TeamColumn
          // setGlobalState={setGlobalState}
          // globalState={globalStateAtom}
          column={1}
        />
        <TeamColumn
          // setGlobalState={setGlobalState}
          // globalState={globalStateAtom}
          column={2}
        />
      </div>
      <div>
        <AddTeamMemberButton
          setGlobalState={setGlobalState}
          globalState={globalStateAtom}
        />
      </div>
      {/* <AddTeamMemberButton setGlobalState={setGlobalState} globalState={globalStateAtom} setLocalTeamMembers={() => setGlobalState({...globalState,team:teammates})} /> */}

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="w-full flex justify-center">
          <Column
            localTeamMembers={col1}
            droppableId={1}
          />
          <Column
            localTeamMembers={col2}
            droppableId={2}
          />
          <Column
            localTeamMembers={col3}
            droppableId={3}
          />
        </div>
      </DragDropContext>
    </div>
  );
}
