/* eslint-disable */
const regExp = /[a-zA-Z]/g;

export async function getTextsAndAddresses() {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/api/get-all-texts`,
    {
      method: "GET",
    }
  );
  const responseData = await response.json();
  const textsWithAddress = responseData.message.texts.filter(function (text) {
    return (
      text.sender_address !== null &&
      text.sender_address !== "" &&
      text.sender_address.length > 5 &&
      !text.sender_address.includes("&") &&
      regExp.test(text.sender_address)
    );
  });

  const prosWithAddress = responseData.message.pros.filter(function (pro) {
    return (
      pro.address !== null &&
      pro.address !== "" &&
      pro.address.length > 5 &&
      !pro.address.includes("&") &&
      regExp.test(pro.address)
    );
  });

  const cleanedProAddresses = prosWithAddress.map((pro) => {
    const newAddress = pro.address.replace(/undefined,/g, "");
    const newAddress2 = newAddress.replace(/\+/g, " ");
    pro.address = newAddress2;
    return pro;
  });

  console.log("texts with addresses", textsWithAddress);
  console.log("cleaned pro addresses", cleanedProAddresses);

  return [textsWithAddress, cleanedProAddresses];
}

export async function getTextCounts() {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/api/get-text-counts`,
    {
      method: "GET",
    }
  );
  const responseData = await response.json();
  const textCounts = responseData.message.textCounts;
  return textCounts;
}

export function renderOptions(chartData, chartTitle) {
  const options = {
    series: [
      {
        data: chartData,
      },
    ],
    legend: {
      show: false,
    },
    chart: {
      width: 700,
      height: 700,
      type: "treemap",
    },
    title: {
      text: chartTitle,
      align: "center",
    },
  };

  return options;
}

export async function postTextGeoToDb(
  textId,
  latitude,
  longitude,
  city?,
  state?
) {
  console.log("posting:", textId, latitude, longitude);
  return await fetch(
    `${process.env.REACT_APP_SERVER_URL}/api/save-text-geocodes`,
    {
      method: "POST",
      body: JSON.stringify({
        textId: textId,
        latitude: latitude,
        longitude: longitude,
        city: city ? city : "",
        state: state ? state : "",
      }),
    }
  );
}

export async function postLandingPageGeoToDb(
  landingPageId,
  latitude,
  longitude,
  city?,
  state?
) {
  return await fetch(
    `${process.env.REACT_APP_SERVER_URL}/api/save-landing-page-geocodes`,
    {
      method: "POST",
      body: JSON.stringify({
        landingPageId: landingPageId,
        latitude: latitude,
        longitude: longitude,
        city: city ? city : "",
        state: state ? state : "",
      }),
    }
  );
}
