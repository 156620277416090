/* eslint-disable */
import { Combobox } from "@headlessui/react";
import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { loadFont } from "./utils";

export default function FontDropdown({ defaultFont, setFont }: any) {
  const [fonts, setFonts] = useState([]);

  const [displayedFonts, setDisplayedFonts] = useState([]);
  const [filteredFonts, setFilteredFonts] = useState([]);
  const [selectedFont, setSelectedFont] = useState(defaultFont);

  async function getGoogleFonts() {
    fetch(`https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyCYiwosY7m9a0s5JJ5UdjZpC6zc7rulRsg`).then(
      (res) => res.json()).then(
        (data) => {
          const fetchedFonts = data.items.map((item) => ({
            name: item.family,
            style: `font-family: ${item.family};`,
          }));
          setFonts(fetchedFonts);
        });
  }

  useEffect(() => {
    getGoogleFonts();
  }, []);

  useEffect(() => {
    if (filteredFonts.length > 0) {
      setDisplayedFonts(filteredFonts.slice(0, 50));
    } else {
      setDisplayedFonts(fonts.slice(0, 50));
    }
  }, [filteredFonts]);

  useEffect(() => {
    displayedFonts.forEach((font) => loadFont(font.name));
  }, [displayedFonts]);

  function onChange(value) {
    const filtered = fonts.filter((font) =>
      font?.name?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredFonts(filtered);
  }

  function selectItem(font) {
    setSelectedFont(font);
    setFont(font);
  }

  function openDropdown() {
    if (filteredFonts.length === 0 && displayedFonts.length === 0) {
      setDisplayedFonts(fonts.slice(0, 50));
    }
  }

  return (
    <div>
      <Combobox
        as="div"
        className="w-full"
        value={selectedFont?.name}
        onChange={(item) => selectItem(item)}
      >
        <div className="w-full relative cursor-pointer">
          <Combobox.Button
            onClick={openDropdown}
            className="w-full cursor-pointer"
            as="div"
          >
            <Combobox.Input
              className={`px-4 py-2 relative border border-gray-300 w-full shadow-sm rounded-lg bg-white focus:z-10 focus:border-blue-600 focus:ring-blue-600 text-body-normal sm:shadow-sm`}
              onChange={(event) => onChange(event.target.value)}
              displayedValue={(item: string) => item}
              placeholder={"Search for a font"}
            />
            <ChevronDownIcon className="absolute w-5 h-5 text-gray-700 z-20 top-3 right-2" />
          </Combobox.Button>
          {displayedFonts?.length > 0 && (
            <Combobox.Options
              style={{ zIndex: 9999 }}
              className="absolute mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-body-normal"
            >
              {displayedFonts?.map((item, idx) => (
                <Combobox.Option
                  key={idx}
                  value={item}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-3 pr-9
                      ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`
                  }
                  onClick={() => selectItem(item)}
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected && "text-body-semibold"
                        }`}
                        style={{ fontFamily: item.name }}
                      >
                        {item?.name ? item?.name : ""}
                      </span>
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  );
}
