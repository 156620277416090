/* eslint-disable */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
// import globalState from '../../../store'
import PhotoProps from "../types/photoProps";
import Props from "../../../store/props";
import globalState from "../../../store";
// import AddFaqButton from './components/AddFAQButton'

interface GalleriesButtonsProps {
  addSelectedPhotosToGallery: React.MouseEventHandler<HTMLButtonElement>;
  // addSelectedPhotosToGallery: (item:any, selectedPhotos:any) => void
  selectedPhotos: PhotoProps[];
}

const DeleteButton: React.FC<GalleriesButtonsProps> = ({
  selectedPhotos,
  addSelectedPhotosToGallery,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { gallery_names, gallery, fbData } = globalStateAtom;

  // needed to move this into a component which had received globalState as a prop so it wouldn't run into a TypeScript error
  const updateGlobalGalleryState = () => {
    // TODO: SPLICE ALL THE SELECTED ITEMS OUT OF THE ARRAY TO ENABLE MULTI-DELETE
    const selectedItems = { ...selectedPhotos };
    // // console.log(selectedPhotos, 'selected photos delete')
    const newGallery: PhotoProps[] = [...gallery];
    for (let i = 0; i < gallery.length; i++) {
      const item: any = gallery[i].photo;
      if (selectedItems[item]) {
        // // console.log('back again')
        // find the service with the matching 'order' property
        const posOfThisItem = newGallery.findIndex(
          (photo) => photo.photo === selectedItems[item].photo
        );
        // // console.log(posOfThisItem, 'pos')
        newGallery.splice(posOfThisItem, 1);
      }
    }
    // selectedPhotos is set to {} in GalleriesButtons.tsx
    setGlobalState({ ...globalStateAtom, gallery: newGallery });
  };

  return (
    <button
      onClick={(e) => {
        const confirmation = prompt(
          "Are you sure you want to delete these photos?",
          "yes"
        );
        if (confirmation) {
          updateGlobalGalleryState();
          addSelectedPhotosToGallery(e);
        }
      }}
      className="px-2  m-3 rounded-full border-2 border-red-500 text-red-500 bg-white"
    >
      Delete
    </button>
  );
};

export default DeleteButton;
