import axios, { AxiosError } from "axios";
import { ALL_APPLE_PATENT_IMAGE_FORMAT_ARRAY } from "./constants";

export const checkApplePatentImageExtension = (extension: string): boolean => {
  const isApplePatentImageExtension =
    ALL_APPLE_PATENT_IMAGE_FORMAT_ARRAY.includes(extension.toLowerCase());
  return isApplePatentImageExtension;
};
export const getFileExtensionFromType = (fileName: string): string => {
  const fileExtension = fileName.split("/").pop() as string;
  if (fileExtension) return fileExtension;
  return "";
};

export const reactQuillModules = {
  toolbar: [
    [{ size: [] }],
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    // ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
export const reactQuillFormats = [
  "size",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  // 'color',
  "list",
  "bullet",
  "indent",
  "link",
  "align",
];

export const generateRefinedLogo = async (
  sitePk: number,
  logo: string
): Promise<string> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/generate-refined-logo/${sitePk}`,
      {
        logo,
      }
    );
    return response.data?.logo_refined;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const axiosError = e as AxiosError;
      if (
        axiosError.response &&
        axiosError.response.data &&
        axiosError.response.data.message
      ) {
        const errorMessage = axiosError.response.data.message;
        console.error("Error message:", errorMessage);
        alert(errorMessage);
      }
    } else {
      console.log("error:", e);
      alert(e);
    }
    return "";
  }
};
