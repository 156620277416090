/* eslint-disable */
import React, { useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";
import Faq from "./Faq";
import Props from "../../../store/props";
import { useAtom } from "jotai";
import globalState from "../../../store";

// interface ColumnProps {
//     localFaqs: any
//     setLocalFaqs: React.Dispatch<React.SetStateAction<ItemProps[]>>
// }

interface ItemProps {
  answer: string;
  "landing-page": number;
  order: number;
  question: string;
  draggableId: string;
}

const Column: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const handleDelete = (index: any) => {
    const thisFaq = globalStateAtom.faqs[index] as any;
    // // console.log(thisFaq, 'thisFaq')
    // make placeholder array for added Faqs
    const newFaqs = [...globalStateAtom.faqs] as any;
    // find position of selected Faq
    const posOfFaq = newFaqs.findIndex((serv: any) => serv === thisFaq);
    // splice that Faq out of the array
    newFaqs.splice(posOfFaq, 1);
    // set the state of Faqs to the spliced array
    // setLocalFaqs(newFaqs)
    setGlobalState({ ...globalStateAtom, faqs: newFaqs });
  };

  // when localFaqs changes, change the global state so the changes can be accurately saved to the db
  // useEffect(() => {
  //     // make sure the order of the items matches the order of the drag-n-drop
  //     const orderedList = globalStateAtom.faqs.map((item: any, idx: any) => {
  //         return { ...item, order: idx }
  //     })
  //     setGlobalState({ ...globalStateAtom, faqs: orderedList })
  // }, [globalStateAtom.faqs])

  return (
    <Droppable droppableId="col-1">
      {(provided) => (
        <div
          className="flex flex-col w-8/12 items-start"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {globalStateAtom.faqs.map((faq: ItemProps, index: number) => (
            <Faq
              key={faq.draggableId}
              // setLocalFaqs={setLocalFaqs}
              // localFaqs={localFaqs}
              draggableId={faq.draggableId}
              faq={faq}
              handleDelete={() => handleDelete(index)}
              index={index}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Column;
