/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useAtom } from "jotai";
import { useState, useRef } from "react";
import Thumbnail from "../../../shared/Thumbnail";
import globalState from "../../../store";
// import PhotoProps from '../types/photoProps'
import { getSignedRequest } from "../../../utils/helpers/uploadFileUtil";
import ImageCropModal from "../../logo/components/image-crop-modal/ImageCropModal";
import { IoCropOutline } from "react-icons/io5";
import {
  getMediaTypeFromURL,
  videoTypes,
} from "../../../utils/helpers/getMediaType";
import { buttonSmall } from "../../../shared/styles";

interface AllPhotosProps {
  id: string;
  thisPartnership: any;
  setThisPartnership: any;
}

const UploadFiles: React.FC<AllPhotosProps> = ({
  id,
  thisPartnership,
  setThisPartnership,
}) => {
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { products } = globalStateAtom;

  // @ts-ignore
  // const [thisPartnership, setThisPartnership] = useState(products.find(t => t.draggableId === id))
  const [loading, setLoading] = useState(false);

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event: any) => {
    // // console.log('cleek')
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const closeImageCropModal = () => {
    setIsCropModalOpen(false);
  };

  const setCroppedImage = (imageURL: string) => {
    const croppedPhoto = imageURL.split(
      "https://landing-page-app-hero-images.s3.amazonaws.com/media/"
    )[1]
    const newPartnerships = [...products];
    const thisPartner = {
      ...thisPartnership!,
      photo: croppedPhoto,
    };
    setThisPartnership(thisPartner);
    const posOfThisTeammate = newPartnerships.findIndex(
      (t) => t === thisPartnership
    );
    newPartnerships.splice(posOfThisTeammate, 1, thisPartner);
    setGlobalState({
      ...globalStateAtom,
      products: newPartnerships,
    });
  };

  const imageURL =
    products && products.length && thisPartnership && thisPartnership.photo
      ? "https://landing-page-app-hero-images.s3.amazonaws.com/media/" +
        thisPartnership.photo
      : "";

  return (
    <div className="w-64">
      <div className="relative">
        {loading ? (
          <div className="w-52 h-52">
            <img src="/loading.gif" width="50" />
          </div>
        ) : (
          <div className="flex justify-between items-center w-full">
            <Thumbnail
              photo={{
                photo: imageURL,
              }}
              handleDelete={() => {
                const newPartnerships = [...products];
                const posOfThisTeammate = newPartnerships.findIndex(
                  (t) => t === thisPartnership
                );
                const thisPartner = { ...thisPartnership!, photo: "" };
                newPartnerships.splice(posOfThisTeammate, 1, thisPartner);
                setGlobalState({
                  ...globalStateAtom,
                  products: newPartnerships,
                });
                setThisPartnership(thisPartner);
              }}
              handleClick={handleClick}
              draggable={false}
            />
            {!videoTypes.includes(getMediaTypeFromURL(thisPartnership.photo)) &&
              getMediaTypeFromURL(thisPartnership.photo) !== "gif" && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsCropModalOpen(true);
                  }}
                  className={`${buttonSmall} position whitespace-nowrap`}
                >
                  Crop Photo
                </button>
              )}
          </div>
        )}
      </div>

      <div>
        <input
          type="file"
          ref={hiddenFileInput}
          className="hidden"
          // style={{'display':'none'}}
          onChange={async (e) => {
            setLoading(true);
            const curFile = e.target.files![0];
            const uploadedImageUrl = URL.createObjectURL(e.target.files![0]);
            const newPartnerships = [...products];
            if (uploadedImageUrl) {
              const signedS3Photo = await getSignedRequest(curFile);
              // // console.log(signedS3Photo, 'signedS3Photo')
              const uploadedImageObject = {
                photo: signedS3Photo,
                saved: false,
                fileName: signedS3Photo,
              };
              const thisPartner = {
                ...thisPartnership!,
                photo: signedS3Photo,
              };
              setThisPartnership(thisPartner);
              const posOfThisTeammate = newPartnerships.findIndex(
                (t) => t === thisPartnership
              );
              newPartnerships.splice(posOfThisTeammate, 1, thisPartner);
              // // console.log(
              //     newPartnerships,
              //     'newPartnerships UPLOADED>?>?'
              // )
            }
            setLoading(false);
            setGlobalState({
              ...globalStateAtom,
              products: newPartnerships,
            });
          }}
        />
      </div>
      <ImageCropModal
        closeModal={closeImageCropModal}
        isOpen={isCropModalOpen}
        imageURL={imageURL}
        setImageURL={setCroppedImage}
      />
    </div>
  );
};

export default UploadFiles;
