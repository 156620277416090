/* eslint-disable */
import { useAtom } from "jotai";
import React from "react";
import globalState from "../../../../store";
import { IoTrashBinOutline } from "react-icons/io5";
import CustomerTable from "./CustomerTable";

const ExistingCustomers = ({ customers }: any) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const deleteCustomer = (index: number) => {
    const { customerInfo = [] } = globalStateAtom;
    customerInfo.splice(index, 1);
    setGlobalState({ ...globalStateAtom, customerInfo });
  };

  const trashBinClickHandler = (index: number) => {
    deleteCustomer(index);
  };
  return (
    <div>
      {customers && customers.length > 0 && (
        <>
          <p className="font-extrabold text-2xl">Existing Customers</p>
          <CustomerTable
            customers={customers}
            onRemoveClickHandler={trashBinClickHandler}
          />
        </>
      )}
      {!customers ||
        (customers && customers.length === 0 && (
          <p className="font-extrabold text-2xl">No Existing Customers</p>
        ))}
    </div>
  );
};

export default ExistingCustomers;
