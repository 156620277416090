/* eslint-disable */
import { useState } from "react";
import { buttonWhiteSmall } from "../../../shared/styles";

interface GMBProps {
  placeId: any;
  mapsUri: any;
  addPhotos: any;
  linkToGMBDashboard: string;
}

const ManageGMB: React.FC<GMBProps> = ({
  placeId,
  mapsUri,
  addPhotos,
  linkToGMBDashboard,
}) => {
  return (
    <>
      <h2 className="absolute top-32 right-24">Google My Business</h2>
      <div className="bg-white m-12 rounded-lg p-6">
        <div className="flex justify-between">
          <div
            onClick={() => window.open(linkToGMBDashboard, "_blank")}
            className={`p-2 w-36 my-3 rounded-full border-2 border-gray-500 bg-white text-sm text-gray-500 cursor-pointer flex justify-center items-center mb-3`}
          >
            <p>Update GMB</p>
          </div>
          <div onClick={addPhotos} className={`${buttonWhiteSmall}`}>
            Add All Photos
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <p className="text-left">Place ID</p>
            <p className="text-left text-sm text-gray-500">{placeId}</p>
          </div>
          <button
            className={`${buttonWhiteSmall}`}
            onClick={() => {
              navigator.clipboard.writeText(placeId);
            }}
          >
            Copy
          </button>
        </div>
        <div
          className="text-left mt-2 cursor-pointer"
          onClick={() => window.open(mapsUri, "_blank")}
        >
          Link to GMB Profile
        </div>
      </div>
    </>
  );
};

export default ManageGMB;
