import React, { useState, useEffect, createContext, Dispatch, SetStateAction } from "react";
import { Switch, Route } from "react-router-dom";
import Nav from "./shared/Nav";
import "./App.css";
import { useAtom } from "jotai";
import authState from "./store/auth";
import globalState from "./store";
import NewSite from "./shared/NewSite";
import BasicInfoPage from "./pages/basicInfo";
import DomainPage from "./pages/domain";
import FAQsPage from "./pages/faqs";
import LogoPage from "./pages/logo";
import PhotosPage from "./pages/photos";
import PreviewPage from "./pages/preview";
import ReviewsPage from "./pages/reviews";
import ServicesPage from "./pages/services";
import TeamPage from "./pages/team";
import PartnershipsPage from "./pages/partnerships";
import axios from "axios";
import MapPage from "./pages/map";
import CustomValuesPage from "./pages/custom";
import CustomPage from "./pages/custom-page";
import LeadGeneratingForm from "./shared/LeadGeneratingForm";
import AdsLandingPage from "./pages/ads-page";
import AuthPage from "./pages/auth";

interface AppContextInterface {
  isSaveClicked: boolean;
  setIsSaveClicked: Dispatch<SetStateAction<boolean>>;
  addOrChangePaidUrl: boolean;
  setAddOrChangePaidUrl: Dispatch<SetStateAction<boolean>>;
  aboutUs: string;
  setAboutUs: Dispatch<SetStateAction<string>>;
  tagline: string;
  setTagline: Dispatch<SetStateAction<string>>;
  saveClickedCount: number;
  setSaveClickedCount: Dispatch<SetStateAction<number>>;
}

const defaultAppContext: AppContextInterface = {
  isSaveClicked: false,
  setIsSaveClicked: () => undefined,
  addOrChangePaidUrl: false,
  setAddOrChangePaidUrl: () => undefined,
  aboutUs: "",
  setAboutUs: () => undefined,
  tagline: "",
  setTagline: () => undefined,
  saveClickedCount: 0,
  setSaveClickedCount: () => undefined,
}

export const AppContext = createContext<AppContextInterface>(defaultAppContext);
function App() {
  const [authStateAtom, setAuthState] = useAtom(authState);
  const [globalStateAtom] = useAtom(globalState);
  const [mounted, setMounted] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [addOrChangePaidUrl, setAddOrChangePaidUrl] = useState(false);
  const [aboutUs, setAboutUs] = useState("");
  const [tagline, setTagline] = useState("");
  const [saveClickedCount, setSaveClickedCount] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const refreshToken = localStorage.getItem("refresh_token") || "";
        if (refreshToken) {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/pi/refresh`,
            {
              refresh_token: refreshToken,
            }
          );
          if (response)
            setAuthState({
              ...authStateAtom,
              refresh_token: response.data.refresh_token,
            });
        }
      } catch {
        console.log("error refreshing token");
      }
    })();
  }, []);

  useEffect(() => {
    if (globalStateAtom.slug) {
      setMounted(true);
      localStorage.setItem("globalState", JSON.stringify(globalStateAtom));
    }
  }, [globalStateAtom]);

  return authStateAtom.refresh_token === "" ? (
    <AuthPage />
  ) : (
    <div className="App">
      <AppContext.Provider
        value={{
          isSaveClicked,
          setIsSaveClicked,
          addOrChangePaidUrl,
          setAddOrChangePaidUrl,
          aboutUs,
          setAboutUs,
          tagline,
          setTagline,
          saveClickedCount,
          setSaveClickedCount,
        }}
      >
        <Switch>
          <Route exact path="/">
            <NewSite />
          </Route>
          <Route exact path="/lead_generation">
            <div>
              <LeadGeneratingForm />
            </div>
          </Route>
          <Route path="/logo/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <LogoPage />}
            </>
          </Route>
          <Route path="/basicInfo/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <BasicInfoPage />}
            </>
          </Route>
          <Route path="/services/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <ServicesPage />}
            </>
          </Route>
          <Route path="/photos/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <PhotosPage />}
            </>
          </Route>
          <Route path="/faqs/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <FAQsPage />}
            </>
          </Route>
          <Route path="/reviews/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <ReviewsPage />}
            </>
          </Route>
          <Route path="/partnerships/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <PartnershipsPage />}
            </>
          </Route>
          <Route path="/team/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <TeamPage />}
            </>
          </Route>
          <Route path="/domain/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <DomainPage />}
            </>
          </Route>
          <Route path="/custom/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <CustomValuesPage />}
            </>
          </Route>
          <Route path="/custom-page/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <CustomPage />}
            </>
          </Route>
          <Route path="/ads-page/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <AdsLandingPage />}
            </>
          </Route>
          <Route path="/preview/:slug">
            <>
              <Nav />
              {!mounted ? <p>loading</p> : <PreviewPage />}
            </>
          </Route>
          <Route path="/map">
            <>
              <Nav />
              <MapPage />
            </>
          </Route>
        </Switch>
      </AppContext.Provider>
    </div>
  );
}

export default App;
