/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { v4 as uuidv4 } from "uuid";
import Props from "../../../store/props";
import { buttonSmall } from "../../../shared/styles";
import { useAtom } from "jotai";
import globalState from "../../../store";
import { getArrayofThreeRandomPhoto } from "../utils";

interface ItemProps {
  "landing-page": number;
  order: number;
  "service-description": string;
  "service-description-long": string;
  "service-name": string;
  draggableId: string;
  photos: any[];
}

interface AddServiceButtonProps {
  setActiveService: any;
}

const AddServiceButton: React.FC<AddServiceButtonProps> = ({
  setActiveService,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { gallery } = globalStateAtom;



  const addNewService = () => {
    // @ts-ignore
    const blankServiceObject: ItemProps = {
      "landing-page": globalStateAtom.sitePk,
      order:
        globalStateAtom.services && globalStateAtom.services.length
          ? globalStateAtom.services.length
          : 0,
      "service-description": "",
      "service-description-long": "",
      "service-name": `NEW SERVICE ${
        !globalStateAtom.services
          .map((s) => s && s["service-name"])
          .includes(`NEW SERVICE ${globalStateAtom.services.length}`)
          ? globalStateAtom.services && globalStateAtom.services.length
            ? globalStateAtom.services.length
            : 0
          : uuidv4()
      }`,
      draggableId: uuidv4(),
      photos: getArrayofThreeRandomPhoto(gallery),
    };
    setGlobalState({
      ...globalStateAtom,
      services: [...globalStateAtom.services, blankServiceObject],
    });
    setActiveService(blankServiceObject);
  };

  return (
    <button onClick={addNewService} className={`${buttonSmall}`}>
      Add Service
    </button>
  );
};

export default AddServiceButton;
