/* eslint-disable */
import { useState, useEffect, useReducer } from "react";
import { useAtom } from "jotai";
import globalState from "../../../store";
import PhotoProps from "../types/photoProps";
import Props from "../../../store/props";
import DeleteButton from "./DeleteButton";
import UncategorizeButton from "./UncategorizeButton";
// import AddFaqButton from './components/AddFAQButton'

interface GalleriesButtonsMicroProps {
  photo: any;
  addPhotoToGallery: any;
  localGalleryNames: any;
  addSelectedPhotosToGallery: React.MouseEventHandler<HTMLButtonElement>;
  // addSelectedPhotosToGallery: (item:any, selectedPhotos:any) => void
  setSelectedPhotos: React.Dispatch<React.SetStateAction<any>>;
  selectedPhotos: PhotoProps[];
  showMicroGalleryButtons: boolean;
  showMicroServicePhotoButtons: boolean;
  handleDelete: any;
}

const GalleriesButtonsMicro: React.FC<GalleriesButtonsMicroProps> = ({
  addPhotoToGallery,
  photo,
  // setGlobalState,
  // globalState,
  localGalleryNames,
  selectedPhotos,
  addSelectedPhotosToGallery,
  setSelectedPhotos,
  showMicroGalleryButtons,
  showMicroServicePhotoButtons,
  handleDelete,
}) => {

  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  // // console.log('global state GalleriesButtonsMicro',globalState)
  // const { gallery, services, fbData } = globalState
  return showMicroServicePhotoButtons && showMicroGalleryButtons ? (
    <div className="w-full h-100 bg-gray-200 bg-opacity-70 -mt-1 p-1">
      {localGalleryNames &&
        localGalleryNames.map((gallery: any) => {
          return (
            <button
              key={gallery.gallery_name}
              onClick={(e) => {
                addPhotoToGallery(e, photo, gallery["gallery-name"]);
                addSelectedPhotosToGallery(e);
              }}
              className="p-1 rounded-full border-2 text-white bg-gray-500"
            >
              {gallery["gallery-name"]}
              {/* {gallery['gallery-name'] ? gallery['gallery-name'].substring(0,3) : "NaN"} */}
            </button>
          );
        })}

      <button
        key={"uncategorizeda"}
        onClick={(e) => {
          addPhotoToGallery(e, photo, "Uncategorized");
          addSelectedPhotosToGallery(e);
        }}
        className="p-1 rounded-full border-2 text-white bg-gray-500"
      >
        Uncategorized
      </button>
      <button
        key={"uncategorized"}
        onClick={(e) => {
          handleDelete(photo.photo);
        }}
        className="p-1 rounded-full border-2 text-white bg-gray-500"
      >
        Delete
      </button>
      <button
        key={"setlogoxx"}
        className={
          globalStateAtom.hero_gallery_photos.includes(photo.photo)
            ? "bg-green-400 p-1 rounded-full"
            : "" + "p-1 rounded-full border-2 text-white bg-gray-500"
        }
        onClick={(e) => {
          const newHeroGallery = [...globalStateAtom.hero_gallery_photos];
          newHeroGallery.push({
            alt_tag: "-image",
            caption: null,
            order: newHeroGallery.length,
            photo: photo.photo
          });
          setGlobalState({
            ...globalStateAtom,
            hero_gallery_photos: newHeroGallery,
          });
        }}
      >
        Set Cover
      </button>
      <button
        key={"setlogoxas" + photo.order}
        className={
          globalStateAtom.about_upload == photo.photo
            ? "bg-green-400 p-1 rounded-full"
            : "" + "p-1 rounded-full border-2 text-white bg-gray-500"
        }
        onClick={(e) => {
          // setAsLogo()
          console.log("globalStateAtom photo", globalStateAtom.logo);
          console.log("globalStateAtom photo", photo.photo);

          setGlobalState({
            ...globalStateAtom,
            about_upload: photo.photo,
            // about_us_image: photo.photo,
          });
          // setGlobalState({ ...globalStateAtom, logo: photo.photo })
          console.log("globalStateAtom", globalStateAtom);
          console.log("globalStateAtom photo", photo);
        }}
      >
        Set About
      </button>
      <button
        key={"setlogox" + photo.order}
        // key="setlogo"
        className={
          globalStateAtom.logo == photo.photo
            ? "bg-green-400 p-1 rounded-full"
            : "" + "p-1 rounded-full border-2 text-white bg-gray-500"
        }
        onClick={(e) => {
          // setAsLogo()
          console.log("globalStateAtom photo", globalStateAtom.logo);
          console.log("globalStateAtom photo", photo.photo);

          setGlobalState({ ...globalStateAtom, logo: photo.photo });
          console.log("globalStateAtom", globalStateAtom);
          console.log("globalStateAtom photo", photo);
        }}
      >
        Set Logo
      </button>
      {globalStateAtom.services.map((service) => {
        // {showMicroServicePhotoButtons && globalStateAtom.services.map((service) => {
        return (
          <>
            <button
              key={"setlogo"}
              // key={"setlogo"+service['service-name']+photo.order}
              className={
                service.photos.some((serv_photo: any) => {
                  if (photo.photo == serv_photo.photo) {
                    console.log("photo xxxx", photo);
                    console.log("serv_photo", serv_photo);
                    return true;
                  }
                  return false;
                })
                  ? "bg-green-400 p-1 rounded-full"
                  : "" +
                    // service.photos.some((serv_photo:any)=>{ return true}) ? 'bg-green-400':'' +
                    "p-1 rounded-full border-2 text-white bg-gray-500"
              }
              onClick={(e) => {
                // setAsLogo()

                console.log(
                  "globalStateAtom photo service",
                  globalStateAtom.logo
                );
                console.log("globalStateAtom photo", photo.photo);

                console.log("globalStateAtom.services", service);
                let newServices = globalStateAtom.services;
                console.log("newServices", newServices);
                newServices = newServices.map((newService) => {
                  if (
                    newService &&
                    service &&
                    newService["service-name"] == service["service-name"]
                  ) {
                    //found
                    console.log("snewService", newService);
                    const newPhotos = newService.photos;
                    console.log("newPhotos", newPhotos);
                    newPhotos.push({
                      photo: photo.photo,
                      order:
                        newService &&
                        newService?.photos &&
                        newService.photos.length
                          ? newService.photos.length
                          : 0,
                      stock: false,
                    });
                    // newPhotos.photos.push({'photo':photo.photo,'order':newService && newService?.photos && newService.photos.length ? newService.photos.length : 0, 'stock':false})
                    console.log("newPhotos", newPhotos);
                    newService.photos = newPhotos;
                  }
                  return newService;
                });

                console.log("newServices", newServices);
                // const curService = ''
                // newServices
                setGlobalState({
                  ...globalStateAtom,
                  services: newServices,
                });
                console.log("globalStateAtom", globalStateAtom);
                console.log("globalStateAtom photo", photo);
              }}
            >
              Set Service {service && service["service-name"]} -{" "}
              {service && service["photos"] && service["photos"].length}
            </button>
          </>
        );
      })}
    </div>
  ) : showMicroGalleryButtons ? (
    <div className="w-full h-100 bg-gray-200 bg-opacity-70 -mt-1 p-1">
      {localGalleryNames &&
        localGalleryNames.map((gallery: any) => {
          // console.log("gallllery", gallery);
          return (
            <button
              key={gallery.gallery_name}
              onClick={(e) => {
                addPhotoToGallery(e, photo, gallery["gallery-name"]);
                addSelectedPhotosToGallery(e);
              }}
              className="p-1 rounded-full border-2 text-white bg-gray-500"
            >
              {gallery["gallery-name"]}
              {/* {gallery['gallery-name'] ? gallery['gallery-name'].substring(0,3) : "NaN"} */}
            </button>
          );
        })}

      <button
        key={"uncategorizeda"}
        onClick={(e) => {
          addPhotoToGallery(e, photo, "Uncategorized");
          addSelectedPhotosToGallery(e);
        }}
        className="p-1 rounded-full border-2 text-white bg-gray-500"
      >
        Uncategorized
      </button>
      <button
        key={"uncategorized"}
        onClick={(e) => {
          handleDelete(photo.photo);
        }}
        className="p-1 rounded-full border-2 text-white bg-gray-500"
      >
        Delete
      </button>
      <button
        key={"setlogoxx"}
        className={
          globalStateAtom.hero_gallery_photos.find((el: any) => el.photo === photo.photo)
            ? "bg-green-400 p-1 rounded-full"
            : "" + "p-1 rounded-full border-2 text-white bg-gray-500"
        }
        onClick={(e) => {
          const newHeroGallery = [...globalStateAtom.hero_gallery_photos];
          newHeroGallery.push({
            alt_tag: "-image",
            caption: null,
            order: newHeroGallery.length,
            photo: photo.photo
          });
          setGlobalState({
            ...globalStateAtom,
            hero_gallery_photos: newHeroGallery,
          });
          console.log("globalStateAtom", globalStateAtom);
          console.log("globalStateAtom photo", photo);
        }}
      >
        Set Cover
      </button>
      <button
        key={"setlogoxas" + photo.order}
        className={
          globalStateAtom.about_upload == photo.photo
            ? "bg-green-400 p-1 rounded-full"
            : "" + "p-1 rounded-full border-2 text-white bg-gray-500"
        }
        onClick={(e) => {
          // setAsLogo()
          console.log("globalStateAtom photo", globalStateAtom.logo);
          console.log("globalStateAtom photo", photo.photo);

          setGlobalState({
            ...globalStateAtom,
            about_upload: photo.photo,
            // about_us_image: photo.photo,
          });
          // setGlobalState({ ...globalStateAtom, logo: photo.photo })
          console.log("globalStateAtom", globalStateAtom);
          console.log("globalStateAtom photo", photo);
        }}
      >
        Set About
      </button>
      <button
        key={"setlogox" + photo.order}
        // key="setlogo"
        className={
          globalStateAtom.logo == photo.photo
            ? "bg-green-400 p-1 rounded-full"
            : "" + "p-1 rounded-full border-2 text-white bg-gray-500"
        }
        onClick={(e) => {
          // setAsLogo()
          console.log("globalStateAtom photo", globalStateAtom.logo);
          console.log("globalStateAtom photo", photo.photo);

          setGlobalState({ ...globalStateAtom, logo: photo.photo });
          console.log("globalStateAtom", globalStateAtom);
          console.log("globalStateAtom photo", photo);
        }}
      >
        Set Logo
      </button>
    </div>
  ) : (
    <></>
  );
  // ))
  // </div>
};

export default GalleriesButtonsMicro;
