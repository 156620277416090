/* eslint-disable */
import { v4 as uuidv4 } from "uuid";
import { buttonSmall } from "../../../shared/styles";
import Props from "../../../store/props";

const AddReviewButton: React.FC<Props> = ({ setGlobalState, globalState }) => {
  const { teammates } = globalState;

  const blankReviewObject = {
    name: "",
    role: "",
    photo: "",
    alt_tag: "",
    draggableId: uuidv4(),
    order: teammates ? teammates.length : 0,
  };

  const addNewReview = () => {
    setGlobalState({
      ...globalState,
      teammates: [...teammates, blankReviewObject],
    });
  };

  return (
    <button onClick={addNewReview} className={`${buttonSmall} relative`}>
      Add Teammate
    </button>
  );
};

export default AddReviewButton;
