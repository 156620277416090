/* eslint-disable */
import axios from "axios";

export const getCustomerBySearchQuery = async (query: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/api/get-customer-by-any`,
    { search_key: query }
  );
  if (response?.data.status === "success") {
    return response.data.message.customers;
  }
  return [];
};

export const changeUsersToTeammates = async (
  customerIds: any[],
  primaryOwnerId: string
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/api/make-customers-teammates`,
    { customer_ids: customerIds, primary_owner_id: primaryOwnerId }
  );
  if (response?.data.status === "success") {
    return true;
  }
  return [];
};
