/* eslint-disable */
import { v4 as uuid4 } from "uuid";
import axios from "axios";

function uploadFile(file: any, signedRequest: any, url: any) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedRequest);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          // console.log(url, 'url in uploadFile')
          resolve(file.name);
          //   resolve(url)
        } else {
          alert("Could not upload file.");
        }
      }
    };
    xhr.send(file);
  });
}

export function getSignedRequest(file: any): Promise<string> {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    const uuid = uuid4();
    const fileName = `${uuid}.${file.type.split("/")[1]}`.replace("+xml", "");
    // console.log(fileName, 'fileName')
    const newUrl = `${process.env.REACT_APP_NODE_SERVER_URL}/sign_s3?file_name=${fileName}&file_type=${file.type}`;
    // console.log('newurl', newUrl)
    xhr.open("GET", newUrl);
    xhr.onreadystatechange = async function () {
      // console.log('getSignedRequest - in onreadystatechange - xhr', xhr)
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          const uploaded = await uploadFile(
            file,
            response.signedRequest,
            response.url
          );
          resolve(fileName);
        } else {
          alert("Could not get signed URL.");
        }
      }
    };
    // console.log('getSignedRequest -pre xhr.send')
    xhr.send();
  });
}

export const pushImageToS3 = async (file: File | Blob): Promise<string[]> => {
  const uploadedImageUrl = URL.createObjectURL(file);
  if (uploadedImageUrl) {
    const signedS3Photo = await getSignedRequest(file);

    const completePhotoUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${signedS3Photo}`;
    return [completePhotoUrl, signedS3Photo];
  }
  return ["", ""];
};

export const uploadExternalImageUrl = async (ImageUrl: string) => {
  const nodeUrl = `${process.env.REACT_APP_NODE_SERVER_URL}/saveExtUrlToS3Endpoint`;
  const uploadImageResponse = await axios.post(nodeUrl, { imgUrl: ImageUrl });
  if (uploadImageResponse?.data) {
    return uploadImageResponse.data;
  }
  return null;
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
