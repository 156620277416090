/* eslint-disable */
import React from "react";
import CustomerTable from "./CustomerTable";
const SelectedCustomers = ({ selectedCustomer, setSelectedCustomer }) => {
  const deleteSelectedCustomer = (index: number) => {
    selectedCustomer.splice(index, 1);
    setSelectedCustomer([...selectedCustomer]);
  };

  return (
    <div>
      {selectedCustomer && selectedCustomer.length > 0 && (
        <>
          <p className="font-extrabold text-2xl">Selected Customers</p>
          <CustomerTable
            customers={selectedCustomer}
            onRemoveClickHandler={deleteSelectedCustomer}
          />
        </>
      )}
    </div>
  );
};

export default SelectedCustomers;
