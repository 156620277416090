/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useAtom } from "jotai";
import { useState, useRef } from "react";
import Thumbnail from "../../../shared/Thumbnail";
import globalState from "../../../store";
import Props from "../../../store/props";
// import PhotoProps from '../types/photoProps'
import { getSignedRequest } from "../../../utils/helpers/uploadFileUtil";

interface AllPhotosProps {
  id: string;
  thisTeammate: any;
  setThisTeammate: any;
}

const UploadFiles: React.FC<AllPhotosProps> = ({
  id,
  thisTeammate,
  setThisTeammate,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { teammates } = globalStateAtom;

  // @ts-ignore
  // const [thisTeammate, setThisTeammate] = useState(teammates.find(t => t.draggableId === id))
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event: any) => {
    // console.log('cleek')
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  return (
    <>
      {loading ? (
        <div className="w-52 h-52">
          <img src="/loading.gif" width="50" />
        </div>
      ) : (
        <Thumbnail
          photo={{
            photo:
              teammates && teammates.length && thisTeammate
                ? thisTeammate.photo
                : "/prophone_favicon_square.png",
          }}
          handleDelete={() => {
            setImage("");
            const newTeammates = [...teammates];
            const posOfThisTeammate = newTeammates.findIndex(
              (t) => t === thisTeammate
            );
            const thisTeamMember = { ...thisTeammate!, photo: "" };
            newTeammates.splice(posOfThisTeammate, 1, thisTeamMember);
            setGlobalState({
              ...globalStateAtom,
              teammates: newTeammates,
            });
            setThisTeammate(thisTeamMember);
          }}
          handleClick={handleClick}
          draggable={false}
        />
      )}
      <div>
        <input
          type="file"
          ref={hiddenFileInput}
          className="hidden"
          onChange={async (e) => {
            setLoading(true);
            const curFile = e.target.files![0];
            const uploadedImageUrl = URL.createObjectURL(e.target.files![0]);
            const newTeammates = [...teammates];
            // console.log(newTeammates.length, 'new teammates length')
            if (uploadedImageUrl) {
              const signedS3Photo = await getSignedRequest(curFile);
              const completePhotoUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${signedS3Photo}`;
              // console.log(signedS3Photo, 'signedS3Photo')
              const uploadedImageObject = {
                photo: completePhotoUrl,
                saved: false,
                fileName: signedS3Photo,
              };
              const thisTeamMember = {
                ...thisTeammate!,
                photo: completePhotoUrl,
              };
              setThisTeammate(thisTeamMember);
              const posOfThisTeammate = newTeammates.findIndex(
                (t) => t === thisTeammate
              );
              newTeammates.splice(posOfThisTeammate, 1, thisTeamMember);
              // console.log(
              //     newTeammates,
              //     'newTeammates UPLOADED>?>?'
              // )
              setImage(completePhotoUrl);
            }
            setLoading(false);
            setGlobalState({
              ...globalStateAtom,
              teammates: newTeammates,
            });
          }}
        />
      </div>
    </>
  );
};

export default UploadFiles;
