/* eslint-disable */
import ApexCharts from "apexcharts";
import { useEffect } from "react";
import { renderOptions } from "./utils";

export default function Leaderboard(props) {
  const { textCounts } = props;

  const textCountsArray = [];
  for (const property in textCounts) {
    textCountsArray.push({
      name: property,
      count: textCounts[property],
    });
  }
  textCountsArray.sort((a, b) => (a.count < b.count ? 1 : -1));

  return (
    <>
      <h1 className="text-xl mt-10">Pro Leaderboard</h1>
      <h4 className="text-gray-500 mb-10">(number of texts received)</h4>
      <div className="flex flex-col w-1/4 m-auto">
        {Object.keys(textCounts).length === 0 && (
          <div>Loading leaderboard...</div>
        )}
        {textCountsArray.map((pro) => {
          return (
            <div key={pro.name} className="flex">
              <div className="flex mr-8 w-20">{pro.count}</div>
              <div className="flex text-left w-full">{pro.name}</div>
            </div>
          );
        })}
      </div>
    </>
  );
}
