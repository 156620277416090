/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import { IoCloseCircle } from "react-icons/io5";
import Props from "../../../store/props";
import {
  borderedInputWhite,
  borderedInputWhiteWrap,
  boxContainer,
  boxContainerFull,
  label,
  labelWrap,
} from "../../../shared/styles";
import UploadFiles from "./UploadFiles";
import { useAtom } from "jotai";
import globalState from "../../../store";

interface ReviewProps {
  review: {
    title: string;
    subtitle: string;
    photo: string;
    link: string;
    draggableId: string;
    order: number;
  };
  draggableId: any;
  localTeamMembers: any;
  // setLocalTeamMembers: React.Dispatch<React.SetStateAction<ItemProps[]>>
  handleDelete: () => void;
  index: number;
}

interface ItemProps {
  title: string;
  subtitle: string;
  photo: string;
  link: string;
  draggableId: string;
  order: number;
}

const Review: React.FC<ReviewProps> = ({
  review,
  index,
  draggableId,
  handleDelete,
  localTeamMembers,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { products } = globalStateAtom;

  const [partnershipState, setThisPartnership] = useState(
    localTeamMembers[index]
  );
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const thisReview = localTeamMembers[index];
    // make copy of the reviews array
    const newReviews = [...products];
    if (thisReview) {
      // find the review with the matching 'order' property
      const posOfThisReview = newReviews.findIndex(
        (review) => review === thisReview
      );
      // set that review to the current local state of review
      newReviews[posOfThisReview] = partnershipState;
      // update the global state
      // setLocalTeamMembers(newReviews)
      newReviews.map((item: any, idx: any) => {
        return { ...item, order: idx };
      });
      
      if (mounted) {
        setGlobalState({ ...globalStateAtom, products: newReviews });
      } else {
        setMounted(true);
      }
    }
  }, [partnershipState]);

  return (
    <Draggable draggableId={review.draggableId} index={index}>
      {(provided) => (
        // 'provided' is a built-in ref of the beautiful-dnd library that keeps track of the element
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="bg-white rounded-lg p-6 w-full flex flex-col mt-3 mb-3 ml-6"
        >
          <div
            onClick={() => {
              const confirmation = prompt(
                "Are you sure you want to delete this review?",
                "yes"
              );
              if (confirmation) handleDelete();
            }}
            className="absolute transform -translate-y-5 -translate-x-5 rounded-full cursor-pointer"
          >
            <IoCloseCircle color="#E5E7EB" size={30} />
          </div>
          <UploadFiles
            id={review.draggableId}
            setThisPartnership={setThisPartnership}
            thisPartnership={partnershipState}
          />

          <div className="flex flex-col w-full">
            <label className={label}>
              Title
              <input
                className={borderedInputWhite}
                onChange={(e) =>
                  setThisPartnership({
                    ...partnershipState,
                    title: e.target.value,
                  })
                }
                value={partnershipState.title}
              />
            </label>
            <label className={label}>
              Subtitle
              <input
                className={borderedInputWhite}
                onChange={(e) =>
                  setThisPartnership({
                    ...partnershipState,
                    subtitle: e.target.value,
                  })
                }
                value={partnershipState.subtitle}
              />
            </label>
            <label className={label}>
              Link
              <input
                className={borderedInputWhite}
                onChange={(e) =>
                  setThisPartnership({
                    ...partnershipState,
                    link: e.target.value,
                  })
                }
                value={partnershipState.link}
              />
            </label>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Review;
