/* eslint-disable */
import { useAtom } from "jotai";
import React, { useState, useEffect } from "react";
import CustomSearchBar from "../../../../shared/CustomSearchBar";
import globalState from "../../../../store";
import { changeUsersToTeammates, getCustomerBySearchQuery } from "./utils";
import { debounce } from "lodash";
import SelectedCustomers from "./SelectedCustomers";

const AddCustomers = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [customerSearchResults, setCustomerSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const debounceCustomerSearch = debounce(searchCustomers, 500);

  useEffect(() => {
    if (globalStateAtom) {
      const { customerInfo = [] } = globalStateAtom;
      setCustomers(customerInfo);
    }
  }, [globalStateAtom]);

  const handleOnChange = async (e: any) => {
    const { value } = e.target;
    if (value) {
      debounceCustomerSearch(value);
    } else {
      debounceCustomerSearch.cancel();
      setCustomerSearchResults([]);
      setIsSearching(false);
    }
  };

  const addAllSelectedCustomersToExistingCustomers = () => {
    const { customerInfo = [] } = globalStateAtom;
    const newCustomerInfo = [...customerInfo, ...selectedCustomer];
    setGlobalState({ ...globalStateAtom, customerInfo: newCustomerInfo });
    setSelectedCustomer([]);
  };
  const cancelAllSelectedCustomers = () => {
    setSelectedCustomer([]);
  };

  async function searchCustomers(value: string) {
    setIsSearching(true);
    const searchResult = await getCustomerBySearchQuery(value);
    setCustomerSearchResults(searchResult);
    setIsSearching(false);
  }

  async function makeCustomersTeammatesOfCurrentPage() {
    const customerIds = selectedCustomer.map((cust) => cust.id);
    const primaryOwner = customers.find(
      (cust) => cust.user_type === "Primary Owner"
    );
    const success = await changeUsersToTeammates(customerIds, primaryOwner.id);
    if (success) addAllSelectedCustomersToExistingCustomers();
    else alert("Error changing customer to teammate of this page");
  }

  return (
    <div>
      <div className="flex justify-center space-x-4 space-y-4">
        <CustomSearchBar
          results={customerSearchResults}
          value={selectedCustomer?.name}
          renderHeaders={() => (
            <div className="flex justify-between font-extrabold ">
              <p>Id</p>
              <p>Name</p>
              <p>Phone</p>
            </div>
          )}
          renderItem={(item: any) => (
            <div className="flex justify-between">
              <p>{item.id}</p>
              <p>{item.name}</p>
              <p>{item.phone}</p>
            </div>
          )}
          onChange={handleOnChange}
          onSelect={(item: any) =>
            setSelectedCustomer([...selectedCustomer, item])
          }
        />

        {selectedCustomer && selectedCustomer.length > 0 && (
          <div className="flex items-center justify-center items-center">
            <button
              onClick={makeCustomersTeammatesOfCurrentPage}
              className={`p-2 w-36 rounded-full border-2 border-gray-500 bg-white text-sm text-gray-500 cursor-pointer`}
            >
              <p>Add Selected Customers as Teammates</p>
            </button>
            <button
              onClick={addAllSelectedCustomersToExistingCustomers}
              className={`p-2 w-36 rounded-full border-2 border-gray-500 bg-white text-sm text-gray-500 cursor-pointer`}
            >
              <p>Add</p>
            </button>
            <button
              onClick={cancelAllSelectedCustomers}
              className={`p-2 w-36 rounded-full border-2 border-gray-500 bg-white text-sm text-gray-500 cursor-pointer`}
            >
              <p>Cancel</p>
            </button>
          </div>
        )}
      </div>
      {isSearching && (
        <img src="/loading.gif" width="20px" height="20px" alt="loading" />
      )}
      <SelectedCustomers
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
      />
    </div>
  );
};

export default AddCustomers;
