/* eslint-disable */
import {
  borderedInputWhite,
  boxContainerFull,
  label,
  labelWrap,
} from "../../../shared/styles";
import Props from "../../../store/props";

const ColorScheme: React.FC<Props> = ({ setGlobalState, globalState }) => {
  const {
    about_header,
    socials_header,
    service_header,
    service_subtitle,
    testimonial_header,
    testimonial_subtitle,
    three_step_header,
    three_step_subtitle,
    work_header,
    work_subtitle,
    product_header,
    payment_header,
    payment_subtitle,
    faq_header,
    find_header,
    jobs_header,
    jobs_subtitle,
    cta_header,
    cta_subtitle,
    final_cta_header,
    final_cta_subtitle,
    partners_header,
    partners_subtitle,
    form_header,
    contact_chat_message,
  } = globalState;

  return (
    <>
      <div className={`${boxContainerFull} w-1/2 h-full`}>
        <h2 className="">Section Headers</h2>
        <label className={labelWrap}>
          About Us Title
          <input
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalState({
                ...globalState,
                about_header: e.target.value,
              })
            }
            value={about_header}
          />
        </label>
        <label className={labelWrap}>
          Socials Title
          <input
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalState({
                ...globalState,
                socials_header: e.target.value,
              })
            }
            value={socials_header}
          />
        </label>
        <div className="flex justify-between">
          <label className={labelWrap}>
            Services Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  service_header: e.target.value,
                })
              }
              value={service_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            Services Subtitle
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  service_subtitle: e.target.value,
                })
              }
              value={service_subtitle}
            />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={labelWrap}>
            3-Step Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  three_step_header: e.target.value,
                })
              }
              value={three_step_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            3-Step Subtitle
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  three_step_subtitle: e.target.value,
                })
              }
              value={three_step_subtitle}
            />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={labelWrap}>
            Photos Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  work_header: e.target.value,
                })
              }
              value={work_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            Photos Subtitle
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  work_subtitle: e.target.value,
                })
              }
              value={work_subtitle}
            />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={labelWrap}>
            Partners Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  partners_header: e.target.value,
                })
              }
              value={partners_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            Partners Subtitle
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  partners_subtitle: e.target.value,
                })
              }
              value={partners_subtitle}
            />
          </label>
        </div>
        <label className={labelWrap}>
          Products Title
          <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  product_header: e.target.value,
                })
              }
              value={product_header}
            />
        </label>
        <div className="flex justify-between">
          <label className={labelWrap}>
            Reviews Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  testimonial_header: e.target.value,
                })
              }
              value={testimonial_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            Reviews Subtitle
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  testimonial_subtitle: e.target.value,
                })
              }
              value={testimonial_subtitle}
            />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={labelWrap}>
            Payment Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  payment_header: e.target.value,
                })
              }
              value={payment_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            Payment Subtitle
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  payment_subtitle: e.target.value,
                })
              }
              value={payment_subtitle}
            />
          </label>
        </div>
        <label className={labelWrap}>
          FAQs Title
          <input
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalState({
                ...globalState,
                faq_header: e.target.value,
              })
            }
            value={faq_header}
          />
        </label>
        <label className={labelWrap}>
          Service Areas Title
          <input
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalState({
                ...globalState,
                find_header: e.target.value,
              })
            }
            value={find_header}
          />
        </label>
        <div className="flex justify-between">
          <label className={labelWrap}>
            Jobs Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  jobs_header: e.target.value,
                })
              }
              value={jobs_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            Jobs Subtitle
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  jobs_subtitle: e.target.value,
                })
              }
              value={jobs_subtitle}
            />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={labelWrap}>
            CTA Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  cta_header: e.target.value,
                })
              }
              value={cta_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            CTA Subtitle
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  cta_subtitle: e.target.value,
                })
              }
              value={cta_subtitle}
            />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={labelWrap}>
            Final CTA Title
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  final_cta_header: e.target.value,
                })
              }
              value={final_cta_header}
            />
          </label>
          <label className={`${label} -ml-3 pl-6 w-7/12`}>
            Final CTA Subtitle
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  final_cta_subtitle: e.target.value,
                })
              }
              value={final_cta_subtitle}
            />
          </label>
        </div>
        <label className={labelWrap}>
          Lead Form Title
          <input
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalState({
                ...globalState,
                form_header: e.target.value,
              })
            }
            value={form_header}
          />
        </label>
        <label className={labelWrap}>
          Contact Chat Pop-up Message
          <textarea
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalState({
                ...globalState,
                contact_chat_message: e.target.value,
              })
            }
            value={contact_chat_message}
          />
        </label>
      </div>
    </>
  );
};

export default ColorScheme;
