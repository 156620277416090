/* eslint-disable */
import { useAtom } from "jotai";
import { useState } from "react";
import { borderedInputWhite, label } from "../../../shared/styles";
import globalState from "../../../store";
import Props from "../../../store/props";

interface CtaProps {
  index: number;
}

const Payment: React.FC<CtaProps> = ({ index }) => {
  
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { payments } = globalStateAtom;

  const [slug, setSlug] = useState(
    payments[index] &&
      payments[index].link &&
      payments[index].link.split(".com/").length > 1
      ? payments[index].link.split(".com/")[1]
      : ""
  );

  const updatePaymentsLink = (val: any, index: number) => {
    const newPayments = [...payments];
    newPayments[index] = {
      ...newPayments[index],
      link: val,
    };
    setGlobalState({
      ...globalStateAtom,
      payments: newPayments,
    });
  };

  const updatePaymentsTitle = (val: any, index: number) => {
    const newLink =
      val === "Venmo"
        ? "www.venmo.com"
        : val === "PayPal"
        ? "www.paypal.com"
        : val === "Zelle"
        ? "www.zelle.com"
        : "";
    let newPayments = [...payments];
    if (!newLink) {
      newPayments = newPayments.filter((_, elementIndex) => elementIndex !== index)
    } else {
      newPayments[index] = {
        title: val,
        link: `${newLink}/${slug}`,
      };
    }
    if (newLink === "") setSlug("");
    setGlobalState({
      ...globalStateAtom,
      payments: newPayments,
    });
  };

  return (
    <div className="flex justify-between w-full items-center">
      <label className={`${label} w-full`}>
        Payment Link
        <input
          value={payments[index] ? payments[index].link : ""}
          onChange={(e) => {
            updatePaymentsLink(e.target.value, index);
            // this maintains the value after the '.com/'
            const splittable =
              payments[index].link.split(".com/").length > 1 && true;
            setSlug(splittable ? e.target.value.split(".com/")[1] : "");
          }}
          className={`${borderedInputWhite} ${!payments[index] ? 'bg-gray-100 opacity-25}' : ''}`}
          disabled={!payments[index]}
          placeholder={!payments[index] ? 'Choose payment method ->' : ''}
        />
      </label>
      <label className={`${label} w-full ml-5`}>
        Display Text
        <select
          onChange={(e) => {
            updatePaymentsTitle(e.target.value, index);
          }}
          value={payments[index] ? payments[index].title : "None"}
          className={"p-2 mt-3 w-full rounded-md border-2" + ((index !== 0 && !payments[index-1]) ? ' bg-gray-100 opacity-25}' : '')}
          disabled={(index !== 0 && !payments[index-1])}
        >
          <option value="None">None</option>
          <option value="Venmo">Venmo</option>
          <option value="PayPal">PayPal</option>
          <option value="Zelle">Zelle</option>
        </select>
      </label>
    </div>
  );
};

export default Payment;
