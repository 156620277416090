import axios from "axios";

export async function checkIfDuplicateSlug(slug) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/check-if-slug-duplicate`,
      {
        slug,
      }
    );
    return response?.data?.message;
  } catch (error) {
    return "error";
  }
}

export async function checkIfTypos(text: string) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/spellcheck/`,
      {
        text,
      }
    );
    return response?.data?.misspelled_words || [];
  } catch (error) {
    return "error";
  }
}

export function hasArrayChanged(arr1, arr2) {
  if (arr1.length !== arr2.length) return true;
  return !arr1.every((element, index) => JSON.stringify(element) === JSON.stringify(arr2[index]));
}


export function galleryPhotosUpdated(originalLandingPageData, globalStateAtom) {
  const originalGalleryPhotos = originalLandingPageData?.gallery || [];
  const galleryPhotosHaveChanged = hasArrayChanged(
    originalGalleryPhotos,
    globalStateAtom?.gallery
  );

  return galleryPhotosHaveChanged;
}

export function heroPhotosUpdated(originalLandingPageData, globalStateAtom) {
  const originalHeroPhotos = originalLandingPageData?.hero_gallery_photos || [];
  const heroPhotosHaveChanged = hasArrayChanged(
    originalHeroPhotos,
    globalStateAtom?.hero_gallery_photos
  );

  return heroPhotosHaveChanged;
}
