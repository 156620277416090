/* eslint-disable */
import { useAtom } from "jotai";
import { useState } from "react";
import { pageContainerWithOverflow } from "../../shared/styles";
import globalState from "../../store";
import { getExternalPhotoUrl } from "../../utils/helpers/getExternalPhotoUrl";
import {
  pushImageToS3,
  uploadExternalImageUrl,
} from "../../utils/helpers/uploadFileUtil";
import {
  checkApplePatentImageExtension,
  getFileExtensionFromType,
} from "../logo/components/utils";
import { getJpegFileFromHeic } from "../photos/components/utils";
import UploadImages from "../services/components/upload/UploadImages";
import { IoTrashOutline } from "react-icons/io5";

export default function CustomPage() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const inputClassName = "border border-gray-300 rounded-lg px-2 py-1 mb-4";

  const [loading, setLoading] = useState(false);

  function handleChange(e: any, value: string) {
    setGlobalState({
      ...globalStateAtom,
      custom_page: {
        ...globalStateAtom.custom_page,
        [value]: e.target.value,
      },
    });
  }

  function toggleCustomPage() {
    let templateVersion = globalStateAtom.custom_layout["custom-page"].version;

    if (
      !globalStateAtom.custom_page.custom_page &&
      templateVersion === "none"
    ) {
      templateVersion = "v2.1";
    }

    setGlobalState({
      ...globalStateAtom,
      custom_page: {
        ...globalStateAtom.custom_page,
        custom_page: !globalStateAtom.custom_page.custom_page,
      },
      custom_layout: {
        ...globalStateAtom.custom_layout,
        "custom-page": {
          ...globalStateAtom.custom_layout["custom-page"],
          version: templateVersion,
        },
      },
    });
  }

  const onChangeHandler = async (files: any) => {
    setLoading(true);
    let file = (files && files[0]) || null;
    if (/image\//.test(file?.type) === true) {
      const fileExtension = getFileExtensionFromType(file.type);
      if (checkApplePatentImageExtension(fileExtension)) {
        file = await getJpegFileFromHeic(file);
      }
    }
    // // console.log(file, 'file here')
    if (file) {
      const [uploadedImageUrl, uploadedImageFileName] = await pushImageToS3(
        file
      );
      // // console.log(uploadedImageUrl, 'uploadedImageUrl')
      if (uploadedImageUrl) {
        setLoading(false);
      }
      console.log("uploadedImageUrl:", uploadedImageUrl);

      setGlobalState({
        ...globalStateAtom,
        custom_page: {
          ...globalStateAtom.custom_page,
          custom_page_photo: uploadedImageUrl,
        },
      });
    }
  };

  const onDropHandler = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      onChangeHandler(files);
    } else {
      // const imageUrl = event.dataTransfer.getData('text/html')
      // const rex = /src="?([^"\s]+)"?\s*/
      // let url = rex.exec(imageUrl)[1]
      // url = url.replace(/;/g, '&')
      const url = await getExternalPhotoUrl(event.dataTransfer.getData("url"));

      if (url) {
        const uploadedLogoDataFileName = await uploadExternalImageUrl(url);
        const uploadedLogoDataUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${uploadedLogoDataFileName}`;
      }
    }
    setLoading(false);
  };

  const handleDeleteImage = () => {
    setGlobalState({
      ...globalStateAtom,
      custom_page: {
        ...globalStateAtom.custom_page,
        custom_page_photo: "",
      },
    });
  };

  return (
    <div className={`${pageContainerWithOverflow} flex w-full p-8`}>
      <div className="flex flex-col justify-start align-start bg-white w-1/2 h-1/2 rounded-lg p-8">
        <h1 className="text-left text-lg font-bold mb-4">Custom Page</h1>

        <div className="flex flex-row items-center">
          <label className="text-left my-2 mr-4">Show custom page?</label>
          <input
            className="mr-1"
            type="radio"
            onChange={toggleCustomPage}
            checked={globalStateAtom.custom_page?.custom_page}
          />
          <div className="mr-2">Yes</div>
          <input
            className="mr-1"
            type="radio"
            onChange={toggleCustomPage}
            checked={!globalStateAtom.custom_page?.custom_page}
          />
          <div className="mr-2">No</div>
        </div>

        <label className="text-left my-2">Tab Name</label>
        <input
          className={inputClassName}
          onChange={(e) => handleChange(e, "custom_page_tab_name")}
          value={globalStateAtom.custom_page?.custom_page_tab_name}
        />
        <label className="text-left my-2">Header</label>
        <input
          className={inputClassName}
          onChange={(e) => handleChange(e, "custom_page_header")}
          value={globalStateAtom.custom_page?.custom_page_header}
        />
        <label className="text-left my-2">Subheader</label>
        <input
          className={inputClassName}
          onChange={(e) => handleChange(e, "custom_page_subheader")}
          value={globalStateAtom.custom_page?.custom_page_subheader}
        />

        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex flex-col">
            <label className="text-left my-2">CTA</label>
            <input
              className={inputClassName}
              onChange={(e) => handleChange(e, "custom_page_cta")}
              value={globalStateAtom.custom_page?.custom_page_cta}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-left my-2">CTA Link</label>
            <input
              className={inputClassName}
              onChange={(e) => handleChange(e, "custom_page_cta_link")}
              value={globalStateAtom.custom_page?.custom_page_cta_link}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-left my-2">CTA 2</label>
            <input
              className={inputClassName}
              onChange={(e) => handleChange(e, "custom_page_cta_secondary")}
              value={globalStateAtom.custom_page?.custom_page_cta_secondary}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-left my-2">CTA 2 Link</label>
            <input
              className={inputClassName}
              onChange={(e) =>
                handleChange(e, "custom_page_cta_secondary_link")
              }
              value={
                globalStateAtom.custom_page?.custom_page_cta_secondary_link
              }
            />
          </div>
        </div>

        <label className="text-left my-2">Body</label>
        <textarea
          className={inputClassName}
          onChange={(e) => handleChange(e, "custom_page_body")}
          value={globalStateAtom.custom_page?.custom_page_body}
          rows={8}
        />
        <div className="flex justify-between my-2">
          <label className="text-left">Photo</label>
          <button onClick={handleDeleteImage} className="cursor-pointer">
            <IoTrashOutline size={20} />
          </button>
        </div>
        <UploadImages
          onChangeHandler={onChangeHandler}
          onDropHandler={onDropHandler}
        />
        {globalStateAtom.custom_page?.custom_page_photo && (
          <div className="m-auto my-8 w-1/2 h-1/2">
            <img src={globalStateAtom.custom_page?.custom_page_photo} />
          </div>
        )}
      </div>
    </div>
  );
}
