/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from "react";
import { boxContainerFull } from "../../../shared/styles";
import { getSignedRequest } from "../../../utils/helpers/uploadFileUtil";
import Thumbnail from "../../../shared/Thumbnail";
import { useAtom } from "jotai";
import globalState from "../../../store";
import UploadImages from "../../services/components/upload/UploadImages";

interface PhotoProps {
  alt_tag: string | null;
  archived: boolean;
  associated_service: string | null;
  gallery: string | null;
  photo: string;
  landing_page: number;
  order: number;
  uncropped_gallery_photo: string | null;
  gallery_name: string | null;
  selected: boolean;
}

const Awards: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const images = globalStateAtom.awards
  const [hero_image, setHeroImage] = useState(globalStateAtom.cover_upload);
  // uses local state to make sure photos dis/appear live
  const [loading, setLoading] = useState(false);

  const onChangeHandler = async (files: any) => {
    setLoading(true);
    let completePhotoUrl = hero_image;
    const photos = [...images];
    for (let i = 0; i < files!.length; i++) {
      const curFile = files![i];
      const uploadedImageUrl = URL.createObjectURL(
        files![i]
      );
      if (uploadedImageUrl) {
        const signedS3Photo = await getSignedRequest(curFile);
        completePhotoUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${signedS3Photo}`;
        // setGlobalState({...globalState, cover_upload:completePhotoUrl, hero_image:completePhotoUrl})
        photos.push({ photo: completePhotoUrl });
      }
    }
    setGlobalState({
      ...globalStateAtom,
      awards: photos,
    });
    setLoading(false);
  }

  const onDropHandler = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      await onChangeHandler(files);
    }
    setLoading(false)
  }

  const handleDelete = (photo: string) => {
    const newImagesArray = images.filter((img) => img.photo !== photo);
    setGlobalState({ ...globalStateAtom, awards: newImagesArray });
  };

  return (
    <div className="w-full flex flex-col -ml-6 max-w-2xl">
      <h2 className="self-end -mr-6 -mb-6">Awards</h2>
      <div className={boxContainerFull}>
        <div className="h-32 w-full">
          <UploadImages
            onChangeHandler={onChangeHandler}
            onDropHandler={onDropHandler}
            multiple={true}
          />
        </div>
        <div className="flex flex-col items-center">
          {loading && <img src="/loading.gif" width="50px" />}
          <div className="flex flex-grow w-full h-64 flex-wrap overflow-y-scroll overflow-x-hidden">
            {images.length &&
              images.map((photo: any) => {
                return (
                  <div
                    key={photo.photo}
                    className="w-1/3 "
                  >
                    <Thumbnail
                      photo={photo}
                      handleDelete={handleDelete}
                      handleClick={() => null}
                      draggable={false}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;
