/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useRef } from "react";
import { boxContainerFull } from "../../../shared/styles";
import {
  getSignedRequest,
  pushImageToS3,
  uploadExternalImageUrl,
} from "../../../utils/helpers/uploadFileUtil";
import Thumbnail from "../../../shared/Thumbnail";

import UploadImages from "./upload/UploadImages";
import { useAtom } from "jotai";
import globalState from "../../../store";
import { addPhotoToService } from "../utils";
import { getExternalPhotoUrl } from "../../../utils/helpers/getExternalPhotoUrl";
import { getJpegFileFromHeic } from "../../photos/components/utils";
import {
  checkApplePatentImageExtension,
  getFileExtensionFromType,
} from "../../logo/components/utils";
import { ListManager } from "react-beautiful-dnd-grid";

interface ServiceImagesProps {
  thisService: any;
  activeService: any;
  setActiveService: (service: any) => void;
  reorderServiceImage: any;
}

const ServiceImages: React.FC<ServiceImagesProps> = ({
  thisService,
  activeService,
  setActiveService,
  reorderServiceImage,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [loading, setLoading] = useState(false);
  const [stockPhotosBool, setStockPhotosBool] = useState<boolean | null>(null);

  async function onDropHandler(event: any) {
    setLoading(true);
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      await onChangeHandler(files);
    } else {
      const url = await getExternalPhotoUrl(event.dataTransfer.getData("url"));
      if (url) {
        const uploadedLogoDataFileName = await uploadExternalImageUrl(url);
        if (uploadedLogoDataFileName) {
          const uploadedLogoDataUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${uploadedLogoDataFileName}`;
          const newServices = addPhotoToService(
            uploadedLogoDataUrl,
            thisService,
            globalStateAtom.services
          );
          setGlobalState({
            ...globalStateAtom,
            services: newServices!,
          });
          // thisService updates automatically on globalState update
          setActiveService({...thisService})
        }
      }
    }
    setLoading(false);
  }

  const onChangeHandler = async (files: any) => {
    setLoading(true);
    for (let i = 0; i < files!.length; i++) {
      let curFile = files![i];
      if (/image\//.test(curFile?.type) === true) {
        const fileExtension = getFileExtensionFromType(curFile.type);
        if (checkApplePatentImageExtension(fileExtension)) {
          curFile = await getJpegFileFromHeic(curFile);
        }
      }
      const [pushedImageUrl, pushedImageName] = await pushImageToS3(curFile);
      if (pushedImageUrl) {
        const newServices = addPhotoToService(
          pushedImageUrl,
          thisService,
          globalStateAtom.services
        );
        setGlobalState({
          ...globalStateAtom,
          services: newServices!,
        });
        // thisService updates automatically on globalState update
        setActiveService({...thisService})
      }
    }
    setLoading(false);
  };

  // this checks for stock photos to auto-set the 'Stock photos' checkbox based on if there are stock photos
  useEffect(() => {
    if (stockPhotosBool) {
      // add the stock photos back for this service
      const stockPhotos = [1, 2, 3].map((num) => ({
        photo:
          `https://landing-page-app-hero-images.s3.amazonaws.com/media/${thisService[
            "service-name"
          ]
            .replace("&", "")
            .replace("%26", "")
            .toLocaleLowerCase()
            .split(" ")
            .join("-")}-${num}.jpeg`.replace("--", "-"),
        order: thisService.photos.length + num - 1,
        stock: true,
      }));
      let stockPhotosAlreadyThere = false;
      if (thisService?.photos && thisService?.photos.length) {
        for (let i = 0; i < thisService?.photos.length; i++) {
          if (thisService?.photos[i].stock) {
            stockPhotosAlreadyThere = true;
          }
        }
      }
      const newServicePhotos = !stockPhotosAlreadyThere
        ? [...thisService?.photos, ...stockPhotos]
        : thisService?.photos.length
        ? thisService?.photos
        : stockPhotos;
      const newServices = [...globalStateAtom.services];
      const posOfThisService = newServices.findIndex((s) => s === thisService);
      newServices[posOfThisService] = {
        ...thisService,
        photos: newServicePhotos,
      };
      setGlobalState({ ...globalStateAtom, services: newServices });
      // thisService updates automatically on globalState update
      setActiveService({...thisService});
    } else if (stockPhotosBool === false) {
      // remove stock photos
      const newPhotosArray = [];
      if (thisService && thisService?.photos) {
        for (let i = 0; i < thisService?.photos.length; i++) {
          if (!thisService?.photos[i].stock) {
            newPhotosArray.push(thisService?.photos[i]);
          }
        }
        const newServices = [...globalStateAtom.services];
        const posOfThisService = newServices.findIndex(
          (s) => s === thisService
        );
        newServices[posOfThisService] = {
          ...thisService,
          photos: newPhotosArray,
        };
        setGlobalState({
          ...globalStateAtom,
          services: newServices,
        });
        // thisService updates automatically on globalState update
        setActiveService({...thisService})
      }
    }
  }, [stockPhotosBool]);

  const handleDelete = (photo: string) => {
    const newServices = globalStateAtom.services.map(serv => {
      if (serv["service-name"] === thisService["service-name"]) {
        serv.photos = serv.photos.filter(img => img.photo !== photo)
      }
      return serv
    })
    setGlobalState({ ...globalStateAtom, services: newServices });
    // thisService updates automatically on globalState update
    setActiveService({...thisService})
  };

  const deleteServicePhotos = () => {
    if (thisService?.photos) {
      const newServices = [...globalStateAtom.services];
      const posOfThisService = newServices.findIndex((s) => s === thisService);
      newServices[posOfThisService] = { ...thisService, photos: [] };
      setGlobalState({ ...globalStateAtom, services: newServices });
      // thisService updates automatically on globalState update
      setActiveService({...thisService})
    }
  };
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event: any) => {
    // @ts-ignore
    // hiddenFileInput.current.click()
  };

  return (
    <div className={`${boxContainerFull} mt-3`}>
      {loading && <img src="/loading.gif" width="20" alt="loading" />}
      <div className="flex flex-col items-center">
        {/* <FileUploader
                    name="file"
                    id="upload"
                    type="file"
                    types={fileTypes}
                    multiple
                    classes="w-full"
                    children={
                        <div
                            ref={hiddenFileInput}
                            className="w-full border-gray-200 border-2 border-dashed rounded-lg h-24 flex items-center justify-center cursor-pointer"
                        >
                            <p className="text-gray-500">
                                Upload Photos via files
                            </p>
                        </div>
                    }
                    handleChange={onChangeHandler}
                /> */}
        <div className="h-32 w-full">
          <UploadImages
            onChangeHandler={onChangeHandler}
            onDropHandler={onDropHandler}
            activeService={activeService}
            multiple={true}
          />
        </div>
        <div className="max-h-96 overflow-y-scroll">
          <ListManager
            items={thisService?.photos || []}
            direction="horizontal"
            maxItems={3}
            render={(photo) => {
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={photo.photo}
                  className="w-36 flex-shrink-0 mt-3 mr-6"
                >
                  <Thumbnail
                    photo={photo}
                    handleDelete={handleDelete}
                    handleClick={handleClick}
                    draggable={false}
                  />
                </div>
              );
            }}
            onDragEnd={reorderServiceImage}
          />
        </div>
        {/* <div className="flex flex-grow w-full no-wrap overflow-x-scroll">
                    {thisService &&
                        thisService?.photos &&
                        thisService?.photos.length > 0 &&
                        thisService?.photos.map((photo: any, index: number) => {
                            return (
                                <div
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${index}`}
                                    className="w-24 h-28 flex-shrink-0 mt-3 mr-6"
                                >
                                    <Thumbnail
                                        photo={photo}
                                        handleDelete={handleDelete}
                                        handleClick={handleClick}
                                        draggable={false}
                                    />
                                </div>
                            )
                        })}
                </div> */}
        <label className="flex justify-center items-center">
          Stock photos
          <input
            type="checkbox"
            className="ml-3"
            onChange={(e) => setStockPhotosBool(!stockPhotosBool)}
            checked={!!stockPhotosBool}
          />
        </label>
        <label className="flex justify-center items-center mt-6">
          {/* Delete service photos */}
          <button
            // type="checkbox"
            className="ml-3"
            onClick={deleteServicePhotos}
            // checked={stockPhotosBool}
          >
            Delete service photos
          </button>
        </label>
      </div>
    </div>
  );
};

export default ServiceImages;