import { useAtom } from "jotai";
import AnnouncementIcon from "./AnnouncementIcon";
import globalState from "../../../../store";
import SwitchToggle from "../../../../shared/SwitchToggle";

const Announcement: React.FC = () => {
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);

  const { custom_primary, custom_secondary } = globalStateAtom;

  const {
    show_announcement,
    background_color,
    text_color,
    icon_color,
    description,
  } = globalStateAtom?.announcement;

  const handleSwitch = () => {
    setGlobalStateAtom({
      ...globalStateAtom,
      announcement: {
        ...globalStateAtom?.announcement,
        show_announcement: !show_announcement,
      },
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGlobalStateAtom({
      ...globalStateAtom,
      announcement: {
        ...globalStateAtom?.announcement,
        description: e.target.value,
      },
    });
  };

  const handleBackgroundColorChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGlobalStateAtom({
      ...globalStateAtom,
      announcement: {
        ...globalStateAtom?.announcement,
        background_color: e.target.value,
      },
    });
  };

  const handleTextColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalStateAtom({
      ...globalStateAtom,
      announcement: {
        ...globalStateAtom?.announcement,
        text_color: e.target.value,
      },
    });
  };

  const handleIconColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalStateAtom({
      ...globalStateAtom,
      announcement: {
        ...globalStateAtom?.announcement,
        icon_color: e.target.value,
      },
    });
  };

  return (
    <div className="p-4 bg-gray-100 rounded-md">
      <div className="space-x-2 flex justify-center mb-4">
        <SwitchToggle
          checked={show_announcement}
          onChange={handleSwitch}
          label="Announcement"
        />
      </div>
      {show_announcement && (
        <>
          <div className="flex justify-around space-x-8">
            <div className="text-start">
              <AnnouncementIcon />
            </div>
            <textarea
              rows={2}
              className="border border-gray-300 rounded flex-1 p-2"
              onChange={handleChange}
              value={description ? description : ""}
            />
          </div>
          <div className="flex py-2 gap-2 w-full">
            <div
              style={{ backgroundColor: custom_primary }}
              className="p-2 px-4 rounded-lg text-white bg-blue-400 w-full"
            >
              Primary
            </div>
            <div
              style={{ backgroundColor: custom_secondary }}
              className="p-2 px-4 rounded-lg text-white bg-blue-400 w-full"
            >
              Secondary
            </div>
          </div>
          <div className="flex gap-4 justify-between rounded-md pt-4">
            <label className="flex flex-col items-start bg-gray-100 p-2 rounded-lg w-full gap-2">
              Background
              <input
                type="color"
                onChange={handleBackgroundColorChange}
                value={background_color ? background_color : ""}
                className="w-full p-2 rounded-md"
              />
              <div
                style={{ backgroundColor: background_color }}
                className="w-full h-8 rounded-lg"
              />
            </label>
            <label className="flex flex-col items-start bg-gray-100 p-2 rounded-lg w-full gap-2">
              Text
              <input
                type="color"
                onChange={handleTextColorChange}
                value={text_color ? text_color : ""}
                className="w-full p-2 rounded-md"
              />
              <div
                style={{ backgroundColor: text_color }}
                className="w-full h-8 rounded-lg"
              />
            </label>
            <label className="flex flex-col items-start bg-gray-100 p-2 rounded-lg w-full gap-2">
              Icon
              <input
                type="color"
                onChange={handleIconColorChange}
                value={icon_color ? icon_color : ""}
                className="w-full p-2 rounded-md"
              />
              <div
                style={{ backgroundColor: icon_color }}
                className="w-full h-8 rounded-lg"
              />
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default Announcement;
