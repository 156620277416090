/* eslint-disable */
import { useAtom } from "jotai";
import { useState } from "react";
import MultiSelectModal from "../../shared/MultiSelectModal/MultiSelectModal";
import { pageContainerWithOverflow } from "../../shared/styles";
import globalState from "../../store";
import { get } from "http";

export default function AdsLandingPage() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [isBestWorkPhotosModalOpen, setIsBestWorkPhotosModalOpen] =
    useState(false);
  const [isTestimonialsModalOpen, setIsTestimonialsModalOpen] = useState(false);
  const inputClassName = "border border-gray-300 rounded-lg px-2 py-1 mb-4";

  const handleAdsLandingPageChange = (ads_landing_page_id, e, value) => {
    setGlobalState({
      ...globalStateAtom,
      ads_landing_pages: globalStateAtom.ads_landing_pages.map(
        (ads_landing_page) => {
          if (ads_landing_page.id === ads_landing_page_id) {
            return {
              ...ads_landing_page,
              [value]: e,
            };
          }
          return ads_landing_page;
        }
      ),
    });
  };

  const getServiceName = (service_id) => {
    const service = globalStateAtom.services.find(
      (service) => service["service-name"] === service_id
    );
    return service["service-name"];
  };

  const handleServiceSelect = (ads_landing_page, serviceName) => {
    const service = globalStateAtom.services.find(
      (service) => service["service-name"] === serviceName
    );

    if (service["service-name"]) {
      const ads_landing_page_id = ads_landing_page.id;
      const testimonials = globalStateAtom.testimonials
        .filter(
          (testimonial) => testimonial.service === service["service-name"]
        )
        .map((testimonial) => testimonial.id)
        .slice(0, 5);

      const selectedTestimonials = testimonials.length
        ? testimonials
        : ads_landing_page.testimonials_ids;
      setGlobalState({
        ...globalStateAtom,
        ads_landing_pages: globalStateAtom.ads_landing_pages.map(
          (ads_landing_page) => {
            if (ads_landing_page.id === ads_landing_page_id) {
              return {
                ...ads_landing_page,
                selected_service: service["service-name"],
                best_work_photos: service.photos,
                testimonials_ids: selectedTestimonials,
              };
            }
            return ads_landing_page;
          }
        ),
      });
    }
  };

  return (
    <div className={`${pageContainerWithOverflow} flex w-full p-8`}>
      <div className="flex flex-col justify-start align-start bg-white w-full  h-1/2 rounded-lg p-8">
        <h1 className="text-left text-lg font-bold mb-4">ads Page</h1>
        {globalStateAtom.ads_landing_pages.length &&
          globalStateAtom.ads_landing_pages.map(
            (ads_landing_page: any, index: number) => {
              return (
                <>
                  <div className="flex flex-col w-full mb-4">
                    {/* select option from globalStateAtom.services */}
                    <div className="flex flex-col w-full mb-4">
                      <label
                        htmlFor="services"
                        className="text-left text-sm mb-2"
                      >
                        Select a service
                      </label>
                      {ads_landing_page.selected_service}
                      <select
                        id="services"
                        className={inputClassName}
                        value={
                          ads_landing_page.selected_service
                            ? ads_landing_page.selected_service
                            : ""
                        }
                        onChange={(e) => {
                          handleServiceSelect(ads_landing_page, e.target.value);
                        }}
                      >
                        {!ads_landing_page?.selected_service && (
                          <option>---</option>
                        )}
                        {globalStateAtom.services.map((service: any) => (
                          <option key={service.id} value={service.id}>
                            {service["service-name"]}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* Text area with about us sections */}
                    <label
                      htmlFor="about-us"
                      className="text-left text-sm mb-2"
                    >
                      About Us
                    </label>
                    <textarea
                      id="about-us"
                      className={inputClassName}
                      value={ads_landing_page.about_us}
                      onChange={(e) =>
                        handleAdsLandingPageChange(
                          ads_landing_page.id,
                          e.target.value,
                          "about_us"
                        )
                      }
                    />
                  </div>
                  {/* selected best work photos */}
                  <div className="flex flex-col w-full mb-4">
                    <div className="flex justify-between">
                      <label
                        htmlFor="best-work"
                        className="text-left text-sm mb-2"
                      >
                        Best Work Photos
                      </label>
                      <button
                        onClick={() => {
                          setIsBestWorkPhotosModalOpen(true);
                        }}
                      >
                        edit
                      </button>
                    </div>
                    <div className="flex flex-wrap -mx-1 lg:-mx-4">
                      {ads_landing_page.best_work_photos &&
                        ads_landing_page?.best_work_photos?.map(
                          (photo: any, index: number) => {
                            return (
                              <div
                                className="my-1 px-1 w-1/2 md:w-1/3 lg:my-4 lg:px-4"
                                key={index}
                              >
                                {photo && (
                                  <img
                                    src={photo?.photo}
                                    alt={`Best Work ${index + 1}`}
                                    className="object-cover h-48 w-full"
                                  />
                                )}
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                  <div className="flex flex-col w-full mb-4">
                    <div className="flex justify-between">
                      <label
                        htmlFor="best-work"
                        className="text-left text-sm mb-2"
                      >
                        Testimonials
                      </label>
                      <button
                        onClick={() => {
                          setIsTestimonialsModalOpen(true);
                        }}
                      >
                        edit
                      </button>
                    </div>
                    <div className="flex flex-wrap -mx-1 lg:-mx-4 gap">
                      {ads_landing_page.testimonials_ids &&
                        ads_landing_page?.testimonials_ids?.map(
                          (testimonial: any, index: number) => {
                            const testimonial_obj =
                              globalStateAtom.testimonials.find(
                                (t: any) => t.id === testimonial
                              );
                            return (
                              <>
                                <div className="my-1 px-1 w-1/2 md:w-1/3 lg:my-4 lg:px-4 border">
                                  {testimonial_obj && (
                                    <label
                                      htmlFor="testimonials"
                                      className="text-left text-sm mb-2"
                                    >
                                      {testimonial_obj?.review}
                                    </label>
                                  )}
                                </div>
                              </>
                            );
                          }
                        )}
                    </div>
                  </div>
                  {isBestWorkPhotosModalOpen && (
                    <MultiSelectModal
                      items={globalStateAtom.gallery}
                      selectedIds={ads_landing_page.best_work_photos.map(
                        (photo) => photo.photo
                      )}
                      onSave={(selectedIds: any) => {
                        const bestWorkPhotos = selectedIds.map((id) => {
                          const knownPhoto =
                            ads_landing_page.best_work_photos.find(
                              (photo) => photo.photo === id
                            );
                          if (knownPhoto) {
                            return knownPhoto;
                          }
                          return {
                            photo: id,
                          };
                        });
                        handleAdsLandingPageChange(
                          ads_landing_page.id,
                          bestWorkPhotos,
                          "best_work_photos"
                        );
                        setIsBestWorkPhotosModalOpen(false);
                      }}
                      onClose={() => {
                        setIsBestWorkPhotosModalOpen(false);
                      }}
                      renderItem={(item: any) => (
                        <div className="flex flex-col">
                          <img
                            src={item.photo}
                            alt={item.photo}
                            className="object-cover h-48 w-full"
                          />
                        </div>
                      )}
                      idAttribute="photo"
                    />
                  )}
                  {isTestimonialsModalOpen && (
                    <MultiSelectModal
                      items={globalStateAtom.testimonials}
                      selectedIds={ads_landing_page.testimonials_ids}
                      onSave={(selectedIds: any) => {
                        handleAdsLandingPageChange(
                          ads_landing_page.id,
                          selectedIds,
                          "testimonials_ids"
                        );
                        setIsTestimonialsModalOpen(false);
                      }}
                      onClose={() => {
                        setIsTestimonialsModalOpen(false);
                      }}
                      renderItem={(item: any) => (
                        <div className="border">
                          <label htmlFor="testimonials" className="mb-2">
                            {item.review}
                          </label>
                        </div>
                      )}
                      idAttribute="id"
                    />
                  )}
                </>
              );
            }
          )}
      </div>
    </div>
  );
}
