/* eslint-disable */
import Location from "./Location";
import { boxContainer, button } from "../../../shared/styles";
import { useAtom } from "jotai";
import globalState from "../../../store";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import Address from "./Address";
import { getGeonames, getGeonamesByCoordinates } from "./utils";
import { useState } from "react";
import { ServiceAreaArray } from "../../../types/types";

const LocationsContainer: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [radius, setRadius] = useState(30);

  const { service_area_array, service_areas } = globalStateAtom;
  const isFindMoreEnabled =
    service_areas[0] && service_areas[0].city && service_areas[0].state;
  const handleAdd = () => {
    const newOrder = Math.max(...service_areas.map((o) => o.order), 0) + 1;

    const newArray: ServiceAreaArray[] = [
      ...service_area_array,
      ["", "", 50, newOrder],
    ];
    const newServiceAreas = [
      ...service_areas,
      {
        city: "",
        state: "",
        radius: 50,
        order: newOrder,
      },
    ];

    setGlobalState({
      ...globalStateAtom,
      service_areas: newServiceAreas,
      service_area_array: newArray,
    });
  };
  
  const reorder = <T,>(
    list: T[],
    startIndex: number,
    endIndex: number
  ): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reOrderedService_areas = reorder(
      service_areas,
      result.source.index,
      result.destination.index
    ).map((item, index) => {
      return { ...item, order: index + 1 };
    });

    const reOrderedService_area_array = reorder(
      service_area_array,
      result.source.index,
      result.destination.index
    ).map((item, index) => {
      item.splice(item.length - 1, 1, index + 1);
      return item;
    });
    setGlobalState({
      ...globalStateAtom,
      service_areas: reOrderedService_areas,
      service_area_array: reOrderedService_area_array,
    });
  }

  async function addCities() {
    if (service_areas?.length === 0) {
      return;
    }

    const numberOfCitiesToAdd = 10 - service_areas?.length;

    const firstServiceAreaCity = service_areas[0]?.city;
    const firstServiceAreaState = service_areas[0]?.state;

    const nearbyPlaces = await getGeonames(
      firstServiceAreaCity,
      firstServiceAreaState
    );

    const latitude = nearbyPlaces[0].lat;
    const longitude = nearbyPlaces[0].lng;

    const cities = await getGeonamesByCoordinates(latitude, longitude, radius);
    cities.sort((a, b) => b.population - a.population);

    const allCities = service_areas.map((serviceArea) => {
      return serviceArea?.city;
    });

    allCities.forEach((city) => {
      const index = cities.findIndex((c) => c.name === city);
      if (index > -1) {
        cities.splice(index, 1);
      }
    });

    let newCities = [...cities].splice(0, numberOfCitiesToAdd);

    if (cities && cities.length > 0) {
      const firstCity = cities[0]?.name;
      if (
        service_areas?.length > 0 &&
        service_areas[0]?.city?.toLowerCase() === firstCity.toLowerCase()
      ) {
        newCities = [...cities].splice(1, numberOfCitiesToAdd);
      }
    }

    const maxOrder =
      Math.max(...service_areas.map((serviceArea) => serviceArea?.order)) + 1;

    const serviceAreasArray: ServiceAreaArray[] = newCities.map(
      (city, index) => {
        return [city.name, city.adminCode1, 50, index + maxOrder];
      }
    );
    const serviceAreas = newCities.map((city, index) => {
      return {
        city: city.name,
        state: city.adminCode1,
        radius: 50,
        order: index + maxOrder,
      };
    });

    const newArray = [...service_area_array, ...serviceAreasArray];
    const newServiceAreas = [...service_areas, ...serviceAreas];

    setGlobalState({
      ...globalStateAtom,
      service_areas: newServiceAreas,
      service_area_array: newArray,
    });
  }

  function changeRadius(e: React.ChangeEvent<HTMLInputElement>) {
    const newRadius = Number(e.target.value);
    if (!isNaN(newRadius)) {
      setRadius(newRadius);
    }
  }

  return (
    <div className="flex flex-col w-full items-start">
      <div className="flex flex-col w-full items-start">
        <h2 className="pl-12 transform translate-y-6">Address</h2>
        <div className={`${boxContainer} w-9/12 mr-12`}>
          <Address />
        </div>
      </div>
      <div className="flex flex-col w-full items-start">
        <div className="flex flex-row items-center pl-12">
          <h2 className="flex mr-4">Location</h2>
          <div className="flex">
            <div
              title={
                isFindMoreEnabled
                  ? "Find 10 nearest cities"
                  : "Please fill in the first service area to enable this button"
              }
              className={`${
                isFindMoreEnabled
                  ? "bg-blue-800 cursor-pointer"
                  : "bg-blue-200  cursor-not-allowed"
              } text-white rounded-lg p-4`}
              onClick={isFindMoreEnabled ? addCities : () => {}}
            >
              Find 10 nearest cities 🥳
            </div>
          </div>
          <div className="text-gray-900 ml-4 mr-2">Radius:</div>
          <input
            className="h-full p-3 w-20 border border-gray-300 rounded-lg"
            type="text"
            placeholder="30"
            value={radius}
            onChange={changeRadius}
          />
        </div>
        <div className={`${boxContainer} w-9/12 mr-12 `}>
          {/* first location is locked up and is not draggable */}
          {globalStateAtom.service_area_array &&
            globalStateAtom.service_area_array.length > 0 && (
              <Location
                item={globalStateAtom.service_area_array[0]}
                index={0}
              />
            )}
          {/* draggable location starts with the second location */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {globalStateAtom.service_area_array &&
                    globalStateAtom.service_area_array.length > 1 &&
                    globalStateAtom.service_area_array
                      .slice(1)
                      .map((item, index) => {
                        return (
                          <Draggable
                            draggableId={`${index + 1}`}
                            index={index + 1}
                          >
                            {(provided) => (
                              <div
                                key={`${item?.city || "new City"}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Location item={item} index={index + 1} />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <button onClick={handleAdd} className={`${button} mt-10`}>
            + Add Service Area
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationsContainer;
