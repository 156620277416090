import { Switch } from "@headlessui/react";

interface SwitchToggleProps {
  checked: boolean;
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
  label?: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const SwitchToggle: React.FC<SwitchToggleProps> = ({
  checked,
  onChange,
  label,
}) => {
  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch
          checked={checked}
          onChange={onChange}
          className={classNames(
            checked ? "bg-blue-800" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none"
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              checked ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
        {label && (
          <Switch.Label className="ml-4 cursor-pointer">{label}</Switch.Label>
        )}
      </div>
    </Switch.Group>
  );
};

export default SwitchToggle;
