import { useAtom } from "jotai";
import { IoCloseCircle } from "react-icons/io5";
import { borderedInputWhite, label } from "../../../shared/styles";
import globalState from "../../../store";
import { ServiceAreaArray } from "../../../types/types";
import { useEffect, useState } from "react";
import { getGeoName, getSuggestedGeoNames, SuggestedGeoName } from "./utils";
import { useDebounce } from "use-debounce";

interface LocationProps {
  index: number;
}

const Location: React.FC<LocationProps> = ({ index }) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { service_area_array, service_areas } = globalStateAtom;
  const [suggestedGeoNames, setSuggestedGeoNames] = useState<
    SuggestedGeoName[]
  >([]);
  const [locationQuery] = useDebounce(
    `${service_areas[index].city} ${service_areas[index].state}`,
    1000
  );
  const [showSuggestedGeoNames, setShowSuggestedGeoNames] = useState(false);
  const [closestGeoNameMatch, setClosestGeoNameMatch] = useState<
    SuggestedGeoName | undefined | null
  >();

  useEffect(() => {
    const item = service_areas[index];
    if (!item || !showSuggestedGeoNames) return;

    getSuggestedServiceLocations(locationQuery.toLowerCase());
  }, [locationQuery, showSuggestedGeoNames]);

  const handleDelete = (index: number) => {
    const thisService = service_area_array[index];
    const splicedArray = service_area_array.filter((item) => {
      return item !== thisService;
    });
    const splicedAreaArray = service_areas.filter((item) => {
      return item.city !== thisService[0];
    });
    setGlobalState({
      ...globalStateAtom,
      service_areas: splicedAreaArray,
      service_area_array: splicedArray,
    });
  };

  useEffect(() => {
    checkIfServiceAreaIsValid();
  }, []);

  const getSuggestedServiceLocations = async (address: string) => {
    const geoNames = await getSuggestedGeoNames(address);
    const filteredGeoNames = geoNames.filter(
      (geoName) => geoName.state_code && geoName.country_code === "US"
    );
    setSuggestedGeoNames(filteredGeoNames);
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowSuggestedGeoNames(true);
    const thisServiceArea = [
      e.target.value,
      service_area_array[index][1],
      service_area_array[index][2],
      service_area_array[index][3],
    ] as ServiceAreaArray;
    const newServiceAreaArray = [...service_area_array] as ServiceAreaArray[];
    newServiceAreaArray[index] = thisServiceArea;
    const newServiceAreas = [...service_areas];
    newServiceAreas[index] = {
      city: thisServiceArea[0],
      state: thisServiceArea[1],
      radius:
        typeof thisServiceArea[2] === "string"
          ? parseInt(thisServiceArea[2])
          : thisServiceArea[2],
      order:
        typeof thisServiceArea[3] === "string"
          ? parseInt(thisServiceArea[3])
          : thisServiceArea[3],
    };
    setGlobalState({
      ...globalStateAtom,
      service_areas: newServiceAreas,
      service_area_array: newServiceAreaArray,
    });
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowSuggestedGeoNames(true);
    const thisServiceArea = [
      service_area_array[index][0],
      e.target.value,
      service_area_array[index][2],
      service_area_array[index][3],
    ] as ServiceAreaArray;
    const newServiceAreaArray = [...service_area_array];
    newServiceAreaArray[index] = thisServiceArea;
    const newServiceAreas = [...service_areas];
    newServiceAreas[index] = {
      city: thisServiceArea[0],
      state: thisServiceArea[1],
      radius:
        typeof thisServiceArea[2] === "string"
          ? parseInt(thisServiceArea[2])
          : thisServiceArea[2],
      order:
        typeof thisServiceArea[3] === "string"
          ? parseInt(thisServiceArea[3])
          : thisServiceArea[3],
    };
    setGlobalState({
      ...globalStateAtom,
      service_areas: newServiceAreas,
      service_area_array: newServiceAreaArray,
    });
  };

  const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const thisServiceArea = [
      service_area_array[index][0],
      service_area_array[index][1],
      parseInt(e.target.value),
      service_area_array[index][3],
    ] as ServiceAreaArray;
    const newServiceAreaArray = [...service_area_array];
    newServiceAreaArray[index] = thisServiceArea;
    const newServiceAreas = [...service_areas];
    newServiceAreas[index] = {
      city: thisServiceArea[0],
      state: thisServiceArea[1],
      radius: thisServiceArea[2],
      order:
        typeof thisServiceArea[3] === "string"
          ? parseInt(thisServiceArea[3])
          : thisServiceArea[3],
    };

    setGlobalState({
      ...globalStateAtom,
      service_areas: newServiceAreas,
      service_area_array: newServiceAreaArray,
    });
  };

  const useGeoName = (geoName: SuggestedGeoName) => {
    console.log("geoName:", geoName);
    const thisServiceArea = [
      geoName.city,
      geoName.state_code,
      service_area_array[index][2],
      service_area_array[index][3],
    ] as ServiceAreaArray;
    const newServiceAreaArray = [...service_area_array];
    newServiceAreaArray[index] = thisServiceArea;
    const newServiceAreas = [...service_areas];
    newServiceAreas[index] = {
      city: thisServiceArea[0],
      state: thisServiceArea[1],
      radius:
        typeof thisServiceArea[2] === "string"
          ? parseInt(thisServiceArea[2])
          : thisServiceArea[2],
      order:
        typeof thisServiceArea[3] === "string"
          ? parseInt(thisServiceArea[3])
          : thisServiceArea[3],
    };

    setGlobalState({
      ...globalStateAtom,
      service_areas: newServiceAreas,
      service_area_array: newServiceAreaArray,
    });
    setShowSuggestedGeoNames(false);
    setClosestGeoNameMatch(null);
  };

  const checkIfServiceAreaIsValid = async () => {
    const item = service_areas[index];
    if (!item) return;
    const query = `${item.city} ${item.state}`.toLowerCase();
    const closestMatch = await getGeoName(query);
    if (closestMatch) {
      const isIdentic =
        query ===
        `${closestMatch.city} ${closestMatch.state_code}`.toLowerCase();

      if (isIdentic) {
        setClosestGeoNameMatch(null);
      } else {
        setClosestGeoNameMatch(closestMatch);
      }
    }
  };

  return (
    <>
      <div
        className={
          "flex relative p-2 rounded-md " +
          (closestGeoNameMatch && "bg-red-100")
        }
        id={`${index}`}
      >
        <div
          onClick={(_) => {
            const confirmation = prompt(
              "Are you sure you want to delete this location?",
              "yes"
            );
            if (confirmation) handleDelete(index);
          }}
          className="absolute transform -translate-x-6 cursor-pointer rounded-full flex p-2"
        >
          <IoCloseCircle color="#E5E7EB" size={30} id={`${index}`} />
        </div>
        <label className={label}>
          <p className="transform translate-x-6">City</p>
          <input
            onChange={handleCityChange}
            value={service_area_array[index][0]}
            className={borderedInputWhite}
          />
        </label>
        <label className={`${label} ml-1`}>
          State
          <input
            onChange={handleStateChange}
            value={service_area_array[index][1]}
            className={borderedInputWhite}
          />
        </label>
        <label className={`${label} ml-1`}>
          Radius
          <input
            onChange={handleRadiusChange}
            value={service_area_array[index][2]}
            className={borderedInputWhite}
          />
        </label>
        <label className={`${label} ml-1`}>
          order - {service_area_array[index][3]}
        </label>

        {/* Suggested service areas */}
        {showSuggestedGeoNames && !!suggestedGeoNames.length && (
          <ul className="max-h-40 overflow-scroll absolute rounded-md border-gray-200 border-2 bg-white top-full z-30 w-full shadow-lg">
            {suggestedGeoNames.map((geoName) => {
              return (
                <li key={geoName.id}>
                  <button
                    className="cursor-pointer hover:bg-gray-200 p-4 text-left w-full block"
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log("clicked:", geoName);
                      useGeoName(geoName);
                    }}
                  >
                    {geoName.city}, {geoName.state_code || geoName.state}
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {closestGeoNameMatch && (
        <div className="text-left text-red-600">
          Did you mean{" "}
          <button
            className="font-extrabold"
            onClick={(_) => useGeoName(closestGeoNameMatch)}
          >
            {closestGeoNameMatch.city} {closestGeoNameMatch.state_code}
          </button>
          ?
        </div>
      )}
    </>
  );
};

export default Location;
