/* eslint-disable */
import { useAtom } from "jotai";
import globalState from "../../../store";

interface CustomLayoutRadioProps {
  label: string;
  dataField: string;
  setCustomLayout: any;
  v2ImageSource: string;
  v21ImageSource: string;
  v22ImageSource: string;
}

export default function CustomLayoutRadio({
  label,
  dataField,
  setCustomLayout,
  v21ImageSource,
  v2ImageSource,
  v22ImageSource,
}: CustomLayoutRadioProps) {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  return (
    <div className="mb-2 w-full border-b-2">
      <h1 className="flex sm:pb-3">{label}</h1>
      <div className="flex-column md:flex w-full justify-between">
        <div className="flex w-3/12 mx-8">
          <input
            className="flex mr-1"
            checked={
              globalStateAtom.custom_layout?.[dataField]?.version === "v2"
            }
            type="radio"
            name={dataField}
            value="v2"
            onChange={(e) => {
              setGlobalState({
                ...globalStateAtom,
                custom_layout: {
                  ...globalStateAtom.custom_layout,
                  [dataField]: {
                    ...globalStateAtom.custom_layout[dataField],
                    version: e.target.value,
                  },
                },
              });
              setCustomLayout({
                ...globalStateAtom.custom_layout,
                [dataField]: {
                  ...globalStateAtom.custom_layout[dataField],
                  version: e.target.value,
                },
              });
            }}
          />
          {!v2ImageSource && <div className="mr-4 ">v2</div>}
          {v2ImageSource && !v2ImageSource.includes("mov") && (
            <img
              className="sm:pb-2"
              src={v2ImageSource}
              width="300"
              alt={label}
            />
          )}
          {v2ImageSource && v2ImageSource.includes("mov") && (
            <video
              className="sm:pb-2"
              src={v2ImageSource}
              width="300"
              controls
            />
          )}
        </div>
        <div className="flex w-3/12">
          <input
            className="flex mr-1"
            checked={
              globalStateAtom.custom_layout?.[dataField]?.version === "v2.1"
            }
            type="radio"
            name={dataField}
            value="v2.1"
            onChange={(e) => {
              setGlobalState({
                ...globalStateAtom,
                custom_layout: {
                  ...globalStateAtom.custom_layout,
                  [dataField]: {
                    ...globalStateAtom.custom_layout[dataField],
                    version: e.target.value,
                  },
                },
              });
              setCustomLayout({
                ...globalStateAtom.custom_layout,
                [dataField]: {
                  ...globalStateAtom.custom_layout[dataField],
                  version: e.target.value,
                },
              });
            }}
          />
          {!v21ImageSource && <div className="mr-4">v2.1</div>}
          {v21ImageSource && !v21ImageSource.includes("mov") && (
            <img
              className="sm:pb-2"
              src={v21ImageSource}
              width="300"
              alt={label}
            />
          )}
          {v21ImageSource && v21ImageSource.includes("mov") && (
            <video
              className="sm:pb-2"
              src={v21ImageSource}
              width="300"
              controls
            />
          )}
        </div>
        {dataField === "our-best-work" && (
          <>
            <div className="flex w-3/12">
              <input
                className="flex mr-1 ml-8"
                checked={
                  globalStateAtom.custom_layout?.[dataField]?.version === "v2.2"
                }
                type="radio"
                name={dataField}
                value="v2.2"
                onChange={(e) => {
                  setGlobalState({
                    ...globalStateAtom,
                    custom_layout: {
                      ...globalStateAtom.custom_layout,
                      [dataField]: {
                        ...globalStateAtom.custom_layout[dataField],
                        version: e.target.value,
                      },
                    },
                  });
                  setCustomLayout({
                    ...globalStateAtom.custom_layout,
                    [dataField]: {
                      ...globalStateAtom.custom_layout[dataField],
                      version: e.target.value,
                    },
                  });
                }}
              />
              {/* <div className="z-50 flex items-center">v2.2 with animation</div> */}
              <div className="flex flex-col">
                {v22ImageSource && (
                  <video
                    className="sm:pb-2"
                    src={v22ImageSource}
                    width="300"
                    controls
                  />
                )}
                <div className="z-50 flex items-center">
                  v2.2 with animation
                </div>
              </div>
              {/* {v21ImageSource && !v21ImageSource.includes("mov") && (
              <img
                className="sm:pb-2"
                src={v21ImageSource}
                width="300"
                alt={label}
              />
            )}
            {v21ImageSource && v21ImageSource.includes("mov") && (
              <video
                className="sm:pb-2"
                src={v21ImageSource}
                width="300"
                controls
              />
            )} */}
            </div>
            <div className="flex w-3/12">
              <input
                className="flex ml-8"
                checked={
                  globalStateAtom.custom_layout?.[dataField]?.version === "v2.3"
                }
                type="radio"
                name={dataField}
                value="v2.3"
                onChange={(e) => {
                  setGlobalState({
                    ...globalStateAtom,
                    custom_layout: {
                      ...globalStateAtom.custom_layout,
                      [dataField]: {
                        ...globalStateAtom.custom_layout[dataField],
                        version: e.target.value,
                      },
                    },
                  });
                  setCustomLayout({
                    ...globalStateAtom.custom_layout,
                    [dataField]: {
                      ...globalStateAtom.custom_layout[dataField],
                      version: e.target.value,
                    },
                  });
                }}
              />
              <div className="z-50 flex items-center">
                v2.3 center aligned with square photos
              </div>
            </div>
          </>
        )}
        {dataField === "text-us-button" && (
          <div className="flex w-3/12">
            <input
              className="flex ml-8"
              checked={
                globalStateAtom.custom_layout?.[dataField]?.version === "v2.2"
              }
              type="radio"
              name={dataField}
              value="v2.2"
              onChange={(e) => {
                setGlobalState({
                  ...globalStateAtom,
                  custom_layout: {
                    ...globalStateAtom.custom_layout,
                    [dataField]: {
                      ...globalStateAtom.custom_layout[dataField],
                      version: e.target.value,
                    },
                  },
                });
                setCustomLayout({
                  ...globalStateAtom.custom_layout,
                  [dataField]: {
                    ...globalStateAtom.custom_layout[dataField],
                    version: e.target.value,
                  },
                });
              }}
            />
            <div className="z-50 flex items-center">v2.2 chat button</div>
          </div>
        )}
        {dataField === "gallery-page" && (
          <div className="flex w-3/12">
            <input
              className="flex ml-8"
              checked={
                globalStateAtom.custom_layout?.[dataField]?.version === "v2.3"
              }
              type="radio"
              name={dataField}
              value="v2.3"
              onChange={(e) => {
                setGlobalState({
                  ...globalStateAtom,
                  custom_layout: {
                    ...globalStateAtom.custom_layout,
                    [dataField]: {
                      ...globalStateAtom.custom_layout[dataField],
                      version: e.target.value,
                    },
                  },
                });
                setCustomLayout({
                  ...globalStateAtom.custom_layout,
                  [dataField]: {
                    ...globalStateAtom.custom_layout[dataField],
                    version: e.target.value,
                  },
                });
              }}
            />
            <div className="z-50 flex items-center">
              v2.3 with square photos
            </div>
          </div>
        )}

        {([
          "reviews-page",
          "team-page",
          "blog-page",
          "jobs-page",
          "custom-page",
          "gallery-page",
          "services-page",
        ].includes(dataField) ||
          !dataField?.includes("-page")) && (
          <div className="flex gap-1 items-center w-3/12 -mr-8">
            <input
              checked={
                globalStateAtom.custom_layout?.[dataField]?.version === "v3"
              }
              type="radio"
              name={dataField}
              value="v3"
              onChange={(e) => {
                setGlobalState({
                  ...globalStateAtom,
                  custom_layout: {
                    ...globalStateAtom.custom_layout,
                    [dataField]: {
                      ...globalStateAtom.custom_layout[dataField],
                      version: e.target.value,
                    },
                  },
                });
                setCustomLayout({
                  ...globalStateAtom.custom_layout,
                  [dataField]: {
                    ...globalStateAtom.custom_layout[dataField],
                    version: e.target.value,
                  },
                });
              }}
            />
            <span>v3</span>
          </div>
        )}
        
          <div className="flex gap-1 items-center w-3/12 -mr-8">
            <input
              checked={
                globalStateAtom.custom_layout?.[dataField]?.version === "v4"
              }
              type="radio"
              name={dataField}
              value="v4"
              onChange={(e) => {
                setGlobalState({
                  ...globalStateAtom,
                  custom_layout: {
                    ...globalStateAtom.custom_layout,
                    [dataField]: {
                      ...globalStateAtom.custom_layout[dataField],
                      version: e.target.value,
                    },
                  },
                });
                setCustomLayout({
                  ...globalStateAtom.custom_layout,
                  [dataField]: {
                    ...globalStateAtom.custom_layout[dataField],
                    version: e.target.value,
                  },
                });
              }}
            />
            <span>v4</span>
          </div>

        <div className="flex">
          <input
            className="flex mr-1 md:ml-10"
            checked={
              globalStateAtom.custom_layout?.[dataField]?.version === "none"
            }
            type="radio"
            name={dataField}
            value="none"
            disabled={dataField === "modals/text_thank_you"}
            onChange={(e) => {
              setGlobalState({
                ...globalStateAtom,
                custom_layout: {
                  ...globalStateAtom.custom_layout,
                  [dataField]: {
                    ...globalStateAtom.custom_layout[dataField],
                    version: e.target.value,
                  },
                },
              });
              setCustomLayout({
                ...globalStateAtom.custom_layout,
                [dataField]: {
                  ...globalStateAtom.custom_layout[dataField],
                  version: e.target.value,
                },
              });
            }}
          />
          <div className="mr-4 flex items-center">none</div>
        </div>
      </div>
    </div>
  );
}
