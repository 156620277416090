/* eslint-disable */
import { useAtom } from "jotai";
import React, { useState, useRef } from "react";
import {
  DragDropContext,
  DropResult,
  Draggable,
  Droppable,
} from "react-beautiful-dnd";
import globalState from "../store";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ElemBefore: React.ReactNode;
    }
  }
}

export type NavItemProps = {
  title: string;
  itemId: string;
  disabled?: boolean;
  elemBefore?: React.FC<unknown>;
  subNav?: NavItemProps[];
  count?: number;
};

export type SideNavProps = {
  items: NavItemProps[];
  activeItemId: string;
  onSelect?: ({ itemId }: { itemId: string }) => void;
  updateGalleryName: (galleryName: any) => void;
  onDragEnd: ({ source, destination }: DropResult) => null | undefined;
  currentGalleryNames: any[];
};

const SideNav: React.FC<SideNavProps> = ({
  activeItemId,
  onSelect,
  items,
  updateGalleryName,
  onDragEnd,
  currentGalleryNames,
}) => {
  const [activeSubNav, setActiveSubNav] = useState({
    expanded: true,
    selectedId: activeItemId,
  });

  const [editing, setEditing] = useState("");
  const [focused, setFocused] = useState(false);
  const [newGalleryName, setNewGalleryName] = useState("");
  const inputEl = useRef<any>(null);
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);

  // Listen for parent prop changes and update state
  React.useEffect(() => {
    setActiveSubNav((originalSubNav) => ({
      expanded: true,
      selectedId: activeItemId,
    }));
  }, [activeItemId]);

  function handleClick(itemId: string): void {
    // call the callback if supplied
    onSelect?.({ itemId });
  }

  function handleFocus() {
    // this highlights the text as soon as they click into the input
    if (inputEl && inputEl.current && !focused) inputEl.current.select();
    // this makes sure the mouseover event doesn't continue to highlight the text after the initial focus
    setFocused(true);
  }

  function handleSubNavExpand(item: NavItemProps): void {
    if (activeSubNav) {
      const currentItemOrSubNavItemIsOpen: boolean =
        // either the parent item is expanded already
        item.itemId === activeSubNav.selectedId ||
        // or one of its expandable children is selected
        (item.subNav &&
          item.subNav.some(
            (_subNavItem) => _subNavItem.itemId === activeSubNav.selectedId
          )) ||
        false;

      setActiveSubNav({
        expanded: true,
        //   item.subNav && item.subNav.length > 0
        //     ? !currentItemOrSubNavItemIsOpen
        //     : false, // disable expansion value, if not expandable
        selectedId: item.itemId,
      });
    } else {
      setActiveSubNav({
        expanded: true, // expand if expandable
        selectedId: item.itemId,
      });
    }
  }

  const updateAllGalleryName = (newName: string) => {
    setGlobalStateAtom({...globalStateAtom, gallery_all_title: newName})
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {items.length > 0 && (
        <nav
          role="navigation"
          aria-label="side-navigation"
          className="side-navigation-panel"
        >
          {items.map((item: NavItemProps) => {
            const ElemBefore = item.elemBefore;
            const isItemSelected: boolean =
              item.itemId === activeSubNav.selectedId;
            const isActiveTab: boolean =
              // item is expanded and
              activeSubNav.expanded &&
              // either the current expandable section is selected
              (isItemSelected ||
                // or some item in the expandable section of the current item is selected or active
                (item.subNav &&
                  item.subNav.some(
                    (_subNavItem: NavItemProps) =>
                      _subNavItem.itemId === activeSubNav.selectedId
                  )) ||
                true);

            return (
              <ul key={item.itemId} className="side-navigation-panel-select">
                <li className="side-navigation-panel-select-wrap">
                  <div
                    onClick={(): void => {
                      handleSubNavExpand(item);
                      handleClick(item.itemId);
                    }}
                    className={`side-navigation-panel-select-option ${isItemSelected
                        ? "side-navigation-panel-select-option-selected"
                        : ""
                      }`}
                  >
                    <span className="side-navigation-panel-select-option-wrap">
                      {/** Prefix Icon Component */}
                      {ElemBefore && <ElemBefore />}

                      <span className="side-navigation-panel-select-option-text">
                        {item.title}
                      </span>
                    </span>
                  </div>
                </li>

                {item.subNav && item.subNav.length > 0 && isActiveTab && (
                  <ul className="side-navigation-panel-select-inner">
                    {item.title === "Galleries" ? (
                      <Droppable droppableId={"col-1" + item.title}>
                        {(provided) => (
                          <div
                            className="flex flex-col w-8/12 items-start"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {item.subNav!.map(
                              (subNavItem: NavItemProps, index: number) => {
                                const SubItemElemBefore = subNavItem.elemBefore;

                                return (
                                  <li
                                    key={subNavItem.itemId}
                                    className="side-navigation-panel-select-inner-wrap"
                                  >
                                    {
                                      // if we are in the galleries subnav
                                      item.title === "Galleries" &&
                                        // and we're not the 'uncategorized' item
                                        subNavItem.itemId !==
                                        "/galleries/uncategorized" &&
                                        subNavItem.itemId !== "/galleries/add" ? (
                                        // then make a draggable
                                        <Draggable
                                          draggableId={
                                            subNavItem.itemId
                                              ? subNavItem.itemId
                                              : `new-gallery-${index}`
                                          }
                                          index={index - 1}
                                        >
                                          {(provided) => (
                                            // 'provided' is a built-in ref of the beautiful-dnd library that keeps track of the element
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            // className='flex flex-col items-start w-full py-5 m-6 rounded-lg border-2 border-gray-100 bg-white'
                                            >
                                              <div
                                                onClick={(): void => {
                                                  setActiveSubNav({
                                                    ...activeSubNav,
                                                    selectedId:
                                                      subNavItem.itemId,
                                                  });
                                                  handleClick(
                                                    subNavItem.itemId
                                                  );
                                                }}
                                                onDoubleClick={(): void => {
                                                  setEditing(subNavItem.itemId);
                                                }}
                                                className={`side-navigation-panel-select-inner-option ${activeSubNav.selectedId ===
                                                    subNavItem.itemId
                                                    ? "side-navigation-panel-select-inner-option-selected"
                                                    : ""
                                                  } `}
                                              >
                                                <span className="side-navigation-panel-select-inner-option-wrap">
                                                  {/** Prefix Icon Component */}
                                                  {SubItemElemBefore && (
                                                    <SubItemElemBefore />
                                                  )}
                                                  {/* {SubItemElemBefore && subNavItem.itemId === activeSubNav.selectedId && <SubItemElemBefore />} */}
                                                  {editing ===
                                                    subNavItem.itemId ? (
                                                    <input
                                                      ref={inputEl}
                                                      onMouseOver={handleFocus}
                                                      onChange={(e) =>
                                                        setNewGalleryName(
                                                          e.target.value
                                                        )
                                                      }
                                                      value={
                                                        newGalleryName !== ""
                                                          ? newGalleryName
                                                          : subNavItem.title
                                                      }
                                                      onBlur={(e) => {
                                                        for (
                                                          let i = 0;
                                                          i <
                                                          currentGalleryNames.length;
                                                          i++
                                                        ) {
                                                          if (
                                                            newGalleryName ===
                                                            currentGalleryNames[
                                                            i
                                                            ]["gallery-name"]
                                                          ) {
                                                            alert(
                                                              "Please make sure the gallery does not have the same name as another gallery."
                                                            );
                                                            return;
                                                          }
                                                        }
                                                        updateGalleryName(
                                                          newGalleryName !== ""
                                                            ? newGalleryName
                                                            : subNavItem.title
                                                        );
                                                        setEditing("");
                                                        setNewGalleryName("");
                                                        setFocused(false);
                                                      }}
                                                      onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                          e.stopPropagation()
                                                          for (
                                                            let i = 0;
                                                            i <
                                                            currentGalleryNames.length;
                                                            i++
                                                          ) {
                                                            if (
                                                              newGalleryName ===
                                                              currentGalleryNames[
                                                              i
                                                              ]["gallery-name"]
                                                            ) {
                                                              alert(
                                                                "Please make sure the gallery does not have the same name as another gallery."
                                                              );
                                                              return;
                                                            }
                                                          }
                                                          updateGalleryName(
                                                            newGalleryName !== ""
                                                              ? newGalleryName
                                                              : subNavItem.title
                                                          );
                                                          setEditing("");
                                                          setNewGalleryName("");
                                                          setFocused(false);
                                                        }
                                                      }}
                                                    />
                                                  ) : (
                                                    <span className="side-navigation-panel-select-inner-option-text">
                                                      {subNavItem.title}
                                                    </span>
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      ) : (
                                        // ELSE: make a regular sidNav item
                                        <div
                                          onClick={(): void => {
                                            if (
                                              subNavItem.itemId !==
                                              "/galleries/add"
                                            ) {
                                              setActiveSubNav({
                                                ...activeSubNav,
                                                selectedId: subNavItem.itemId,
                                              });
                                            }
                                            handleClick(subNavItem.itemId);
                                          }}
                                          onDoubleClick={(): void => {
                                            setEditing(subNavItem.itemId);
                                          }}
                                          className={`side-navigation-panel-select-inner-option ${activeSubNav.selectedId ===
                                              subNavItem.itemId
                                              ? "side-navigation-panel-select-inner-option-selected"
                                              : ""
                                            } `}
                                        >
                                          <span className="side-navigation-panel-select-inner-option-wrap">
                                            {/** Prefix Icon Component */}
                                            {SubItemElemBefore && (
                                              <SubItemElemBefore />
                                            )}
                                            {/* {SubItemElemBefore && subNavItem.itemId === activeSubNav.selectedId && <SubItemElemBefore />} */}
                                            <span
                                              className={
                                                subNavItem.title ===
                                                  "+ Add Gallery"
                                                  ? "transform -translate-x-16 text-right side-navigation-panel-select-inner-option-text"
                                                  : "side-navigation-panel-select-inner-option-text"
                                              }
                                            >
                                              {subNavItem.title}
                                            </span>
                                          </span>
                                        </div>
                                      )
                                    }
                                  </li>
                                );
                              }
                            )}

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    ) : (
                      // IF NOT THE GALLERIES SUBMENU THEN DON'T DRAG-N-DROP AT ALL

                      item.subNav!.map(
                        (subNavItem: NavItemProps, index: number) => {
                          const SubItemElemBefore = subNavItem.elemBefore;

                          return (
                            <li
                              key={subNavItem.itemId}
                              className="side-navigation-panel-select-inner-wrap"
                            >
                              <div
                                onClick={(): void => {
                                  setActiveSubNav({
                                    ...activeSubNav,
                                    selectedId: subNavItem.itemId,
                                  });
                                  handleClick(subNavItem.itemId);
                                }}
                                onDoubleClick={(): void => {
                                  setEditing(subNavItem.itemId);
                                }}
                                className={`side-navigation-panel-select-inner-option ${activeSubNav.selectedId === subNavItem.itemId
                                    ? "side-navigation-panel-select-inner-option-selected"
                                    : ""
                                  } `}
                              >
                                <span className="side-navigation-panel-select-inner-option-wrap">
                                  {/** Prefix Icon Component */}
                                  {SubItemElemBefore && <SubItemElemBefore />}
                                  {editing === subNavItem.itemId && subNavItem.itemId === "/photos/allPhotos" ? (
                                    <input
                                      ref={inputEl}
                                      onMouseOver={handleFocus}
                                      onChange={(e) =>
                                        setNewGalleryName(
                                          e.target.value
                                        )
                                      }
                                      value={
                                        newGalleryName !== ""
                                          ? newGalleryName
                                          : subNavItem.title
                                      }
                                      onBlur={(e) => {
                                        for (
                                          let i = 0;
                                          i <
                                          currentGalleryNames.length;
                                          i++
                                        ) {
                                          if (
                                            newGalleryName ===
                                            currentGalleryNames[
                                            i
                                            ]["gallery-name"]
                                          ) {
                                            alert(
                                              "Please make sure the gallery does not have the same name as another gallery."
                                            );
                                            return;
                                          }
                                        }
                                        updateAllGalleryName(
                                          newGalleryName !== ""
                                            ? newGalleryName
                                            : subNavItem.title
                                        );
                                        setEditing("");
                                        setNewGalleryName("");
                                        setFocused(false);
                                      }}
                                      onMouseEnter={(e) =>
                                        e.currentTarget.select()
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          for (
                                            let i = 0;
                                            i <
                                            currentGalleryNames.length;
                                            i++
                                          ) {
                                            if (
                                              newGalleryName ===
                                              currentGalleryNames[
                                              i
                                              ]["gallery-name"]
                                            ) {
                                              alert(
                                                "Please make sure the gallery does not have the same name as another gallery."
                                              );
                                              return;
                                            }
                                          }
                                          updateAllGalleryName(
                                            newGalleryName !== ""
                                              ? newGalleryName
                                              : subNavItem.title
                                          );
                                          setEditing("");
                                          setNewGalleryName("");
                                          setFocused(false);
                                        }
                                      }}
                                    />
                                  ) : (
                                    <span className="side-navigation-panel-select-inner-option-text">
                                      {subNavItem.title}
                                    </span>
                                  )}
                                </span>
                                <span className="rounded-md bg-gray-500 text-white text-xs font-mono px-2 py-1 ml-2">{subNavItem.count}</span>
                              </div>
                            </li>
                          );
                        }
                      )
                    )}
                  </ul>
                )}
              </ul>
            );
          })}
        </nav>
      )}
    </DragDropContext>
  );
};

export default SideNav;
