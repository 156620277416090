/* eslint-disable */
import { useAtom } from "jotai";

import { useEffect, useState } from "react";
import globalState from "../../../store";
import FontDropdown from "../../logo_generator/FontDropdown";
import { ChakraProvider } from "@chakra-ui/react";
import WebFont from "webfontloader";

const Font: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { custom_hero_font, custom_all_font } = globalStateAtom;
  const [showDefaultHeroFont, setShowDefaultHeroFont] = useState(true);
  const [showDefaultAllFont, setShowDefaultAllFont] = useState(true);
  const DEFAULT_FONT =
    'Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

  const defaultHeroFont = {
    name: custom_hero_font || "Inter",
    style: `font-family: ${custom_hero_font || "Inter"};`,
  };

  const defaultAllFont = {
    name: custom_all_font || "Inter",
    style: `font-family: ${custom_all_font || "Inter"};`,
  };

  useEffect(() => {
    if (
      custom_hero_font === "" ||
      custom_hero_font === DEFAULT_FONT ||
      custom_hero_font == null ||
      !custom_hero_font
    ) {
      setGlobalState({
        ...globalStateAtom,
        custom_hero_font: "",
      });
      setShowDefaultHeroFont(true);
    } else {
      setShowDefaultHeroFont(false);
    }
  }, [custom_hero_font]);

  useEffect(() => {
    if (
      custom_all_font === "" ||
      custom_all_font === DEFAULT_FONT ||
      custom_all_font == null ||
      !custom_all_font
    ) {
      setGlobalState({
        ...globalStateAtom,
        custom_all_font: "",
      });
      setShowDefaultAllFont(true);
    } else {
      setShowDefaultAllFont(false);
    }
  }, [custom_all_font]);

  function handleOnChangeHeroFont(selectedFont: any) {
    setGlobalState({
      ...globalStateAtom,
      custom_hero_font: selectedFont.name,
    });
    setShowDefaultHeroFont(false);
  }

  function handleAllFontOnChange(selectedFont: any) {
    setGlobalState({
      ...globalStateAtom,
      custom_all_font: selectedFont.name,
    });
    setShowDefaultAllFont(false);
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: [custom_hero_font || "", custom_all_font || ""],
      },
    });
  }, [custom_hero_font, custom_all_font]);

  return (
    <ChakraProvider>
      <div className="w-full sm:w-1/2">
        <h2 className="text-left">Font</h2>
        <div className={`bg-white rounded-lg p-6 justify-between w-10/12 mt-4`}>
          <div className="flex flex-col items-start bg-gray-100 m-3 p-3 rounded-lg">
            Hero
            {!showDefaultHeroFont && (
              <div className="flex flex-col gap-4 w-full">
                <div className="mt-2 flex">
                  <div className="w-60">
                    <FontDropdown defaultFont={defaultHeroFont} setFont={handleOnChangeHeroFont} />
                  </div>
                </div>
                <div
                  style={{ fontFamily: custom_hero_font }}
                  className="text-lg flex flex-col p-4 rounded-md bg-white font-medium"
                >
                  <p className="mt-2 ml-2">
                    the quick brown fox jumps over the lazy dog.
                  </p>
                  <p className="mt-2 ml-2">
                    THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG.
                  </p>
                </div>
              </div>
            )}
            <label className="mr-5 mt-3">
              <input
                className="mr-1"
                name="insured"
                type="checkbox"
                checked={showDefaultHeroFont}
                onChange={(e) => {
                  const oldDefaultFontStatus = showDefaultHeroFont;
                  setShowDefaultHeroFont(!showDefaultHeroFont);
                  if (!oldDefaultFontStatus) {
                    setGlobalState({
                      ...globalStateAtom,
                      custom_hero_font: "",
                    });
                  }
                  if (custom_hero_font === DEFAULT_FONT) {
                    setGlobalState({
                      ...globalStateAtom,
                      custom_hero_font: "",
                    });
                  }
                }}
              />
              Set to default
            </label>
          </div>

          <div className="flex flex-col items-start bg-gray-100 m-3 p-3 rounded-lg mt-6">
            All
            {!showDefaultAllFont && (
              <div className="flex flex-col gap-4 w-full">
                <div className="mt-2 flex">
                  <div className="w-60">
                    <FontDropdown defaultFont={defaultAllFont} setFont={handleAllFontOnChange} />
                  </div>
                </div>
                <div
                  style={{ fontFamily: custom_all_font }}
                  className="text-lg flex flex-col p-4 rounded-md bg-white font-medium"
                >
                  <p className="mt-2 ml-2">
                    the quick brown fox jumps over the lazy dog.
                  </p>
                  <p className="mt-2 ml-2">
                    THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG.
                  </p>
                </div>
              </div>
            )}
            <label className="mr-5 mt-3">
              <input
                className="mr-1"
                name="insured"
                type="checkbox"
                checked={showDefaultAllFont}
                onChange={(e) => {
                  const oldDefaultFontStatus = showDefaultAllFont;
                  setShowDefaultAllFont(!showDefaultAllFont);
                  if (!oldDefaultFontStatus) {
                    setGlobalState({
                      ...globalStateAtom,
                      custom_all_font: "",
                    });
                  }

                  if (custom_all_font === DEFAULT_FONT) {
                    setGlobalState({
                      ...globalStateAtom,
                      custom_all_font: "",
                    });
                  }
                }}
              />
              Set to default
            </label>
          </div>
        </div>
      </div>
    </ChakraProvider>
  );
};

export default Font;
