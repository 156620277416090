/* eslint-disable */
import { useAtom } from "jotai";
import { buttonSmall } from "../../../shared/styles";
import globalState from "../../../store";
import { v4 as uuidv4 } from "uuid";
import { IService } from "../../../types/types";

interface AddFooterButtonProps {
  service: IService;
  servicePackageId: number | string;
  // eslint-disable-next-line
  setActiveService: React.Dispatch<React.SetStateAction<any>>;
}

const AddFooterButton: React.FC<AddFooterButtonProps> = ({
  service,
  servicePackageId,
  setActiveService,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const blankFooterObject = {
    note: "",
    service_package_id: servicePackageId,
    status: "approved",
    id: uuidv4(),
  };

  const addNewFooter = () => {
    const newServicePackages = [...service.packages];
    newServicePackages.map((servicePackage) => {
      if (servicePackage.id === servicePackageId) {
        servicePackage.service_footers.push(blankFooterObject);
      }
      return servicePackage;
    });
    const newService = { ...service, packages: newServicePackages };

    setGlobalState({
      ...globalStateAtom,
      services: globalStateAtom.services.map((s: IService) =>
        s.order === service.order ? newService : s
      ),
    });

    // Should get rid of this pattern
    setActiveService(newService);
  };

  return (
    <button onClick={addNewFooter} className={`${buttonSmall} relative`}>
      Add Footer
    </button>
  );
};

export default AddFooterButton;
