/* eslint-disable */
import { v4 as uuidv4 } from "uuid";

export default function formatLandingPage(landingPage: any) {
  const landingPageKeys = Object.keys(landingPage);
  for (let i = 0; i < landingPageKeys.length; i++) {
    if (landingPageKeys[i] === "product" || landingPageKeys[i] === "payments") {
      let landingPageKeyJson = JSON.parse(landingPage[landingPageKeys[i]]);
      landingPageKeyJson = landingPageKeyJson.map((obj: any) => {
        return obj && obj.fields ? obj.fields : "";
      });

      landingPage[landingPageKeys[i]] = landingPageKeyJson;
    }
  }
  return landingPage;
}

export function getTestimonialsWithDraggableId(testimonials: any) {
  const reviews = testimonials.map((item: any) => {
    if (!item?.draggableId) {
      item.draggableId = uuidv4();
    }
    return item;
  });
  return reviews;
}
