/* eslint-disable */

import { useAtom } from "jotai";
import { borderedInputWhite } from "../../../shared/styles";
import globalState from "../../../store";

export default function CustomerSegment() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const formattedPlans = [
    {
      value: 1,
      text: "Segment 1: Beginner Chuck and a Truck & Solo Chuck and a Truck",
    },
    { value: 2, text: "Segment 2: Reputable Joe & Professional Joe" },
    { value: 3, text: "Segment 3: Grayson & Growing Grayson" },
  ];

  function handleChange(e) {
    setGlobalState({
      ...globalStateAtom,
      customer_segment: e.target.value,
    });
  }

  return (
    <div className="flex flex-col justify-start items-start bg-white p-6 rounded-lg mt-8">
      <div className="text-h1 font-bold">Customer Segment</div>
      <div className="flex w-full pt-2">
        <select
          onChange={(e) => handleChange(e)}
          value={globalStateAtom?.customer_segment}
          className={`${borderedInputWhite}`}
          placeholder="Please select a segment"
        >
          <option
            value=""
            disabled
            selected={!globalStateAtom?.customer_segment}
          >
            Please select a segment
          </option>
          {formattedPlans?.map((plan) => {
            return <option value={plan?.value}>{plan?.text}</option>;
          })}
        </select>
      </div>
    </div>
  );
}
