import { useAtom } from "jotai";
import { IoCloseCircle } from "react-icons/io5";
import globalState from "../../../store";
import { IReviewLink } from "../../../types/types";
import UploadFile from "../../services/components/upload/UploadFile";
import { useState } from "react";
import { pushImageToS3 } from "../../../utils/helpers/uploadFileUtil";
import { label } from "../../../shared/styles";

const CustomReviewLink: React.FC = () => {
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);

  const { review_links } = globalStateAtom;

  const handleChangeTitle = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newReviewLinks = [...review_links];
    newReviewLinks[index].title = e.target.value;
    setGlobalStateAtom({
      ...globalStateAtom,
      review_links: newReviewLinks,
    });
  };

  const handleChangeLink = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newReviewLinks = [...review_links];
    newReviewLinks[index].link = e.target.value;
    setGlobalStateAtom({
      ...globalStateAtom,
      review_links: newReviewLinks,
    });
  };

  const handleDelete = (index: number) => {
    const newReviewLink = review_links.filter((link, count) => count !== index);
    setGlobalStateAtom({
      ...globalStateAtom,
      review_links: newReviewLink,
    });
  };

  const handleAdd = () => {
    const newReviewLink: IReviewLink = {
      title: "",
      icon: "",
      link: "",
    };
    setGlobalStateAtom({
      ...globalStateAtom,
      review_links: [...review_links, newReviewLink],
    });
  };

  const handleDropFileInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      handleChangeFileInput(files, index);
    }
  };

  const handleChangeFileInput = async (files: FileList, index: number) => {
    setLoading(true);
    setLoadingIndex(index);
    const file = files[0];
    console.log(file);
    const [pushedImageUrl] = await pushImageToS3(file);
    if (pushedImageUrl) {
      const newReviewLinks = [...review_links];
      newReviewLinks[index].icon = pushedImageUrl;
      setGlobalStateAtom({
        ...globalStateAtom,
        review_links: newReviewLinks,
      });
    }
    setLoadingIndex(null);
    setLoading(false);
  };

  const handleDeleteIcon = (index: number) => {
    const newCustomBadges = [...review_links];
    newCustomBadges[index].icon = "";
    setGlobalStateAtom({
      ...globalStateAtom,
      review_links: newCustomBadges,
    });
  };

  return (
    <>
      <div className="space-y-2">
        {review_links &&
          review_links?.map((review_link, index) => (
            <div key={index} className="flex items-center py-2">
              <button
                disabled={loading}
                className={
                  "border rounded-full mt-10" +
                  (loading
                    ? " opacity-25 cursor-not-allowed bg-gray-300"
                    : " hover:bg-red-300 cursor-pointer")
                }
                onClick={() => {
                  const confirmation = prompt(
                    "Are you sure you want to delete this review link?",
                    "yes"
                  );
                  if (confirmation) handleDelete(index);
                }}
              >
                <IoCloseCircle color="#E5E7EB" size={30} />
              </button>
              <div className="flex items-center ml-2 gap-2 w-full">
                <div className="flex justify-between">
                  <label className={`${label} w-full`}>
                    Review Link
                    <input
                      className="p-2 mt-1 rounded-md border-2 border-gray-200 w-full"
                      onChange={(e) => handleChangeLink(e, index)}
                      value={review_link?.link}
                    />
                  </label>
                  <label className={`${label} w-full ml-2`}>
                    Button Text
                    <input
                      className="p-2 mt-1 rounded-md border-2 border-gray-200 w-full"
                      onChange={(e) => handleChangeTitle(e, index)}
                      value={review_link?.title}
                    />
                  </label>
                </div>
                <div className="h-16 w-20 mt-6 relative text-sm flex justify-center items-center">
                  {loading && loadingIndex === index ? (
                    <div className="mt-4 p-2 w-12 flex justify-center items-center">
                      <img src="/loading.gif" width="25px" />
                    </div>
                  ) : review_link?.icon ? (
                    <>
                      <div className="mt-4 p-2 w-12 flex justify-center items-center">
                        <img
                          src={review_link.icon}
                          alt="icon"
                          className="h-12"
                        />
                      </div>
                      <button
                        onClick={() => {
                          const confirmation = prompt(
                            "Are you sure you want to delete this icon?",
                            "yes"
                          );
                          if (confirmation) handleDeleteIcon(index);
                        }}
                      >
                        <IoCloseCircle
                          size={20}
                          className="absolute right-0 top-0 text-red-300 hover:text-red-500 cursor-pointer"
                        />
                      </button>
                    </>
                  ) : (
                    <UploadFile
                      onDropHandler={(e) => handleDropFileInput(e, index)}
                      onChangeHandler={(files) =>
                        handleChangeFileInput(files, index)
                      }
                      accept=".svg"
                      title="Upload .svg"
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="flex items-center mt-4">
        <button
          className={
            "mt-2 border rounded-lg text-white bg-blue-800 py-2 px-4 text-center" +
            (loading
              ? " opacity-25 cursor-not-allowed bg-gray-300"
              : " hover:bg-blue-600 cursor-pointer")
          }
          disabled={loading}
          onClick={handleAdd}
        >
          Add Review Link
        </button>
      </div>
    </>
  );
};

export default CustomReviewLink;
