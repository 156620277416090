/* eslint-disable */
import { useEffect } from "react";
import {
  borderedInputWhite,
  boxContainer,
  label,
} from "../../../shared/styles";
import Props from "../../../store/props";
import CustomReviewLink from "./CustomReviewLinks";

const AdditionalLinks: React.FC<Props> = ({ setGlobalState, globalState }) => {
  const {
    review_link,
    leave_review_link_header,
    review_link_more,
    see_more_reviews_header,
    leave_review_link_3_header,
    leave_review_link_3,
    yelp_review_link,
    other_review_link_header,
  } = globalState;


  // useEffect(() => {
  //     // if (!leave_review_link_header)
  //     //     setTimeout(
  //     //         () =>
  //     //             setGlobalState({
  //     //                 ...globalState,
  //     //                 leave_review_link_header: 'Google',
  //     //             }),
  //     //         100
  //     //     ) // if they all fire at once the last one overrides the others and only the last one gets set
  //     // if (!see_more_reviews_header)
  //     //     setTimeout(
  //     //         () =>
  //     //             setGlobalState({
  //     //                 ...globalState,
  //     //                 see_more_reviews_header: 'Yelp',
  //     //             }),
  //     //         200
  //     //     )
  //     // if (!leave_review_link_3_header)
  //     //     setTimeout(
  //     //         () =>
  //     //             setGlobalState({
  //     //                 ...globalState,
  //     //                 leave_review_link_3_header: 'Facebook',
  //     //             }),
  //     //         300
  //     //     )
  //     //     if(!review_link && google) setTimeout(() => setGlobalState({...globalState, review_link: `https://${google}`}),400)
  //     //     if(!review_link_more && yelp) setTimeout(() => setGlobalState({...globalState, review_link_more: `https://${yelp}`}),500)
  //     // if (!leave_review_link_3)
  //     //     setTimeout(
  //     //         () =>
  //     //             setGlobalState({
  //     //                 ...globalState,
  //     //                 leave_review_link_3: `${facebook}/reviews`,
  //     //             }),
  //     //         600
  //     //     )
  // }, [])

  return (
    <>
      <h2 className="absolute top-32 left-24">Additional Links</h2>
      <div className={boxContainer}>
        <div className="flex justify-between">
          <label className={`${label} w-full`}>
            Google Review Link
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  review_link: e.target.value,
                })
              }
              value={review_link}
            />
          </label>
          <label className={`${label} w-full ml-6`}>
            Button Text
            <input
              className={borderedInputWhite}
              onChange={(e) => {
                setGlobalState({
                  ...globalState,
                  leave_review_link_header: e.target.value,
                });
              }}
              value={leave_review_link_header}
            />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={`${label} w-full`}>
            Facebook Review Link
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  leave_review_link_3: e.target.value,
                })
              }
              value={leave_review_link_3}
            />
          </label>
          <label className={`${label} w-full ml-6`}>
            Button Text
            <input
              className={borderedInputWhite}
              onChange={(e) =>
                setGlobalState({
                  ...globalState,
                  leave_review_link_3_header: e.target.value,
                })
              }
              value={leave_review_link_3_header}
            />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={`${label} w-full`}>
              Yelp Review Link
              <input
                  className={borderedInputWhite}
                  onChange={(e) =>
                      setGlobalState({
                          ...globalState,
                          yelp_review_link: e.target.value,
                      })
                  }
                  value={yelp_review_link}
              />
          </label>
          <label className={`${label} w-full ml-6`}>
              Button Text
              <input
                  className={borderedInputWhite}
                  onChange={(e) =>
                      setGlobalState({
                          ...globalState,
                          see_more_reviews_header: e.target.value,
                      })
                  }
                  value={see_more_reviews_header}
              />
          </label>
        </div>
        <div className="flex justify-between">
          <label className={`${label} w-full`}>
              Other Review Link
              <input
                  className={borderedInputWhite}
                  onChange={(e) =>
                      setGlobalState({
                          ...globalState,
                          review_link_more: e.target.value,
                      })
                  }
                  value={review_link_more}
              />
          </label>
          <label className={`${label} w-full ml-6`}>
              Button Text
              <input
                  className={borderedInputWhite}
                  onChange={(e) =>
                      setGlobalState({
                          ...globalState,
                          other_review_link_header: e.target.value,
                      })
                  }
                  value={other_review_link_header}
              />
          </label>
        </div>
        <CustomReviewLink />
      </div>
    </>
  );
};

export default AdditionalLinks;
