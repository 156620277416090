/* eslint-disable */
import * as Heroicons from "@heroicons/react/24/solid";
import { useAtom } from "jotai";
import globalState from "../../../../store";
import { useEffect, useMemo, useState } from "react";

const icons: string[] = [];
for (const key in Heroicons) {
  icons.push(key);
}

const AnnouncementIcon: React.FC = () => {
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);
  const [query, setQuery] = useState("");
  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });

  const pascalToKebab = (str: string): string => {
    if (!str) return "";
    const kebabStr = str
      .replace("2X2", "2x2")
      .replace("3D", "-3d")
      .split("")
      .map((char, index) => {
        if (/[A-Z]/.test(char)) {
          const newChar =
            index === 0 ? char.toLowerCase() : "-" + char.toLowerCase();
          return newChar;
        }
        return char;
      })
      .join("");
    const match = /\d+/.exec(kebabStr);
    if (!match) return kebabStr;
    return kebabStr.slice(0, match.index) + "-" + kebabStr.slice(match.index);
  };

  const getSelectedIconPascalCaseName = (
    icons: string[],
    selectedIconNameInKebab: string
  ): keyof typeof Heroicons => {
    if (!selectedIconNameInKebab) return "" as keyof typeof Heroicons;
    return icons.find((iconName: string) => {
      const iconNameInKebab = pascalToKebab(iconName).replace("-icon", "");
      return iconNameInKebab === selectedIconNameInKebab;
    }) as keyof typeof Heroicons;
  };

  const selectedIconPascalCaseName: keyof typeof Heroicons =
    getSelectedIconPascalCaseName(icons, globalStateAtom?.announcement?.icon);

  const SelectedIcon = Heroicons[selectedIconPascalCaseName];

  useEffect(() => {
    const handleClick = () => setClicked(false);
    if (clicked === true) {
      document.addEventListener("click", handleClick);
    }
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [clicked]);

  const handleClickOpenIconSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setClicked(true);
    setPoints({
      x: e.pageX,
      y: e.pageY,
    });
  };

  const handleIconSelect = (value: string) => {
    setGlobalStateAtom({
      ...globalStateAtom,
      announcement: {
        ...globalStateAtom?.announcement,
        icon: value,
      },
    });
  };

  const filteredIcons = useMemo(() => {
    return icons.filter((icon) =>
      icon.toLowerCase().includes(query.toLowerCase())
    );
  }, [query]);

  return (
    <>
      <div
        onClick={handleClickOpenIconSelect}
        title={globalStateAtom?.announcement?.icon}
        className={
          "w-16 h-16 rounded-md cursor-pointer flex justify-center items-center " +
          (selectedIconPascalCaseName
            ? "border-solid border-green-600 border-4"
            : "border border-dashed border-gray-300")
        }
      >
        {selectedIconPascalCaseName ? (
          <SelectedIcon className="h-12 w-12" />
        ) : (
          <span className="font-mono text-gray-300">ICON</span>
        )}
      </div>
      {clicked && (
        <div className="h-64 w-64 overflow-auto border border-gray-300 rounded-md absolute bg-gray-200">
          <input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="mt-2 border-0 rounded-md p-2"
            onClick={(e) => e.stopPropagation()}
          />
          <ul
            className="gap-2 p-4 grid grid-cols-5"
            style={{ top: points.y + "px", left: points.x + "px" }}
          >
            {filteredIcons.map((icon) => {
              const Icon = Heroicons[icon as keyof typeof Heroicons];
              const iconNameInKebab = pascalToKebab(icon).replace("-icon", "");
              return (
                <li key={icon}>
                  <button
                    onClick={handleIconSelect.bind(this, iconNameInKebab)}
                  >
                    <Icon
                      title={iconNameInKebab}
                      className={
                        "h-8 w-8 text-gray-700 rounded-md p-1 cursor-pointer bg-white" +
                        (iconNameInKebab === globalStateAtom?.announcement?.icon
                          ? " border-green-600 border-4 text-green-600 box-border transform scale-150"
                          : "")
                      }
                    />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default AnnouncementIcon;
