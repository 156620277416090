/* eslint-disable */
import { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import { IoCloseCircle } from "react-icons/io5";
import Props from "../../../store/props";
import { borderedInputWhite } from "../../../shared/styles";
import { useAtom } from "jotai";
import globalState from "../../../store";

interface ServiceProps {
  faq: {
    answer: string;
    "landing-page": number;
    order: number;
    question: string;
    draggableId: string;
  };
  // localFaqs: any
  // setLocalFaqs: React.Dispatch<React.SetStateAction<ItemProps[]>>
  draggableId: string;
  handleDelete: () => void;
  index: number;
}

interface ItemProps {
  answer: string;
  "landing-page": number;
  order: number;
  question: string;
  draggableId: string;
}

const Service: React.FC<ServiceProps> = ({
  faq,
  draggableId,
  // setLocalFaqs,
  handleDelete,
  index,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  // const { phone } = globalStateAtom

  const [faqstate, setFaq] = useState(globalStateAtom.faqs[index]) as any;

  // useEffect(() => {
  //     setFaq(
  //         globalStateAtom.faqs &&
  //         globalStateAtom.faqs[index] &&
  //         globalStateAtom.faqs[index].answer &&
  //         globalStateAtom.faqs[index].answer.includes(
  //                 'Give us a call or send us a text at'
  //             )
  //             ? {
  //                   ...globalStateAtom.faqs[index] as any,
  //                   answer: `Give us a call or send us a text at ${globalStateAtom.phone}. Thanks!`,
  //               }
  //             : globalStateAtom.faqs[index]
  //     )
  // }, [])

  return (
    // the ternary in draggableId makes sure the newly created service has a draggableId no matter what
    // and can therefore be dragged and dropped
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        // 'provided' is a built-in ref of the beautiful-dnd library that keeps track of the element
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="flex flex-col items-start w-full py-3 m-2 rounded-lg border-2 border-gray-100 bg-white"
        >
          <div
            onClick={() => {
              const confirmation = prompt(
                "Are you sure you want to delete this FAQ?",
                "yes"
              );
              if (confirmation) handleDelete();
            }}
            className="rounded-full w-full flex"
          >
            <IoCloseCircle
              color="#E5E7EB"
              size={30}
              className="absolute right-50"
            />
          </div>
          {/* TODO: change the labels to divs and include the 'Generate' p tag */}
          <label className="flex justify-between my-3 px-6 w-full items-center">
            <p className="mr-3">FAQ</p>
            <textarea
              className={borderedInputWhite}
              onChange={(e) => {
                const thisFaq = globalStateAtom.faqs[index] as any;
                thisFaq.question = e.target.value;
                const newFaqs = [...globalStateAtom.faqs] as any;
                newFaqs[index] = thisFaq;
                setGlobalState({
                  ...globalStateAtom,
                  faqs: newFaqs,
                });
              }}
              value={faq.question}
              rows={2}
            />
          </label>
          <label className="flex justify-between my-3 px-6 w-full items-center">
            <p className="mr-3">Answer</p>
            <textarea
              className={borderedInputWhite}
              onChange={(e) => {
                const thisFaq = globalStateAtom.faqs[index] as any;
                thisFaq.answer = e.target.value;
                const newFaqs = [...globalStateAtom.faqs] as any;
                newFaqs[index] = thisFaq;
                setGlobalState({
                  ...globalStateAtom,
                  faqs: newFaqs,
                });
              }}
              value={faq.answer}
            />
          </label>
        </div>
      )}
    </Draggable>
  );
};

export default Service;
