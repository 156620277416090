/* eslint-disable */
import { useAtom } from "jotai";
import { pageContainer } from "../../shared/styles";
import globalState from "../../store";
import Logo from "./components/Logo";
// import ColorScheme from "./components/ColorScheme"

export default function PreviewPage() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  return (
    <div className={pageContainer}>
      {/* <iframe
                height="100%"
                width="100%"
                src={`${process.env.REACT_APP_SERVER_URL}/${globalStateAtom.slug}`}
                title="W3Schools Free Online Web Tutorials"
            /> */}
      {/* <iframe height="100%" width="100%" src={`https://www.prosite.prophone.com/${globalStateAtom.slug}`} title="W3Schools Free Online Web Tutorials"></iframe> */}
    </div>
  );
}
