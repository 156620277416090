/* eslint-disable */
import { useState, useEffect } from "react";
import AuthForm from "./components/AuthForm";
import VerificationForm from "./components/VerificationForm";

const AuthPage: React.FC = () => {
  const [formNum, setFormNum] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [form, setForm] = useState(<AuthForm setFormNum={setFormNum} />);

  useEffect(() => {
    if (formNum === 1) setForm(<AuthForm setFormNum={setFormNum} />);
    else setForm(<VerificationForm setFormNum={setFormNum} />);
  }, [formNum]);

  return form;
};

export default AuthPage;
