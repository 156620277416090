import globalState from "../../../store";
import { useAtom } from "jotai";
import {
  borderedInputWhite,
  boxContainer,
  label,
} from "../../../shared/styles";
import AddServicePackageButton from "./AddServicePackageButton";
import AddFooterButton from "./AddFooterButton";
import AddServiceProductButton from "./AddServiceProductButton";
import { IoCloseCircle } from "react-icons/io5";
import { IService, IServicePackage } from "../../../types/types";

interface ServicePackagesProps {
  service: IService;
  // eslint-disable-next-line
  setActiveService: React.Dispatch<React.SetStateAction<any>>;
}

const ServicePackages: React.FC<ServicePackagesProps> = ({
  service,
  setActiveService,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const servicePackages = service.packages || [];

  const updateServiceProductColView = (packageId: number | string) => {
    const newServicePackages = servicePackages.map(
      (servicePackage: IServicePackage) => {
        if (servicePackage.id === packageId) {
          return {
            ...servicePackage,
            service_product_col_view: !servicePackage.service_product_col_view,
          };
        }
        return servicePackage;
      }
    );

    const newService = { ...service, packages: newServicePackages };
    setGlobalState({
      ...globalStateAtom,
      services: globalStateAtom.services.map((s: IService) =>
        s.order === service.order ? newService : s
      ),
    });
    // Should get rid of this pattern
    setActiveService(newService);
  };

  const handleDelete = (
    packageIndex: number,
    field: string,
    childIndex: number | null = null
  ) => {
    const newServicePackages = [...servicePackages];

    if (field === "servicePackages") {
      console.log("field", field);
      console.log("servicePackages", servicePackages)
      newServicePackages.splice(packageIndex, 1);
      console.log("newServicePackages", newServicePackages)
    } else if (field === "serviceFooters") {
      if (childIndex)
        newServicePackages[packageIndex].service_footers.splice(childIndex, 1);
    } else if (field === "serviceProduct") {
      if (childIndex)
        newServicePackages[packageIndex].service_products.splice(childIndex, 1);
    }

    const newService = { ...service, packages: newServicePackages };

    setGlobalState({
      ...globalStateAtom,
      services: globalStateAtom.services.map((s) =>
        s["service-name"] === service["service-name"] ? newService : s
      ),
    });

    // Should get rid of this pattern
    setActiveService(newService);
  };

  async function updateServicePackages(
    packageIndex: number,
    changedValue: string,
    field: string,
    childIndex: number | null = null
  ) {
    const newServicePackages = [...servicePackages];

    if (childIndex !== null) {
      if (field === "service_footers") {
        newServicePackages[packageIndex].service_footers[childIndex].note =
          changedValue;
      } else if (
        field === "name" ||
        field === "price" ||
        field === "description"
      ) {
        // all other cases are for service_products objects
        newServicePackages[packageIndex].service_products[childIndex][field] =
          changedValue;
      }
    } else if (field === "service_package_title") {
      // update service package title
      newServicePackages[packageIndex][field] = changedValue;
    }

    const newService = { ...service, packages: newServicePackages };

    setGlobalState({
      ...globalStateAtom,
      services: globalStateAtom.services.map((s: IService) =>
        s.order === service.order ? newService : s
      ),
    });

    // Should get rid of this pattern
    setActiveService(newService);
  }

  return (
    <div className={`${boxContainer} w-full flex mt-3 mb-3 ml-6 p-2`}>
      {servicePackages?.map((p, packageIndex) => {
        return (
          <div
            key={p.id}
            className={`${borderedInputWhite} flex flex-col flex-1`}
          >
            <div
              onClick={() => {
                const confirmation = prompt(
                  "Are you sure you want to delete this Service Package?",
                  "yes"
                );
                if (confirmation) handleDelete(packageIndex, "servicePackages");
              }}
              className="rounded-full w-full flex m-4 relative"
            >
              <IoCloseCircle
                color="#E5E7EB"
                size={30}
                className="absolute right-0 m-3"
              />
            </div>
            <label className={label}>
              Title
              <input
                className={borderedInputWhite}
                value={p?.service_package_title}
                onChange={(e) =>
                  updateServicePackages(
                    packageIndex,
                    e.target.value,
                    "service_package_title"
                  )
                }
              />
            </label>
            <label className={label}>
              Footer(s)
              {p?.service_footers.map((f, footerIndex) => {
                return (
                  <div
                    key={f?.id}
                    className="flex justify-between my-3 px-6 w-full items-center"
                  >
                    <div
                      onClick={() => {
                        handleDelete(
                          packageIndex,
                          "serviceFooters",
                          footerIndex
                        );
                      }}
                    >
                      <IoCloseCircle
                        color="#E5E7EB"
                        size={30}
                        className="relative right-0 m-3"
                      />
                    </div>
                    <input
                      className={borderedInputWhite}
                      value={f?.note}
                      onChange={(e) =>
                        updateServicePackages(
                          packageIndex,
                          e.target.value,
                          "service_footers",
                          footerIndex
                        )
                      }
                    />
                  </div>
                );
              })}
            </label>
            <AddFooterButton
              service={service}
              servicePackageId={p?.id}
              setActiveService={setActiveService}
            />
            <label className="flex justify-center items-center">
              Display Service Products in Two Cols
              <input
                type="checkbox"
                className="ml-3"
                onChange={() => updateServiceProductColView(p?.id)}
                checked={p?.service_product_col_view}
              />
            </label>
            <label className={label}>
              Service Product(s)
              {p?.service_products.map((f, productIndex) => (
                <div
                  key={productIndex}
                  className={`${borderedInputWhite} input-group`}
                >
                  <div
                    onClick={() => {
                      const confirmation = prompt(
                        "Are you sure you want to delete this Service Product?",
                        "yes"
                      );
                      if (confirmation)
                        handleDelete(
                          packageIndex,
                          "serviceProduct",
                          productIndex
                        );
                    }}
                    className="rounded-full w-full flex m-1 relative"
                  >
                    <IoCloseCircle
                      color="#E5E7EB"
                      size={30}
                      className="absolute right-0 m-2"
                    />
                  </div>
                  <label className="flex justify-between my-3 px-6 w-full items-center">
                    <p className="mr-3">Name: </p>
                    <input
                      className={borderedInputWhite}
                      value={f?.name}
                      onChange={(e) =>
                        updateServicePackages(
                          packageIndex,
                          e.target.value,
                          "name",
                          productIndex
                        )
                      }
                    />
                  </label>
                  <label className="flex justify-between my-3 px-6 w-full items-center">
                    <p className="mr-3">Price: </p>
                    <input
                      className={borderedInputWhite}
                      value={f?.price}
                      onChange={(e) =>
                        updateServicePackages(
                          packageIndex,
                          e.target.value,
                          "price",
                          productIndex
                        )
                      }
                    />
                  </label>
                  <label className="flex justify-between my-3 px-6 w-full items-center">
                    <p className="mr-3">Description: </p>
                    <input
                      className={borderedInputWhite}
                      value={f?.description}
                      onChange={(e) =>
                        updateServicePackages(
                          packageIndex,
                          e.target.value,
                          "description",
                          productIndex
                        )
                      }
                    />
                  </label>
                </div>
              ))}
            </label>
            <AddServiceProductButton
              service={service}
              servicePackageId={p?.id}
              setActiveService={setActiveService}
            />
          </div>
        );
      })}
      <div>
        <AddServicePackageButton
          service={service}
          setActiveService={setActiveService}
        />
      </div>
    </div>
  );
};

export default ServicePackages;
