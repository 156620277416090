import React, { useState } from "react";
import { useAtom } from "jotai";
import globalState from "../../../store";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { IDefaultService, IService } from "../../../types/types";

interface InActiveService {
  inactiveIndustryServices: IDefaultService[];
}

const InActiveService: React.FC<InActiveService> = ({
  inactiveIndustryServices,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { services, sitePk, gallery, company_type, company_name } =
    globalStateAtom;
  const [loading, setLoading] = useState(false);

  const blankServiceObject: IService = {
    "landing-page": sitePk,
    order: services && services.length ? services.length : 0,
    "service-description": "",
    "service-description-long": "",
    "service-name": `NEW SERVICE`,
    draggableId: uuidv4(),
    photos: [],
    include_in_socials: true,
    packages: [],
    primary_keyword: "",
    keywords: [],
  };

  // Click event to add a new service
  const addNewService = async (selectedService: IDefaultService) => {
    setLoading(true);
    try {
      const newGall = [...gallery];
      let gpt3ServiceDesc = "";
      let gpt3ServiceDescLong = "";
      const gpt3ServiceDescRes = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/services/generate-description`,
        {
          params: {
            type: "short",
            industry: company_type,
            service: selectedService.service_name.replace("/", " "),
            company_name: company_name,
            primary_keyword: selectedService.primary_keyword,
            keywords: selectedService.keywords,
          },
        }
      );
      const gpt3ServiceDescLongRes = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/services/generate-description`,
        {
          params: {
            type: "longV2",
            industry: company_type,
            service: selectedService.service_name.replace("/", " "),
            company_name: company_name,
            primary_keyword: selectedService.primary_keyword,
            keywords: selectedService.keywords,
          },
        }
      );
      gpt3ServiceDesc = gpt3ServiceDescRes.data.message;
      gpt3ServiceDescLong = gpt3ServiceDescLongRes.data.message;
      const currentBlankService = {
        ...blankServiceObject,
        "service-name": selectedService.service_name,
        "service-description": gpt3ServiceDesc,
        "service-description-long": gpt3ServiceDescLong,
        photos: [1, 2, 3].map(() => {
          const randNum = Math.floor(Math.random() * newGall.length);
          const randPhoto = newGall[randNum];
          newGall.splice(randNum, 1); // so there's never two of the same photo
          return {
            ...randPhoto,
            stock: false,
          };
        }),
        primary_keyword: selectedService.primary_keyword,
        keywords: selectedService.keywords,
      };
      setGlobalState({
        ...globalStateAtom,
        services: [...services, currentBlankService],
      });
    } catch {
      alert("Error adding default service");
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col text-start align-middle  justify-center ">
      {loading && <img src="/loading.gif" width="20px" />}
      {inactiveIndustryServices && (
        <>
          {inactiveIndustryServices &&
            inactiveIndustryServices.map((service, idx: number) => {
              return (
                <button
                  key={idx}
                  onClick={() => addNewService(service)}
                  value={service["service_name"]}
                  className="border-2 rounded-md p-2 my-1 text-gray-800"
                >
                  {service["service_name"]}
                </button>
              );
            })}
        </>
      )}
    </div>
  );
};

export default InActiveService;
