interface GenerateProps {
  loading: boolean;
  generate: () => void;
  buttonName?: string;
}

const Generate: React.FC<GenerateProps> = ({
  loading,
  generate,
  buttonName = "Generate",
}) => {
  return (
    <div className="generate-button" id={buttonName} onClick={generate}>
      {loading ? (
        <img src="/loading.gif" width="20" alt="loading" />
      ) : (
        <p className="cursor-pointer">{buttonName}</p>
      )}
    </div>
  );
};

export default Generate;
