/* eslint-disable */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import globalState from "../../../store";
import PhotoProps from "../types/photoProps";
import Props from "../../../store/props";
import DeleteButton from "./DeleteButton";
import UncategorizeButton from "./UncategorizeButton";
// import AddFaqButton from './components/AddFAQButton'

interface GalleriesButtonsProps {
  localGalleryNames: any;
  addSelectedPhotosToGallery: React.MouseEventHandler<HTMLButtonElement>;
  // addSelectedPhotosToGallery: (item:any, selectedPhotos:any) => void
  setSelectedPhotos: React.Dispatch<React.SetStateAction<any>>;
  selectedPhotos: PhotoProps[];
}

const GalleriesButtons: React.FC<GalleriesButtonsProps> = ({
  localGalleryNames,
  selectedPhotos,
  addSelectedPhotosToGallery,
  setSelectedPhotos,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { gallery_names, gallery, services, fbData } = globalStateAtom;

  // needed to move this into a component which had received globalState as a prop so it wouldn't run into a TypeScript error
  // (but there is a hacky way to avoid this in the useeffects in index)
  // ALL THIS DOES IS UPDATE THE GLOBAL STATE AFTER addSelectedPhotosToGallery() changes the gallery_names of the selectedPhotos
  const updateGlobalGalleryState = (e: any) => {
    // // console.log('here')
    let galleryName = e.target.innerHTML;
    if (galleryName === "Uncategorize") galleryName = "";
    const selectedItems = { ...selectedPhotos };

    // Adding selected photos (which have the new gallery names already thanks to addSelectedPhotosToGallery in index) to gallery
    const newGallery: PhotoProps[] = [...gallery];
    // // Make an object to check if a photo already exists in the gallery (and if so, don't add it again or identical keys/ids start messing things up)
    // let newGalleryCache:any = {}
    // for(let j = 0; j < newGallery.length; j++) {
    //     newGalleryCache[newGallery[j].photo] = newGallery[j].photo
    // }
    // // console.log(selectedItems, 'selectedItems')
    for (let i = 0; i < newGallery.length; i++) {
      const item: any = newGallery[i] && newGallery[i].photo;
      if (selectedItems[item]) {
        // && !newGalleryCache[i]
        newGallery[i] = selectedItems[item];
        // Remove item from selectedItems object so we are left with only facebook photos (to process in the next loop below)
        delete selectedItems[item];
      }
    }

    // Adding facebook photos
    for (let i = 0; i < Object.keys(selectedItems).length; i++) {
      try {
        const fbPhoto = {
          alt_tag: services[0]["service-name"], // TODO: change this
          archived: false,
          associated_service: services[0]["service-name"],
          gallery: null,
          photo: Object.keys(selectedItems)[i],
          landing_page: globalStateAtom.sitePk,
          order: i + newGallery.length, // TODO: probably change this
          uncropped_gallery_photo: null,
          gallery_name: galleryName,
          selected: false,
          caption: "",
        };
        newGallery[i + newGallery.length] = fbPhoto;
      } catch (e) {
        // console.log(e)
      }
    }

    setGlobalState({ ...globalStateAtom, gallery: newGallery });
    setSelectedPhotos({});
  };

  return (
    <div className="w-fit fixed z-10 bg-gray-200 bg-opacity-70 -mt-3 py-3 px-6">
      <div className="w-full flex flex-row flex-wrap justify-center items-center">
        <UncategorizeButton
          addSelectedPhotosToGallery={addSelectedPhotosToGallery}
          selectedPhotos={selectedPhotos}
        />
        {localGalleryNames &&
          localGalleryNames.map((gallery: any, i: any) => {
            // console.log('gallery',gallery)
            return (
              <button
                key={gallery.gallery_name}
                onClick={(e) => {
                  // Remove FB photos from the localFBPhotosGallery when they are added to a gallery
                  // let newFBGallery:any[] = [...localFBGalleryPhotos]
                  // for(let i = 0; i < fbData.photos.length; i++) {
                  //     const item:any = fbData.photos[i]
                  //     if(selectedPhotos[item]) {
                  //         // find the service with the matching 'order' property
                  //         const posOfThisItem = newFBGallery.findIndex(
                  //             photo => photo === selectedPhotos[item].photo
                  //         )
                  //         newFBGallery.splice(posOfThisItem,1)
                  //         // console.log(newFBGallery.length, 'lehngthnsd')
                  //     }
                  // }
                  // setLocalFBGalleryPhotos(newFBGallery)
                  // setGlobalState({...globalState,fbData:{...fbData, photos:newFBGallery}})
                  updateGlobalGalleryState(e);
                  addSelectedPhotosToGallery(e);
                }}
                className="px-2  m-3 rounded-full border-2 text-white bg-gray-500"
              >
                {gallery["gallery-name"]}
              </button>
            );
          })}
        <DeleteButton
          addSelectedPhotosToGallery={addSelectedPhotosToGallery}
          selectedPhotos={selectedPhotos}
        />
      </div>
    </div>
  );
};

export default GalleriesButtons;
