/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect } from "react";
import { boxContainerFull, button } from "../../../shared/styles";
import ThumbnailSelectable from "../../../shared/ThumbnailSelectable";
import PhotoProps from "../types/photoProps";
import {
  getSignedRequest,
  uploadExternalImageUrl,
} from "../../../utils/helpers/uploadFileUtil";
import globalState from "../../../store";
import { useAtom } from "jotai";
import { ListManager } from "react-beautiful-dnd-grid";
import UploadImages from "../../services/components/upload/UploadImages";
import { getExternalPhotoUrl } from "../../../utils/helpers/getExternalPhotoUrl";
import { getJpegFileFromHeic } from "./utils";
import {
  checkApplePatentImageExtension,
  getFileExtensionFromType,
} from "../../logo/components/utils";
import { IoTrashOutline } from "react-icons/io5";


function cleanPhotosOrder(list: any) {
  return list.map((el: any, i: any) => {
    el.order = i;
    return el;
  });
}

function sortList(list: any) {
  return JSON.parse(JSON.stringify(list)).sort(
    (first: any, second: any) => first.order - second.order
  );
}

interface CoverPhotoProps {
  handleSelect: any;
  rerenderAfterDeletePhoto: (item: PhotoProps) => void;
  setSelectedPhotos: React.Dispatch<React.SetStateAction<any>>;
  selectedPhotos: any;
}

const CoverPhoto: React.FC<CoverPhotoProps> = ({
  setSelectedPhotos,
  selectedPhotos,
  handleSelect,
  rerenderAfterDeletePhoto,
}) => {
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);

  const { services, company_name, hero_gallery_photos, cover_upload, version } =
    globalStateAtom;
  const [loading, setLoading] = useState(false);
  // uses local state to make sure photos dis/appear live
  const [coverVersion, setCoverVersion] = useState<string>("v2");

  useEffect(() => {
    version === "v1" ? setCoverVersion("v1") : setCoverVersion("v2");
  }, [version]);

  function arrayMove(arr: Array<any>, fromIndex: number, toIndex: number) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  }

  const reorderList = (sourceIndex: number, destinationIndex: number) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    const list = [...hero_gallery_photos];
    const reorderedList = arrayMove(list, sourceIndex, destinationIndex);
    const reMappedList = cleanPhotosOrder(reorderedList);

    setGlobalStateAtom({
      ...globalStateAtom,
      hero_gallery_photos: [...reMappedList],
    });
  };

  const addSelectedPhotosToCover = () => {
    const newCoverPhotos = cleanPhotosOrder([
      ...hero_gallery_photos,
      ...Object.values(selectedPhotos),
    ]);
    setGlobalStateAtom({
      ...globalStateAtom,
      hero_gallery_photos: newCoverPhotos,
    });
    setSelectedPhotos({});
  };

  const handleDelete = (photo: string) => {
    // make placeholder array for added image
    let newImagesArray = [...hero_gallery_photos];
    // find position of selected image
    const posOfImage = newImagesArray.findIndex((img) => img.photo === photo);
    // splice that image out of the array
    newImagesArray.splice(posOfImage, 1);
    // clean order fields
    newImagesArray = cleanPhotosOrder(newImagesArray);
    // set the state of images to the spliced array
    setGlobalStateAtom({
      ...globalStateAtom,
      hero_gallery_photos: newImagesArray,
    });
  };

  const addImageToState = (imageUrl: any) => {
    setGlobalStateAtom((prevState) => ({
      ...prevState,
      hero_gallery_photos: [...prevState.hero_gallery_photos, {
        photo: imageUrl,
        order: prevState.hero_gallery_photos.length,
        alt_tag: "",
      }],
    }));
  };

  const onChangeHandler = async (files: any) => {
    setLoading(true);

    for (let i = 0; i < files!.length; i++) {
      let curFile = files![i];
      if (/image\//.test(curFile?.type) === true) {
        const fileExtension = getFileExtensionFromType(curFile.type);
        if (checkApplePatentImageExtension(fileExtension)) {
          curFile = await getJpegFileFromHeic(curFile);
        }
      }
      const uploadedImageUrl = URL.createObjectURL(curFile);
      if (uploadedImageUrl) {
        const signedS3Photo = await getSignedRequest(curFile);
        const completePhotoUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${signedS3Photo}`;
        addImageToState(completePhotoUrl);
      }
    }
    setLoading(false);
  };
  const onDropHandler = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const files = event.dataTransfer.files;

    if (files.length > 0) {
      await onChangeHandler(files);
    } else {
      const url = await getExternalPhotoUrl(event.dataTransfer.getData("url"));
      if (url) {
        const uploadedLogoDataFileName = await uploadExternalImageUrl(url);

        if (uploadedLogoDataFileName) {
          const uploadedLogoDataUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${uploadedLogoDataFileName}`;
          addImageToState(uploadedLogoDataUrl);
        }
      }
    }
    setLoading(false);
  };

  const onVersionChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoverVersion(e.target.value);
  };

  const onV1ChangeHandler = async (files) => {
    setLoading(true);
    console.log(files);
    if (files && files[0]) {
      let curFile = files[0];
      if (/image\//.test(curFile?.type) === true) {
        const fileExtension = getFileExtensionFromType(curFile.type);
        if (checkApplePatentImageExtension(fileExtension)) {
          curFile = await getJpegFileFromHeic(curFile);
        }
      }
      const uploadedImageUrl = URL.createObjectURL(curFile);
      if (uploadedImageUrl) {
        const signedS3Photo = await getSignedRequest(curFile);
        const completePhotoUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${signedS3Photo}`;
        setGlobalStateAtom({
          ...globalStateAtom,
          cover_upload: completePhotoUrl,
        });
      }
    }

    setLoading(false);
  };

  const onV1DropHandler = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const files = event.dataTransfer.files;

    if (files.length > 0) {
      await onV1ChangeHandler(files);
    } else {
      const url = await getExternalPhotoUrl(event.dataTransfer.getData("url"));
      if (url) {
        const uploadedLogoDataFileName = await uploadExternalImageUrl(url);

        if (uploadedLogoDataFileName) {
          const uploadedLogoDataUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${uploadedLogoDataFileName}`;
          setGlobalStateAtom({
            ...globalStateAtom,
            cover_upload: uploadedLogoDataUrl,
          });
        }
      }
    }
    setLoading(false);
  };

  const onAddV1Photo = () => {
    setGlobalStateAtom({
      ...globalStateAtom,
      cover_upload: Object.values(selectedPhotos)[0].photo,
    });
    setSelectedPhotos({});
  };

  useEffect(() => {
    const cleanedPhotos = cleanPhotosOrder(sortList(hero_gallery_photos));
    setGlobalStateAtom({
      ...globalStateAtom,
      hero_gallery_photos: cleanedPhotos,
    });
  }, []);

  return (
    <>
      <h2>Cover Photo</h2>
      <div
        onChange={onVersionChangeHandler}
        className="bg-gray-200 mt-12 ml-12 flex justify-start"
      >
        <label
          htmlFor="v2"
          className={`block py-2 w-24 text-center rounded-md rounded-bl-none rounded-br-none cursor-pointer ${
            coverVersion === "v2" ? "bg-white" : "bg-gray-100 border border-b-0"
          }`}
        >
          v2+
        </label>
        <input
          id="v2"
          type="radio"
          value="v2"
          name="version"
          className="hidden"
        />
        <label
          htmlFor="v1"
          className={`block py-2 w-24 text-center rounded-md rounded-bl-none rounded-br-none cursor-pointer ${
            coverVersion === "v1" ? "bg-white" : "bg-gray-100 border border-b-0"
          }`}
        >
          v1
        </label>
        <input
          id="v1"
          type="radio"
          value="v1"
          name="version"
          className="hidden"
        />
      </div>
      <div className={boxContainerFull + " mt-0 rounded-tl-none"}>
        {loading && <img src="/loading.gif" width="25px" />}
        {coverVersion === "v2" && (
          <div className="flex flex-col">
            <div className="h-32 w-full">
              <UploadImages
                onChangeHandler={onChangeHandler}
                onDropHandler={onDropHandler}
                multiple={true}
              />
            </div>
            <div className="flex flex-row justify-start my-8">
              <ListManager
                items={hero_gallery_photos}
                direction="horizontal"
                onDragEnd={reorderList}
                maxItems={5}
                render={(photo) => {
                  return (
                    <ThumbnailSelectable
                      key={photo.photo}
                      photo={photo}
                      handleDelete={handleDelete}
                      handleSelect={handleSelect}
                      selectedPhotos={selectedPhotos}
                      rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
                      draggable={false}
                    />
                  );
                }}
              />
            </div>
            <div className="items-center">
              <button className={button} onClick={addSelectedPhotosToCover}>
                + Add Selected Photos
              </button>
            </div>
          </div>
        )}
        {coverVersion === "v1" && (
          <>
            <div className="flex space-x-8 items-center">
              <div className="-mt-1 h-44 w-1/3">
                <UploadImages
                  onChangeHandler={onV1ChangeHandler}
                  onDropHandler={onV1DropHandler}
                />
              </div>

              {cover_upload && (
                <div
                  onClick={() =>
                    setGlobalStateAtom({ ...globalStateAtom, cover_upload: "" })
                  }
                  className="relative"
                >
                  <div className="absolute top-0 right-0 w-8 h-8 mt-2 mr-2 px-1 cursor-pointer rounded-full border-2 border-white bg-gray-600 flex justify-center items-center">
                    <IoTrashOutline color="white" opacity="80%" size={20} />
                  </div>

                  <img
                    src={cover_upload}
                    alt="cover-upload"
                    className="rounded shadow-md object-cover w-44 h-44 -mt-1"
                  />
                </div>
              )}
            </div>
            <div className="mt-4">
              <button className={button} onClick={onAddV1Photo}>
                + Add Selected Photo
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CoverPhoto;
