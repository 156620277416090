/* eslint-disable */
import Props from "../../../store/props";
import ThumbnailSelectable from "../../../shared/ThumbnailSelectable";
import PhotoProps from "../types/photoProps";
import { boxContainerFullSlim } from "../../../shared/styles";
import globalState from "../../../store";
import { useAtom } from "jotai";

interface AllPhotosProps {
  addPhotoToGallery: any;
  addSelectedPhotosToGallery: any;
  localGalleryNames: any;
  setSelectedPhotos: React.Dispatch<React.SetStateAction<any>>;
  selectedPhotos: any;
  handleSelect: any;
  filter: string;
  rerenderAfterDeletePhoto: (item: PhotoProps) => void;
  showMicroGalleryButtons: boolean;
  showMicroServicePhotoButtons: boolean;
  archives?: any;
}

const PhotoGallery: React.FC<AllPhotosProps> = ({
  showMicroGalleryButtons,
  showMicroServicePhotoButtons,
  addPhotoToGallery,
  localGalleryNames,
  addSelectedPhotosToGallery,
  // setGlobalState,
  // globalState,
  setSelectedPhotos,
  selectedPhotos,
  handleSelect,
  filter,
  rerenderAfterDeletePhoto,
  archives,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { gallery } = globalStateAtom;

  const handleDelete = (photoUrl: string) => {
    const newGalleryArray: any[] = [...gallery];

    const posOfImage = newGalleryArray.findIndex(
      (img) => img.photo === photoUrl
    );

    if (posOfImage !== -1) newGalleryArray.splice(posOfImage, 1);

    setGlobalState({ ...globalStateAtom, gallery: newGalleryArray });
  };

  return (
    <div className={boxContainerFullSlim}>
      {archives ? <div>ARCHIVED</div> : <></>}
      <div className="w-full flex flex-row flex-wrap relative mb-0 pt-12 w-full overflow-hidden">
        {gallery.length &&
          gallery.map((photo: PhotoProps) => {
            if (archives) {
              return (
                photo.archived === true && (
                  <ThumbnailSelectable
                    showMicroGalleryButtons={showMicroGalleryButtons}
                    showMicroServicePhotoButtons={showMicroServicePhotoButtons}
                    addPhotoToGallery={addPhotoToGallery}
                    setSelectedPhotos={setSelectedPhotos}
                    localGalleryNames={localGalleryNames}
                    addSelectedPhotosToGallery={addSelectedPhotosToGallery}
                    // globalState={globalState}
                    // setGlobalState={setGlobalState}
                    photo={photo}
                    handleDelete={handleDelete}
                    handleSelect={handleSelect}
                    selectedPhotos={selectedPhotos}
                    rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
                    draggable={false}
                  />
                )
              );
            } else {
              return (
                photo.gallery_name === filter && (
                  <ThumbnailSelectable
                    showMicroGalleryButtons={showMicroGalleryButtons}
                    showMicroServicePhotoButtons={showMicroServicePhotoButtons}
                    addPhotoToGallery={addPhotoToGallery}
                    setSelectedPhotos={setSelectedPhotos}
                    localGalleryNames={localGalleryNames}
                    addSelectedPhotosToGallery={addSelectedPhotosToGallery}
                    // globalState={globalState}
                    // setGlobalState={setGlobalState}
                    photo={photo}
                    handleDelete={handleDelete}
                    handleSelect={handleSelect}
                    selectedPhotos={selectedPhotos}
                    rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
                    draggable={false}
                  />
                )
              );
            }
          })}
      </div>
    </div>
  );
};

export default PhotoGallery;
