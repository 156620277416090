export const extractQuestionAndAnswer = (faqResponse: string) => {
  const faqLines = faqResponse.split("\n");
  const faqLinesFiltered = faqLines.filter((line) => line.trim().length > 0);
  let completeResponse = {};
  faqLinesFiltered.forEach((line) => {
    if (line.startsWith("Q:")) {
      const result = line.match(/Q:.*\?/g);
      if (result) {
        const question = result[0].replace("Q:", "");
        completeResponse = { ...completeResponse, question };
      }
    }
    if (line.startsWith("A:")) {
      const result = line.match(/A:.*/g);
      if (result) {
        const answer = result[0].replace("A:", "");
        completeResponse = { ...completeResponse, answer };
      }
    }
  });
  return completeResponse;
};
