/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  IoTrashOutline,
  IoCheckmarkCircleSharp,
  IoLocateOutline,
} from "react-icons/io5";
import GalleriesButtonsMicro from "../pages/photos/components/GalleriesButtonsMicro";
import PhotoProps from "../pages/photos/types/photoProps";
import { videoTypes, getMediaType } from "../utils/helpers/getMediaType";

interface ThumbnailProps {
  showMicroGalleryButtons: boolean;
  showMicroServicePhotoButtons: boolean;
  addPhotoToGallery: any;
  setSelectedPhotos: any;
  addSelectedPhotosToGallery: () => void;
  photo: PhotoProps;
  localGalleryNames: any;
  handleDelete: (photo: string) => void;
  handleSelect: (photo: PhotoProps) => void;
  selectedPhotos: any;
  rerenderAfterDeletePhoto: (item: PhotoProps) => void;
  draggable: boolean;
}

const ThumbnailSelectable: React.FC<ThumbnailProps> = ({
  showMicroGalleryButtons,
  showMicroServicePhotoButtons,
  addPhotoToGallery,
  setSelectedPhotos,
  addSelectedPhotosToGallery,
  localGalleryNames,
  photo,
  handleDelete,
  handleSelect,
  selectedPhotos,
  rerenderAfterDeletePhoto,
  draggable,
}) => {
  const [selected, setSelected] = useState(false);
  const [showMaximizeImage, setShowMaximizeImage] = useState(false);
  const [imageObjectFit, setImageObjectFit] = useState("cover");

  useEffect(() => {
    if (showMaximizeImage) {
      setImageObjectFit("contain");
    } else {
      setImageObjectFit("cover");
    }
  }, [showMaximizeImage]);

  useEffect(() => {
    // make sure that if the user navs away while some photos are selected then those photos stay selected
    // otherwise weird things start to happen
    const photoUrl: string = photo.photo;
    if (selectedPhotos[photoUrl]) setSelected(true);
    // this ensures that the photos that are now in the place of deleted photos don't remain selected
    else setSelected(false);
  }, [selectedPhotos]);

  return (
    <>
      <div className={"w-1/4  flex-shrink-0 inline hover:bg-opacity-40"}>
        <div className={`w-full m-4 flex  hover:bg-opacity-40`}>
          <div
            style={{ zIndex: 999 }}
            className={`${
              draggable ? "absolute" : "translate-x-28  w-0"
            } flex flex-row justify-between transform`}
          >
            <div
              className={`${
                draggable ? "" : "-ml-20"
              } w-8 h-8 mt-2 mx-2 cursor-pointer rounded-full border-2 border-white bg-gray-600`}
            >
              {selected ? (
                <IoCheckmarkCircleSharp
                  color="white"
                  opacity="80%"
                  className="w-8 h-8 pb-1 pr-1"
                />
              ) : (
                <div className="w-8" />
              )}
            </div>
            <div
              onClick={() => {
                // const confirmation = prompt(
                //   "Are you sure you want to delete this photo?",
                //   "yes"
                // );
                // if (confirmation) {
                // this actually deletes the image from globalState.gallery
                handleDelete(photo.photo);
                // this literally only sets the state of selectedItems so the page re-renders
                // and the item disappears (see the useEffect at the top of photos/index.tsx)
                rerenderAfterDeletePhoto(photo);
                // }
              }}
              className="w-8 h-8 mt-2  px-1 cursor-pointer rounded-full border-2 border-white bg-gray-600 flex justify-center items-center"
            >
              <IoTrashOutline color="white" opacity="80%" size={20} />
            </div>
            <div
              className={`w-8 h-8 mx-2 mt-2 cursor-pointer rounded-full border-2 border-white bg-gray-600`}
            >
              <IoLocateOutline
                color="white"
                opacity="80%"
                className="w-8 h-8 pb-1 pr-1"
                onClick={() => {
                  // handleSelect(photo)
                  setShowMaximizeImage(!showMaximizeImage);
                }}
              />
            </div>
          </div>
          {videoTypes && videoTypes.includes(getMediaType(photo)) ? (
            <div
              onClick={() => {
                handleSelect(photo);
                setSelected(!selected);
              }}
              className="rounded shadow-md w-44 h-44 -mt-1 overflow-hidden relative flex justify-center items-center"
            >
              <video
                playsInline
                autoPlay
                muted
                loop
                className="object-cover w-full h-full"
              >
                {/* <source src={photo.photo} type={"video/" + getMediaType(photo)} /> */}
                <source src={photo.photo} type="" />
                Your browser does not support the video tag.
              </video>
              <div className="w-full h-full bg-transparent absolute inset-0" />
            </div>
          ) : (
            <img
              onClick={() => {
                handleSelect(photo);
                setSelected(!selected);
              }}
              src={
                photo && photo.photo
                  ? photo.photo.includes("fbcdn.net")
                    ? photo.photo
                    : photo.photo.includes("https")
                    ? `https://d3p2r6ofnvoe67.cloudfront.net/fit-in/500x500/filters:format(webp)/filters:quality(60)/media/${
                        photo.photo.split(
                          "https://landing-page-app-hero-images.s3.amazonaws.com/media/"
                        )[1]
                      }`
                    : `https://d3p2r6ofnvoe67.cloudfront.net/fit-in/500x500/filters:format(webp)/filters:quality(60)/media/${
                        photo.photo.split(
                          "http://landing-page-app-hero-images.s3.amazonaws.com/media/"
                        )[1]
                      }`
                  : "/prophone_favicon_square.png"
              }
              className="rounded shadow-md object-cover w-44 h-44 -mt-1"
            />
          )}
        </div>
        <div className="flex-col">
          {photo.archived ? "ARCHIVED PHOTO - id - " + photo.id : ""}
        </div>
        <div
          className={`${
            "w-full"
            // draggable ? "w-full" : "w-1/6 mb-8 flex-shrink-0"
          } flex h:fit px-4 hover:bg-opacity-40`}
        >
          <GalleriesButtonsMicro
            showMicroGalleryButtons={showMicroGalleryButtons}
            showMicroServicePhotoButtons={showMicroServicePhotoButtons}
            addPhotoToGallery={addPhotoToGallery}
            photo={photo}
            localGalleryNames={localGalleryNames}
            addSelectedPhotosToGallery={addSelectedPhotosToGallery}
            selectedPhotos={selectedPhotos}
            setSelectedPhotos={setSelectedPhotos}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};
export default ThumbnailSelectable;
