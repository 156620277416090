/* eslint-disable */
import React, { useState } from "react";
import { extractThreeStepTileAndSubtitle, get3StepsQuotes } from "./utils";
import { useAtom } from "jotai";
import globalState from "../../../store";
const GenerateThreeStep = ({ step }: { step: number }) => {
  const [loading, setLoading] = useState(false);
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);

  const onGenerateClickHandler = async () => {
    try {
      const { company_type, company_name } = globalStateAtom;
      setLoading(true);
      const response = await get3StepsQuotes(company_name, company_type);
      const threeStepArray = extractThreeStepTileAndSubtitle(response);
      const stepInfo = threeStepArray[step];
      setGlobalStateAtom({
        ...globalStateAtom,
        ...stepInfo,
      });
      setLoading(false);
    } catch (error) {
      console.log("error ", error);
    }
  };
  return (
    <>
      <div onClick={onGenerateClickHandler}>
        {loading ? (
          <img src="/loading.gif" width="20" alt="loading" />
        ) : (
          <p className="cursor-pointer">Generate</p>
        )}
      </div>
    </>
  );
};

export default GenerateThreeStep;
