/* eslint-disable */
import React from "react";
import { buttonSmall } from "../../../../shared/styles";
import globalState from "../../../../store";
import { mergePhotoRequest } from "../../service/photoService";
import { useAtom } from "jotai";

const PostOrderActions = ({
  selectedPhotos,
  setSelectedPhotos,
  setLocalGallery,
  localGallery,
  manualOrderButtonShowing,
  setManualOrderButtonShowing,
  setManualOrderingTool,
  setLoading,
  setIs_show,
  context,
}: any) => {
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);

  const { gallery } = globalStateAtom;

  console.log("global gallery", gallery);
  console.log("local gallery", localGallery);

  const onUndoHandler = () => {
    setSelectedPhotos({});
    setManualOrderButtonShowing(true);
    setManualOrderingTool(false);
    setIs_show(true);
  };

  const onSaveHandler = async () => {
    setLoading(true);

    const selectedPhotosArray = Object.keys(selectedPhotos).map((photo) => {
      return selectedPhotos[photo];
    });
    const unselectedPhotos = [];

    localGallery.forEach((p: any) => {
      if (!selectedPhotosArray.some((photo) => photo.photo === p.photo)) {
        unselectedPhotos.push(p);
      }
    });

    const photosCount = localGallery.length;
    const indexesToReorder: any[] = [];
    for (let i = 1; i <= photosCount; i++) {
      indexesToReorder.push(i);
    }
    indexesToReorder.reverse();

    let selectedPhotosReordered;
    if (context === "All Photos") {
      selectedPhotosReordered = selectedPhotosArray.map((item, index) => {
        return { ...item, all_photos_order: indexesToReorder[index] };
      });
    } else {
      selectedPhotosReordered = selectedPhotosArray.map((item, index) => {
        return { ...item, gallery_order: indexesToReorder[index] };
      });
    }

    const currentGalleryReordered =
      selectedPhotosReordered.concat(unselectedPhotos);

    let currentGalleryReorderedResorted: any[];
    if (context === "All Photos") {
      currentGalleryReorderedResorted = currentGalleryReordered.map(
        (item, index) => {
          return { ...item, all_photos_order: indexesToReorder[index] };
        }
      );
    } else {
      currentGalleryReorderedResorted = currentGalleryReordered.map(
        (item, index) => {
          return { ...item, gallery_order: indexesToReorder[index] };
        }
      );
    }

    setLocalGallery(currentGalleryReorderedResorted);

    if (localGallery.length !== globalStateAtom.gallery.length) {
      const allPhotos = globalStateAtom.gallery.map((currentImage) => {
        if (
          currentGalleryReorderedResorted.some(
            (image) => image.photo === currentImage.photo
          )
        ) {
          return currentGalleryReorderedResorted.find(
            (image) => image.photo === currentImage.photo
          );
        } else {
          return currentImage;
        }
      });
      const allPhotosReordered = allPhotos.sort(
        (a, b) => b.all_photos_order - a.all_photos_order
      );
      setGlobalStateAtom({
        ...globalStateAtom,
        gallery: allPhotosReordered,
      });
    } else {
      setGlobalStateAtom({
        ...globalStateAtom,
        gallery: currentGalleryReorderedResorted,
      });
    }

    onUndoHandler();
    setLoading(false);
  };

  return (
    !manualOrderButtonShowing && (
      <div className="flex ">
        <button
          className={`${buttonSmall}  right-3 bottom-3 z-10  py-3 px-6`}
          onClick={onUndoHandler}
        >
          Cancel
        </button>
        <button
          className={`${buttonSmall}  right-3 bottom-3 z-10  py-3 px-6`}
          onClick={onSaveHandler}
        >
          Save
        </button>
      </div>
    )
  );
};

export default PostOrderActions;
