/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import { IoCloseCircle } from "react-icons/io5";
import {
  borderedInputWhite,
  borderedInputWhiteWrap,
  boxContainer,
  boxContainerFull,
  label,
  labelWrap,
} from "../../../shared/styles";
import UploadFiles from "./UploadFiles";
import { useAtom } from "jotai";
import globalState from "../../../store";

interface ReviewProps {
  review: {
    name: string;
    role: string;
    photo: string;
    draggableId: string;
    order: number;
  };
  localTeamMembers: any;
  handleDelete: () => void;
  index: number;
}

interface ItemProps {
  name: string;
  role: string;
  photo: string;
  order: number;
}

const Review: React.FC<ReviewProps> = ({
  review,
  index,
  handleDelete,
  localTeamMembers,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { teammates } = globalStateAtom;

  const [reviewState, setTeammateState] = useState(localTeamMembers[index]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const thisReview = localTeamMembers[index];
    // make copy of the reviews array
    const newReviews = [...teammates];
    if (thisReview) {
      // find the review with the matching 'order' property
      const posOfThisReview = newReviews.findIndex(
        (review) => review === thisReview
      );
      // console.log(reviewState)
      // set that review to the current local state of review
      newReviews[posOfThisReview] = reviewState;
      // update the global state
      // setLocalTeamMembers(newReviews)
      if (mounted) {
        setGlobalState({ ...globalStateAtom, teammates: newReviews });
      } else {
        setMounted(true);
      }
    }
  }, [reviewState]);

  return (
    <Draggable draggableId={review.draggableId} index={index}>
      {(provided) => (
        // 'provided' is a built-in ref of the beautiful-dnd library that keeps track of the element
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${boxContainer} w-full flex mt-3 mb-3 ml-6 p-2`}
        >
          <div className="mr-4 w-32 h-32">
            <UploadFiles
              id={review.draggableId}
              setThisTeammate={setTeammateState}
              thisTeammate={reviewState}
            />
          </div>

          <div className="flex flex-col flex-1">
            <label className={label}>
              Name
              <input
                className={borderedInputWhite}
                onChange={(e) =>
                  setTeammateState({
                    ...reviewState,
                    name: e.target.value,
                  })
                }
                value={reviewState.name}
              />
            </label>
            <label className={label}>
              Role
              <input
                className={borderedInputWhite}
                onChange={(e) =>
                  setTeammateState({
                    ...reviewState,
                    role: e.target.value,
                  })
                }
                value={reviewState.role}
              />
            </label>
            <label className={label}>
              Bio
              <textarea
                maxLength={175}
                className={borderedInputWhite + " h-40"}
                onChange={(e) =>
                  setTeammateState({
                    ...reviewState,
                    bio: e.target.value,
                  })
                }
                value={reviewState.bio}
                placeholder="175 characters max"
              />
            </label>
          </div>

          <div
            onClick={() => {
              const confirmation = prompt(
                "Are you sure you want to delete this review?",
                "yes"
              );
              if (confirmation) handleDelete();
            }}
            className="rounded-full cursor-pointer"
          >
            <IoCloseCircle color="#E5E7EB" size={30} />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Review;
