import {
  boxContainerFull,
} from "../../../shared/styles";
import globalState from "../../../store";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { IoTrashBinOutline } from "react-icons/io5";
import { IRedirect } from "../../../types/types";

export const Redirects: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [redirects, setRedirects] = useState<IRedirect[]>([]);
  const [source, setSource] = useState<string>('');
  const [destination, setDestination] = useState<string>('');
  const [error, setError] = useState<string>('');
  useEffect(() => {
    if (globalStateAtom && globalStateAtom.redirects) {
      setRedirects(globalStateAtom.redirects);
    }
  }, [globalStateAtom]);
  
  const updateGlobalState = (newRedirects: IRedirect[]) => {
    setGlobalState({
      ...globalStateAtom,
      redirects: newRedirects
    });
  };

  const handleDelete = (index: number) => {
    const { redirects = [] } = globalStateAtom;
      redirects.splice(index, 1);
      updateGlobalState(redirects);
  };
  
  const isValidPath = (path: string) => path.startsWith('/');
  
  const handleAdd = () => {
    if (source && destination) {
      if (isValidPath(source) && isValidPath(destination)) {
        const newRedirects = [...redirects, { source, destination }];
        updateGlobalState(newRedirects);
        setSource('');
        setDestination('');
        setError('');
      } else {
        setError('Both paths must start with a slash ("/").');
      }
    } else {
      setError('Both fields are required.');
    }
  };
  
  return (
    <div className={`${boxContainerFull} mx-auto mt-8`}>
      <div className="flex mb-4">
        <input
          type="text"
          className="border rounded p-2 mr-2 flex-1"
          placeholder="/old-path"
          value={source}
          onChange={(e) => setSource(e.target.value)}
        />
        <input
          type="text"
          className="border rounded p-2 mr-2 flex-1"
          placeholder="/redirect-to-path"
          value={destination}
          onChange={(e) => setDestination(e.target.value)}
        />
        <button
          className={`font-bold py-2 px-4 rounded ${
            !source || !destination
              ? "bg-gray-500 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-700 text-white"
            }`}
          onClick={handleAdd}
          disabled={!source || !destination}
        >
          Add Redirect Path
        </button>
      </div>
      {error && <p className="text-red-500">{error}</p>}
      <table className="min-w-full border-collapse">
        <thead>
          <tr>
            <th className="bg-gray-200 border-b px-4 py-2 text-left">Old Path</th>
            <th className="bg-gray-200 border-b px-4 py-2 text-left">Redirect to Path</th>
            <th className="bg-gray-200 border-b px-4 py-2 text-left">Type</th>
            <th className="bg-gray-200 border-b px-4 py-2 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {redirects.map((redirect, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="border-b px-4 py-2 text-left whitespace-nowrap overflow-hidden text-ellipsis">{redirect.source}</td>
              <td className="border-b px-4 py-2 text-left whitespace-nowrap overflow-hidden text-ellipsis">{redirect.destination}</td>
              <td className="border-b px-4 py-2 text-left whitespace-nowrap overflow-hidden text-ellipsis">{redirect.redirect_type}</td>
              <td className="border-b px-4 py-2 text-left cursor-pointer" onClick={() => handleDelete(index)}>
                <IoTrashBinOutline />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
