/* eslint-disable */
import { useEffect, useState } from "react";
import Leaderboard from "./leaderboard";
import AllTextsMap from "./map";
import TreeMap from "./treemap";
import {
  getTextCounts,
  getTextsAndAddresses,
  postLandingPageGeoToDb,
  postTextGeoToDb,
} from "./utils";

export default function MapPage() {
  const [geoDataTexts, setGeoDataTexts] = useState<any[]>([]);
  const [geoDataPros, setGeoDataPros] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [textCounts, setTextCounts] = useState({});

  useEffect(() => {
    async function getDataOnLoad() {
      const textsAndAddresses = await getTextsAndAddresses();
      await getGeoDataTexts(textsAndAddresses[0]);
      await getGeoDataPros(textsAndAddresses[1]);
    }
    getDataOnLoad();
  }, []);
  useEffect(() => {
    async function textCounts() {
      const countsJson = await getTextCounts();
      setTextCounts(JSON.parse(countsJson));
    }
    textCounts();
  }, []);

  async function getGeoDataTexts(texts) {
    setLoading(true);
    const geoDataArray = [];
    for (let i = 0; i < texts.length; i++) {
      const address = texts[i].sender_address;
      const textId = texts[i].id;
      let latitude = texts[i]?.sender_latitude || "";
      let longitude = texts[i]?.sender_longitude || "";
      const city = texts[i]?.city || "";
      const state = texts[i]?.state || "";

      if (
        (!latitude || !longitude) &&
        !address.toLowerCase().includes("example")
      ) {
        try {
          const response = await fetch(
            `http://api.positionstack.com/v1/forward?access_key=${process.env.REACT_APP_GEO_API_KEY}&query=${address}&country=US`,
            {
              method: "GET",
            }
          );
          const responseData = await response.json();

          latitude = responseData.data[0]?.latitude
            ? responseData.data[0]?.latitude
            : "";
          longitude = responseData.data[0]?.longitude
            ? responseData.data[0]?.longitude
            : "";
          if (latitude && longitude) {
            await postTextGeoToDb(textId, latitude, longitude);
          }
        } catch (e) {
          console.log("error", e);
        }
      }
      if (!state && latitude && longitude) {
        try {
          const response = await fetch(
            `http://api.positionstack.com/v1/reverse?access_key=${process.env.REACT_APP_GEO_API_KEY}&query=${latitude},${longitude}`,
            {
              method: "GET",
            }
          );
          const responseData = await response.json();
          const data = responseData.data[0];
          const city = data?.locality || "";
          const state = data?.region || "";
          await postTextGeoToDb(textId, latitude, longitude, city, state);
        } catch (e) {
          console.log("error", e);
        }
      }
      geoDataArray.push({
        latitude: latitude,
        longitude: longitude,
        city: city,
        state: state,
      });
    }
    setGeoDataTexts(geoDataArray);
    setLoading(false);
  }

  async function getGeoDataPros(pros) {
    setLoading(true);
    const geoDataArray = [];
    for (let i = 0; i < 1000; i++) {
      const address = pros[i].address;
      const landingPageId = pros[i].id;
      let latitude = pros[i]?.address_latitude || "";
      let longitude = pros[i]?.address_longitude || "";
      const city = pros[i]?.city || "";
      const state = pros[i]?.state || "";

      if (!latitude && !longitude) {
        try {
          const response = await fetch(
            `http://api.positionstack.com/v1/forward?access_key=${process.env.REACT_APP_GEO_API_KEY}&query=${address}&country=US`,
            {
              method: "GET",
            }
          );
          const responseData = await response.json();

          latitude = responseData.data[0]?.latitude
            ? responseData.data[0]?.latitude
            : "";
          longitude = responseData.data[0]?.longitude
            ? responseData.data[0]?.longitude
            : "";

          if (latitude && longitude) {
            await postLandingPageGeoToDb(landingPageId, latitude, longitude);
          }
        } catch (e) {
          console.log("error", e);
        }
      }
      if (!state && latitude && longitude) {
        try {
          const response = await fetch(
            `http://api.positionstack.com/v1/reverse?access_key=${process.env.REACT_APP_GEO_API_KEY}&query=${latitude},${longitude}`,
            {
              method: "GET",
            }
          );
          const responseData = await response.json();
          const data = responseData.data[0];
          const city = data?.locality || "";
          const state = data?.region || "";
          await postLandingPageGeoToDb(
            landingPageId,
            latitude,
            longitude,
            city,
            state
          );
        } catch (e) {
          console.log("error", e);
        }
      }
      geoDataArray.push({
        latitude: latitude,
        longitude: longitude,
        city: city,
        state: state,
      });
    }
    setGeoDataPros(geoDataArray);
    setLoading(false);
  }

  return (
    <div className="bg-gray-200 h-auto p-6">
      <div className="flex justify-center">
        {loading ? "Loading the map..." : ""}
      </div>
      <AllTextsMap geoDataTexts={geoDataTexts} geoDataPros={geoDataPros} />
      <TreeMap geoDataTexts={geoDataTexts} geoDataPros={geoDataPros} />
      <Leaderboard textCounts={textCounts} />
    </div>
  );
}
