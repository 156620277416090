/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { initAligningGuidelines } from "./initAligningGuidelines";

export default function FabricCanvas({
  fabricCanvas,
  image,
  textSettings,
  backgroundColor,
  showFirstTextbox,
  showSecondTextbox,
  getActiveTextbox,
  text0Value,
  imagePosition,
  textPosition0,
  textPosition1,
}: any) {
  const canvasRef = useRef();
  const imageRef = useRef();
  const textObject0 = useRef();
  const textObject1 = useRef();

  const [initialRender, setInitialRender] = useState(false);

  useEffect(() => {
    setInitialRender(true);
  }, []);

  useEffect(() => {
    fabricCanvas.current = new fabric.Canvas(canvasRef.current, {
      width: 1000,
      height: 1000,
      subTargetCheck: true,
      preserveObjectStacking: true,
      selectionColor: "rgba(0, 255, 0, 0.3)",
    });

    addImage();
    addText0();

    initAligningGuidelines(fabricCanvas.current);
  }, []);

  useEffect(() => {
    let activeObject = fabricCanvas.current.getActiveObject();
    const activeIndex = getActiveTextbox();
    if (!activeObject) {
      activeObject = textObject0.current;
    }

    if (activeObject) {
      const font = textSettings[activeIndex].family;
      setFont(activeObject, font);
    }
  }, [textSettings]);

  useEffect(() => {
    fabricCanvas.current.setBackgroundColor(backgroundColor, () => {
      fabricCanvas.current.renderAll();
    });
  }, [backgroundColor]);

  useEffect(() => {
    if (!initialRender) return;

    if (initialRender && showFirstTextbox) {
      addText0();
    } else {
      fabricCanvas.current.remove(textObject0.current);
      fabricCanvas.current.renderAll();
    }
  }, [showFirstTextbox]);

  useEffect(() => {
    if (!initialRender) return;

    if (initialRender && showSecondTextbox) {
      addText1();
    } else {
      fabricCanvas.current.remove(textObject1.current);
      fabricCanvas.current.renderAll();
    }
  }, [showSecondTextbox]);

  useEffect(() => {
    const activeIndex = getActiveTextbox();
    let activeObject = fabricCanvas.current.getActiveObject();
    if (!activeObject) {
      activeObject = textObject0.current;
    }

    activeObject?.set({
      fontSize: textSettings[activeIndex].size,
      fill: textSettings[activeIndex].color,
      fontWeight: parseInt(textSettings[activeIndex].weight),
      textAlign: textSettings[activeIndex].alignment,
      lineHeight: textSettings[activeIndex].lineHeight,
    });
    fabricCanvas.current.renderAll();
  }, [textSettings]);

  function setFont(activeObject, font) {
    activeObject.set("fontFamily", font);
    fabricCanvas.current.renderAll();
  }

  function addImage() {
    const left = imagePosition?.x;
    const top = imagePosition?.y;

    fabric.Image.fromURL(
      image,
      function (img) {
        img.left = left ? left : (1000 - 600) / 2;
        img.top = top ? top : 0;
        img.scaleToWidth(600);
        img.scaleToHeight(600);
        img.sendToBack();
        img.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
        });

        fabricCanvas.current.add(img);
        fabricCanvas.current.moveTo(img, 0);
        img.set("id", "image0");
        imageRef.current = img;
      },
      { crossOrigin: "anonymous" }
    );
  }

  function addText0() {
    const left = textPosition0?.x;
    const top = textPosition0?.y;

    const text = new fabric.IText(text0Value, {
      fontSize: textSettings[0].size,
      fill: textSettings[0].color,
      fontFamily: textSettings[0].family,
      fontWeight: parseInt(textSettings[0].weight),
      textAlign: textSettings[0].alignment,
      lineHeight: textSettings[0].lineHeight,
      top: top ? top : 500,
      padding: 30,
    });
    text.left = left ? left : (1000 - text.width) / 2;
    text.set("id", "textbox0");
    text.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    });
    fabricCanvas.current.add(text).setActiveObject(text);
    textObject0.current = text;
    fabricCanvas.current.moveTo(text, 1);
  }

  function addText1() {
    const left = textPosition1?.x;
    const top = textPosition1?.y;

    const textbox1 = new fabric.IText("Textbox 2", {
      left: left ? left : 200,
      top: top ? top : 60,
      width: 400,
      fontSize: 20,
      fill: textSettings[0].color,
      fontWeight: parseInt(textSettings[0].weight),
      textAlign: textSettings[0].alignment,
      lineHeight: textSettings[0].lineHeight,
      fontFamily: textSettings[0].family,
      padding: 30,
    });
    textbox1.set("id", "textbox1");
    textbox1.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    });
    fabricCanvas.current.add(textbox1).setActiveObject(textbox1);
    fabricCanvas.current.renderAll();
    textObject1.current = textbox1;
  }

  return (
    <div className="border">
      <div id="canvas-container" style={{ width: 1000, height: 1000 }}>
        <canvas id="canvas" ref={canvasRef} width={1000} height={1000} />
      </div>
    </div>
  );
}
