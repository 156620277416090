/* eslint-disable */
import { useAtom } from "jotai";
import { useState } from "react";
import { phoneNumberJotai } from "../../../store/auth";
import { button, containerCentered, input } from "../../../shared/styles";
import formatPhoneNumber from "../../../utils/helpers/formatPhoneNumber";

interface AuthFormProps {
  setFormNum: any;
}

const AuthForm: React.FC<AuthFormProps> = ({ setFormNum }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [, setPhoneNumberAtom] = useAtom(phoneNumberJotai);

  const sendCode = async () => {
    setPhoneNumberAtom(formatPhoneNumber(phoneNumber, "INTERNATIONAL"));
    const auth_res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/pi/auth`,
      {
        method: "POST",
        headers: { "content-type": "application/json" }, // , 'Access-Control-Allow-Origin':'*'
        body: JSON.stringify({
          phoneNumber: formatPhoneNumber(phoneNumber, "INTERNATIONAL"),
        }),
      }
    );
    const data = await auth_res.json();
    // // console.log(data, 'verified account')
    setFormNum(2);
  };

  return (
    <div className={containerCentered}>
      <img src="prophone_favicon_square.png" width="50" className="mb-2" />
      <h1 className="text-3xl">Admin Portal</h1>
      <input
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Phone Number"
        value={phoneNumber}
        className={input}
      />
      <button onClick={() => sendCode()} className={button}>
        Send Code
      </button>
    </div>
  );
};

export default AuthForm;
