/* eslint-disable */
import { useAtom } from "jotai";
import {
  borderedInputWhite,
  boxContainerFull,
  label,
} from "../../../shared/styles";
import globalState from "../../../store";

const SoTellUs: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { so_tell_us_chat_code } = globalStateAtom;
  return (
    <div className="w-full flex flex-col">
      {/* <h2 className="self-end -mr-6 -mb-6">CTA Class Text Class : cta_text_button </h2><br/> */}
      <h2 className="self-end -mr-6 -mb-6">SoTellUs Chat Code</h2>
      <div className={`${boxContainerFull} ml-6`}>
        <label className={label}>
          HTML
          <textarea
            value={so_tell_us_chat_code}
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                so_tell_us_chat_code: e.target.value,
              })
            }
          />
        </label>
      </div>
    </div>
  );
};

export default SoTellUs;
