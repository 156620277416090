/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import globalState from "../../store";
import Column from "./components/Column";
import AddTeamMemberButton from "./components/AddTeamMemberButton";
import { pageContainerWithOverflow } from "../../shared/styles";
// Matthew was here
interface TeamMemberProps {
  title: string;
  subtitle: string;
  photo: string;
  link: string;
  draggableId: string;
  order: number;
}

export default function PartnershipsPage() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { products } = globalStateAtom;

  // generating a consistent draggableId and key for each review
  useEffect(() => {
    const draggableServices = products
      ? products.map((item: TeamMemberProps, index: number) => {
          return { ...item, order: index, draggableId: uuidv4() };
        })
      : [
          {
            title: "",
            subtitle: "",
            photo: "",
            link: "",
            draggableId: "",
            order: 0,
          },
        ].map((item: TeamMemberProps, index: number) => {
          return { ...item, order: index, draggableId: uuidv4() };
        });
    // @ts-ignore
    setGlobalState({ ...globalStateAtom, products: draggableServices });
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (
      products &&
      products.length &&
      products[0] &&
      // @ts-ignore
      !products[0].draggableId
    ) {
      const draggableServices = products
        ? products.map((item: TeamMemberProps, index: number) => {
            return { ...item, order: index, draggableId: uuidv4() };
          })
        : [
            {
              title: "",
              subtitle: "",
              photo: "",
              link: "",
              draggableId: "",
              order: 0,
            },
          ].map((item: TeamMemberProps, index: number) => {
            return { ...item, order: index, draggableId: uuidv4() };
          });
      // @ts-ignore
      setGlobalState({ ...globalStateAtom, products: draggableServices });
    }
  }, [products]);

  const columnMultiplier = (
    index: number,
    column: string,
    srcOrDes: string
  ) => {
    const actualIndex =
      index === 0 && column === "1"
        ? index
        : index === 0 && column === "2"
        ? (index = 1)
        : index === 0 && column === "3"
        ? (index = 2)
        : (index = parseInt(column) + index * 3 - 1);
    return actualIndex;
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    )
      return null;

    // If they move to the same column
    const sameColumn = destination.droppableId === source.droppableId;

    // Move the item within the list
    const newList = products;

    // Start by making a new list without the dragged item
    // @ts-ignore
    const newMembers = sameColumn
      ? newList
      : newList.filter(
          (serv) =>
            // @ts-ignore
            serv.draggableId !==
            products[
              columnMultiplier(source.index, source.droppableId, "src")
              // @ts-ignore
            ].draggableId
        );
    // Then insert the item at the right location
    if (sameColumn) {
      // If dragging into the same column, simply switch the items
      const prevSrc =
        newMembers[columnMultiplier(source.index, source.droppableId, "src")];
      newMembers[columnMultiplier(source.index, source.droppableId, "src")] =
        newMembers[
          columnMultiplier(destination.index, destination.droppableId, "des")
        ];
      newMembers[
        columnMultiplier(destination.index, destination.droppableId, "des")
      ] = prevSrc;
      // Else move everything to the right one place
    } else
      newMembers.splice(
        columnMultiplier(destination.index, destination.droppableId, "des"),
        0,
        products[columnMultiplier(source.index, source.droppableId, "src")]
      );
    
    const finalList = newMembers.map((item: any, index: number) => {
      return {
        ...item,
        order: index,
      };
    });

    // Update the list
    // @ts-ignore
    setGlobalState({ ...globalStateAtom, products: finalList });
  };
  const [col1, setCol1] = useState<TeamMemberProps[]>();
  const [col2, setCol2] = useState<TeamMemberProps[]>();
  const [col3, setCol3] = useState<TeamMemberProps[]>();
  let lastColumn = 0;
  const column1TeamMembers: TeamMemberProps[] = [];
  const column2TeamMembers: TeamMemberProps[] = [];
  const column3TeamMembers: TeamMemberProps[] = [];

  useEffect(() => {
    const prod =
      products &&
      products.forEach((member, index) => {
        if (lastColumn === 0) {
          column1TeamMembers.push(member);
          // setCol1Services(column1Services)
          lastColumn = 1;
        } else if (lastColumn === 1) {
          column2TeamMembers.push(member);
          // setCol2Services(column2Services)
          lastColumn = 2;
        } else {
          column3TeamMembers.push(member);
          // setCol3Services(column3Services)
          lastColumn = 0;
        }
      });
    setCol1(column1TeamMembers);
    setCol2(column2TeamMembers);
    setCol3(column3TeamMembers);
  }, [products]);

  return (
    <div className={pageContainerWithOverflow}>
      <div className="transform translate-y-10 right-12 p-3">
        <AddTeamMemberButton
          setGlobalState={setGlobalState}
          globalState={globalStateAtom}
        />
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="w-full flex justify-around mt-6">
          <Column localTeamMembers={col1} droppableId={1} />
          <Column localTeamMembers={col2} droppableId={2} />
          <Column localTeamMembers={col3} droppableId={3} />
        </div>
      </DragDropContext>
    </div>
  );
}
