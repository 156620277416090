/* eslint-disable */
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import globalState from "../../../store";
import ReactSlider from "react-slider";


const LogoSize: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { custom_logo_size } = globalStateAtom;
  const [suggested, setSuggested] = useState(160);
  const [logo, setLogo] = useState(globalStateAtom.logo);

  useEffect(() => {
    setLogo(globalStateAtom.logo);
  }, [globalStateAtom.logo]);

  function suggestedValueOnClick() {
    setGlobalState({
      ...globalStateAtom,
      custom_logo_size: Math.floor(suggested).toString(),
    });
  }

  function calculateSize(width: number, height: number) {
    if (height > 0) {
      const ratio = width / height;
      const calculatedMaxWidth = ratio * 150;
      if (calculatedMaxWidth >= 160 && calculatedMaxWidth <= 480) {
        setSuggested(calculatedMaxWidth);
      } else if (calculatedMaxWidth < 160) {
        setSuggested(160);
      } else {
        setSuggested(480);
      }
    }
  }

  async function loadLogoFile() {
    if (logo) {
      const logoImage = new Image();
      logoImage.src = logo;
      logoImage.onload = () => {
        const width = logoImage.width;
        const height = logoImage.height;
        calculateSize(width, height);
      }
    }
  }

  useEffect(() => {
    (async () => {
      await loadLogoFile();
    }
    )();
  }, [logo]);

  return (
    <div className="w-full sm:w-1/2">
      <h2 className="text-left ml-12">Logo Size</h2>
      <div
        className={`bg-white rounded-lg p-6 justify-between w-10/12 mt-4 ml-12`}
      >
        <label className="flex flex-col items-start bg-gray-100 m-3 p-3 rounded-lg">
          Logo Size
          <div className="flex w-full">
            {/* 448px is max-w-md set in prosite when there is no team page and jobs page, 160px is default width now on prod */}
            <div className="w-full flex items-center">
              <ReactSlider
                step={1}
                min={160}
                max={448}
                className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab"
                thumbClassName="absolute w-5 h-5 cursor-grab bg-blue-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px"
                value={custom_logo_size}
                onChange={(value: any) => {
                  setGlobalState({
                    ...globalStateAtom,
                    custom_logo_size: value,
                  });
                }}
              />
            </div>
            <span className="flex items-center ml-2 mr-2">max</span>
          </div>
          <span>{custom_logo_size}px</span>
        </label>
        <div className="flex">
          <div className="text-gray-400 mt-2 ml-5 mr-2">suggested value: </div>
          <button
            className="bg-blue-800 text-white rounded-full py-2 px-4  cursor-pointer"
            onClick={suggestedValueOnClick}
          >
            {Math.floor(suggested)} px
          </button>
        </div>

      </div>
    </div>
  );
};

export default LogoSize;
