/* eslint-disable */
import React from "react";
import { IoTrashBinOutline } from "react-icons/io5";
const CustomerTable = ({ customers, onRemoveClickHandler }: any) => {
  return (
    <>
      <table className="w-full border my-4">
        <tr>
          <th>Phone Number</th>
          <th>Name</th>
          <th>Customer Id</th>
        </tr>
        {customers &&
          customers.map((customer: any, index: number) => {
            return (
              <tr className="border my-2">
                <td>{customer?.phone}</td>
                <td>
                  {customer?.name}{" "}
                  {customer?.user_type === "Primary Owner"
                    ? `(Primary Owner - ${customer.landing_page_id})`
                    : ""}
                </td>
                <td>{customer?.id}</td>
                <td
                  onClick={() => onRemoveClickHandler(index)}
                  className="cursor-pointer"
                >
                  <IoTrashBinOutline />
                </td>
              </tr>
            );
          })}
      </table>
    </>
  );
};

export default CustomerTable;
