/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useRef } from "react";
import Thumbnail from "../../../shared/Thumbnail";
import { boxContainerFull } from "../../../shared/styles";
import {
  pushImageToS3,
  uploadExternalImageUrl,
} from "../../../utils/helpers/uploadFileUtil";
import { useAtom } from "jotai";
import globalState from "../../../store";
import UploadImages from "../../services/components/upload/UploadImages";
import { getExternalPhotoUrl } from "../../../utils/helpers/getExternalPhotoUrl";
import { getJpegFileFromHeic } from "./utils";
import {
  checkApplePatentImageExtension,
  getFileExtensionFromType,
} from "../../logo/components/utils";
import ImageCropModal from "../../logo/components/image-crop-modal/ImageCropModal";
import { IoCropOutline } from "react-icons/io5";
import {
  getMediaTypeFromURL,
  videoTypes,
} from "../../../utils/helpers/getMediaType";
import SwitchToggle from "../../../shared/SwitchToggle";

const AboutUsPhoto: React.FC<any> = ({
  // setGlobalState,
  // globalState,
  setAboutUsImage,
  // photo,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  // uses local state to ensure the image is updated onChange
  const { about_upload, about_us_controls } = globalStateAtom;
  const [image, setImage] = useState<string | null>(about_upload);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event: any) => {
    // // console.log('cleek')
    // @ts-ignore
    hiddenFileInput.current.click();
  };
  const addImageToState = (imageUrl: any) => {
    setAboutUsImage(imageUrl);
    setImage(imageUrl as string);
  };
  const onChangeHandler = async (files: any) => {
    setLoading(true);
    let file = (files && files[0]) || null;
    if (/image\//.test(file?.type) === true) {
      const fileExtension = getFileExtensionFromType(file.type);
      if (checkApplePatentImageExtension(fileExtension)) {
        file = await getJpegFileFromHeic(file);
      }
    }
    if (file) {
      const [uploadedImageUrl, uploadedImageFileName] = await pushImageToS3(
        file
      );
      // // console.log(uploadedImageUrl, 'uploadedImageUrl')
      if (uploadedImageUrl) {
        addImageToState(uploadedImageUrl);
      }
    }
    setLoading(false);
  };
  const onDropHandler = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const files = event.dataTransfer.files;

    if (files.length > 0) {
      onChangeHandler(files);
    } else {
      const url = await getExternalPhotoUrl(event.dataTransfer.getData("url"));
      if (url) {
        const uploadedLogoDataFileName = await uploadExternalImageUrl(url);

        if (uploadedLogoDataFileName) {
          const uploadedLogoDataUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${uploadedLogoDataFileName}`;
          addImageToState(uploadedLogoDataUrl);
        }
      }
    }
    setLoading(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <h2>About Us Photo</h2>
      <div className={boxContainerFull}>
        {about_upload &&
          videoTypes.includes(getMediaTypeFromURL(about_upload)) && (
            <div className="p-3 flex bg-gray-100 rounded-lg mb-3">
              <SwitchToggle
                checked={about_us_controls}
                onChange={() =>
                  setGlobalState({
                    ...globalStateAtom,
                    about_us_controls: !about_us_controls,
                  })
                }
                label="Allow video controls (this allows to stop/unmute/download the about us video)"
              />
            </div>
          )}
        <div className="relative">
          <div className="h-32 w-full">
            <UploadImages
              onChangeHandler={onChangeHandler}
              onDropHandler={onDropHandler}
            />
          </div>
          {loading && <img src="/loading.gif" width="25px" />}
          {image && (
            <>
              <Thumbnail
                photo={{
                  alt_tag: `1`, // TODO: change this
                  archived: false,
                  associated_service: "",
                  gallery: null,
                  photo: image,
                  landing_page: globalStateAtom.sitePk,
                  order: 0, // TODO: probably change this
                  uncropped_gallery_photo: null,
                  gallery_name: null,
                  selected: false,
                }}
                handleDelete={() => {
                  setImage(null);
                  setGlobalState({
                    ...globalStateAtom,
                    about_upload: "",
                  });
                }}
                handleClick={handleClick}
                draggable={false}
                height={"1050"}
                width={"1050"}
                quality={100}
              />
              {!videoTypes.includes(getMediaTypeFromURL(image)) &&
                getMediaTypeFromURL(image) !== "gif" && (
                  <div className="absolute top-36 right-2 w-8 h-8 mt-2 mx-2 px-1 cursor-pointer rounded-full border-2 border-white bg-gray-600 flex justify-center items-center">
                    <IoCropOutline
                      className={"text-white " + getMediaTypeFromURL(image)}
                      size={20}
                      onClick={() => setIsModalOpen(true)}
                    />
                  </div>
                )}
            </>
          )}
        </div>
      </div>
      <ImageCropModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        imageURL={image}
        setImageURL={addImageToState}
      />
    </>
  );
};

export default AboutUsPhoto;
