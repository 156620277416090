import axios from "axios";
import { useAtom } from "jotai";
import globalState from "../../../store";
import { getTestimonialsWithDraggableId } from "../../../utils/helpers/formatLandingPage";
import { ITestimonial } from "../../../types/types";

interface CategorizeReviewsProps {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CategorizeReviews: React.FC<CategorizeReviewsProps> = ({
  loading,
  setLoading,
}) => {
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);
  const { testimonials } = globalStateAtom;

  const handleClick = async () => {
    const testimonialsBodyArr = testimonials.filter(
      (review: ITestimonial) => review?.checked
    );
    if (!testimonialsBodyArr.length) {
      return alert("Please select reviews to categorize.");
    }

    setLoading(true);

    let response;
    try {
      response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/categorize-reviews`,
        {
          testimonials: testimonialsBodyArr,
        }
      );
    } catch (e) {
      console.log("Error categorizing reviews: ", e);
      alert("Failed categorizing reviews");
      setLoading(false);
      return;
    }

    const categorizedReviews = response.data.testimonials.map(
      (review: ITestimonial) => ({
        ...review,
        checked: false,
      })
    );
    const nonCheckedReviews = testimonials.filter(
      (review: ITestimonial) => !review.checked
    );
    const combinedReviews = [...categorizedReviews, ...nonCheckedReviews];

    const combinedReviewsWithId =
      getTestimonialsWithDraggableId(combinedReviews);

    combinedReviewsWithId.sort(
      (a: ITestimonial, b: ITestimonial) => a.order - b.order
    );

    setGlobalStateAtom({
      ...globalStateAtom,
      testimonials: combinedReviewsWithId,
    });
    setLoading(false);
  };

  return loading ? (
    <div className="flex">
      <img src="/loading.gif" width="20" alt="loading" />
      <p className="ml-3">Categorizing reviews...</p>
    </div>
  ) : (
    <button
      className="py-2 px-4 rounded-full border-2 border-red-500 text-red-500 bg-white hover:bg-gray-300"
      onClick={handleClick}
    >
      Categorize Reviews
    </button>
  );
};

export default CategorizeReviews;
