/* eslint-disable */
// Containers
export const containerCentered =
  "flex flex-col justify-center items-center w-full h-screen";
export const pageContainer = "bg-gray-200 p-6";
export const pageContainerWithOverflow = "bg-gray-200 h-full p-6 min-h-screen";
export const boxContainer = "bg-white w-5/12 m-12 rounded-lg p-6";
export const boxContainerFull = "bg-white w-full m-12 rounded-lg p-6 z-50";
export const boxContainerFullSlim = "bg-white w-full m-12 rounded-lg px-6";

// Forms
export const button =
  "p-2 w-72 m-3 rounded-full border-2 text-white text-sm bg-gray-500";
export const buttonSmall =
  "p-2 w-36 m-3 rounded-full border-2 text-white text-sm bg-gray-500";
export const buttonWhiteSmall =
  "p-2 w-36 m-3 rounded-full border-2 border-gray-500 bg-white text-sm text-gray-500 cursor-pointer";
export const input = "p-2 mt-3 w-72 bg-gray-200 rounded-md";
export const inputWhite = "p-2 mt-3 w-48 rounded-md";
export const borderedInputWhite =
  "p-2 mt-3 w-48 rounded-md border-2 border-gray-200 w-full";
export const borderedInputWhiteWrap =
  "p-2 mt-3 w-48 rounded-md border-2 border-gray-200 w-5/12";
export const label = "flex flex-col items-start mt-3";
export const labelWrap = "flex flex-col items-start mt-3 w-5/12 mr-3";
export const blueButton = "py-2 px-4 rounded-full border-2 text-white text-sm bg-blue-900 hover:bg-blue-800"