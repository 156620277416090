/* eslint-disable */
import axios from "axios";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { containerCentered } from "./styles";

function LeadGeneratingForm() {
  const [state, setState] = useState("");
  const [industry, setIndustry] = useState("");
  const [running, setRunning] = useState(false);
  const [generatingCsv, setGeneratingCsv] = useState(false);
  const [error, setError] = useState("");

  async function generateLeads() {
    try {
      const generateLeadsRes = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/leadGen`,
        {
          state: state,
          industry: industry.toLowerCase(),
        }
      );
      console.log("made it past generateLeadsRes", generateLeadsRes);
      return generateLeadsRes.data;
    } catch (e) {
      console.log(e, "error generating leads");
      setError("Error generating leads");
    }
  }

  async function generateCsv() {
    setGeneratingCsv(true);
    try {
      const responseObj = await axios.post(
        `${
          process.env.REACT_APP_SERVER_URL
        }/lead_gen/get_leads_by_industry/${state}/${industry.toLowerCase()}`
      );

      console.log(responseObj, "responseObj in generateLeads");

      const responseMessage = responseObj.data.message.data;
      const csvArr = [];
      const date = new Date();

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
      const currentDate = `${month}-${day}-${year}`;

      for (const company of responseMessage) {
        console.log("what is company in here", company);
        csvArr.push([
          company.business_status,
          company.industry,
          company.google_maps_url,
          company.has_website,
          company.website_url.replaceAll("#", ""),
          company.company_name
            .replaceAll(",", " ")
            .replaceAll("#", "number ")
            .replaceAll("&", " and "),
          company.phone,
          company.address
            .replaceAll(",", " ")
            .replaceAll("#", "number ")
            .replaceAll("&", " and "),
          company.city,
          company.state,
          company.data_source,
          company.ts,
          company.rating,
          company.total_ratings,
          company.recent_review_date,
          company.recent_review
            .replaceAll(",", " ")
            .replaceAll("\n", " ")
            .replaceAll("#", " ")
            .replaceAll("&", " and "),
          company.place_id,
        ]);
      }
      let csv =
        "Business Status,Type,Google Business URL,Has Website,Website URL,Company,Mobile,Address,City,State,Data Source,Data Date,Rating,Total Ratings,Last Review Date,Most Recent Reviews,Place_ID\n";
      csvArr.forEach(function (csvArr) {
        console.log("what is row", csvArr);
        csv += Object.values(csvArr).slice().join(",");
        csv += "\n";
      });
      const hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";

      //provide the name for the CSV file to be downloaded
      hiddenElement.download = `${state}_${industry}_google_leads_${currentDate}.csv`;
      hiddenElement.click();
    } catch (e) {
      console.log(e, "error generating csv");
      setError("Error generating csv");
    }
    setGeneratingCsv(false);
  }

  async function onSubmitHandler() {
    setRunning(true);
    try {
      const newLeadsGenerated = await generateLeads();
      if (newLeadsGenerated === "success") {
        await generateCsv();
      }
      setState("");
      setIndustry("");
    } catch (e) {
      setError("Error generating leads");
      console.log("error generating leads", e);
    }
    setRunning(false);
  }

  function onStateChangeHandler(e) {
    console.log("form being change");
    e.preventDefault();
    setState(e.target.value);
  }

  function onIndustryChangeHandler(e) {
    e.preventDefault();
    setIndustry(e.target.value);
  }

  return (
    <>
      <NavLink className="absolute left-3 top-3 border-b-2" to={`/`}>
        Back
      </NavLink>
      <div className={containerCentered}>
        {!running && !error && industry !== "" && state !== "" && (
          <button
            className={`absolute transform translate-x-72  h-12 py-0.5 px-2 mt-2 bg-gray-200 border-2 rounded-full hover:bg-gray-100`}
            onClick={generateCsv}
          >
            {generatingCsv ? (
              <img src="/loading.gif" className="w-5 h-5" />
            ) : (
              "Generate CSV from Database"
            )}
          </button>
        )}
        <div className="border-2 border-red-500 rounded-lg p-5 mt-6">
          <div className="flex flex-col items-center">
            <p className="text-red-500">WARNING!</p>
            <p className="text-red-500 w-64">
              Lead generation costs as much as <b>several hundred dollars</b>{" "}
              per click.
            </p>
          </div>
          <div className="flex">
            <label className="flex items-center">State</label>
            <select
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="ml-8 border-2 rounded-md p-1 pr-5 my-3 mx-3"
            >
              {states.map((state) => (
                <option value={state}>{state}</option>
              ))}
            </select>
          </div>
          <div className="flex">
            <label className="flex items-center">Industry</label>
            <input
              className="border-2 rounded-md p-0.5 pl-2 my-3 mx-3"
              placeholder="ex: landscapers"
              type="text"
              value={industry}
              name="industry"
              onChange={onIndustryChangeHandler}
            />
          </div>
          <button
            // type="submit"
            className={`${
              running || industry === "" || state === ""
                ? "cursor-not-allowed"
                : ""
            } py-0.5 px-2 mt-2 bg-gray-200 border-2 rounded-full hover:bg-gray-100`}
            onClick={onSubmitHandler}
            disabled={running || industry === "" || state === ""}
          >
            {running ? (
              <img src="/loading.gif" className="w-5 h-5" />
            ) : (
              "Generate Leads"
            )}
          </button>
          {error && <p className="text-red-500">{error}</p>}
        </div>
      </div>
    </>
  );
}

export default LeadGeneratingForm;

const states = [
  "Alaska",
  "Alabama",
  "Arkansas",
  "American Samoa",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  "North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Virgin Islands",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
];
