/* eslint-disable */
import { useState, useEffect } from "react";
import Props from "../../../store/props";
import ThumbnailSelectable from "../../../shared/ThumbnailSelectable";
import PhotoProps from "../types/photoProps";
import { boxContainer, boxContainerFull } from "../../../shared/styles";
import { useAtom } from "jotai";
import globalState from "../../../store";

interface AllPhotosProps {
  localGalleryNames: any;
  addSelectedPhotosToGallery: any;
  setSelectedPhotos: React.Dispatch<React.SetStateAction<any>>;
  selectedPhotos: any;
  handleSelect: any;
  rerenderAfterDeletePhoto: (item: PhotoProps) => void;
}

const CarouselAllPhotos: React.FC<AllPhotosProps> = ({
  localGalleryNames,
  addSelectedPhotosToGallery,

  setSelectedPhotos,
  selectedPhotos,
  handleSelect,
  rerenderAfterDeletePhoto,
}) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { gallery, hero_image, services } = globalStateAtom;

  const [localGallery, setLocalGalleryState] = useState<any>(gallery);

  const handleDelete = (photoUrl: string) => {
    // make placeholder array for added image
    const newGalleryArray = [...gallery];
    // find position of selected image
    const posOfImage = newGalleryArray.findIndex(
      (img) => img.photo === photoUrl
    );
    // splice that image out of the array
    newGalleryArray.splice(posOfImage, 1);

    setLocalGalleryState(newGalleryArray);
    setGlobalState({ ...globalStateAtom, gallery: newGalleryArray });
  };

  return (
    <div className="bg-white w-full m-12 rounded-lg p-6 z-50 overflow-x-scroll">
      {/* <h2>Upload a New Photo</h2>
            <div className={boxContainerFull}>
                Drop image here
                <input type="file" onChange={(e) => {
                    const uploadedImageUrl = e && e.target && e.target.files && URL.createObjectURL(e.target.files[0])
                    if(uploadedImageUrl) {
                        const uploadedImageObject = {
                            photo:uploadedImageUrl,
                            order:0,
                            gallery_name: "",
                            associated_service: null,
                            alt_tag: `${services[0]}-image`, // TODO: change this
                            // photo_pk: null
                        }
                        const newLocalGallery = [uploadedImageObject,...localGallery.map((pic:any) => {return {...pic,order: pic.order + 1}})]
                        setLocalGalleryState(newLocalGallery)
                        setGlobalState({...globalState,gallery:newLocalGallery})
                    }
                }} />
            </div> */}
      <div className="flex w-full overflow-x-scroll">
        {localGallery.length &&
          localGallery.map((photo: PhotoProps) => {
            return (
              <ThumbnailSelectable
                setSelectedPhotos={setSelectedPhotos}
                localGalleryNames={localGalleryNames}
                addSelectedPhotosToGallery={addSelectedPhotosToGallery}
                photo={photo}
                handleDelete={handleDelete}
                handleSelect={handleSelect}
                selectedPhotos={selectedPhotos}
                rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
                draggable={false}
              />
            );
          })}
      </div>
      {/* <h2 className="w-full mb-8">Facebook Photos</h2>
            {globalState.fbData && globalState.fbData.photos && globalState.fbData.photos.length &&
                globalState.fbData.photos.map((photo:string,idx:number) => {
                    const fbPhoto = {
                        alt_tag: services[0]['service-name'],// TODO: change this
                        archived: false,
                        associated_service: services[0]['service-name'],
                        gallery: null,
                        photo: photo,
                        landing_page: globalState.sitePk,
                        order:idx + gallery.length, // TODO: probably change this
                        uncropped_gallery_photo:null,
                        gallery_name: null,
                        selected: false
                    }
                    return(
                        <ThumbnailSelectable photo={fbPhoto} handleDelete={handleDelete} handleSelect={handleSelect} selectedPhotos={selectedPhotos} rerenderAfterDeletePhoto={rerenderAfterDeletePhoto} />
                    )
                })
            } */}
    </div>
  );
};

export default CarouselAllPhotos;
