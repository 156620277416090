/* eslint-disable */
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Props from "../../../store/props";
import { buttonSmall } from "../../../shared/styles";
import { useAtom } from "jotai";
import globalState from "../../../store";

// interface AddServiceButtonProps  {
//     localFaqs: any
//     setLocalFaqs: React.Dispatch<React.SetStateAction<ItemProps[]>>
// }

interface ItemProps {
  answer: string;
  "landing-page": number;
  order: number;
  question: string;
  draggableId: string;
}

const AddServiceButton: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const { faqs, sitePk } = globalStateAtom;

  const blankServiceObject: ItemProps = {
    answer: "",
    "landing-page": sitePk,
    order: faqs && faqs.length ? faqs.length : 0,
    question: "",
    draggableId: uuidv4(),
  };

  const addNewService = () => {
    // setLocalFaqs([...localFaqs, blankServiceObject])
    setGlobalState({
      ...globalStateAtom,
      faqs: [...globalStateAtom.faqs, blankServiceObject] as any,
    });
  };

  // useEffect(() => {
  //     setGlobalState({ ...globalStateAtom, faqs: globalStateAtom.faqs })
  // }, [localFaqs])

  return (
    <button onClick={addNewService} className={`${buttonSmall}  `}>
      Add FAQ
    </button>
  );
};

export default AddServiceButton;
