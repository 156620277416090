/* eslint-disable */
import { useAtom } from "jotai";
import {
  borderedInputWhite,
  boxContainerFull,
  label,
} from "../../../shared/styles";
import globalState from "../../../store";
import Props from "../../../store/props";

const GoogleAnalytics: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { google_analytics_tag, google_analytics_tag_body } = globalStateAtom;
  return (
    <div className="w-full flex flex-col">
      <h2 className="self-end -mr-6 -mb-6">
        CTA Class Text Class : cta_text_button{" "}
      </h2>
      <br />
      <h2 className="self-end -mr-6 -mb-6">Google Analytics Headers Tag</h2>
      <div className={`${boxContainerFull} ml-6`}>
        <label className={label}>
          HTML
          <textarea
            value={google_analytics_tag}
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                google_analytics_tag: e.target.value,
              })
            }
          />
        </label>
      </div>
      <h2 className="self-end -mr-6 -mb-6">Google Analytics Body Tag</h2>
      <div className={`${boxContainerFull} ml-6`}>
        <label className={label}>
          HTML
          <textarea
            value={google_analytics_tag_body}
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                google_analytics_tag_body: e.target.value,
              })
            }
          />
        </label>
      </div>
    </div>
  );
};

export default GoogleAnalytics;
