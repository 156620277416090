/* eslint-disable */
import React from "react";
import { buttonSmall } from "../../../../shared/styles";
import PostAlignActions from "./PostAlignActions";
import PostOrderActions from "./PostOrderActions";

const ManualOrderingTool = ({
  selectedPhotos,
  setSelectedPhotos,
  setLocalGallery,
  localGallery,
  manualOrderButtonShowing,
  setManualOrderButtonShowing,
  setManualOrderingTool,
  setLoading,
  setIs_show,
  context,
}: any) => {
  return (
    <div className="flex ">
      {selectedPhotos && (
        <PostOrderActions
          selectedPhotos={selectedPhotos}
          setSelectedPhotos={setSelectedPhotos}
          setLocalGallery={setLocalGallery}
          localGallery={localGallery}
          manualOrderButtonShowing={manualOrderButtonShowing}
          setManualOrderButtonShowing={setManualOrderButtonShowing}
          setManualOrderingTool={setManualOrderingTool}
          setLoading={setLoading}
          setIs_show={setIs_show}
          context={context}
        />
      )}
    </div>
  );
};

export default ManualOrderingTool;
