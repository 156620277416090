import { useAtom } from "jotai";
import { KEYWORD_COUNT } from "./HomepageKeywordFamily";
import KeywordFamily from "./KeywordFamily";
import globalStateStore from "../../../../store";
import { button } from "../../../../shared/styles";
import { IService } from "../../../../types/types";

interface ServiceKeywordFamilyProps {
  service: IService;
  // The 'any' type is used here because it is what is set where setActiveService is defined
  //eslint-disable-next-line
  setActiveService: React.Dispatch<any>;
  generate: (type: "short" | "long" | "longV2") => void;
}

const ServiceKeywordFamily: React.FC<ServiceKeywordFamilyProps> = ({
  service,
  setActiveService,
  generate,
}) => {
  const [globalState, setGlobalState] = useAtom(globalStateStore);

  const onKeywordsChange = (keywords: string[]) => {
    const newService = { ...service, keywords };

    setGlobalState({
      ...globalState,
      services: globalState.services.map((s: IService) =>
        s.order === service.order ? newService : s
      ),
    });
    setActiveService(newService);
  };

  const onPrimaryKeywordChange = (keyword: string) => {
    const newService = { ...service, primary_keyword: keyword };

    setGlobalState({
      ...globalState,
      services: globalState.services.map((s: IService) =>
        s.order === service.order ? newService : s
      ),
    });
    setActiveService(newService);
  };

  const generateServiceContent = () => {
    generate("short");
    generate("longV2");
  };

  return (
    <div className="flex flex-row w-12/12 rounded-lg bg-white p-6 m-12">
      <KeywordFamily
        onKeywordsChange={onKeywordsChange}
        onPrimaryKeywordChange={onPrimaryKeywordChange}
        keywords={service.keywords || []}
        primaryKeyword={service.primary_keyword || ""}
        keywordCount={KEYWORD_COUNT}
      />
      <button className={`${button}`} onClick={generateServiceContent}>
        Update Content
      </button>
    </div>
  );
};

export default ServiceKeywordFamily;
