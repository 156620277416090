/* eslint-disable */
import React from "react";
import { buttonSmall } from "../../../../shared/styles";
import PostAlignActions from "./PostAlignActions";

const MergePhotoTool = ({
  // setGlobalState,
  // globalState,
  onVerticalClickHandler,
  onHorizontalClickHandler,
  selectedPhotos,
  setSelectedPhotos,
  IsVerticalSelected,
  isHorizontalSelected,
  setLocalGallery,
  localGallery,
  setIs_show,
  setIsMergePhotoSelected,
  setLoading,
  setManualOrderButtonShowing,
}: any) => {
  const cancelMerge = () => {
    setIsMergePhotoSelected(false);
    setIs_show(true);
    setManualOrderButtonShowing(true);
    setSelectedPhotos({});
    onVerticalClickHandler(false);
    onHorizontalClickHandler(false);
  };

  return (
    <div className="flex ">
      <button
        className={`${buttonSmall}  right-3 bottom-3 z-10  py-3 px-6`}
        onClick={onVerticalClickHandler}
      >
        Vertical
      </button>
      <button
        className={`${buttonSmall}  right-3 bottom-3 z-10  py-3 px-6`}
        onClick={onHorizontalClickHandler}
      >
        Horizontal
      </button>
      <button
        className={`${buttonSmall}  right-3 bottom-3 z-10  py-3 px-6`}
        onClick={cancelMerge}
      >
        Cancel
      </button>
      {(IsVerticalSelected || isHorizontalSelected) && (
        <PostAlignActions
          onVerticalClickHandler={onVerticalClickHandler}
          onHorizontalClickHandler={onHorizontalClickHandler}
          selectedPhotos={selectedPhotos}
          setSelectedPhotos={setSelectedPhotos}
          IsVerticalSelected={IsVerticalSelected}
          isHorizontalSelected={isHorizontalSelected}
          // setGlobalState={setGlobalState}
          // globalState={globalState}
          setLocalGallery={setLocalGallery}
          localGallery={localGallery}
          setIs_show={setIs_show}
          setIsMergePhotoSelected={setIsMergePhotoSelected}
          setLoading={setLoading}
          setManualOrderButtonShowing={setManualOrderButtonShowing}
        />
      )}
    </div>
  );
};

export default MergePhotoTool;
