/* eslint-disable */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useReducer } from "react";
import { useAtom } from "jotai";
import { IoTrashOutline } from "react-icons/io5";
import { DropResult } from "react-beautiful-dnd";
import globalState from "../../store";
import SideNav from "../../shared/SideNav";

import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import CoverPhoto from "./components/CoverPhoto";
import AboutUsPhoto from "./components/AboutUsPhoto";
import PhotoProps from "./types/photoProps";
import GalleriesButtons from "./components/GalleriesButtons";
import PhotoGallery from "./components/PhotoGallery";

import DraggablePhotoGalleryContainer from "./components/DraggablePhotoGalleryContainer";
import {
  borderedInputWhite,
  buttonSmall,
  labelWrap,
  pageContainerWithOverflow,
} from "../../shared/styles";
import CarouselAllPhotos from "./components/CarouselAllPhotos";
import AllPhotosDraggable from "./components/AllPhotosDraggable";
import axios from "axios";
import { IGalleryName } from "../../types/types";

export default function PhotosPage() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  // const { about_upload, gallery, gallery_names } = globalStateAtom
  const { gallery, gallery_names, hero_gallery_photos } = globalStateAtom;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [showArchive, setShowArchive] = useState<boolean>(false);
  const [countArchivePulled, setCountArchivePulled] = useState<number>(0);
  const [archivePhotos, setArchivePhotos] = useState<any>([]);
  const [pullingArchive, setPullingArchive] = useState<boolean>(false);
  const [showMicroGalleryButtons, setShowMicroGalleryButtons] =
    useState<boolean>(true);
  const [showMicroServicePhotoButtons, setShowMicroServicePhotoButtons] =
    useState<boolean>(false);
  const [selectedPhotos, setSelectedPhotos] = useState<any>({});
  const [sideNavId, setSideNavId] = useState<string>(
    "/galleries/uncategorized"
  );
  const [activeGalleryName, setActiveGalleryName] =
    useState<string>("uncategorized");
  const [localGalleryNames, setLocalGalleryNames] =
    useState<any>(gallery_names);
  // this is so far only used when a gallery name is updated (see updateGalleryName function and localGalleryNames useEffect below)
  // and in the selectedPhotos useEffect just below this to ensure when a gallery is added the rest of the photos retain their gallery_name
  const [localGalleryPhotos, setLocalGalleryPhotos] = useState<any>(gallery);

  // re-render the contents when
  useEffect(() => {
    // // console.log(selectedPhotos, 'selectedpHotos useeffect')
    // // console.log(activeGalleryName, sideNavId, 'activeGal and sideNav')
    setContentTo(sideNavId, activeGalleryName);
    // to make sure when a new gallery is added the other photos retain their state
    setLocalGalleryPhotos(gallery);
  }, [selectedPhotos, showMicroGalleryButtons, showMicroServicePhotoButtons]);

  // useEffect(() => {
  //     selectAllPhotos('')
  // }, [])

  // useful hacky way to avoid any async/typescript issues with our globalStateAtom
  useEffect(() => {
    // when the localGalleryNames state changes (i.e. when a name is changed or a gallery is added), the global state will change as well
    // the local state ensures the items re-render and update live, while the global state change ensure the new data will be saved accurately
    if (globalStateAtom)
      setGlobalState({
        ...globalStateAtom,
        gallery_names: localGalleryNames,
        gallery: localGalleryPhotos,
      });
  }, [localGalleryNames, localGalleryPhotos]);

  const selectedItems = { ...selectedPhotos };
  // TODO: consider eliminating this orderCounter and giving facebook photos an 'order' property in another way, the filter/sort logic in the DraggablePhotoGallery component makes this unused/useless
  const [orderCounter, setOrderCounter] = useState(
    hero_gallery_photos?.length || 0
  );
  const handleSelect = (item: PhotoProps) => {
    // TODO: make sure we have logic here for different pages if need be (i.e. heroImages v. gallery pages)
    if (!selectedItems[item.photo]) {
      setOrderCounter(orderCounter + 1);
      selectedItems[item.photo] = { ...item, order: orderCounter };
    } else {
      setOrderCounter(orderCounter - 1);
      delete selectedItems[item.photo];
    }
    setSelectedPhotos(selectedItems);
    // // console.log('selectedItems', selectedItems)
    // // console.log('globalStateAtom', globalStateAtom)
  };
  const setShowButtons = () => {
    setShowMicroGalleryButtons(!showMicroGalleryButtons);
  };
  const setShowServiceButtons = () => {
    console.log("gobucks");
    setShowMicroServicePhotoButtons(!showMicroServicePhotoButtons);
    forceUpdate();
  };
  const selectAllPhotos = (filter: string) => {
    if (filter === "uncategorized") filter = "";
    const selectedPhotosObject = {};
    const gal = gallery.filter((photo) => photo.gallery_name === filter);
    // @ts-ignore
    gal.forEach((photo: any) => (selectedPhotosObject[photo.photo] = photo));

    const selectedPhotosObjectLength = Object.keys(selectedPhotos).length;
    const selectedPhotosLength = Object.keys(selectedPhotosObject).length;
    // if all photos are selected, deselect them
    if (
      selectedPhotosObjectLength === gal.length &&
      selectedPhotosLength === gal.length
    ) {
      setSelectedPhotos({});
      return;
    }
    setSelectedPhotos(selectedPhotosObject);
    // this handles a weird edge case where on first render of uncategorized the photos are never selected (for some reason)
    if (filter === "") setContentTo(`/galleries/uncategorized`, "");
  };

  const rerenderAfterDeletePhoto = (item: PhotoProps) => {
    // this updates selectedPhotos, which sets off the useEffect(()=>{},[selectedPhotos]) above and re-renders the page;
    // all we're doing here is ensuring a re-render after the photo is deleted
    setSelectedPhotos(selectedItems);
  };

  const triggerHeroRender = (images: any, completePhotoUrl: any) => {
    // // console.log('globalSTateAtom in HERO image',globalStateAtom)

    // const oldHeros = globalStateAtom.hero_gallery_photos
    // setGlobalState({
    //     ...globalStateAtom,
    //     hero_gallery_photos: images
    // })
    // // console.log('images',images)
    // // console.log('completePhotoUrl',completePhotoUrl)
    // // console.log('global lstate',globalStateAtom)

    setGlobalState({
      ...globalStateAtom,
      hero_gallery_photos: [
        ...images,
        {
          photo: completePhotoUrl,
          order: images.length,
          alt_tag: "",
        },
      ],
      cover_upload: completePhotoUrl,
      hero_image: completePhotoUrl,
    });
    // setContentTo(`/galleries/uncategorized`, '')
    setContentTo("/photos/heroImages", "");
  };

  const setAboutUsImage = (item: any) => {
    // // console.log('globalSTateAtom in about us image',globalStateAtom)

    setGlobalState({
      ...globalStateAtom,
      about_upload: item,
    });
    // setContentTo(`/galleries/uncategorized`, '')
    setContentTo("/photos/heroImages", "");
  };

  const addPhotoToGallery = (e: any, photo: any, gallery: any) => {
    let galleryName = e.target.innerHTML;
    if (galleryName === "Uncategorize") galleryName = "";
    // const realGalleryName = findGalleryName(galleryName)
    const selectedItems = { ...selectedPhotos };

    // Adding selected photos (which have the new gallery names already thanks to addSelectedPhotosToGallery in index) to gallery
    const newGallery: PhotoProps[] = [...globalStateAtom.gallery];

    for (let i = 0; i < newGallery.length; i++) {
      if (
        newGallery[i] &&
        newGallery[i].photo &&
        newGallery[i].photo == photo.photo
      ) {
        newGallery[i].gallery_name = galleryName;
      }
    }

    // remove photo from selected
    if (photo && photo.photo) {
      delete selectedItems[photo.photo];
    }
    // // console.log(selectedItems, 'selectedItems')
    setGlobalState({ ...globalStateAtom, gallery: newGallery });
    setSelectedPhotos({ ...selectedItems });
  };

  const addSelectedPhotosToGallery: React.MouseEventHandler<
    HTMLButtonElement
  > = (item: any) => {
    // get the text of the button to determine which gallery to add the photo to
    let galleryName = item.target.innerHTML;

    if (galleryName === "Uncategorize") galleryName = "";
    if (galleryName === "Delete") {
      // // console.log('ruh roh')
      setSelectedPhotos({});
      return;
    }

    for (let i = 0; i < Object.keys(selectedItems).length; i++) {
      const item: any = Object.keys(selectedItems)[i];
      selectedItems[item].gallery_name = galleryName;
    }

    setSelectedPhotos({});
  };

  const addNewGallery = () => {
    // modal with a form to type in the gallery name
    const galleryName = prompt("Enter the gallery name: ");
    // setNewGallery(e.target.value)

    if (galleryName) {
      for (let i = 0; i < gallery_names.length; i++) {
        if (galleryName === gallery_names[i]["gallery-name"]) {
          alert(
            "Please make sure the gallery does not have the same name as another gallery."
          );
          return;
        }
      }
      const newGalleryNames: IGalleryName[] = [...gallery_names];
      newGalleryNames.push({
        "gallery-name": galleryName,
        "gallery-subtitle": "Our Best Work",
        gallery_cc_widget: "",
      });
      setLocalGalleryNames(newGalleryNames);
      setContentTo(`/galleries/uncategorized`, "");
      // this also re-renders the page so the buttons update (see the useEffect at the top of the file)
      setSelectedPhotos({});
    }
  };

  const deleteGallery = (galleryName: any) => {
    const confirmation = prompt(
      `Are you sure you want to delete the ${galleryName} gallery?`,
      "yes"
    );

    if (confirmation) {
      const newGalleryNames: any[] = [...gallery_names];
      const posOfGalleryName = newGalleryNames.findIndex(
        (galName) => galName["gallery-name"] === galleryName
      );
      newGalleryNames.splice(posOfGalleryName, 1);
      setLocalGalleryNames(newGalleryNames);
    }
  };

  const pullArchivedPhotos = async () => {
    setPullingArchive(true);
    try {
      const photos = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/getArchivedPhotos/${globalStateAtom.sitePk}`
      );
      const newGallery = [...gallery, ...photos.data.message];
      setGlobalState({ ...globalStateAtom, gallery: newGallery });
      setArchivePhotos(photos.data);
      setCountArchivePulled(photos?.data?.message?.length);
    } catch (e) {
      console.log("error");
    }
    setPullingArchive(false);
  };

  const updateGalleryName = (galleryName: any) => {
    const newGalleryNames: any[] = [...gallery_names];
    const posOfGalleryName = newGalleryNames.findIndex(
      (galName) => galName["gallery-name"] === activeGalleryName
    );
    // update all the individual photos within that gallery
    const galleryPhotosWithUpdatedName: any[] = [...gallery];
    for (let i = 0; i < galleryPhotosWithUpdatedName.length; i++) {
      if (
        galleryPhotosWithUpdatedName[i].gallery_name ===
        newGalleryNames[posOfGalleryName]["gallery-name"]
      ) {
        galleryPhotosWithUpdatedName[i].gallery_name = galleryName;
      }
    }
    // update the local state so the useEffect above is triggered and the photos in globalState.gallery match the newly changed name
    setLocalGalleryPhotos(galleryPhotosWithUpdatedName);
    newGalleryNames[posOfGalleryName]["gallery-name"] = galleryName;
    setLocalGalleryNames(newGalleryNames);
    setActiveGalleryName(galleryName);
    // update the title of the currently showing gallery so it changes instantly
    setContentTo(`/galleries/${galleryName}`, galleryName);
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (destination.index === source.index) return null;
    // // console.log(destination, source)

    // Move the item within the list
    // Start by making a new list without the dragged item
    const newList = localGalleryNames.filter(
      (_: any, idx: number) => idx !== source.index
    );

    // Then insert the item at the right location
    newList.splice(destination.index, 0, localGalleryNames[source.index]);

    // Update the list
    setLocalGalleryNames(newList);
  };

  const [content, setContent] = useState(
    <>
      <button
        className={`${buttonSmall} fixed right-3 bottom-3 z-10 mt-36 py-3 px-6`}
        onClick={() => selectAllPhotos("")}
      >
        Select All
      </button>
      <div className="flex flex-col">
        <GalleriesButtons
          localGalleryNames={localGalleryNames}
          addSelectedPhotosToGallery={addSelectedPhotosToGallery}
          selectedPhotos={selectedPhotos}
          setSelectedPhotos={setSelectedPhotos}
        />
        <div
          className={`${
            gallery_names && gallery_names.length > 4 ? "mt-40" : "mt-12"
          }`}
        >
          {/* <PhotoGallery
                        setGlobalState={setGlobalState}
                        globalState={globalStateAtom}
                        setSelectedPhotos={setSelectedPhotos}
                        selectedPhotos={selectedPhotos}
                        handleSelect={handleSelect}
                        filter=""
                        rerenderAfterDeletePhoto={rerenderAfterDeletePhoto} addSelectedPhotosToGallery={undefined} localGalleryNames={undefined}                    /> */}
          <PhotoGallery
            showMicroGalleryButtons={showMicroGalleryButtons}
            showMicroServicePhotoButtons={showMicroServicePhotoButtons}
            addPhotoToGallery={addPhotoToGallery}
            addSelectedPhotosToGallery={addSelectedPhotosToGallery}
            localGalleryNames={localGalleryNames}
            // setGlobalState={setGlobalState}
            // globalState={globalStateAtom}
            setSelectedPhotos={setSelectedPhotos}
            selectedPhotos={selectedPhotos}
            handleSelect={handleSelect}
            filter=""
            rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
          />
        </div>
      </div>
    </>
  );
  const setContentTo = (itemId: string, selectedGallery: string) => {
    switch (itemId) {
      case "/photos/heroImages":
        setContent(
          <>
            <CoverPhoto
              triggerHeroRender={triggerHeroRender}
              // setGlobalState={setGlobalState}
              // globalState={globalStateAtom}
              selectedPhotos={selectedPhotos}
              handleSelect={handleSelect}
              setSelectedPhotos={setSelectedPhotos}
              rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
            />
            <CarouselAllPhotos
              // setGlobalState={setGlobalState}
              // globalState={globalStateAtom}
              setSelectedPhotos={setSelectedPhotos}
              selectedPhotos={selectedPhotos}
              handleSelect={handleSelect}
              rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
              localGalleryNames={localGalleryNames}
              addSelectedPhotosToGallery={addSelectedPhotosToGallery}
            />
            <AboutUsPhoto
              setAboutUsImage={setAboutUsImage}
              setGlobalState={setGlobalState}
              globalState={globalStateAtom}
              // photo={about_upload}
            />
          </>
        );
        setSideNavId("/photos/heroImages");
        break;
      case "/photos/allPhotos":
        setContent(
          <>
            {/* passing in setLocalGalleryPhotos in the setParentLocalGallery prop so when a photo is uploaded on the AllPhotos page, the local state up here will change and the photo won't get lost when a new gallery is added */}
            <AllPhotosDraggable
              // showMicroGalleryButtons={showMicroGalleryButtons}
              setGlobalState={setGlobalState}
              globalState={globalStateAtom}
              setSelectedPhotos={setSelectedPhotos}
              selectedPhotos={selectedPhotos}
              setParentLocalGallery={setLocalGalleryPhotos}
              handleSelect={handleSelect}
              filter={selectedGallery}
              rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
            />
          </>
        );
        setSideNavId("/photos/allPhotos");
        break;
      case "/galleries/uncategorized":
        setContent(
          <>
            <button
              className={`${buttonSmall} fixed right-3 bottom-3 z-10 mt-36 py-3 px-6`}
              onClick={() => selectAllPhotos(selectedGallery)}
            >
              Select All
            </button>
            <GalleriesButtons
              setGlobalState={setGlobalState}
              globalState={globalStateAtom}
              localGalleryNames={localGalleryNames}
              addSelectedPhotosToGallery={addSelectedPhotosToGallery}
              selectedPhotos={selectedPhotos}
              setSelectedPhotos={setSelectedPhotos}
            />
            <div className="flex flex-col">
              <div
                className={`${gallery_names.length > 4 ? "mt-40" : "mt-12"}`}
              >
                <PhotoGallery
                  showMicroGalleryButtons={showMicroGalleryButtons}
                  showMicroServicePhotoButtons={showMicroServicePhotoButtons}
                  addPhotoToGallery={addPhotoToGallery}
                  addSelectedPhotosToGallery={addSelectedPhotosToGallery}
                  localGalleryNames={localGalleryNames}
                  // setGlobalState={setGlobalState}
                  // globalState={globalStateAtom}
                  setSelectedPhotos={setSelectedPhotos}
                  selectedPhotos={selectedPhotos}
                  handleSelect={handleSelect}
                  filter=""
                  rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
                />
              </div>
            </div>
          </>
        );
        setSideNavId("/galleries/uncategorized");
        break;
      case "/galleries/add":
        addNewGallery();
        break;
      case `/galleries/${selectedGallery}`:
        setContent(
          <>
            <button
              className={`${buttonSmall} fixed right-3 bottom-3 z-10 mt-36 py-3 px-6`}
              onClick={() => selectAllPhotos(selectedGallery)}
            >
              Select All
            </button>
            <div className="flex flex-col">
              <GalleriesButtons
                localGalleryNames={localGalleryNames}
                addSelectedPhotosToGallery={addSelectedPhotosToGallery}
                selectedPhotos={selectedPhotos}
                setSelectedPhotos={setSelectedPhotos}
              />
              <button onClick={() => selectAllPhotos(activeGalleryName)}>
                Select All
              </button>
              <DraggablePhotoGalleryContainer
                addSelectedPhotosToGallery={addSelectedPhotosToGallery}
                localGalleryNames={localGalleryNames}
                addPhotoToGallery={addPhotoToGallery}
                setGlobalState={setGlobalState}
                globalState={globalStateAtom}
                setSelectedPhotos={setSelectedPhotos}
                selectedPhotos={selectedPhotos}
                setParentLocalGallery={setLocalGalleryPhotos}
                handleSelect={handleSelect}
                filter={selectedGallery}
                rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
              />
            </div>
          </>
        );
        setSideNavId(`/galleries/${activeGalleryName}`);
        break;
      default:
        break;
    }
  };

  const dedupe = () => {
    const photoHash = {} as any;
    const newGallery = [] as any;
    globalStateAtom.gallery.map((photo) => {
      if (!photoHash[photo.photo]) {
        console.log("go bucks");
        photoHash[photo.photo] = photo;
        newGallery.push(photo);
      }
    });
    setGlobalState({ ...globalStateAtom, gallery: newGallery });
  };

  const handleCCWidgetChange = (value: string, galleryName: string) => {
    const newGalleries = gallery_names.map((gallery) => {
      if (gallery["gallery-name"] !== galleryName) {
        return gallery;
      }
      gallery.gallery_cc_widget = value;
      return gallery;
    });
    setGlobalState({
      ...globalStateAtom,
      gallery_names: newGalleries as any,
    });
  };

  return (
    <div className={pageContainerWithOverflow}>
      {/* <Logo /> */}
      <div className={`${buttonSmall}`} onClick={() => dedupe()}>
        DEDUPE Button
      </div>

      <div
        className="flex"
        onClick={() => {
          setShowArchive(!showArchive);
        }}
      >
        Archived
      </div>
      {showArchive ? (
        <div>
          <div className="bg-green-500" onClick={pullArchivedPhotos}>
            pull archived {pullingArchive ? "PULLING" : ""}{" "}
          </div>
          <div>Pulled photo count - {countArchivePulled} </div>
          <PhotoGallery
            showMicroGalleryButtons={showMicroGalleryButtons}
            showMicroServicePhotoButtons={showMicroServicePhotoButtons}
            addPhotoToGallery={addPhotoToGallery}
            addSelectedPhotosToGallery={addSelectedPhotosToGallery}
            localGalleryNames={localGalleryNames}
            // setGlobalState={setGlobalState}
            // globalState={globalStateAtom}
            setSelectedPhotos={setSelectedPhotos}
            selectedPhotos={selectedPhotos}
            handleSelect={handleSelect}
            filter=""
            rerenderAfterDeletePhoto={rerenderAfterDeletePhoto}
            archives={showArchive}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="flex">
        <div>
          <SideNav
            currentGalleryNames={globalStateAtom.gallery_names}
            updateGalleryName={updateGalleryName}
            onDragEnd={onDragEnd}
            activeItemId="/galleries/uncategorized"
            onSelect={({ itemId }) => {
              const selectedGallery =
                itemId.split("/")[1] === "galleries"
                  ? itemId.split("/").length === 4
                    ? `${itemId.split("/")[2]}/${itemId.split("/")[3]}`
                    : itemId.split("/")[2]
                  : "";
              setContentTo(itemId, selectedGallery && selectedGallery);
              if (selectedGallery !== "add") setSideNavId(itemId);
              if (selectedGallery !== "add")
                setActiveGalleryName(selectedGallery && selectedGallery);
            }}
            items={[
              {
                title: "Photos",
                itemId: "/photos",
                disabled: true,
                // icon is optional
                // elemBefore: () => <p>icon</p>,
                subNav: [
                  {
                    title: "Hero Images",
                    count: globalStateAtom.hero_gallery_photos.length,
                    itemId: "/photos/heroImages",
                  },
                  {
                    title: globalStateAtom.gallery_all_title,
                    count: globalStateAtom.gallery.length,
                    itemId: "/photos/allPhotos",
                  },
                ],
              },
              {
                title: "Galleries",
                itemId: "/galleries",
                // elemBefore: () => <p>icon</p>,
                subNav: [
                  {
                    title: "Uncategorized",
                    itemId: "/galleries/uncategorized",
                  },
                  ...localGalleryNames.map((gallery: any) => {
                    return {
                      title: gallery["gallery-name"],
                      itemId: `/galleries/${gallery["gallery-name"]}`,
                      // eslint-disable-next-line
                      elemBefore: () => (
                        <IoTrashOutline
                          opacity="80%"
                          size={20}
                          onClick={() => {
                            deleteGallery(gallery["gallery-name"]);
                          }}
                        />
                      ),
                    };
                  }),
                  {
                    title: "+ Add Gallery",
                    itemId: "/galleries/add",
                  },
                ],
              },
            ]}
          />
          <div className="mt-12 bg-white py-2 px-4 rounded-md">
            <label className={`${labelWrap} -ml-3 pl-6 w-full`}>
              Link text:
              <input
                className={borderedInputWhite}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    work_link_text: e.target.value,
                  })
                }
                value={globalStateAtom.work_link_text}
              />
            </label>
          </div>
          {activeGalleryName !== "uncategorized" && activeGalleryName && (
            <div className="mt-12 bg-white py-2 px-4 rounded-md">
              <label className={`${labelWrap} -ml-3 pl-6 w-full`}>
                CompanyCam widget code:
                <textarea
                  className={borderedInputWhite}
                  onChange={(e) =>
                    handleCCWidgetChange(e.target.value, activeGalleryName)
                  }
                  value={(() => {
                    const gallery = globalStateAtom.gallery_names.find(
                      (gallery) => gallery["gallery-name"] === activeGalleryName
                    );
                    return gallery?.gallery_cc_widget
                      ? gallery.gallery_cc_widget
                      : "";
                  })()}
                  cols={10}
                  rows={5}
                ></textarea>
              </label>
            </div>
          )}
          {sideNavId === "/photos/allPhotos" && (
            <div className="mt-12 bg-white py-2 px-4 rounded-md">
              <label className={`${labelWrap} -ml-3 pl-6 w-full`}>
                CompanyCam widget code:
                <textarea
                  className={borderedInputWhite}
                  onChange={(e) =>
                    setGlobalState({
                      ...globalStateAtom,
                      all_photos_cc_widget: e.target.value,
                    })
                  }
                  value={globalStateAtom.all_photos_cc_widget}
                  cols={10}
                  rows={5}
                ></textarea>
              </label>
            </div>
          )}
        </div>
        <div className="w-9/12 ml-8">{content}</div>
        <button
          className={`${buttonSmall} fixed right-40 bottom-3 z-10 mt-36 py-3 px-6`}
          onClick={setShowButtons}
        >
          {!showMicroGalleryButtons
            ? "Show buttons under photos"
            : "Hide buttons under photos"}
        </button>
        <button
          className={`${buttonSmall} fixed right-80 bottom-3 z-10 mt-36 py-3 px-6`}
          onClick={setShowServiceButtons}
        >
          {!showMicroServicePhotoButtons
            ? "Show service buttons under photos"
            : "Hide service buttons under photos"}
        </button>
      </div>
    </div>
  );
}
