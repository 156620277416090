/* eslint-disable */
export const videoTypes = [
  "mp4",
  "mov",
  "wmv",
  "flv",
  "avi",
  "webm",
  "quicktime",
];

export const removeBgTypes = [
  "jpeg",
  "jpg",
  "png",
  "webp",
]

export const getMediaTypeFromURL = (url: string) => {
  const urlSplit = url.split(".");
  return urlSplit[urlSplit.length - 1];
}

export const getMediaType = (media: any) => {
  if (media.photo && media.photo.length > 0) {
    const mediaSrc = media.photo.toLowerCase();
    return getMediaTypeFromURL(mediaSrc)
  }
  return "";
};
