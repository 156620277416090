/* eslint-disable */
import { useAtom } from "jotai";
import { boxContainerFull } from "../../../shared/styles";
import globalState from "../../../store";
import Props from "../../../store/props";
import Payment from "./Payment";

const Payments: React.FC = () => {

  const [globalStateAtom, setGlobalState] = useAtom(globalState);

  const handleButtonClear = () => {
    setGlobalState({
      ...globalStateAtom,
      payments: [],
    })
  }

  return (
    <div className="w-full flex flex-col">
      <h2 className="self-end -mr-6 -mb-6">Payments</h2>
      <div className={`${boxContainerFull} ml-2`}>
        <div className="flex items-center justify-between mb-4">
          <button 
          className="px-5 py-2 m-3 rounded-full border-2 border-gray-500 bg-gray-500 text-white"
          onClick={handleButtonClear}
          >
            Clear all
          </button>
        </div>
        {[0, 1, 2].map((item) => {
          return (
            <Payment
              key={`${item}`}
              // globalState={globalState}
              // setGlobalState={setGlobalState}
              index={item}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Payments;
