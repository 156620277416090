/* eslint-disable */
import axios from "axios";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const get3StepsQuotes = async (
  companyName: string,
  companyType: string
) => {
  // get quotes from API /generate3StepQuotes/:companyName/:companyType with axios post call to REACT_APP_NODE_SERVER_URL with companyName and companyType
  const response = await axios.get(
    `${process.env.REACT_APP_NODE_SERVER_URL}/generate3StepQuotes/${companyName}/${companyType}`
  );
  return response.data;
};

export const extractThreeStepTileAndSubtitle = (threeStepResponse: string) => {
  const threeStepHeadingArray = [
    "1.Contact Us",
    "2.Share More Info",
    "3.Receive a Quote",
  ];
  // extract lines from threeStepResponse
  const threeStepLines = threeStepResponse.split("\n").join("");
  // extract threeStepTile and threeStepSubtitle for each heading  from threeStepLines by splitting from threeStepHeadingArray
  const threeStepTileAndSubtitle = threeStepHeadingArray.map(
    (heading, index) => {
      if (index < 2) {
        const temp = threeStepLines?.split(heading)[1];
        const threeStepSubtitle =
          temp?.split(threeStepHeadingArray[index + 1])[0] || "";
        return {
          heading,
          threeStepSubtitle,
        };
      } else {
        return {
          heading,
          threeStepSubtitle: threeStepLines.split(heading)[1],
        };
      }
    }
  );
  console.log(threeStepTileAndSubtitle, "threeStepTileAndSubtitle");

  // map threeStepHeadingArray and threeStepLinesFiltered to an object with three_step_{index}_header and three_step_{index}_subtitle
  const completeResponse = threeStepHeadingArray.map((heading, index) => {
    index = index + 1;
    return {
      [`three_step_${index}_header`]: heading.split(".")[1],
      [`three_step_${index}_subtitle`]:
        threeStepTileAndSubtitle[index - 1]["threeStepSubtitle"],
    };
  });
  return completeResponse;
};
