/* eslint-disable */
import { v4 as uuidv4 } from "uuid";
import { buttonSmall } from "../../../shared/styles";
import Props from "../../../store/props";

const AddReviewButton: React.FC<Props> = ({ setGlobalState, globalState }) => {
  const { products } = globalState;

  const blankReviewObject = {
    title: "",
    subtitle: "",
    photo: "",
    link: "",
    draggableId: "",
    order: products ? products.length : 0,
  };

  const addNewReview = () => {
    const uuid = uuidv4();
    setGlobalState({
      ...globalState,
      products: [...products, { ...blankReviewObject, draggableId: uuid }],
    });
    // // console.log('new review added')
  };

  return (
    <button onClick={addNewReview} className={`${buttonSmall} position`}>
      Add Teammate
    </button>
  );
};

export default AddReviewButton;
